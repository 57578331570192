import * as React from "react";
import dayjs from "dayjs";
import { Toggles } from "./Toggles";

interface IProps {
  Timestamp: Date;
  Toggles: Toggles;
}

class Timestamp extends React.Component<IProps> {
  public render() {
    if (this.props.Timestamp === undefined) {
      return <span className="Timestamp Empty">-</span>;
    } else {
      var schmeeb: Date = this.props.Timestamp as Date;
      var schmeeby = dayjs(schmeeb);
      var longstring = this.props.Timestamp.toString();
      if (this.props.Toggles.ShowMilliseconds === true) {
        var squishystring = schmeeby.format("YYYY-MM-DD HH:mm:ss.SSS");
        return (
          <span className="Timestamp" title={longstring}>
            {squishystring}
          </span>
        );
      } else {
        var schmeebstring = schmeeby.format("YYYY-MM-DD HH:mm:ss");
        return (
          <span className="SquishedTimestamp" title={longstring}>
            {schmeebstring}
          </span>
        );
      }
    }
  }
}

export default Timestamp;
