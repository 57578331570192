import { PagerOptions } from "../Structs/Interfaces/PagerOptions";

interface MondrianPagerProps {
  Options: PagerOptions;
  Pages: Number;
  Page: Number;
  SetPage: (PG: Number) => void;
}

export const MondrianPager = (props: MondrianPagerProps) => {
  return (
    <div className="Mondrian__Grid_Pagers">
      <div className="Mondrian__Grid_Pagers_Header">Page: </div>
    </div>
  );
};
