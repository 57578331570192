import * as React from "react";

import { Logging, LoggingLevel } from "./Logging";
import { DataAccess, MODE, User } from "./data/DataAccess";

import TaskList from "./models/TaskList";
import TaskListGroup from "./models/TaskListGroup";
import ModeStruct from "./models/ModeStruct";
import SPOUploadLocation from "./models/SPOUploadLocation";
import FullPageLoader from "./components/FullPageLoader";

import "./assets/css/COLOURS/ColourValues.css";
import "./assets/css/SPOWebUIOnPrem.css";
import "./assets/css/COLOURS/Colours.css";
import "./assets/css/STRUCT/Panels.css";
import "./assets/css/GridSupplementary.css";
import "./assets/css/COLOURS/Themes.css";
import "./assets/css/Components/Components_MultiUse.css";
import "./assets/css/Components/Components_SingleUse.css";
import "./assets/css/Components/CookieMonsterControls.css";
import "./assets/css/Components/Header_Charms.css";
import "./assets/css/Components/Mosaic.css";

import { IMsalContext } from "@azure/msal-react";
import {
  EndSessionRequest,
  PublicClientApplication,
} from "@azure/msal-browser";
import ErrorMessage from "./models/ErrorMessage";
import StatusMessage from "./models/StatusMessage";
import { DB_UserResponse } from "./models/DB_UserResponse";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Embargo from "./models/Embargo";
import Notice from "./models/Notice";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import UGEAF_LF from "./components/UGEAF_LF/UGEAF_LF";
import PGEAF_LF from "./components/PGEAF_LF/PGEAF_LF";
import Clearing_LF from "./components/Clearing_LF/Clearing_LF";
import EAF_LF from "./components/EAF_LF/EAF_LF";
import { SearchResultWrapper } from "./components/SearchResultWrapper";
import { TaskListWrapper } from "./components/TaskListWrapper";
import ECF_LF from "./components/ECF_LF/ECF_LF";
import ESF_LF from "./components/ESF_LF/ESF_LF";
import Admin_LF from "./components/Admin_LF/Admin_LF";
import WorkflowAssignmentGroupEditor from "./integrations/WorkflowAssignmentGroupEditor/WorkflowAssignmentGroupEditor";
import ComplianceHomepage from "./components/Compliance_LF/Screens/ComplianceHomepage";
import { UploadManager } from "./integrations/SPUploadManager/UploadManager";
import { ESFWrapper } from "./components/summaries/ESFWrapper";
import { PGEAFWrapper } from "./components/summaries/PGEAFWrapper";
import { UGEAFWrapper } from "./components/summaries/UGEAFWrapper";
import { MainBracketWrapper } from "./components/MainBracketWrapper";
import { InvarTopology } from "./integrations/Invar/InvarTopology";
import {
  InvarConveyance,
  InvarConveyanceLoader,
} from "./integrations/Invar/InvarConveyance";
import { InvarMonitorAll } from "./integrations/Invar/InvarMonitorAll";
import { InvarMonitorOne } from "./integrations/Invar/InvarMonitorOne";
import { SingleFileMetaEditor } from "./utilities/SingleFileMetaEditor";
import { AutomatedMailViewer } from "./components/AutomatedMailViewer";
import IfPermitted from "./components/IfPermitted";
import UCASDAprintreq from "./utilities/UCASDAprintreq";
import HelpArticle from "./utilities/HelpArticle";
import AOS2 from "./components/AOS2/Screens/AOS2";
import AOS2_UG_APPLICANT from "./components/AOS2/Screens/AOS2_UG_APPLICANT";
import AOS2_UG_RECENT from "./components/AOS2/Screens/AOS2_UG_RECENT";
import AOS2_UG_ARCHIVE from "./components/AOS2/Screens/AOS2_UG_ARCHIVE";
import AOS2_SEARCH from "./components/AOS2/Screens/AOS2_SEARCH";
import AOS2_PG_APPLICANT from "./components/AOS2/Screens/AOS2_PG_APPLICANT";
import AOS2_PG_ARCHIVE from "./components/AOS2/Screens/AOS2_PG_ARCHIVE";
import AOS2_PG_RECENT from "./components/AOS2/Screens/AOS2_PG_RECENT";
import AOS2_UG_UNSUBMITTED from "./components/AOS2/Screens/AOS2_UG_UNSUBMITTED";
import { GetAgentData } from "./components/AOS2/Components/AgentSidebar";
import { WorkflowViewInfoPathWrapper } from "./components/WorkFlowViewInfoPathWrapper";
import { TimetableSearchWrapper } from "./utilities/TimetableSearchWrapper";
import { InvarDataAccess } from "./data/InvarDataAccess";
import ESFAllEAFDocs from "./components/ESFAllEAFDocs";
import ReviewTaskAssignees from "./utilities/ReviewTaskAssignees";
import { SURFACE, SurfaceAttributes } from "./Providers/SurfaceProvider";
import { UserProvider } from "./Providers/UserProvider";

import AOS2_PG_UNSUBMITTED from "./components/AOS2/Screens/AOS2_PG_UNSUBMITTED";
import AOS2_Linked_Student_Withdrawals from "./components/AOS2/AOS2_Linked_Student_Withdrawals";
import AOS2_ADMIN_Agent_Access from "./components/AOS2/AOS2_ADMIN_Agent_Access";
import AOS_MNAE from "./components/AOS2/MNAE/MNAE";
import { ComplianceAuditFlagger } from "./components/Compliance_LF/Screens/ComplianceAuditFlagger";
import { ApiProvider } from "./Providers/ApiProvider";
import { GDPRSurface } from "./components/Compliance_LF/Screens/GDPRSurface";
import { HomeOfficeAuditSurface } from "./components/Compliance_LF/Screens/HomeOfficeAuditSurface";
import { InvarAuditor } from "./components/Invar/InvarAuditor";
import { PersonType } from "./components/Compliance_LF/Components/AuditFlagEditor/AuditFlagEditor";
import { FunctionProvider } from "./Providers/FunctionProvider";
import { ComplianceAuditorScreen } from "./components/Compliance_LF/Screens/ComplianceAuditorScreen";
import { ECF } from "./components/summaries/ECF";
import { ECFUploadDocsReport } from "./components/ECF_LF/ECFUploadDocsReport";
import { ClearingApplicantReview } from "./components/Clearing_LF/Screens/ClearingApplicantReview";
import WhatsNew from "./utilities/WhatsNew";
import UserGuide from "./utilities/UserGuide";
import OutlookHelp from "./utilities/OutlookHelp";
import KnownIssues from "./utilities/KnownIssues";
import { UserAccessChecker } from "./integrations/UserAccessChecker/UserAccessChecker";
import { ComplianceAttendance } from "./components/Compliance_LF/Screens/ComplianceAttendance";
import { DocumentViewer } from "./components/DocumentViewer";
import { WorkflowSummary } from "./integrations/WorkflowSummary/WorkflowSummary";

interface IProps {
  MODE_LOCAL: boolean;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
  Surface: SurfaceAttributes;
  Logger: Logging;
  MSAL: IMsalContext;
  PCA: PublicClientApplication;
  APIAccessToken: string;
  GraphAccessToken: string;
  ActiveAccount: any;
  URL: string;
  Imp: string;
  HiVis: boolean;
  SetHiVis: (val: boolean) => void;
  ThemeName: string;
  SetThemeName: (input: string) => void;
}

interface IState {
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  URLRoot: string;
  URLParams: string;

  ErrorMessages: ErrorMessage[];
  StatusMessages: StatusMessage[];
  Mode: string[];
  _Embargoes: Embargo[];
  _Notices: Notice[];

  MSAL: IMsalContext;
  PCA: PublicClientApplication;
  TailoredLogoutRequest: EndSessionRequest;
  APIAccessToken: string;
  GraphAccessToken: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  AuthedUser: User;
  ViewAsUser: User;
  ViewAsNotFound: boolean;
  ViewAsAll: boolean;
  AuthedUserGroups: string[];
  ViewAsUserGroups: string[];
  Header_AuthedCharmData: DB_UserResponse;
  Header_ViewAsCharmData: DB_UserResponse;

  Modes: ModeStruct[];
  SPOUploadLocations: SPOUploadLocation[];

  da: DataAccess;
  IDA: InvarDataAccess;
  SearchTerm: string;
  TaskListGroups: TaskListGroup[] | undefined;
  RenderedTaskList: string | undefined;
  Refresher: string;
}

class SPOWebUIOnPrem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    var initError: ErrorMessage = undefined;

    var _URLParts = document.URL.split("?");
    var _URL_Parameters = "";
    if (_URLParts.length === 2) {
      _URL_Parameters = _URLParts[1];
    } else if (_URLParts.length > 2) {
      initError = {
        Reference: "SPOWebUIOnPrem()",
        Message: "URL seems malformed! [ " + document.URL + " ]",
      };
    }

    console.log(props.ThemeName);

    let currentMode = MODE.LOCAL;
    if (props.MODE_DEVELOPMENT) currentMode = MODE.DEVELOPMENT;
    if (props.MODE_STAGING) currentMode = MODE.STAGING;
    if (props.MODE_LIVE) currentMode = MODE.PRODUCTION;

    const init_da = new DataAccess(
      props.Logger,
      props.MSAL.instance,
      currentMode,
      props.Surface,
    );

    //  (async () => {
    //    await init_da.logAccess(props.Surface.Title);
    //  })();

    const _ida = new InvarDataAccess(
      this.props.Logger,
      this.props.MSAL.instance,
      props.MODE_LOCAL
        ? MODE.LOCAL
        : props.MODE_DEVELOPMENT
          ? MODE.DEVELOPMENT
          : props.MODE_STAGING
            ? MODE.STAGING
            : MODE.PRODUCTION,
    );

    const _TailoredLogoutRequest = {
      account: props.ActiveAccount,
      postLogoutRedirectUri: props.URL,
    };

    const _MODE = GetModeFromString(props.URL);

    this.state = {
      Environment: props.MODE_LOCAL
        ? "LOCAL"
        : props.MODE_DEVELOPMENT
          ? "DEV"
          : props.MODE_STAGING
            ? "TEST"
            : props.MODE_LIVE
              ? "LIVE"
              : "",
      MSAL: props.MSAL,
      PCA: props.PCA,
      Alias: undefined,
      AliasChecked: false,
      TailoredLogoutRequest: _TailoredLogoutRequest,
      APIAccessToken: props.APIAccessToken,
      GraphAccessToken: props.GraphAccessToken,
      AuthedUser: undefined,
      ViewAsUser: undefined,
      ViewAsNotFound: false,
      ViewAsAll: false,
      AuthedUserGroups: [],
      ViewAsUserGroups: [],
      Header_AuthedCharmData: undefined,
      Header_ViewAsCharmData: undefined,
      URLRoot: props.URL,
      URLParams: _URL_Parameters,
      ErrorMessages: initError ? [initError] : [],
      StatusMessages: [],
      SearchTerm: "",
      Mode: _MODE,
      da: init_da,
      IDA: _ida,
      _Embargoes: [],
      _Notices: [],
      TaskListGroups: undefined,
      RenderedTaskList: undefined,
      Refresher: (Math.floor(Math.random() * 10000) + 1).toString(),
      Modes: [],
      SPOUploadLocations: [],
    };

    this.props.Logger.log("Starting SPOWebUIOnPrem...", LoggingLevel.INFO);
  }

  public async componentDidMount() {
    dayjs.extend(utc);
    dayjs.extend(customParseFormat);

    this.props.Logger.log("Mounting SPOWebUIOnPrem...", LoggingLevel.TRACE);

    await this.RegisterStatus("Results Embargoes", "Checking...").then(
      () => {
        this.GetEmbargoes().then(async () => {
          await this.DeregisterStatus("Results Embargoes");
          await this.DeregisterError("Results Embargoes");
          //  console.log('View As User Permissions');
          //  console.log(this.state.ViewAsUserPermissions);
        });
      },
      async (error) => {
        await this.RegisterStatus("Results Embargoes", "Failed.");
        await this.RegisterError("Results Embargoes", error);
      },
    );

    await this.RegisterStatus("Notices", "Checking...").then(
      () => {
        this.GetNotices().then(async () => {
          await this.DeregisterStatus("Notices");
          await this.DeregisterError("Notices");
          //  console.log('View As User Permissions');
          //  console.log(this.state.ViewAsUserPermissions);
        });
      },
      async (error) => {
        await this.RegisterStatus("Notices", "Failed.");
        await this.RegisterError("Notices", error);
      },
    );

    //  this.RegisterStatus('Permissions Matrix', 'Loading...').then(() => {
    //    this.GetPermissionsMatrix().then(async () => {
    //      this.DeregisterStatus('Permissions Matrix');
    //      this.DeregisterError('Permissions Matrix');
    //  console.log('Permissions Matrix');
    //  console.log(this.state._PermissionsMatrix);

    await this.RegisterStatus("User Data", "Loading...").then(() => {
      this.RetrieveAuthedUserDetails().then(
        async () => {
          //  this.state.da.get('/user', '', false).then((data) => {
          //    this.setState({ AuthedUser: data }, async () => {
          //  console.log('User Data');
          //  console.log(this.state.AuthedUser);
          await this.DeregisterStatus("User Data");
          await this.DeregisterError("User Data");

          await this.RegisterStatus("User Groups", "Retrieving...").then(
            () => {
              this.RetrieveAuthedUserGroups().then(async () => {
                //  console.log('User Groups');
                //  console.log(this.state.AuthedUserGroups);
                await this.DeregisterStatus("User Groups");
                await this.DeregisterError("User Groups");

                //  await this.RegisterStatus('User Permissions', 'Calculating...').then(() => {
                //    this.ConvertAuthedUserGroupsToPermissions().then(async () => {
                //      //  console.log('User Permissions');
                //      //  console.log(this.state.AuthedUserPermissions);
                //      await this.DeregisterStatus('User Permissions');
                //      await this.DeregisterError('User Permissions');

                // one of two possibilities: EITHER find out what permissions EDRMAPI is missing and add them so it can consult ANY user's data
                // OR switch back to raw webui/userdatabase/permissionsmatrix consult.

                if (
                  (this.state.Alias !== undefined && this.state.Alias !== "") ||
                  (this.props.Imp !== undefined && this.props.Imp !== "")
                ) {
                  //  console.log('this.props.Imp');
                  //  console.log(this.props.Imp);
                  //  console.log('this.state.Alias');
                  //  console.log(this.state.Alias);
                  await this.RegisterStatus(
                    "View As User Data",
                    "Loading...",
                  ).then(() => {
                    this.RetrieveViewAsUserDetails().then(
                      async () => {
                        //  this.state.da.get('/user/' + this.props.Alias, JSON.stringify({username:this.props.Alias}), false).then((data) => {
                        //    this.setState({ ViewAsUser: data }, async () => {
                        //  console.log('View As User Data');
                        //  console.log(this.state.ViewAsUser);
                        await this.DeregisterStatus("View As User Data");
                        await this.DeregisterError("View As User Data");

                        await this.RegisterStatus(
                          "View As User Groups",
                          "Retrieving...",
                        ).then(
                          () => {
                            this.RetrieveViewAsUserGroups().then(async () => {
                              //  console.log('View As User Groups');
                              //  console.log(this.state.ViewAsUserGroups);
                              await this.DeregisterStatus(
                                "View As User Groups",
                              );
                              await this.DeregisterError("View As User Groups");

                              //  await this.RegisterStatus('View As User Permissions', 'Calculating...').then(() => {
                              //    this.ConvertViewAsUserGroupsToPermissions().then(async () => {
                              //      await this.DeregisterStatus('View As User Permissions');
                              //      await this.DeregisterError('View As User Permissions');
                              //      //  console.log('View As User Permissions');
                              //      //  console.log(this.state.ViewAsUserPermissions);
                              //    })}, async (error) => {
                              //      await this.RegisterStatus('View As User Permissions', 'Failed.');
                              //      await this.RegisterError('View As User Permissions', error);
                              //  });
                            });
                          },
                          async (error) => {
                            await this.RegisterStatus(
                              "View As User Groups",
                              "Failed.",
                            );
                            await this.RegisterError(
                              "View As User Groups",
                              error,
                            );
                          },
                        );

                        await this.RegisterStatus(
                          "View As User Charm Data",
                          "Calculating...",
                        ).then(
                          () => {
                            this.ConvertViewAsUserToCharmData().then(
                              async () => {
                                await this.DeregisterStatus(
                                  "View As User Charm Data",
                                );
                                await this.DeregisterError(
                                  "View As User Charm Data",
                                );
                                //  console.log('View As User Charm Data');
                                //  console.log(this.state.ViewAsCharmData);
                              },
                            );
                          },
                          async (error) => {
                            await this.RegisterStatus(
                              "View As User Charm Data",
                              "Failed.",
                            );
                            await this.RegisterError(
                              "View As User Charm Data",
                              error,
                            );
                          },
                        );
                        //})
                      },
                      async (error) => {
                        await this.RegisterStatus(
                          "View As User Data",
                          "Failed.",
                        );
                        await this.RegisterError("View As User Data", error);
                      },
                    );
                  });
                }

                this.getTaskLists();
                this.getModeNames();
                this.getSPOUploadLocations();
                //  })}, async (error) => {
                //    await this.RegisterStatus('User Permissions', 'Failed.');
                //    await this.RegisterError('User Permissions', error);
                //  });
              });
            },
            async (error) => {
              await this.RegisterStatus("User Groups", "Failed.");
              await this.RegisterError("User Groups", error);
            },
          );

          await this.RegisterStatus("User Charm Data", "Calculating...").then(
            () => {
              this.ConvertAuthedUserToCharmData().then(async () => {
                //  console.log('User Charm Data');
                //  console.log(this.state.AuthedCharmData);
                await this.DeregisterStatus("User Charm Data");
                await this.DeregisterError("User Charm Data");
              });
            },
            async (error) => {
              await this.RegisterStatus("User Charm Data", "Failed.");
              await this.RegisterError("User Charm Data", error);
            },
          );
          //  })
        },
        async (error) => {
          await this.RegisterStatus("User Data", "Failed.");
          await this.RegisterError("User Data", error);
        },
      );
    });

    //    })}, (error) => {
    //    this.RegisterStatus('Permissions Matrix', 'Failed.');
    //    this.RegisterError('Permissions Matrix', error);
    //  });

    this.props.Logger.log("Mounted SPOWebUIOnPrem.", LoggingLevel.INFO);
  }

  public render() {
    var Functions = {
      Logger: this.props.Logger,
      Environment: this.state.Environment,
      da: this.state.da,
      IDA: this.state.IDA,
      URLParams: this.state.URLParams,
      Refresher: this.state.Refresher,
      Alias: this.state.Alias,
      AliasChecked: this.state.AliasChecked,
      ViewAsAll: this.state.ViewAsAll,
      TaskListGroups: this.state.TaskListGroups,
      Refresh: this.Refresh,
      SetSearch: this.SetSearch,
      SetTaskList: this.SetTaskList,
      GetLink: this.GetLink,
      GetMode: this.GetMode,
      GetSPUL: this.GetSPUL,
      RegisterError: this.RegisterError,
      DeregisterError: this.DeregisterError,
      RegisterStatus: this.RegisterStatus,
      DeregisterStatus: this.DeregisterStatus,
      Embargo: this.Embargo,
      HasNotices: this.HasNotices,
      Notices: this.Notices,
      GetModeFromString: GetModeFromString,
      SetImpersonation: this.SetImpersonation,
      HiVis: this.props.HiVis,
      SetHiVis: this.props.SetHiVis,
      Navigation: this.Navigation,
      ThemeName: this.props.ThemeName,
      SetThemeName: this.props.SetThemeName,
    };

    if (
      !this.state.AuthedUser === undefined ||
      this.state.ErrorMessages.length > 0 ||
      this.state.StatusMessages.length > 0
    ) {
      return (
        <div id="SPOWebUIOnPrem" className={this.props.ThemeName}>
          {this.LoadingScreen()}
        </div>
      );
    } else {
      const MorphicRouter = this.MorphicRouter(this.props.Surface, Functions);
      return (
        <FunctionProvider
          FunctionData={{
            GetLink: this.GetLink,
            RegisterError: this.RegisterError,
            DeregisterError: this.DeregisterError,
            RegisterStatus: this.RegisterStatus,
            DeregisterStatus: this.DeregisterStatus,
            Logger: this.props.Logger,
            Refresher: this.state.Refresher,
          }}
        >
          <ApiProvider
            Api={{
              General: this.state.da,
              Invar: this.state.IDA,
            }}
          >
            <UserProvider
              UserData={{
                AuthedUser: this.state.AuthedUser,
                ViewAsUser: this.state.ViewAsUser,
                Alias: this.state.Alias,
                AliasChecked: this.state.AliasChecked,
              }}
            >
              <RouterProvider router={MorphicRouter} />
            </UserProvider>
          </ApiProvider>
        </FunctionProvider>
      );

      // if (
      //   this.props.Surface === "EAF_LF" ||
      //   this.props.Surface === "ECF_LF" ||
      //   this.props.Surface === "ESF_LF"
      // ) {
      //   const MorphicRouter = this.MorphicRouter(this.props.Surface, Functions);
      //   return <RouterProvider router={MorphicRouter} />;
      // } else {
      //   return this.Screen(Functions);
      // }
    }
  }

  MorphicRouter(surface: SurfaceAttributes, functions: any) {
    if (surface.RootSurface === SURFACE.EAF) {
      return createBrowserRouter([
        {
          path: "/",
          element: (
            <MainBracketWrapper
              Permission={this._Permission}
              {...this.state}
              {...functions}
            ></MainBracketWrapper>
          ),
          errorElement: <ErrorPage />,
          children: [
            {
              path: "",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={[
                    "UGEAF.Reader",
                    "PGEAF.Reader",
                    "Edrm.Owner",
                    "Clearing.Reader",
                  ]}
                >
                  <EAF_LF {...this.props} {...functions}></EAF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              /* it confused me at first,
              but 'children' refers to outlettable controls,
              **not** to route children. */
            },

            {
              path: "UGEAF",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <UGEAF_LF {...this.props} {...functions}></UGEAF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "UGEAF/:ucasno/Metadata/:fileIdent",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["UGEAF.Admissions", "Edrm.Owner"]}
                >
                  <SingleFileMetaEditor
                    da={this.state.da}
                    authedUser={this.state.AuthedUser}
                    addBreadcrumbMapping={this.AddBreadcrumbTranslation}
                    RegisterError={this.RegisterError}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "UGEAF/:ident/Document/:docid/:filename",
              element: (
                <IfPermitted Whitelist={["UGEAF.Reader", "Clearing.Reader"]}>
                  <DocumentViewer />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  ident: params.ident,
                  docid: params.docid,
                  filename: params.filename,
                };
              },
            },
            {
              path: "UGEAF/:ident/Workflow/:wfid",
              element: (
                <IfPermitted Whitelist={["UGEAF.Reader", "Clearing.Reader"]}>
                  <DocumentViewer />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  ident: params.ident,
                  wfid: params.wfid,
                };
              },
            },
            {
              path: "UGEAF/:ucasno",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <UGEAFWrapper {...this.props} {...functions}></UGEAFWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return { UCASNo: params.ucasno };
              },
            },
            {
              path: "UGEAF/:personIdent/email/:mailId?",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <AutomatedMailViewer {...this.props} {...functions} />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "UGEAF/Search/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <UGEAF_LF {...this.props} {...functions}></UGEAF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "UGEAF/Tasklists/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <UGEAF_LF {...this.props} {...functions}></UGEAF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "UGEAF/Utilities",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <UGEAF_LF {...this.props} {...functions}></UGEAF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "UGEAF/Search/:searchtype/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <SearchResultWrapper
                    {...this.props}
                    {...functions}
                  ></SearchResultWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({
                params,
              }): { SearchType: string; SearchTerm: string } => {
                return {
                  SearchType: params.searchtype,
                  SearchTerm: undefined,
                };
              },
            },
            {
              path: "UGEAF/Search/:searchtype/*",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <SearchResultWrapper
                    {...this.props}
                    {...functions}
                  ></SearchResultWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({
                params,
              }): { SearchType: string; SearchTerm: string } => {
                return {
                  SearchType: params.searchtype,
                  SearchTerm: params["*"],
                };
              },
            },
            {
              path: "UGEAF/Upload",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Uploader"]}>
                  <UploadManager
                    {...functions}
                    Environment={this.state.Environment}
                    Logger={this.props.Logger}
                    GraphAccessToken={this.props.GraphAccessToken}
                    GetLink={this.GetLink}
                    da={this.state.da}
                    UploadConveyance={"UGEAF Direct Upload --> SPO"}
                    SPUL={this.GetSPUL("UGEAF")}
                    RegisterError={this.RegisterError}
                    DeregisterError={this.DeregisterError}
                    IncludeRUG={true}
                    Refresh={this.Refresh}
                    Refresher={this.state.Refresher}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            //
            {
              path: "UGEAF/Utilities/RequestPrintView",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Admissions"]}>
                  {/* needs completing */}
                  <UCASDAprintreq
                    {...this.props}
                    {...functions}
                  ></UCASDAprintreq>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "UGEAF/Utilities/Invar",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Uploader"]}>
                  <InvarAuditor {...this.props} {...functions}></InvarAuditor>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "UGEAF/Tasklists/:tasklist",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: undefined,
                  Parameter2: undefined,
                };
              },
            },
            {
              path: "UGEAF/Tasklists/:tasklist/:param1",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: params.param1,
                  Parameter2: undefined,
                };
              },
            },
            {
              path: "UGEAF/Tasklists/:tasklist/:param1/:param2",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["UGEAF.Reader"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: params.param1,
                  Parameter2: params.param2,
                };
              },
            },
            {
              path: "PGEAF",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <PGEAF_LF {...this.props} {...functions}></PGEAF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "PGEAF/:ident/Document/:docid/:filename",
              element: (
                <IfPermitted Whitelist={["PGEAF.Reader"]}>
                  <DocumentViewer />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  ident: params.ident,
                  docid: params.docid,
                  filename: params.filename,
                };
              },
            },
            {
              path: "PGEAF/:ident/Workflow/:wfid",
              element: (
                <IfPermitted Whitelist={["PGEAF.Reader"]}>
                  <DocumentViewer />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  ident: params.ident,
                  wfid: params.wfid,
                };
              },
            },
            {
              path: "PGEAF/:pgno",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <PGEAFWrapper {...this.props} {...functions}></PGEAFWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return { PGNo: params.pgno.toUpperCase() };
              },
            },
            {
              path: "PGEAF/:pgno/Metadata/:fileIdent",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["PGEAF.Admissions", "Edrm.Owner"]}
                >
                  <SingleFileMetaEditor
                    da={this.state.da}
                    authedUser={this.state.AuthedUser}
                    addBreadcrumbMapping={this.AddBreadcrumbTranslation}
                    RegisterError={this.RegisterError}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "PGEAF/Search/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <PGEAF_LF {...this.props} {...functions}></PGEAF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "PGEAF/Tasklists/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <PGEAF_LF {...this.props} {...functions}></PGEAF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "PGEAF/Utilities",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <PGEAF_LF {...this.props} {...functions}></PGEAF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "PGEAF/Search/:searchtype/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <SearchResultWrapper
                    {...this.props}
                    {...functions}
                  ></SearchResultWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({
                params,
              }): { SearchType: string; SearchTerm: string } => {
                return {
                  SearchType: params.searchtype,
                  SearchTerm: undefined,
                };
              },
            },
            {
              path: "PGEAF/Search/:searchtype/*",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <SearchResultWrapper
                    {...this.props}
                    {...functions}
                  ></SearchResultWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({
                params,
              }): { SearchType: string; SearchTerm: string } => {
                return {
                  SearchType: params.searchtype,
                  SearchTerm: params["*"],
                };
              },
            },
            {
              path: "PGEAF/Upload",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Uploader"]}>
                  <UploadManager
                    {...functions}
                    UploadConveyance={"PGEAF Direct Upload --> SPO"}
                    SPUL={this.GetSPUL("PGEAF")}
                    IncludeRUG={true}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "PGEAF/Utilities/Invar",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Uploader"]}>
                  <InvarAuditor {...this.props} {...functions}></InvarAuditor>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "PGEAF/Tasklists/:tasklist",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: undefined,
                  Parameter2: undefined,
                };
              },
            },
            {
              path: "PGEAF/Tasklists/:tasklist/:param1",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: params.param1,
                  Parameter2: undefined,
                };
              },
            },
            {
              path: "PGEAF/Tasklists/:tasklist/:param1/:param2",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["PGEAF.Reader"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: params.param1,
                  Parameter2: params.param2,
                };
              },
            },
            {
              path: "Clearing",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["Clearing.Reader"]}>
                  <Clearing_LF {...this.props} {...functions}></Clearing_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Clearing/Utilities/Invar",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["Clearing.Crew"]}>
                  <InvarAuditor {...this.props} {...functions}></InvarAuditor>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Clearing/Upload",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["Clearing.Crew"]}>
                  <UploadManager
                    {...functions}
                    UploadConveyance={"Clearing Direct Upload --> SPO"}
                    SPUL={this.GetSPUL("Clearing")}
                    IncludeRUG={true}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Clearing/Review/:clearingno",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["Clearing.Crew"]}>
                  <ClearingApplicantReview
                    {...this.props}
                    {...functions}
                  ></ClearingApplicantReview>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return { clearingno: params.clearingno.toUpperCase() };
              },
            },
            {
              path: "Clearing/Review/:ident/Document/:docid/:filename",
              element: (
                <IfPermitted Whitelist={["UGEAF.Reader", "Clearing.Reader"]}>
                  <DocumentViewer />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  ident: params.ident,
                  docid: params.docid,
                  filename: params.filename,
                };
              },
            },
            {
              path: "Clearing/Search/:searchterm",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["Clearing.Reader"]}>
                  <SearchResultWrapper
                    {...this.props}
                    {...functions}
                  ></SearchResultWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({
                params,
              }): { SearchType: string; SearchTerm: string } => {
                return {
                  SearchType: "Clearing",
                  SearchTerm: params.searchterm,
                };
              },
            },
            {
              path: "Utilities/HelpArticle",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={[
                    "Clearing.Reader",
                    "PGEAF.Reader",
                    "UGEAF.Reader",
                  ]}
                >
                  <HelpArticle {...this.props} {...functions}></HelpArticle>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/WhatsNew",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={[
                    "Clearing.Reader",
                    "PGEAF.Reader",
                    "UGEAF.Reader",
                  ]}
                >
                  <WhatsNew {...this.props} {...functions}></WhatsNew>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/UserGuide",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={[
                    "Clearing.Reader",
                    "PGEAF.Reader",
                    "UGEAF.Reader",
                  ]}
                >
                  <UserGuide {...this.props} {...functions}></UserGuide>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/OutlookHelp",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={[
                    "Clearing.Reader",
                    "PGEAF.Reader",
                    "UGEAF.Reader",
                  ]}
                >
                  <OutlookHelp {...this.props} {...functions}></OutlookHelp>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/KnownIssues",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={[
                    "Clearing.Reader",
                    "PGEAF.Reader",
                    "UGEAF.Reader",
                  ]}
                >
                  <KnownIssues {...this.props} {...functions}></KnownIssues>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
          ],
        },
      ]);
    } else if (surface.RootSurface === SURFACE.ECF) {
      return createBrowserRouter([
        {
          path: "/",
          element: (
            <MainBracketWrapper
              Permission={this._Permission}
              {...this.state}
              {...functions}
            ></MainBracketWrapper>
          ),
          errorElement: <ErrorPage />,
          children: [
            {
              path: "",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  <ECF_LF {...this.props} {...functions}></ECF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Search/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  <ECF_LF {...this.props} {...functions}></ECF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Tasklists/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  <ECF_LF {...this.props} {...functions}></ECF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  <ECF_LF {...this.props} {...functions}></ECF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Search/:searchtype/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  <SearchResultWrapper
                    {...this.props}
                    {...functions}
                  ></SearchResultWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({
                params,
              }): { SearchType: string; SearchTerm: string } => {
                return {
                  SearchType: params.searchtype,
                  SearchTerm: "",
                };
              },
            },
            {
              path: "Search/:searchtype/*",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  <SearchResultWrapper
                    {...this.props}
                    {...functions}
                  ></SearchResultWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({
                params,
              }): { SearchType: string; SearchTerm: string } => {
                return {
                  SearchType: params.searchtype,
                  SearchTerm: params["*"],
                };
              },
            },
            {
              path: "Upload",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.Uploader"]}>
                  <UploadManager
                    {...functions}
                    UploadConveyance={"ECF Direct Upload --> SPO"}
                    SPUL={this.GetSPUL("ECF")}
                    IncludeRUG={true}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "ECFUploadDocsReport",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.ReportViewers"]}>
                  <ECFUploadDocsReport {...this.props} {...functions} />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "/ECF/:prid",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  {/* <ECFWrapper {...this.props} {...functions}></ECFWrapper> */}
                  <ECF
                    Environment={this.state.Environment}
                    IDA={this.state.IDA}
                    GraphAccessToken={functions.GraphAccessToken}
                    Refresh={this.Refresh}
                    GetSPUL={this.GetSPUL}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return { PRID: params.prid.toUpperCase() };
              },
            },
            {
              path: "ECF/:ident/Document/:docid/:filename",
              element: (
                <IfPermitted Whitelist={["ECF.BasicAccess"]}>
                  <DocumentViewer />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  ident: params.ident,
                  docid: params.docid,
                  filename: params.filename,
                };
              },
            },
            {
              path: "ECF/:ident/Workflow/:wfid",
              element: (
                <IfPermitted Whitelist={["ECF.BasicAccess"]}>
                  <DocumentViewer />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  ident: params.ident,
                  wfid: params.wfid,
                };
              },
            },
            {
              path: "/ECF/:prid/Metadata/:fileIdent",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["ECF.Teams", "Edrm.Owner"]}
                >
                  <SingleFileMetaEditor
                    da={this.state.da}
                    authedUser={this.state.AuthedUser}
                    addBreadcrumbMapping={this.AddBreadcrumbTranslation}
                    RegisterError={this.RegisterError}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "/ECF/:prid/Metadata/:fileIdent",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["ECF.Teams", "Edrm.Owner"]}
                >
                  <SingleFileMetaEditor
                    da={this.state.da}
                    authedUser={this.state.AuthedUser}
                    addBreadcrumbMapping={this.AddBreadcrumbTranslation}
                    RegisterError={this.RegisterError}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/UploadedDocumentsReport",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.ReportViewers"]}>
                  <h2>UploadedDocumentsReport placeholder</h2>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/HelpArticle",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.Reader"]}>
                  <HelpArticle {...this.props} {...functions}></HelpArticle>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/WhatsNew",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.Reader"]}>
                  <WhatsNew {...this.props} {...functions}></WhatsNew>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/UserGuide",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.Reader"]}>
                  <UserGuide {...this.props} {...functions}></UserGuide>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/OutlookHelp",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.Reader"]}>
                  <OutlookHelp {...this.props} {...functions}></OutlookHelp>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/KnownIssues",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.Reader"]}>
                  <KnownIssues {...this.props} {...functions}></KnownIssues>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/Invar",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.Uploader"]}>
                  <InvarAuditor {...this.props} {...functions}></InvarAuditor>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Tasklists/:tasklist",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: undefined,
                  Parameter2: undefined,
                };
              },
            },
            {
              path: "Tasklists/:tasklist/:param1",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: params.param1,
                  Parameter2: undefined,
                };
              },
            },
            {
              path: "Tasklists/:tasklist/:param1/:param2",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ECF.BasicAccess"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: params.param1,
                  Parameter2: params.param2,
                };
              },
            },
          ],
        },
      ]);
    } else if (surface.RootSurface === SURFACE.ESF) {
      return createBrowserRouter([
        {
          path: "/",
          element: (
            <MainBracketWrapper
              Permission={this._Permission}
              {...this.state}
              {...functions}
            ></MainBracketWrapper>
          ),
          errorElement: <ErrorPage />,
          children: [
            {
              path: "",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <ESF_LF {...this.props} {...functions}></ESF_LF>
                </IfPermitted>
              ),
            },
            {
              path: "/ESF/:ident/Document/:docid/:filename",
              element: (
                <IfPermitted Whitelist={["ESF.Reader"]}>
                  <DocumentViewer />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  ident: params.ident,
                  docid: params.docid,
                  filename: params.filename,
                };
              },
            },
            {
              path: "/ESF/:ident/Workflow/:wfid",
              element: (
                <IfPermitted Whitelist={["ESF.Reader"]}>
                  <DocumentViewer />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  ident: params.ident,
                  wfid: params.wfid,
                };
              },
            },
            {
              path: "/ESF/:prid/Metadata/:fileIdent",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={[
                    "ESF.Teams",
                    "Compliance.StudentStayEdit",
                    "Edrm.Owner",
                  ]}
                >
                  <SingleFileMetaEditor
                    da={this.state.da}
                    authedUser={this.state.AuthedUser}
                    addBreadcrumbMapping={this.AddBreadcrumbTranslation}
                    RegisterError={this.RegisterError}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "/ESF/:personIdent/email/:mailId?",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <AutomatedMailViewer {...this.props} {...functions} />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "/ESF/:prid",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <ESFWrapper {...this.props} {...functions}></ESFWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return { PRID: params.prid.toUpperCase() };
              },
            },
            {
              path: "/ESF/:prid/infopathWF/:wfid",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <WorkflowViewInfoPathWrapper
                    {...this.props}
                    {...functions}
                  ></WorkflowViewInfoPathWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return { PRID: params.prid.toUpperCase(), wfid: params.wfid };
              },
            },
            {
              path: "/utilities/AllEAFDocs",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <ESFAllEAFDocs {...this.props} {...functions}></ESFAllEAFDocs>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return { PRID: params.prid.toUpperCase(), wfid: params.wfid };
              },
            },
            {
              path: "Search/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <ESF_LF {...this.props} {...functions}></ESF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Tasklists/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <ESF_LF {...this.props} {...functions}></ESF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <ESF_LF {...this.props} {...functions}></ESF_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Search/:searchtype/",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <SearchResultWrapper
                    {...this.props}
                    {...functions}
                  ></SearchResultWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({
                params,
              }): { SearchType: string; SearchTerm: string } => {
                return {
                  SearchType: params.searchtype,
                  SearchTerm: "",
                };
              },
            },
            {
              path: "Search/:searchtype/*",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <SearchResultWrapper
                    {...this.props}
                    {...functions}
                  ></SearchResultWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({
                params,
              }): { SearchType: string; SearchTerm: string } => {
                return {
                  SearchType: params.searchtype,
                  SearchTerm: params["*"],
                };
              },
            },
            {
              path: "Upload",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Uploader"]}>
                  <UploadManager
                    {...functions}
                    UploadConveyance={"ESF Direct Upload --> SPO"}
                    SPUL={this.GetSPUL("ESF")}
                    IncludeRUG={true}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "RegistrationUpload",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.RegDocUploader"]}>
                  <UploadManager
                    {...functions}
                    UploadConveyance={"ESF Registration Direct Upload --> SPO"}
                    SPUL={this.GetSPUL("ESF")}
                    IncludeRUG={true}
                  />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/LecturerModuleTimetables",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <TimetableSearchWrapper {...this.props} {...functions} />
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/ReviewTaskAssignees",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <ReviewTaskAssignees
                    {...this.props}
                    {...functions}
                  ></ReviewTaskAssignees>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/HelpArticle",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["ESF.Reader", "Compliance.View"]}
                >
                  <HelpArticle {...this.props} {...functions}></HelpArticle>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/WhatsNew",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["ESF.Reader", "Compliance.View"]}
                >
                  <WhatsNew {...this.props} {...functions}></WhatsNew>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/UserGuide",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["ESF.Reader", "Compliance.View"]}
                >
                  <UserGuide {...this.props} {...functions}></UserGuide>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/OutlookHelp",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["ESF.Reader", "Compliance.View"]}
                >
                  <OutlookHelp {...this.props} {...functions}></OutlookHelp>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/KnownIssues",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["ESF.Reader", "Compliance.View"]}
                >
                  <KnownIssues {...this.props} {...functions}></KnownIssues>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Utilities/Invar",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Uploader"]}>
                  <InvarAuditor {...this.props} {...functions}></InvarAuditor>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "Tasklists/:tasklist",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: undefined,
                  Parameter2: undefined,
                };
              },
            },
            {
              path: "Tasklists/:tasklist/:param1",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: params.param1,
                  Parameter2: undefined,
                };
              },
            },
            {
              path: "Tasklists/:tasklist/:param1/:param2",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["ESF.Reader"]}>
                  <TaskListWrapper
                    {...this.props}
                    {...functions}
                  ></TaskListWrapper>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: ({ params }) => {
                return {
                  Tasklist: params.tasklist,
                  Parameter1: params.param1,
                  Parameter2: params.param2,
                };
              },
            },
            {
              path: "AdminPortal",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={[
                    "Collagen.Admins",
                    "Admin.All",
                    "Admin.Collagen",
                    "Admin.Invar",
                    "Admin.WEBUI",
                  ]}
                >
                  <Admin_LF {...this.props} {...functions}></Admin_LF>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "AdminPortal/WAGE",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["Collagen.Admins", "Admin.Collagen"]}
                >
                  <div className="Utility">
                    <WorkflowAssignmentGroupEditor
                      {...this.props}
                      {...functions}
                    ></WorkflowAssignmentGroupEditor>
                  </div>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "AdminPortal/WorkflowSummary/:guid",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["Collagen.Admins", "Admin.Collagen"]}
                >
                  <div className="Utility">
                    <WorkflowSummary
                      {...this.props}
                      {...functions}
                    ></WorkflowSummary>
                  </div>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: async ({ params }) => {
                return params.guid;
              },
            },
            {
              path: "AdminPortal/WorkflowSummary",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["Collagen.Admins", "Admin.Collagen"]}
                >
                  <div className="Utility">
                    <WorkflowSummary
                      {...this.props}
                      {...functions}
                    ></WorkflowSummary>
                  </div>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: async ({ params }) => {
                return "";
              },
            },
            {
              path: "AdminPortal/Permissions",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["Collagen.Admins", "Admin.WEBUI"]}
                >
                  <div className="Utility">
                    <UserAccessChecker
                      {...this.props}
                      {...functions}
                    ></UserAccessChecker>
                  </div>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "AdminPortal/Invar/Monitor",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["Collagen.Admins", "Admin.Invar"]}
                >
                  <InvarMonitorAll
                    {...this.props}
                    {...functions}
                  ></InvarMonitorAll>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "AdminPortal/Invar/Monitor/:workorderid",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["Collagen.Admins", "Admin.Invar"]}
                >
                  <InvarMonitorOne
                    {...this.props}
                    {...functions}
                  ></InvarMonitorOne>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: async ({ params }) => {
                return params.workorderid;
              },
            },
            {
              path: "AdminPortal/Invar/Topology",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["Collagen.Admins", "Admin.Invar"]}
                >
                  <InvarTopology
                    IDA={this.state.IDA}
                    {...this.props}
                    {...functions}
                  ></InvarTopology>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "AdminPortal/Invar/Conveyance/:conveyanceid",
              element: (
                <IfPermitted
                  ShowDisallowed
                  Whitelist={["Collagen.Admins", "Admin.Invar"]}
                >
                  <InvarConveyance
                    IDA={this.state.IDA}
                    {...this.props}
                    {...functions}
                  ></InvarConveyance>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
              loader: async ({ params }): Promise<InvarConveyanceLoader> => {
                return {
                  ConveyanceID: Number.parseInt(params.conveyanceid),
                };
              },
            },
            //  not functional at the moment - fixing it is nice-to-have
            //  {
            //    path: "AdminPortal/Invar/ConveyanceDiff/:conveyanceid1/:conveyanceid2/",
            //    element: (
            //      <IfPermitted ShowDisallowed Whitelist={["Collagen.Admins", "Admin.Invar"]}>
            //        <InvarConveyanceDiff
            //          IDA={this.state.IDA}
            //          {...this.props}
            //        ></InvarConveyanceDiff>
            //      </IfPermitted>
            //    ),
            //    errorElement: <ErrorPage />,
            //    loader: async ({
            //      params,
            //    }): Promise<InvarConveyanceDiffLoader> => {
            //      return {
            //        LHS_ConveyanceID: Number.parseInt(params.conveyanceid1),
            //        RHS_ConveyanceID: Number.parseInt(params.conveyanceid2),
            //      };
            //    },
            //  },
            {
              path: "Compliance",
              errorElement: <ErrorPage />,
              element: <Outlet></Outlet>,
              children: [
                {
                  path: "",
                  element: (
                    <IfPermitted ShowDisallowed Whitelist={["Compliance.View"]}>
                      <ComplianceHomepage
                        {...this.props}
                        {...functions}
                      ></ComplianceHomepage>
                    </IfPermitted>
                  ),
                },
                {
                  path: "Search",
                  element: (
                    <IfPermitted ShowDisallowed Whitelist={["Compliance.View"]}>
                      <Outlet />
                    </IfPermitted>
                  ),
                  children: [
                    {
                      path: ":searchterm?",
                      element: (
                        <IfPermitted
                          ShowDisallowed
                          Whitelist={["Compliance.View"]}
                        >
                          <SearchResultWrapper
                            {...this.props}
                            {...functions}
                          ></SearchResultWrapper>
                        </IfPermitted>
                      ),
                      loader: ({
                        params,
                      }): { SearchType: string; SearchTerm: string } => {
                        return {
                          SearchType: "Compliance",
                          SearchTerm: params.searchterm ?? "",
                        };
                      },
                    },
                    {
                      path: "student/:searchterm?",
                      element: (
                        <IfPermitted
                          ShowDisallowed
                          Whitelist={["Compliance.StudentStayEdit"]}
                        >
                          <SearchResultWrapper
                            {...this.props}
                            {...functions}
                          ></SearchResultWrapper>
                        </IfPermitted>
                      ),
                      loader: ({
                        params,
                      }): { SearchType: string; SearchTerm: string } => {
                        return {
                          SearchType: "StudentVisaAuditFlagSearchESF",
                          SearchTerm: params.searchterm ?? "",
                        };
                      },
                    },
                    {
                      path: "ug/:searchterm?",
                      element: (
                        <IfPermitted
                          ShowDisallowed
                          Whitelist={["Compliance.AdmissionsEdit"]}
                        >
                          <SearchResultWrapper
                            {...this.props}
                            {...functions}
                          ></SearchResultWrapper>
                        </IfPermitted>
                      ),
                      loader: ({
                        params,
                      }): { SearchType: string; SearchTerm: string } => {
                        return {
                          SearchType: "StudentVisaAuditFlagSearchUG",
                          SearchTerm: params.searchterm ?? "",
                        };
                      },
                    },
                    {
                      path: "pg/:searchterm?",
                      element: (
                        <IfPermitted
                          ShowDisallowed
                          Whitelist={["Compliance.AdmissionsEdit"]}
                        >
                          <SearchResultWrapper
                            {...this.props}
                            {...functions}
                          ></SearchResultWrapper>
                        </IfPermitted>
                      ),
                      loader: ({
                        params,
                      }): { SearchType: string; SearchTerm: string } => {
                        return {
                          SearchType: "StudentVisaAuditFlagSearchPG",
                          SearchTerm: params.searchterm ?? "",
                        };
                      },
                    },
                  ],
                },
                {
                  path: "sar",
                  element: (
                    <IfPermitted
                      ShowDisallowed
                      Whitelist={["Compliance.GDPRReportAuthorised"]}
                    >
                      <GDPRSurface {...this.props} {...functions}></GDPRSurface>
                    </IfPermitted>
                  ),
                },
                {
                  path: "audit",
                  element: (
                    <IfPermitted
                      ShowDisallowed
                      Whitelist={["Compliance.AuditReportAuthorised"]}
                    >
                      <HomeOfficeAuditSurface
                        {...this.props}
                        {...functions}
                      ></HomeOfficeAuditSurface>
                    </IfPermitted>
                  ),
                },
                {
                  path: "Utilities/Invar",
                  element: (
                    <IfPermitted
                      ShowDisallowed
                      Whitelist={[
                        "Compliance.GDPRReportAuthorised",
                        "Compliance.AuditReportAuthorised",
                      ]}
                    >
                      <InvarAuditor
                        {...this.props}
                        {...functions}
                      ></InvarAuditor>
                    </IfPermitted>
                  ),
                  errorElement: <ErrorPage />,
                },
                {
                  path: "view/:ident",
                  element: <ComplianceAuditorScreen />,
                  loader: ({ params }) => {
                    return {
                      ident: params.ident.toUpperCase(),
                    };
                  },
                },
                {
                  path: "view/:ident/Document/:docid/:filename",
                  element: (
                    <IfPermitted Whitelist={["Compliance.View"]}>
                      <DocumentViewer />
                    </IfPermitted>
                  ),
                  errorElement: <ErrorPage />,
                  loader: ({ params }) => {
                    return {
                      ident: params.ident,
                      docid: params.docid,
                      filename: params.filename,
                    };
                  },
                },
                {
                  path: "attendance/:ident",
                  element: <ComplianceAttendance />,
                  loader: ({ params }) => {
                    return {
                      ident: params.ident.toUpperCase(),
                    };
                  },
                },
                {
                  path: "flagging",
                  element: <Outlet></Outlet>,
                  children: [
                    {
                      path: "",
                      element: (
                        <div className="Flexor">
                          {/* <LinksToFlagging /> */}
                        </div>
                      ),
                    },
                    {
                      path: "esf/:prid",
                      element: (
                        <IfPermitted
                          ShowDisallowed
                          Whitelist={["Compliance.StudentStayEdit"]}
                        >
                          <ComplianceAuditFlagger />
                        </IfPermitted>
                      ),
                      loader: ({ params }) => {
                        return {
                          personType: PersonType.STUDENT,
                          ident: params.prid.toUpperCase(),
                        };
                      },
                    },
                    {
                      path: "ug/:ucas",
                      element: (
                        <IfPermitted
                          ShowDisallowed
                          Whitelist={["Compliance.AdmissionsEdit"]}
                        >
                          <ComplianceAuditFlagger />
                        </IfPermitted>
                      ),
                      loader: ({ params }) => {
                        return {
                          personType: PersonType.UGAPPLICANT,
                          ident: params.ucas.toUpperCase(),
                        };
                      },
                    },
                    {
                      path: "pg/:pgno",
                      element: (
                        <IfPermitted
                          ShowDisallowed
                          Whitelist={["Compliance.AdmissionsEdit"]}
                        >
                          <ComplianceAuditFlagger />
                        </IfPermitted>
                      ),
                      loader: ({ params }) => {
                        return {
                          personType: PersonType.PGAPPLICANT,
                          ident: params.pgno.toUpperCase(),
                        };
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]);
      // },
      // ]);
    } else if (surface.RootSurface === SURFACE.AOS) {
      return createBrowserRouter([
        {
          path: "/",
          element: (
            <MainBracketWrapper
              Permission={this._Permission}
              {...this.state}
              {...functions}
            ></MainBracketWrapper>
          ),
          errorElement: <ErrorPage />,
          id: "AgentRoot",
          loader: async () => {
            return await GetAgentData(this.state.da, this.state.Alias);
          },
          children: [
            {
              path: "",
              element: <AOS2 {...this.state} {...functions} />,
              errorElement: <ErrorPage />,
            },
            {
              path: "ug",
              element: <Outlet />,
              errorElement: <ErrorPage />,
              children: [
                {
                  path: "applicant",
                  element: <Outlet />,
                  errorElement: <ErrorPage />,
                  children: [
                    {
                      path: ":ucasno",
                      element: (
                        <AOS2_UG_APPLICANT
                          {...this.props}
                          {...this.state}
                          {...functions}
                        />
                      ),
                      errorElement: <ErrorPage />,
                      id: "UgApplicant",
                      loader: ({ params }) => {
                        return { UcasNo: params.ucasno.toUpperCase() };
                      },
                    },
                    {
                      path: ":ident/Document/:docid/:filename",
                      element: (
                        <IfPermitted Whitelist={["AOS.Access"]}>
                          <DocumentViewer />
                        </IfPermitted>
                      ),
                      errorElement: <ErrorPage />,
                      loader: ({ params }) => {
                        return {
                          ident: params.ident,
                          docid: params.docid,
                          filename: params.filename,
                        };
                      },
                    },
                  ],
                },
                {
                  path: "unsubmitted",
                  element: (
                    <AOS2_UG_UNSUBMITTED
                      {...this.props}
                      {...this.state}
                      {...functions}
                    />
                  ),
                  errorElement: <ErrorPage />,
                },
                {
                  path: "current",
                  element: (
                    <AOS2_UG_RECENT
                      {...this.props}
                      {...this.state}
                      {...functions}
                    />
                  ),
                  errorElement: <ErrorPage />,
                },
                {
                  path: "archive",
                  element: (
                    <AOS2_UG_ARCHIVE
                      {...this.props}
                      {...this.state}
                      {...functions}
                    />
                  ),
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: "pg",
              element: <Outlet />,
              errorElement: <ErrorPage />,
              children: [
                {
                  path: "applicant",
                  element: <Outlet />,
                  errorElement: <ErrorPage />,
                  children: [
                    {
                      path: ":pgno",
                      element: (
                        <AOS2_PG_APPLICANT
                          {...this.props}
                          {...this.state}
                          {...functions}
                        />
                      ),
                      errorElement: <ErrorPage />,
                      id: "PgApplicant",
                      loader: ({ params }) => {
                        return { PgNo: params.pgno.toUpperCase() };
                      },
                    },
                    {
                      path: ":ident/Document/:docid/:filename",
                      element: (
                        <IfPermitted Whitelist={["AOS.Access"]}>
                          <DocumentViewer />
                        </IfPermitted>
                      ),
                      errorElement: <ErrorPage />,
                      loader: ({ params }) => {
                        return {
                          ident: params.ident,
                          docid: params.docid,
                          filename: params.filename,
                        };
                      },
                    },
                  ],
                },
                {
                  path: "unsubmitted",
                  element: (
                    <AOS2_PG_UNSUBMITTED
                      {...this.props}
                      {...this.state}
                      {...functions}
                    />
                  ),
                  errorElement: <ErrorPage />,
                },
                {
                  path: "current",
                  element: (
                    <AOS2_PG_RECENT
                      {...this.props}
                      {...this.state}
                      {...functions}
                    />
                  ),
                  errorElement: <ErrorPage />,
                },
                {
                  path: "archive",
                  element: (
                    <AOS2_PG_ARCHIVE
                      {...this.props}
                      {...this.state}
                      {...functions}
                    />
                  ),
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: "search",
              element: <Outlet />,
              errorElement: <ErrorPage />,
              children: [
                {
                  path: ":searchTerm",
                  element: (
                    <AOS2_SEARCH
                      {...this.props}
                      {...this.state}
                      {...functions}
                    />
                  ),
                  errorElement: <ErrorPage />,
                  id: "Search",
                  loader: ({ params }) => {
                    return { SearchTerm: params.searchTerm };
                  },
                },
              ],
            },
            {
              path: "utilities/noticeembargomanager",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["AOS.Admin"]}>
                  <AOS_MNAE {...functions}></AOS_MNAE>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "utilities/linkedwithdrawals",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["AOS.Admin"]}>
                  <AOS2_Linked_Student_Withdrawals
                    {...functions}
                  ></AOS2_Linked_Student_Withdrawals>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "utilities/AgentAccessLog",
              element: (
                <IfPermitted ShowDisallowed Whitelist={["AOS.Admin"]}>
                  <AOS2_ADMIN_Agent_Access
                    {...functions}
                  ></AOS2_ADMIN_Agent_Access>
                </IfPermitted>
              ),
              errorElement: <ErrorPage />,
            },
          ],
        },
      ]);
    }
  }

  LoadingScreen() {
    return (
      <React.Fragment>
        <FullPageLoader
          HiVis={this.props.HiVis}
          SetHiVis={this.props.SetHiVis}
          StatusMessages={this.state.StatusMessages}
          ErrorMessages={this.state.ErrorMessages}
          Surface={this.props.Surface}
          ThemeName={this.props.ThemeName}
          SetThemeName={this.props.SetThemeName}
        ></FullPageLoader>
      </React.Fragment>
    );
  }

  Loading = (ref?: string) => {
    if (this.state.StatusMessages === undefined) {
      return true;
    } else if (this.state.StatusMessages.length === 0) {
      return false;
    } else {
      if (ref === undefined) {
        return (
          this.state.StatusMessages !== undefined &&
          this.state.StatusMessages.length > 0
        );
      } else {
        return (
          this.state.StatusMessages.filter((th) => th.Reference === ref)
            .length > 0
        );
      }
    }
  };

  //TODO Purge this method
  _Permission = (
    _surface: string,
    _scope: string,
    _impish: boolean,
  ): boolean => {
    //  console.log(
    //    "calling root _Permission(" +
    //      _surface +
    //      "." +
    //      _scope +
    //      ", impable = " +
    //      (_impish === undefined ? "false" : _impish) +
    //      "):",
    //  );
    //  console.log("-- current user:");
    //  console.log(this.state.AuthedUser);
    //  console.log("-- imped user:");
    //  console.log(this.state.ViewAsUser);

    _surface = _surface.replace("_LF", "").replace("2", "");

    var returner: boolean = false;
    if (this.state.AuthedUser !== undefined) {
      if (_scope === "") {
        if (this.state.ViewAsUser && !_impish) {
          returner =
            this.state.ViewAsUser.permissions?.filter((th) =>
              th.startsWith(_surface),
            ).length > 0;
        } else {
          returner =
            this.state.AuthedUser.permissions?.filter((th) =>
              th.startsWith(_surface),
            ).length > 0;
        }
      } else {
        const __scope = _surface + "." + _scope;
        if (this.state.ViewAsUser && !_impish) {
          returner =
            this.state.ViewAsUser.permissions.filter((th) => th === __scope)
              .length > 0;
        } else {
          returner =
            this.state.AuthedUser.permissions.filter((th) => th === __scope)
              .length > 0;
        }
      }
    }

    //  console.log(returner);

    return returner;
  };

  Embargo = (scope: string): boolean => {
    return (
      this.state._Embargoes.filter(
        (th) =>
          th._scopes.includes(scope) &&
          dayjs().isBefore(th.EnforcedUntil) &&
          dayjs().isAfter(th.EnforcedFrom),
      ).length > 0
    );
  };

  HasNotices = (AgentIsPAS: boolean, scopes: string[]): boolean => {
    var returner: boolean =
      this.state._Notices
        .filter(
          (th) =>
            dayjs().isBefore(th.VisibleUntil) &&
            dayjs().isAfter(th.VisibleFrom),
        )
        .filter((th) => scopes.every((ith) => th._scopes.includes(ith)))
        .filter(
          (th) =>
            (!AgentIsPAS && !th._scopes.includes("PASOnly")) ||
            (AgentIsPAS && !th._scopes.includes("NonPASOnly")),
        ).length > 0;
    return returner;
  };

  Notices = (AgentIsPAS: boolean, scopes: string[]): React.ReactFragment => {
    return (
      <>
        {this.state._Notices
          .filter(
            (th) =>
              dayjs().isBefore(th.VisibleUntil) &&
              dayjs().isAfter(th.VisibleFrom),
          )
          .filter((th) => scopes.every((ith) => th._scopes.includes(ith)))
          .filter(
            (th) =>
              (!AgentIsPAS && !th._scopes.includes("PASOnly")) ||
              (AgentIsPAS && !th._scopes.includes("NonPASOnly")),
          )
          .map((th) => (
            <div key={"KN_" + th.NoticeID.toString()} className="Notice">
              {th.Title !== undefined && th.Title !== "" ? (
                <div className="NoticeTitle">{th.Title}</div>
              ) : (
                ""
              )}
              <div
                className="NoticeBody"
                dangerouslySetInnerHTML={{ __html: th.Body_HTML }}
              ></div>
            </div>
          ))}
      </>
    );
  };

  GetLink = (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => {
    var dusq = document.URL.split("?");

    var cloneMode = [];

    if (this.state.Mode === undefined) {
      return "";
    } else {
      if (this.state.Mode.length >= 1) {
        for (var i = 0; i < this.state.Mode.length; i++) {
          cloneMode = cloneMode.concat([this.state.Mode[i]]);
        }
      }
    }

    if (NewModes !== undefined) {
      var clearHigherModes = -1;
      NewModes.forEach((th) => {
        if (th.Index > clearHigherModes) {
          clearHigherModes = th.Index;
        }
      });

      cloneMode = cloneMode.filter(
        (value: string, index: number) => index <= clearHigherModes,
      );

      NewModes.forEach((th) => {
        cloneMode[th.Index] = th.Mode;
      });
    }

    var newURL = this.state.URLRoot;

    for (var i = 0; i < cloneMode.length; i++) {
      if (i === 0) {
        newURL += cloneMode[i];
      } else {
        newURL += "/" + cloneMode[i];
      }
    }

    var existingParams: { Name: string; Value: string }[] = [];

    if (dusq.length > 1) {
      dusq[1].split("&").forEach((th) => {
        if (th.split("=")[0] !== "state") {
          // horrible hack until urls are good
          existingParams.push({
            Name: th.split("=")[0],
            Value: th.split("=")[1],
          });
        }
      });
    }

    NewParams.filter((th) => th.Value !== undefined).forEach((th) => {
      if (existingParams.find((ith) => ith.Name === th.Name) !== undefined) {
        existingParams.find((ith) => ith.Name === th.Name).Value = th.Value;
      } else {
        existingParams.push(th);
      }
    });

    NewParams.filter((th) => th.Value === undefined).forEach((th) => {
      existingParams = existingParams.filter((ith) => ith.Name !== th.Name);
    });

    existingParams.forEach((th) => {
      if (newURL.indexOf("?") <= 0) {
        newURL += "?" + th.Name + "=" + th.Value;
      } else {
        newURL += "&" + th.Name + "=" + th.Value;
      }
    });

    return newURL;
  };

  SetSearch = (SearchString: string) => {
    this.setState({ SearchTerm: SearchString });
  };

  SetTaskList = (TLRef: string) => {
    this.setState({ RenderedTaskList: TLRef });
  };

  Navigation = (URL: string) => {
    window.location.assign(URL);
  };

  SetImpersonation = (Alias?: string) => {
    //  console.log("SetImpersonation");
    //  console.log(Alias);

    var comp1 = Alias;
    var comp2 = this.state.Alias;
    if (comp1 === null) {
      comp1 = "";
    }
    if (comp1 === undefined) {
      comp1 = "";
    }
    if (comp2 === null) {
      comp2 = "";
    }
    if (comp2 === undefined) {
      comp2 = "";
    }
    //  console.log(comp1);
    //  console.log(comp2);
    //  console.log(comp1 !== comp2);

    if (comp1 === comp2 && comp1 === "") {
      this.setState(
        {
          ViewAsAll: false,
          ViewAsUser: undefined,
          Alias: undefined,
          AliasChecked: true,
        },
        () => {
          this.RetrieveViewAsUserDetails();
          this.ConvertViewAsUserToCharmData();
          this.RetrieveViewAsUserGroups();
          //  console.log("SetImpersonation[none]...then() ==> Refresh");
          //  this.Refresh();
        },
      );
    } else {
      if (Alias === undefined || Alias === "") {
        this.setState(
          {
            ViewAsAll: false,
            ViewAsUser: undefined,
            Alias: undefined,
            AliasChecked: true,
          },
          () => {
            this.RetrieveViewAsUserDetails();
            this.ConvertViewAsUserToCharmData();
            this.RetrieveViewAsUserGroups();
            //  console.log("SetImpersonation[none]...then() ==> Refresh");
            //  this.Refresh();
          },
        );
      } else if (Alias === "--ALL--") {
        this.setState(
          {
            ViewAsAll: true,
            ViewAsUser: undefined,
            Alias: undefined,
            AliasChecked: true,
          },
          () => {
            this.RetrieveViewAsUserDetails();
            this.ConvertViewAsUserToCharmData();
            this.RetrieveViewAsUserGroups();
            //  console.log("SetImpersonation[--ALL--]...then() ==> Refresh");
            //  this.Refresh();
          },
        );
      } else {
        this.setState(
          {
            ViewAsAll: false,
            ViewAsUser: undefined,
            Alias: undefined,
            AliasChecked: false,
          },
          () => {
            this.state.da.getUser(Alias).then((data) => {
              //  console.log('SetImpersonation');
              //  console.log(data);
              if (data.status === 404) {
                this.setState({
                  ViewAsAll: false,
                  ViewAsNotFound: true,
                });
              } else {
                this.setState(
                  {
                    ViewAsAll: false,
                    ViewAsNotFound: false,
                    ViewAsUser: data,
                    Alias: data.userPrincipalName.split("@")[0],
                    AliasChecked: true,
                  },
                  () => {
                    //  console.log(this.state.ViewAsUser);
                    //  console.log(this.state.Alias);
                    this.RetrieveViewAsUserDetails();
                    this.ConvertViewAsUserToCharmData();
                    this.RetrieveViewAsUserGroups();
                    //  console.log(
                    //    "SetImpersonation[" +
                    //      this.state.Alias +
                    //      "]...then() ==> Refresh",
                    //  );
                    //  this.Refresh();
                  },
                );
              }
            });
          },
        );
      }
    }
  };

  RegisterError = (_ref: string, _mess: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          ErrorMessages: [...this.state.ErrorMessages].concat({
            Reference: _ref,
            Message: _mess,
          }),
        },
        () => {
          if (
            this.state.ErrorMessages.filter((th) => th.Reference === _ref)
              .length === 0
          ) {
            reject("error did not register");
          } else {
            resolve(true);
          }
        },
      );
    });
  };

  DeregisterError = (_ref: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          ErrorMessages: [...this.state.ErrorMessages].filter(
            (th) => th.Reference !== _ref,
          ),
        },
        () => {
          if (
            this.state.ErrorMessages.filter((th) => th.Reference === _ref)
              .length > 0
          ) {
            reject("error was not deregistered");
          } else {
            resolve(true);
          }
        },
      );
    });
  };

  RegisterStatus = (_ref: string, _mess: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          StatusMessages: [...this.state.StatusMessages].concat({
            Reference: _ref,
            Message: _mess,
          }),
        },
        () => {
          if (
            this.state.StatusMessages.filter((th) => th.Reference === _ref)
              .length === 0
          ) {
            reject("status did not register");
          } else {
            resolve(true);
          }
        },
      );
    });
  };

  DeregisterStatus = (_ref: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          StatusMessages: [...this.state.StatusMessages].filter(
            (th) => th.Reference !== _ref,
          ),
        },
        () => {
          if (
            this.state.StatusMessages.filter((th) => th.Reference === _ref)
              .length > 0
          ) {
            reject("status was not deregistered");
          } else {
            resolve(true);
          }
        },
      );
    });
  };

  async ConvertAuthedUserToCharmData() {
    var _initials = "";
    var _fullname = "";
    var _login = "";

    _login = this.state.AuthedUser.userPrincipalName.split("@")[0];
    _fullname =
      this.state.AuthedUser.forename + " " + this.state.AuthedUser.surname;
    _initials =
      this.state.AuthedUser.forename.charAt(0) +
      this.state.AuthedUser.surname.charAt(0);

    this.setState({
      Header_AuthedCharmData: {
        Login: _login,
        FullName: _fullname,
        Initials: _initials,
      },
    });
  }

  async ConvertViewAsUserToCharmData() {
    var _initials = "";
    var _fullname = "";
    var _login = "";

    if (this.state.ViewAsUser !== undefined) {
      _login = this.state.ViewAsUser.userPrincipalName.split("@")[0];
      _fullname =
        this.state.ViewAsUser.forename + " " + this.state.ViewAsUser.surname;
      _initials =
        this.state.ViewAsUser.forename.charAt(0) +
        this.state.ViewAsUser.surname.charAt(0);
    }

    // if
    if (
      this.props.Surface.SURFACE === SURFACE.AOS &&
      (_login.toLowerCase().startsWith("agt") ||
        _login.toLowerCase() === "arinze" ||
        _login.toUpperCase() === "USING HQ")
    ) {
      const agentname = await this.state.da.get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.AOS_GetAgentName",
          params: ["@Agent", _login],
        }),
      );

      //  console.log(agentname[0][0].AgentName);

      _fullname = agentname[0][0].AgentName;
    }

    this.setState({
      Header_ViewAsCharmData: {
        Login: _login,
        FullName: _fullname,
        Initials: _initials,
      },
    });
  }

  Refresh = () => {
    //  console.log("Refresher ==> " + this.state.Refresher);
    this.setState(
      { Refresher: (Math.floor(Math.random() * 10000) + 1).toString() },
      () => {
        //  console.log("Refresher ==> " + this.state.Refresher);
      },
    );
  };

  GetMode = (Either: string) => {
    //  console.log('GetMode() =>');
    //  console.log(Either);
    //  console.log(this.state.Modes);
    //  console.log(this.state.Modes.findIndex(th => th.Machine === Either || th.Human === Either));
    //  console.log(this.state.Modes.find(th => th.Machine === Either || th.Human === Either));

    if (Either === undefined) {
      return { Machine: "", Human: "", Style: "" };
    } else if (
      this.state.Modes.findIndex(
        (th) =>
          th.Machine.toLowerCase() === Either.toLowerCase() ||
          th.Human.toLowerCase() === Either.toLowerCase(),
      ) > -1
    ) {
      return this.state.Modes.find(
        (th) =>
          th.Machine.toLowerCase() === Either.toLowerCase() ||
          th.Human.toLowerCase() === Either.toLowerCase(),
      );
    } else {
      return {
        Machine: Either,
        Human: Either.replaceAll("%20", " ").replaceAll("_", " "),
        Style: "",
      };
    }
  };

  AddBreadcrumbTranslation = (mach: string, hum: string) => {
    const greg: ModeStruct = {
      Machine: mach,
      Human: hum,
      Style: "",
    };

    this.setState({ Modes: this.state.Modes.concat(greg) });
  };

  GetSPUL = (shortname: string) => {
    if (
      this.state.SPOUploadLocations.findIndex(
        (th) => th.ShortName === shortname,
      ) > -1
    ) {
      return this.state.SPOUploadLocations.find(
        (th) => th.ShortName === shortname,
      );
    } else {
      return undefined;
    }
  };

  // new table, new sproc (so that staging doesn't explode).
  // list all principal group names and the permissions they have to the new system.
  // this is JUST a CONSTRUCTIVE model for now. MAYBE consider augmentation-style in the future if issues arise.
  //  private GetPermissionsMatrix(): Promise<any> {
  //    return this.state.da.get('/sproc', JSON.stringify({sproc: 'sputilities.webui.getpermissionsmatrix'}))
  //      .then((data) => {
  //        if (data !== undefined && data[0] !== undefined && data[0].forEach !== undefined) {
  //          var _permat:{
  //            GroupName: string,
  //            PermissionName: string
  //          }[] = [];
  //          data[0].forEach(th => {
  //            _permat = _permat.concat({
  //              GroupName: th.GroupName,
  //              PermissionName: th.PermissionName
  //            })
  //          });
  //          this.setState({ _PermissionsMatrix: _permat })
  //      }}, (reason) => {
  //        this.RegisterError('GetPermissionsMatrix', reason)
  //  })}

  private GetNotices(): Promise<any> {
    return this.state.da
      .get(
        "/sproc",
        JSON.stringify({ sproc: "sputilities.webui.GetAllNotices" }),
      )
      .then(
        (data) => {
          if (
            data !== undefined &&
            data[0] !== undefined &&
            data[0].forEach !== undefined
          ) {
            var _notices: Notice[] = [];
            data[0].forEach((th) => {
              _notices = _notices.concat({
                NoticeID: th.NoticeID,
                Title: th.Title,
                Body_Plain: th.Body_Plain,
                Body_HTML: th.Body_HTML,
                VisibleFrom: dayjs.utc(th.VisibleFrom),
                VisibleUntil: dayjs.utc(th.VisibleUntil),
                EmbargoID: th.EmbargoID,
                _scope: th.Scope,
                _scopes: (th.Scope as string)
                  .split(",")
                  .filter((th) => th !== ""),
              });
            });
            this.setState({ _Notices: _notices });
          }
        },
        (reason) => {
          this.RegisterError("GetNotices", reason);
        },
      );
  }

  private GetEmbargoes(): Promise<any> {
    return this.state.da
      .get(
        "/sproc",
        JSON.stringify({ sproc: "sputilities.webui.getallembargoes" }),
      )
      .then(
        (data) => {
          if (
            data !== undefined &&
            data[0] !== undefined &&
            data[0].forEach !== undefined
          ) {
            var _embargoes: Embargo[] = [];
            data[0].forEach((th) => {
              _embargoes = _embargoes.concat({
                EmbargoID: th.EmbargoID,
                EnforcedFrom: dayjs.utc(th.EnforcedFrom),
                EnforcedUntil: dayjs.utc(th.EnforcedUntil),
                _scope: th.Scope,
                _scopes: (th.Scope as string)
                  .split(",")
                  .filter((th) => th !== ""),
                Author: th.Author,
              });
            });
            this.setState({ _Embargoes: _embargoes });
          }
        },
        (reason) => {
          this.RegisterError("GetEmbargoes", reason);
        },
      );
  }

  private async RetrieveAuthedUserGroups() {
    this.setState({
      AuthedUserGroups: this.state.AuthedUser.groups.map(
        (th) => th.description,
      ),
    });
  }

  private async RetrieveViewAsUserGroups() {
    if (this.state.ViewAsUser === undefined) {
      this.setState({ ViewAsUserGroups: [] });
    } else {
      this.setState({
        ViewAsUserGroups: this.state.ViewAsUser.groups.map(
          (th) => th.description,
        ),
      });
    }
  }

  private RetrieveAuthedUserDetails(): Promise<any> {
    return this.state.da.getAuthedUser().then(
      (data) => {
        var _dt: User = data;
        this.setState({ AuthedUser: _dt });
        var _dt: User = data;
        this.setState({ AuthedUser: _dt });
      },
      (reason) => {
        this.RegisterError("RetrieveAuthedUserDetails", reason);
        //  console.log('RetrieveAuthedUserDetails failed:');
        //  console.log(reason);
      },
    );
  }

  private async RetrieveViewAsUserDetails() {
    //  console.log('RetrieveViewAsUserDetails ==>');
    //  console.log(this.state.Alias);
    //  console.log(this.props.Imp);
    var _newImp = this.props.Imp || this.state.Alias || undefined;
    if (_newImp === undefined) {
      this.setState({ ViewAsUser: undefined });
    } else if (_newImp !== undefined && _newImp !== "") {
      return this.state.da.getUser(_newImp).then(
        (data) => {
          if (data !== undefined) {
            //  console.log('RetrieveViewAsUserDetails ==>');
            //  console.log(data);
            this.setState({ ViewAsUser: data });
          }
        },
        (reason) => {
          this.RegisterError("RetrieveViewAsUserDetails", reason);
          //  console.log('RetrieveViewAsUserDetails failed:');
          //  console.log(reason);
        },
      );
    }
  }

  private async getTaskLists() {
    this.state.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.gettasklists",
          params: [
            "@user",
            this.state.AuthedUser.userPrincipalName.split("@")[0],
          ],
        }),
      )
      .then(
        (data) => {
          if (data !== undefined && data[0] !== undefined) {
            var tlgs: TaskListGroup[] = [];
            var this_tlg: TaskListGroup;
            data.forEach((th: any[]) => {
              if (th.length === 1 && th[0].TaskListGroup !== undefined) {
                this_tlg = { GroupName: "", TaskLists: [] };
                this_tlg.GroupName = th[0].TaskListGroup;
              } else {
                th.forEach((ith: any) => {
                  var tl: TaskList = {
                    Name: ith.Name,
                    TLRef: ith.TLRef,
                    Permitted: ith.Permitted,
                    Display: ith.Display === "true",
                    Params: ith.Params,
                  };
                  this_tlg.TaskLists.push(tl);
                });
                tlgs.push(this_tlg);
              }
            });
            this.setState({
              TaskListGroups: tlgs,
            });
          }
        },
        (reason) => {
          this.RegisterError("getTaskLists", reason);
        },
      );
  }

  private async getModeNames() {
    this.state.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.getmodenames",
          params: [
            "@user",
            this.state.AuthedUser.userPrincipalName.split("@")[0],
          ],
        }),
      )
      .then(
        (data) => {
          if (data !== undefined && data[0] !== undefined) {
            var newModeNames: ModeStruct[] = [];
            data[0].forEach((th: any) => {
              var this_mn: ModeStruct = {
                Machine: th.Machine,
                Human: th.Human,
                Style: th.Style,
              };
              newModeNames.push(this_mn);
            });
            //  console.log(newModeNames);
            this.setState({
              Modes: newModeNames,
            });
          }
        },
        (reason) => {
          this.RegisterError("getModeNames", reason);
        },
      );
  }

  private async getSPOUploadLocations() {
    this.state.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.getspouploadlocations",
          params: [
            "@user",
            this.state.AuthedUser.userPrincipalName.split("@")[0],
          ],
        }),
      )
      .then(
        (data) => {
          if (data !== undefined && data[0] !== undefined) {
            var newSPUPs: SPOUploadLocation[] = [];
            data[0].forEach((th: any) => {
              var this_mn: SPOUploadLocation = {
                ShortName: th.ShortName,
                FriendlyName: th.FriendlyName,
                Site: th.Site,
                List: th.List,
              };
              newSPUPs.push(this_mn);
            });
            this.setState({
              SPOUploadLocations: newSPUPs,
            });
          }
        },
        (reason) => {
          this.RegisterError("getSPOUploadLocations", reason);
        },
      );
  }
}

function GetModeFromString(_URL: string): string[] {
  var returner: string[] = [];

  var _url_pq = "";

  if (_URL.indexOf("?") === -1) {
    _url_pq = _URL;
  } else {
    _url_pq = _URL.split("?")[0];
  }

  _url_pq = _url_pq
    .replace("http://localhost:6220/", "")
    .replace("https://agentstest.essex.ac.uk/", "")
    .replace("https://eaftest.essex.ac.uk/", "")
    .replace("https://ecftest.essex.ac.uk/", "")
    .replace("https://esftest.essex.ac.uk/", "")
    .replace("https://invartest.essex.ac.uk/", "")
    .replace("https://agents.essex.ac.uk/", "")
    .replace("https://eaf.essex.ac.uk/", "")
    .replace("https://ecf.essex.ac.uk/", "")
    .replace("https://esf.essex.ac.uk/", "")
    .replace("https://invar.essex.ac.uk/", "");

  var modebits: string[] = _url_pq.split("/");
  for (var i = 0; i < modebits.length; i++) {
    if (
      modebits[i] !== undefined &&
      modebits[i] !== "" &&
      !modebits[i].startsWith("#code=")
    ) {
      returner = returner.concat([modebits[i]]);
    }
  }

  return returner;
}

export default SPOWebUIOnPrem;
