import { useState } from "react";
import { MondrianKey } from "./Controls/MondrianKey";
import { MondrianSouper } from "./Controls/MondrianSouper";
import { MondrianHider } from "./Controls/MondrianHider";
import { MondrianFilterer } from "./Controls/MondrianFilterer";
import { MondrianDataSurface } from "./Controls/MondrianDataSurface";
import { MondrianPager } from "./Controls/MondrianPager";
import { useMondrianData } from "./MondrianDataProvider";
import { ModulePosition } from "./Structs/Enums/ModulePosition";
import { MondrianOptions } from "./Structs/Interfaces/MondrianOptions";
import "./Core.css";

interface MondrianProps {
  Options: MondrianOptions;
  Page?: Number;
  Pages?: Number;
  SetPage?: (pg: Number) => void;
}

export const Mondrian = (props: MondrianProps) => {
  const Data = useMondrianData();

  const [page, setPage] = useState<Number>(props.Page || 1);
  const [pages, setPages] = useState<Number>(props.Pages || 1);

  const StatefullySetPage = (PG: Number) => {
    if (props.SetPage !== undefined) {
      props.SetPage(PG);
    } else {
      setPage(PG);
    }
  };

  const [soups, setSoups] = useState(props.Options.SoupOptions?.Defaults);
  const [keys, setKeys] = useState(props.Options.KeyOptions?.Items);
  const [filters, setFilters] = useState(props.Options.FilterOptions?.Defaults);
  const [hiders, setHiders] = useState(
    props.Options.HideabilityOptions?.Defaults,
  );

  var Souper = undefined;
  var Keyring = undefined;
  var Filterer = undefined;
  var Hider = undefined;
  var Pager = undefined;

  if (props.Options.PagerOptions?.Paginate) {
    Pager = (
      <MondrianPager
        Options={props.Options.PagerOptions}
        Pages={pages}
        Page={page}
        SetPage={StatefullySetPage}
      ></MondrianPager>
    );
  }

  if (
    soups &&
    (props.Options.SoupOptions?.Groupable ||
      props.Options.SoupOptions?.Sortable)
  ) {
    Souper = (
      <MondrianSouper
        Options={props.Options.SoupOptions}
        Soups={soups}
        SetSoups={setSoups}
        DataColumns={Data.Columns}
      ></MondrianSouper>
    );
  }

  if (keys && keys.length > 0) {
    Keyring = <MondrianKey Keys={keys} SetKeys={setKeys}></MondrianKey>;
  }

  if (filters && props.Options.FilterOptions?.Filterable) {
    Filterer = (
      <MondrianFilterer
        Filters={filters}
        SetFilters={setFilters}
        Filterables={props.Options.FilterOptions.FilterableColumns}
        DataColumns={Data.Columns}
      ></MondrianFilterer>
    );
  }

  if (hiders && props.Options.HideabilityOptions?.Hideable) {
    Hider = (
      <MondrianHider
        Hiders={hiders}
        SetHiders={setHiders}
        Hideables={props.Options.HideabilityOptions.HideableColumns}
        DataColumns={Data.Columns}
      ></MondrianHider>
    );
  }

  return (
    <div className="Mondrian__Grid_Wrapper">
      <div className="Mondrian__Grid_Modules_Top">
        {Keyring && props.Options.KeyOptions.Position === ModulePosition.Top ? (
          Keyring
        ) : (
          <></>
        )}
        {Souper && props.Options.SoupOptions.Position === ModulePosition.Top ? (
          Souper
        ) : (
          <></>
        )}
        {Hider &&
        props.Options.HideabilityOptions.Position === ModulePosition.Top ? (
          Hider
        ) : (
          <></>
        )}
        {Filterer &&
        props.Options.FilterOptions.Position === ModulePosition.Top ? (
          Filterer
        ) : (
          <></>
        )}
      </div>
      <div className="Mondrian__Grid_Wrapper">
        <div className="Mondrian__Grid_Modules_Left">
          {Keyring &&
          props.Options.KeyOptions.Position === ModulePosition.Left ? (
            Keyring
          ) : (
            <></>
          )}
          {Souper &&
          props.Options.SoupOptions.Position === ModulePosition.Left ? (
            Souper
          ) : (
            <></>
          )}
          {Hider &&
          props.Options.HideabilityOptions.Position === ModulePosition.Left ? (
            Hider
          ) : (
            <></>
          )}
          {Filterer &&
          props.Options.FilterOptions.Position === ModulePosition.Left ? (
            Filterer
          ) : (
            <></>
          )}
        </div>
        <MondrianDataSurface
          Display={props.Options.Display}
          DisplayOptions={props.Options.DisplayOptions}
          FilterOptions={filters}
          SoupOptions={soups}
          HideabilityOptions={hiders}
          KeyOptions={keys}
          Data={Data}
        ></MondrianDataSurface>
        <div className="Mondrian__Grid_Modules_Right">
          {Keyring &&
          props.Options.KeyOptions.Position === ModulePosition.Right ? (
            Keyring
          ) : (
            <></>
          )}
          {Souper &&
          props.Options.SoupOptions.Position === ModulePosition.Right ? (
            Souper
          ) : (
            <></>
          )}
          {Hider &&
          props.Options.HideabilityOptions.Position === ModulePosition.Right ? (
            Hider
          ) : (
            <></>
          )}
          {Filterer &&
          props.Options.FilterOptions.Position === ModulePosition.Right ? (
            Filterer
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="Mondrian__Grid_Modules_Bottom">
        {Keyring &&
        props.Options.KeyOptions.Position === ModulePosition.Bottom ? (
          Keyring
        ) : (
          <></>
        )}
        {Souper &&
        props.Options.SoupOptions.Position === ModulePosition.Bottom ? (
          Souper
        ) : (
          <></>
        )}
        {Hider &&
        props.Options.HideabilityOptions.Position === ModulePosition.Bottom ? (
          Hider
        ) : (
          <></>
        )}
        {Filterer &&
        props.Options.FilterOptions.Position === ModulePosition.Bottom ? (
          Filterer
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
