import { useEffect, useState } from "react";
import InnerHeader from "./InnerHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PublicClientApplication,
  EndSessionRequest,
} from "@azure/msal-browser";
import { IMsalContext } from "@azure/msal-react";
import { User } from "../../data/DataAccess";
import { DB_UserResponse } from "../../models/DB_UserResponse";
import ModeStruct from "../../models/ModeStruct";
import SPOUploadLocation from "../../models/SPOUploadLocation";
import TaskListGroup from "../../models/TaskListGroup";
import { useSurface } from "../../Providers/SurfaceProvider";
import { InvarDataAccess } from "../../data/InvarDataAccess";

interface IProps {
  IDA: InvarDataAccess;

  MSAL: IMsalContext;
  PCA: PublicClientApplication;
  TailoredLogoutRequest: EndSessionRequest;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Title: string;

  Header_AuthedCharmData?: DB_UserResponse;
  Header_ViewAsCharmData?: DB_UserResponse;
  AuthedUser: User;
  ViewAsUser: User;
  ViewAsNotFound: boolean;
  ViewAsAll: boolean;

  Mode: string[];
  Modes: ModeStruct[];
  TaskListGroups: TaskListGroup[] | undefined;

  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetTaskList: (TLRef: string) => void;
  Permission: (surface: string, scope: string, impish: boolean) => boolean;
  SetImpersonation: (alias?: string) => void;

  HiVis: boolean;
  SetHiVis: (val: boolean) => void;

  MenuVisibilities: Map<string, boolean>;
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;

  SetThemeName: (input: string) => void;
}

export const Header = (props: IProps) => {
  const [alias, setAlias] = useState<string>("");
  const [miniSearchTerm, setMiniSearchTerm] = useState<string>("");
  const Surface = useSurface();
  const Location = useLocation();
  const Navigation = useNavigate();

  useEffect(() => {
    setAlias(load("alias", props.SetImpersonation));
  }, []);

  useEffect(() => {
    if (alias === "") {
      window.sessionStorage.removeItem("alias");
    } else {
      window.sessionStorage.setItem("alias", alias);
    }
  }, [alias]);

  return (
    <InnerHeader
      {...props}
      Surface={Surface}
      Location={Location}
      Navigation={Navigation}
      _alias={alias}
      _setAlias={setAlias}
      _miniSearchTerm={miniSearchTerm}
      _setMiniSearchTerm={setMiniSearchTerm}
      SetThemeName={props.SetThemeName}
    />
  );
};

function load(key, si) {
  var item = window.sessionStorage.getItem(key) || "";
  if (item === "null" || item === "undefined") {
    item = "";
  }
  si(item);
  return item;
}
