import * as React from "react";
import Timestamp from "./Timestamp";
import { Toggles } from "./Toggles";

interface IProps {
  Toggles: Toggles;
  Object: any;
}

class TLEntryDesignationQueue extends React.Component<IProps> {
  public render() {
    return (
      <div className="DesignationEntry">
        <div className="Icon">&#128269;</div>
        <div className="Label">Event Scan</div>
        <Timestamp
          Timestamp={this.props.Object["Timestamp"]}
          Toggles={this.props.Toggles}
        />
        Scan
        {this.props.Object["Subtype"] === "Queued" ? (
          <React.Fragment> started...</React.Fragment>
        ) : (
          <React.Fragment>
            {" "}
            complete: <b>{this.props.Object["Detail"]}</b>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default TLEntryDesignationQueue;
