import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../data/DataAccess";
import { Logging } from "../../Logging";
import "../../assets/css/PGEAF_LF/PGEAF_LF.css";
import { Link } from "react-router-dom";
import IfPermitted from "../IfPermitted";
import dayjs from "dayjs";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<string>;
  AuthedUser: User;
  ViewAsUser: User;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;

  Navigation: (url: string) => void;
}

interface IState {
  // functional state parts
  Errors: string[];
  _SearchFor: string;
}

declare global {
  interface Window {
    showDeclareUCAS?: any;
  }
}

const StringRegexIdentifier = /^[a-zA-Z0-9]*$/;
const StringRegexName = /^[a-zA-Z -]*$/;
const StringRegexEmail = /^[a-zA-Z0-9\.-]*@essex\.ac\.uk$/;
const DateRegexESIS = /^[0-9]{2}\/[a-zA-Z]{3}\/[0-9]{4}$/;
const DateRegexHyphen = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
const DateRegexSlash = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;

class PGEAF_LF extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      _SearchFor: "",
      Errors: [],
    };
  }

  public async componentDidMount() {
    window.document.title =
      "University of Essex - Postgraduate Electronic Applicant Files";
  }

  GetValidDOB = (term: string): dayjs.Dayjs | undefined => {
    if (DateRegexSlash.test(term)) {
      // dayjs constructor has parameters for format and locale,
      // but either doesn't respect them or has an undocumented gotcha
      // that I'm sure exists on the other end of two days' googling
      // or something equally insufferable

      //  let ret = dayjs();
      //  let splits = term.split("/").map((th) => Number.parseInt(th));
      //  ret = ret
      //    .set("date", splits[0])
      //    .set("month", splits[1] - 1) // good old zero-indexed months
      //    .set("year", splits[2]);
      //  if (ret.isValid()) {
      //    return ret;
      //  }

      // just so; the parameters don't work at all unless you import a
      // specific extra sublibrary. the method does not tell you they don't work.
      // it just blithely goes on about its day as though it respected all your
      // inputs. this industry is driving me to insanity.

      if (dayjs(term, "DD/MM/YYYY", true).isValid()) {
        return dayjs(term, "DD/MM/YYYY", true);
      }
    }

    if (DateRegexHyphen.test(term)) {
      if (dayjs(term, "DD-MM-YYYY", true).isValid()) {
        return dayjs(term, "DD-MM-YYYY", true);
      }
    }

    if (DateRegexESIS.test(term)) {
      // haha, this one doesn't work in strict! Very odd.
      if (dayjs(term).isValid()) {
        return dayjs(term);
      }
    }

    return undefined;
  };

  IsValidStringSearch = (term: string): boolean => {
    return (
      StringRegexEmail.test(term) ||
      StringRegexName.test(term) ||
      StringRegexIdentifier.test(term)
    );
  };

  IsValidSearch = (term: string): boolean => {
    return (
      StringRegexEmail.test(term) ||
      StringRegexName.test(term) ||
      StringRegexIdentifier.test(term) ||
      DateRegexESIS.test(term) ||
      DateRegexHyphen.test(term) ||
      DateRegexSlash.test(term)
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="StructPanels">
          <div className="StructPanelCluster Row Halved">
            <IfPermitted Whitelist={["PGEAF.Reader"]}>
              <div className="StructPanelBlock">
                <div className="StructPanel Cassette">
                  <div className="PanelBar Title">
                    <div className="Left">
                      Search Current/Archived Applicants
                    </div>
                    <div className="Breaker"></div>
                    <div className="Right">PGEAF</div>
                  </div>
                  {/* <div className='PanelBar Action Search'>Search Current or Archived Applicants</div> */}
                  <div className="PanelBody">
                    <div id="Search">
                      <p className="InputRow Wide">
                        <label htmlFor="Searcher">
                          Enter a PG number, a surname, or a PRID.
                        </label>
                      </p>
                      <p className="InputRow Wide">
                        <input
                          className={
                            !this.IsValidSearch(this.state._SearchFor)
                              ? "InvalidSearch"
                              : undefined
                          }
                          type="text"
                          value={this.state._SearchFor}
                          id="Searcher"
                          name="Searcher"
                          onChange={(event) => {
                            const pattern = /^[0-9A-Za-z\/ '\-@\.]*$/g;
                            const inp = event.target.value;
                            this.setState({
                              _SearchFor:
                                inp === ""
                                  ? ""
                                  : pattern.test(inp)
                                    ? inp
                                    : this.state._SearchFor,
                            });
                          }}
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              const sterm =
                                this.state._SearchFor ??
                                "".trim().replaceAll(" ", "_");

                              const dterm = this.GetValidDOB(sterm);

                              if (dterm !== undefined) {
                                this.props.Navigation(
                                  "/PGEAF/Search/CurrentPGApplicantsbyDOB/" +
                                    dterm.format("DD-MM-YYYY"),
                                );
                              } else {
                                this.props.Navigation(
                                  "/PGEAF/Search/CurrentPGApplicants/" + sterm,
                                );
                              }
                            }
                          }}
                          onPaste={(e) => {
                            const sterm = e.clipboardData
                              .getData("text")
                              .trim();

                            if (DateRegexESIS.test(sterm)) {
                              const dterm = this.GetValidDOB(sterm);
                              if (dterm !== undefined) {
                                // Only accept values in format 01/JAN/2021 or 01-01-1999
                                this.props.Navigation(
                                  "/PGEAF/Search/CurrentUGApplicantsbyDOB/" +
                                    dterm.format("DD-MM-YYYY"),
                                );
                              }
                            }
                          }}
                        />
                        {this.state._SearchFor === "" ? (
                          <></>
                        ) : this.IsValidSearch(this.state._SearchFor) ? (
                          <></>
                        ) : this.GetValidDOB(this.state._SearchFor) !==
                          undefined ? (
                          <></>
                        ) : (
                          <div className="InvalidSearch">
                            Invalid or incomplete input. This field accepts
                            dates, names, logins, or UoE reference codes (PRID,
                            PG No, etc).
                          </div>
                        )}
                      </p>
                      <p className="SubmitRow Left">
                        {this.IsValidStringSearch(this.state._SearchFor) ? (
                          <Link
                            to={this.props.GetLink(
                              [
                                { Mode: "PGEAF", Index: 0 },
                                { Mode: "Search", Index: 1 },
                                {
                                  Mode: "CurrentPGApplicants",
                                  Index: 2,
                                },
                                {
                                  Mode: this.state._SearchFor
                                    .trim()
                                    .replaceAll(" ", "_"),
                                  Index: 3,
                                },
                              ],
                              [],
                            )}
                          >
                            Search Current Applicants
                          </Link>
                        ) : this.GetValidDOB(this.state._SearchFor) !==
                          undefined ? (
                          <Link
                            className="CurrentSearch"
                            to={this.props.GetLink(
                              [
                                { Mode: "PGEAF", Index: 0 },
                                { Mode: "Search", Index: 1 },
                                {
                                  Mode: "CurrentPGApplicantsbyDOB",
                                  Index: 2,
                                },
                                {
                                  Mode: this.GetValidDOB(
                                    this.state._SearchFor,
                                  ).format("DD-MM-YYYY"),
                                  Index: 3,
                                },
                              ],
                              [],
                            )}
                          >
                            Search Current Applicants
                          </Link>
                        ) : (
                          <span className="CurrentSearch Disabled">
                            Search Current Applicants
                          </span>
                        )}
                        {this.IsValidStringSearch(this.state._SearchFor) ? (
                          <Link
                            className="ArchiveSearch"
                            to={this.props.GetLink(
                              [
                                { Mode: "PGEAF", Index: 0 },
                                { Mode: "Search", Index: 1 },
                                { Mode: "ArchivedPGApplicants", Index: 2 },
                                {
                                  Mode: this.state._SearchFor
                                    .trim()
                                    .replaceAll(" ", "_"),
                                  Index: 3,
                                },
                              ],
                              [],
                            )}
                          >
                            Search Archived Applicants
                          </Link>
                        ) : (
                          <span className="ArchiveSearch Disabled">
                            Search Archived Students
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </IfPermitted>
            <IfPermitted Whitelist={["PGEAF.Uploader"]}>
              <div className="StructPanelBlock">
                <Link
                  className="StructPanel"
                  to={this.props.GetLink(
                    [
                      { Mode: "PGEAF", Index: 0 },
                      { Mode: "Upload", Index: 1 },
                    ],
                    [],
                  )}
                >
                  <div className="PanelBar Title">
                    <div className="Left">Upload Documents</div>
                    <div className="Breaker"></div>
                    <div className="Right">PGEAF</div>
                  </div>
                  {/* <div className='PanelBar Action UG'>Upload Documents</div> */}
                  <div className="PanelBody">
                    Upload supporting documents to applications.
                  </div>
                </Link>
              </div>
            </IfPermitted>
          </div>
          <div className="StructPanelCluster Row Halved">
            <IfPermitted Whitelist={["PGEAF.Admissions"]}>
              <div className="StructPanelBlock">
                <h2>For Admissions Staff Only</h2>
                <div className="StructPanel Cassette">
                  <div className="PanelBar Title">
                    <div className="Left">
                      Standard application task screens
                    </div>
                    <div className="Breaker"></div>
                    <div className="Right">PGEAF</div>
                  </div>
                  <div className="PanelBody"></div>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_Unprocessed", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    1: Unprocessed Applications
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_MyTasks", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    2: PG My Tasks
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_Pending", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    3: Pending Applications
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_Selection", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    4: Applicants at Selection
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_ReferredToManager", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    8: Referred to Manager
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_PSE_Unprocessed", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    PSE 1: Unprocessed Applications
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_PSE_Pending", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    PSE 3: Pending Applications
                  </Link>
                </div>
                <div className="StructPanel UsefulLinks Cassette">
                  <div className="PanelBar Title">
                    <div className="Left">Useful Links</div>
                    <div className="Breaker"></div>
                    <div className="Right"></div>
                  </div>
                  <div className="PanelBody">
                    <p>These links will open in a new tab.</p>
                    <p>
                      <span>
                        <a
                          href="https://essexuniversity.sharepoint.com/sites/EDRM/"
                          target="_blank"
                        >
                          EDRM Search Hub
                        </a>
                      </span>
                    </p>
                    <p>
                      <span>
                        <a
                          href="https://amp.essex.ac.uk/UEIC/PreSessionalEnglishChoice/Index"
                          target="_blank"
                        >
                          AMP
                        </a>
                      </span>
                    </p>
                    <p>
                      <span>
                        <a href="https://eds.essex.ac.uk/" target="_blank">
                          Electronic Documentation System
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </IfPermitted>
            <IfPermitted Whitelist={["PGEAF.Reader"]}>
              <div className="StructPanelBlock">
                <h2>For Academic Department Staff, Selectors, & Admissions</h2>
                <div className="StructPanel Cassette">
                  <div className="PanelBar Title">
                    <div className="Left">
                      Non-standard application task screens
                    </div>
                    <div className="Breaker"></div>
                    <div className="Right">PGEAF</div>
                  </div>
                  <div className="PanelBody"></div>
                  <IfPermitted Whitelist={["ESF.Reader"]}>
                    <Link
                      className="PanelBar Action"
                      to={this.props.GetLink(
                        [
                          { Mode: "PGEAF", Index: 0 },
                          { Mode: "Tasklists", Index: 1 },
                          { Mode: "WEBUI_mytasks", Index: 2 },
                        ],
                        [],
                      )}
                    >
                      My PG Selection Tasks
                    </Link>
                  </IfPermitted>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_Dept_Selectors", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    4b: Applicants at Selection By Department
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_Dept_Interviews", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    5: Awaiting Interview by Department
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_Dept_Waitlist", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    6: Waiting List by Department
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_Dept_Incomplete", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    7: Incomplete Applications by Department
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "PGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_PGDRF_DEPTLINKS", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    9: Outcomes by Department: Reject, Offer, Firm Accept
                  </Link>
                </div>
              </div>
            </IfPermitted>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PGEAF_LF;
