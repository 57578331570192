import React from "react";
import {
  Checkbox,
  Label,
  TextField,
  TooltipHost,
  Dropdown,
  IDropdownOption,
  DatePicker,
} from "@fluentui/react";
import "../SPUploadManager/datepicker-mutations.css";
import { SPOMetadataField } from "../SPUploadManager/SPInterfacing/SPOMetadataField";

interface IMetaDataFieldProps {
  field: SPOMetadataField;
  value?: string;
  errorText?: string;
  UpdateField: (metadataField: string, value: string | boolean | Date) => void;
}

export function MetaDataField(props: IMetaDataFieldProps) {
  return (
    <React.Fragment>
      <div className="metadata-field Error">
        <TooltipHost
          content={
            "" // props.field.Description
          }
        >
          <Label className="metadata-field-label">
            {props.field.displayName}
          </Label>
        </TooltipHost>
        {props.field.type === "ReadOnly"
          ? renderReadonlyField(props)
          : props.field.type === "Text"
            ? renderTextField(props)
            : props.field.type === "Boolean"
              ? renderBoolField(props)
              : props.field.type === "DateTime"
                ? renderDateTimeField(props)
                : props.field.type === "Choice"
                  ? renderChoiceField(props)
                  : props.field.type === "MultiChoice"
                    ? renderMultiChoiceField(props)
                    : props.field.type === "Note"
                      ? renderTextAreaField(props)
                      : renderDefaultField(props)}
      </div>
    </React.Fragment>
  );
}

function renderDefaultField(props: IMetaDataFieldProps) {
  return (
    <React.Fragment>
      --!!-- UNKNOWN METADATA TYPE: {props.field.type} --!!--
      {props.value as string}
    </React.Fragment>
  );
}

function renderReadonlyField(props: IMetaDataFieldProps) {
  return <TextField disabled={true} value={props.value as string} />;
}

function renderTextField(props: IMetaDataFieldProps) {
  return (
    <TextField
      required={props.field.required}
      value={props.value as string}
      onChange={(_, val) => props.UpdateField(props.field.internalName, val)}
      errorMessage={props.errorText}
    />
  );
}

function renderTextAreaField(props: IMetaDataFieldProps) {
  return (
    <TextField
      required={props.field.required}
      value={props.value as string}
      onChange={(_, val) => props.UpdateField(props.field.internalName, val)}
      multiline
      errorMessage={props.errorText}
    />
  );
}

function renderBoolField(props: IMetaDataFieldProps) {
  return (
    <Checkbox
      required={props.field.required}
      checked={JSON.parse((props.value ?? "false").toString().toLowerCase())}
      onChange={(_, val) => {
        props.UpdateField(
          props.field.internalName,
          val === undefined ? "" : val,
        );
      }}
    />
  );
}

function renderDateTimeField(props: IMetaDataFieldProps) {
  let propsDate: Date | undefined;

  if (props.value != undefined && props.value != "") {
    propsDate = new Date(Date.parse(props.value as string));
  }

  return (
    <React.Fragment>
      <DatePicker
        formatDate={(d: Date): string => {
          return d.toLocaleDateString();
        }}
        isRequired={props.field.required}
        value={propsDate}
        onSelectDate={(val) => {
          props.UpdateField(props.field.internalName, val);
        }}
      />
      {props.errorText}
    </React.Fragment>
  );
}

function renderMultiChoiceField(props: IMetaDataFieldProps) {
  let _options: IDropdownOption[] = props.field.options?.map((opt) => ({
    key: opt,
    text: opt,
  }));

  if (_options.length === 1) {
    return (
      <Dropdown
        multiSelect={true}
        selectedKey={props.value}
        required={props.field.required}
        options={_options}
        onChange={(_, val) =>
          props.UpdateField(
            props.field.internalName,
            val === undefined ? "" : val.key.toString(),
          )
        }
        errorMessage={props.errorText}
      />
    );
  } else {
    return (
      <Dropdown
        multiSelect={true}
        required={props.field.required}
        options={_options}
        onChange={(_, val) =>
          props.UpdateField(
            props.field.internalName,
            val === undefined ? "" : val.key.toString(),
          )
        }
        errorMessage={props.errorText}
      />
    );
  }
}

function renderChoiceField(props: IMetaDataFieldProps) {
  let _options: IDropdownOption[] = props.field.options?.map((opt) => ({
    key: opt,
    text: opt,
  }));

  if (_options.length === 1) {
    return (
      <Dropdown
        multiSelect={false}
        selectedKey={props.value}
        required={props.field.required}
        options={_options}
        onChange={(_, val) =>
          props.UpdateField(
            props.field.internalName,
            val === undefined ? "" : val.key.toString(),
          )
        }
        errorMessage={props.errorText}
      />
    );
  } else {
    return (
      <Dropdown
        multiSelect={false}
        required={props.field.required}
        selectedKey={props.value}
        options={_options}
        onChange={(_, val) =>
          props.UpdateField(
            props.field.internalName,
            val === undefined ? "" : val.key.toString(),
          )
        }
        errorMessage={props.errorText}
      />
    );
  }
}
