import { useApi } from "../../Providers/ApiProvider";
import "../../assets/css/ECF.css";
import { useFunction } from "../../Providers/FunctionProvider";
import { useUser } from "../../Providers/UserProvider";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import SPOUploadLocation from "../../models/SPOUploadLocation";

interface ECFUploadDocsReportProps {
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  IDA: InvarDataAccess;
  GraphAccessToken: string;
  Refresh: () => void;
  GetSPUL: (shortname: string) => SPOUploadLocation;
}

export function ECFUploadDocsReport(props: ECFUploadDocsReportProps) {
  let user = useUser();
  let api = useApi();
  let functions = useFunction();

  return (
    <div className="ECF_Container">
      <div className="ECF_Subcontainer" id="TitleBlock">
        <div className="ECF_Row" id="PlaceholderRow">
          <span id="content">This page is a placeholder for future work</span>
        </div>
      </div>
    </div>
  );
}
