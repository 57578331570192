import { Dayjs } from "dayjs";
import { GetWorkOrderDatumResponse } from "./GetWorkOrderDatumResponse";

export class GetWorkOrderResponse {
  WorkOrderID: number;
  ConveyanceID: number;
  ConveyanceName: string;
  RequestedBy: string;
  InitialValue: string;
  RequestedDate: Date;
  FulfilledDate: Date;
  Status:
    | "Processing"
    | "Stale"
    | "Completed"
    | "Cancelled"
    | "Failed"
    | "Permanently Failed";
  CurrentStep: number;
  MaxStep: number;
  RelevantMetadata: GetWorkOrderDatumResponse[];
  Data: SerialisableDatum[];
  SegmentaryStatus: SerialisableSegment[];
}

export class SerialisableDatum {
  DataName: string;
  DataValue: string;
  Created: Dayjs | undefined;
}

export class SerialisableSegment {
  Ordinal: number;
  SegmentName: string;
  ActionName: string;
  StepAttempts: SerialisableStep[];
}

export class SerialisableStep {
  Created: Dayjs | undefined;
  Deferral: Dayjs | undefined;
  Failed: Dayjs | undefined;
  Completed: Dayjs | undefined;
}
