import { micromark } from "micromark";
import Notice from "../../../models/Notice";

interface IProps {
  Notice: Notice;
  DeleteNotice?: (_noticeID: string) => void;
  EditNotice?: (_noticeID: string) => void;
}

export const NoticeBlock = (props: IProps) => {
  return (
    <div className="Notice">
      <div className="NoticeTitle">{props.Notice.Title}</div>
      <div
        className="NoticeBody"
        dangerouslySetInnerHTML={{
          __html: micromark(props.Notice.Body_Plain),
        }}
      />
      <div className="NoticeVisibility">
        <div className="NoticeVisibilityTextFrom">Visible from: </div>
        <div className="NoticeFrom">
          {props.Notice.VisibleFrom.local().format("YYYY-MM-DD HH:mm:ss")}
        </div>
        <div className="NoticeVisibilityTextUntil"> Until: </div>
        <div className="NoticeUntil">
          {props.Notice.VisibleUntil.local().format("YYYY-MM-DD HH:mm:ss")}
        </div>
      </div>
      <div className="NoticeScopes">
        <div className="NoticeVisibilityTextScope">Scopes:</div>
        {props.Notice._scopes.map((th, i) => {
          return (
            <div key={"ns_" + i.toString()} className="NoticeScope">
              {th}
            </div>
          );
        })}
      </div>
      {props.EditNotice !== undefined ? (
        <div
          className="NoticeEdit"
          onClick={() => {
            props.EditNotice(props.Notice.NoticeID);
          }}
        >
          Edit
        </div>
      ) : (
        ""
      )}
      {props.DeleteNotice !== undefined ? (
        <div
          className="NoticeDelete"
          onClick={() => {
            props.DeleteNotice(props.Notice.NoticeID);
          }}
        >
          Delete
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
