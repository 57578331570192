import { PublicClientApplication } from "@azure/msal-browser";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { DataAccess } from "../../data/DataAccess";
import { GridWrapper } from "../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../Logging";
import "../../assets/css/AOS2/AOS2_Linked_Student_Withdrawals.css";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface IState {
  _from: Dayjs;
  From: Dayjs;
  _to: Dayjs;
  To: Dayjs;
}

class AOS2_Linked_Student_Withdrawals extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      _from: dayjs().subtract(1, "month"),
      From: dayjs().subtract(1, "month"),
      _to: dayjs(),
      To: dayjs(),
    };
  }

  render() {
    var PassToAllGrids = {
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      NoneMessage: "No results were returned.",
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <div className="AOS2_Linked_Student_Withdrawals">
        <h2>Agent Linked Student Withdrawal Requests Report</h2>
        <div className="RequestRow">
          <label className="inputLabel" htmlFor="ReportFrom">
            Academic Stay Start Date from:
          </label>
          <input
            className="dateinput"
            id="ReportFrom"
            type="date"
            value={
              this.state._from !== undefined
                ? this.state._from.format("YYYY-MM-DD")
                : undefined
            }
            onChange={(e) => {
              if (e.target.value === "") {
                this.setState({ _from: dayjs().subtract(1, "month") });
              } else {
                this.setState({ _from: dayjs(e.target.value) });
              }
            }}
          />
          <label className="inputLabel" htmlFor="ReportTo">
            Academic Stay Start Date to:
          </label>
          <input
            className="dateinput"
            id="ReportTo"
            type="date"
            value={
              this.state._to !== undefined
                ? this.state._to.format("YYYY-MM-DD")
                : undefined
            }
            onChange={(e) => {
              if (e.target.value === "") {
                this.setState({ _to: dayjs() });
              } else {
                this.setState({ _to: dayjs(e.target.value) });
              }
            }}
          />
          <input
            id="submit"
            type="button"
            value="Search"
            onClick={() => {
              this.Search();
            }}
          />
        </div>
        <div className="AOS2_Linked_Student_WithdrawalsPanel">
          <GridWrapper
            legacyURLs={true}
            Callouts={false}
            {...PassToAllGrids}
            key={"SPOWUI_AOSACCLOG_" + this.props.Refresher}
            eid="SPOWUI_AOSACCLOG"
            sproc="sputilities.webui.AOSv2_GetActionedWithdrawalRequests"
            params={[
              "@from",
              this.state.From === undefined
                ? ""
                : this.state.From.format("YYYY-MM-DD"),
              "@to",
              this.state.To === undefined
                ? ""
                : this.state.To.add(1, "day").format("YYYY-MM-DD"),
              "@onlyAgents",
              "0",
            ]}
            class="UoE-Grid AOS"
          />
        </div>
      </div>
    );
  }

  Search() {
    this.setState(
      {
        From: this.state._from,
        To: this.state._to,
      },
      () => {
        this.props.Refresh();
      },
    );
  }
}

export default AOS2_Linked_Student_Withdrawals;
