import { GroupList24Filled, LockClosed24Filled } from "@fluentui/react-icons";
import { ReactElement } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../../Logging";
import { DataAccess } from "../../../data/DataAccess";
import { PublicClientApplication } from "@azure/msal-browser";

interface PgApplicantChoicesAndOffersProps {
  PgNo: string;
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export function PgApplicantChoicesAndOffers(
  props: PgApplicantChoicesAndOffersProps,
): ReactElement {
  let agentDetails: any = useRouteLoaderData("AgentRoot");

  var PassToAllGrids = {
    Callouts: false,
    Logger: props.Logger,
    da: props.da,
    PCA: props.PCA,
    GetLink: props.GetLink,
    URLRoot: props.URLRoot,
    URLParams: props.URLParams,
    pgno: props.PgNo,
    class: "UoE-Grid AOSScreen",
    Refresher: props.Refresher,
    Alias: props.Alias,
    AliasChecked: props.AliasChecked,
    ViewAsAll: props.ViewAsAll,
    RegisterError: props.RegisterError,
    DeregisterError: props.DeregisterError,
    RegisterStatus: props.RegisterStatus,
    DeregisterStatus: props.DeregisterStatus,
  };

  return (
    <>
      <div className="ApplicantGrid" id="ChoicesOffers">
        <div className="ChoicesOffersHeader">
          {/* adding extra classes to allow css to colour sperately */}
          <h2 className="ChoicesOffersHeaderText">
            <span className="LeftIcon24">
              <GroupList24Filled></GroupList24Filled>
            </span>{" "}
            <span className="HeaderText">Choices and Offers</span>
          </h2>
          <span className="PastExample">Past Choice</span>
          <span className="CurrentExample">Current Choice</span>
        </div>
        <GridWrapper
          {...PassToAllGrids}
          eid="ChoicesOffers"
          sproc="sputilities.webui.AOSv2_GetPGApplicantChoicesAndOffers"
          params={[
            "@pgno",
            props.PgNo.toUpperCase(),
            "@username",
            // "{%u}",
            // "@agent",
            "{%iu}",
          ]}
          output="accordion"
          heading="Choice_Summary"
          sort="Choice_Summary"
        ></GridWrapper>
      </div>
    </>
  );
}
