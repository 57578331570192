import { KeyItem } from "../Structs/Interfaces/KeyItem";

interface MondrianKeyProps {
  Keys: KeyItem[];
  SetKeys: (KS: KeyItem[]) => void;
}

export const MondrianKey = (props: MondrianKeyProps) => {
  return (
    <div className="Mondrian__Key">
      {props.Keys.map((th) => {
        return <div className={th.ClassName}>{th.Label}</div>;
      })}
    </div>
  );
};
