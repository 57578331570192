import * as React from "react";
import { DataAccess, IParams } from "../../data/DataAccess";
import GridContent from "./GridContent";
import ListContent from "./ListContent";
import AccordionContent from "./AccordionContent";
import { Logging, LoggingLevel } from "../../Logging";
import { DefaultButton, MessageBar, ProgressIndicator } from "@fluentui/react";
import { IItem } from "../../models/IItem";

interface IProps {
  ForceProxy?: boolean;
  legacyURLs?: boolean;
  Callouts: boolean;
  Logger: NonNullable<Logging>;
  da: DataAccess;
  Refresher: string;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  NoneMessage?: string;
  ButtonsAndFunctions?: {
    Name: string;
    ColumnKey: string;
    Function: (id: any) => void;
  }[];
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll?: boolean;
  eid: string;
  URLParams: string;
  RouteParam1?: string;
  RouteParam2?: string;
  Location: any;
  contentTypes?: string[];
  blackList?: boolean;
  Elevation?: string;
  sproc?: string;
  params?: string[];
  output?: string;
  class?: string;
  sort?: string;
  sortdesc?: string;
  group?: string;
  groupdesc?: string;
  groupcond?: string;
  filter?: string;
  columnclass?: string;
  columns?: string;
  selectcolumns?: string;
  title?: string;
  subtitle?: string;
  rename?: string;
  reorder?: string;
  widths?: string;
  format?: string;
  tooltips?: string;
  toprowcount?: string;
  bottomrowcount?: string;
  heading?: string;
  target?: string;
  options?: string;
  start?: number;
  limit?: number;
  ucasno?: string;
  pgno?: string;
  prid?: string;

  // for edocs_ext only.
  SPVals?: string[];
  HomeOfficeAudit?: boolean;
}

interface IState {
  tables: IItem[][];
  ErrorMessages: Map<string, string>;
  dataToReceive: number;
  dataHasReceived: number;
  title?: string;
  subtitle?: string;
  initialOptions?: string;
  class?: string;
  sort?: string;
  sortdesc?: string;
  group?: string;
  groupdesc?: string;
  groupcond?: string;
  filter?: string;
  columnclass?: string;
  columns?: string;
  selectcolumns?: string;
  widths?: string;
  format?: string;
  tooltips?: string;
  rename?: string;
  reorder?: string;
  toprowcount?: string;
  bottomrowcount?: string;
  target?: string;
  start: number;
  limit: number;
  ucasno?: string;
  pgno?: string;
  prid?: string;
  urlparameters?: any;
  sortBy: string[][];
  sortByDesc: boolean[][];
  filterBy: string[][][];
  options?: string;
  showAdvancedSort: boolean;
  showAdvancedGrouping: boolean;
  showAdvancedFiltration: boolean;
  showCookieBouncer: boolean;
}

class Grid extends React.Component<IProps, IState> {
  createPageList = () => {
    let numlist = [];
    var thisLimit = this.state.limit || 100;
    var thisStart = this.state.start || 0;
    var currentPage = thisStart.valueOf() / thisLimit.valueOf(); // essentially a zero-indexed page number.
    for (let i = 0; i <= currentPage; i++) {
      numlist.push(
        i === currentPage ? (
          <div key={"page_" + (i + 1)} className="pageNo">
            {i + 1}
          </div>
        ) : (
          <DefaultButton
            key={"page_" + (i + 1)}
            className="pageNo"
            onClick={() => this.goToPage(i + 1)}
          >
            {(i + 1).toString()}
          </DefaultButton>
        ),
      );
    }
    return numlist;
  };

  PassUpFilterSortChange = (
    gridindex: any,
    newFilterBy: string[][][],
    newSortBy: string[][],
    newSortByDesc: boolean[][],
  ) => {
    var filterchanged: boolean = false;
    if (this.state.filterBy.length !== newFilterBy.length) {
      filterchanged = true;
    } else {
      if (newFilterBy.length > 0) {
        for (var j = 0; j < newFilterBy[gridindex].length; j++) {
          if (newFilterBy[gridindex][j] !== this.state.filterBy[gridindex][j]) {
            filterchanged = true;
          }
        }
      }
    }

    var newStart = this.state.start;
    if (filterchanged) {
      newStart = 0;
      const q_setPage = this.useQueryParam("page", "1")[1];
      q_setPage("1");
    }
    this.setState({
      filterBy: newFilterBy,
      sortBy: newSortBy,
      sortByDesc: newSortByDesc,
      start: newStart,
    });
    this.refresh_with_new_filtersort(
      newFilterBy,
      newSortBy,
      newSortByDesc,
      newStart.valueOf(),
    );
  };

  componentWillReceiveProps(nextProps) {
    //  console.log('componentWillReceiveProps');
    //  console.log(this.props.ViewAsUser);
    //  console.log(nextProps.ViewAsUser);
    //  console.log(this.props.params);
    //  console.log(nextProps.params);
    if (
      nextProps.params !== undefined &&
      this.props.params !== undefined &&
      JSON.stringify(nextProps.params) !== JSON.stringify(this.props.params)
    ) {
      this.loadData(nextProps);
    } else if (nextProps.Alias !== this.props.Alias) {
      this.loadData(nextProps);
    }

    if (
      nextProps.eid !== undefined &&
      this.props.eid !== undefined &&
      nextProps.eid !== this.props.eid
    ) {
      this.loadData(nextProps);
    }
  }

  constructor(props: IProps) {
    super(props);

    const loadURLParameters: any = {};
    loadURLParameters["page"] = this.getQueryStringVal("page");
    const qn_Page =
      loadURLParameters["page"] && loadURLParameters["page"] !== ""
        ? parseInt(loadURLParameters["page"])
        : 1;

    var computed_selectcolumns = "";

    if (this.props.columns) {
      this.props.columns.split(",").forEach((colu) => {
        // if mmdname exists, use it.
        // otherwise, colname
        // otherwise, displayname

        var colName = "";
        var truecolu = colu.trim();
        if (truecolu.includes("=")) {
          colName = truecolu.split("=")[0];
        } else {
          colName = truecolu;
        }
        computed_selectcolumns +=
          (computed_selectcolumns.length > 0 ? "," : "") + colName;
      });
    }

    this.state = {
      ucasno: props.ucasno,
      pgno: props.pgno,
      prid: props.prid,
      tables: [],
      ErrorMessages: new Map<string, string>(),
      dataToReceive: 1,
      dataHasReceived: 0,
      title: this.props.title,
      subtitle: this.props.subtitle,
      class: this.props.class
        ? this.props.class.replace("UoE-Grid", "").trim()
        : undefined,
      sort: this.props.sort,
      sortdesc: this.props.sortdesc,
      group: this.props.group,
      groupdesc: this.props.groupdesc,
      groupcond: this.props.groupcond,
      filter: this.props.filter,
      columnclass: this.props.columnclass,
      columns: this.props.columns,
      selectcolumns: computed_selectcolumns,
      widths: this.props.widths,
      format: this.props.format,
      rename: this.props.rename,
      options: this.props.options,
      reorder: this.props.reorder,
      toprowcount: this.props.toprowcount,
      bottomrowcount: this.props.bottomrowcount,
      target: this.props.target,
      start:
        loadURLParameters["page"] !== ""
          ? (qn_Page - 1) * (this.props.limit || 0)
          : this.props.start,
      limit: this.props.limit,
      urlparameters: loadURLParameters,
      sortBy: [],
      sortByDesc: [],
      filterBy: [],
      showAdvancedFiltration: false,
      showAdvancedGrouping: false,
      showAdvancedSort: false,
      showCookieBouncer: false,
    };

    this.props.Logger.log("Starting GridAPI Part...", LoggingLevel.INFO);
  }

  public async componentDidMount() {
    this.props.Logger.log("Mounting GridAPI Part...", LoggingLevel.TRACE);

    this.loadData(this.props);
  }

  public async loadData(props: IProps) {
    this.clearData();

    var c_sortBy = [];
    var c_sortByDesc = [];
    var c_filterBy = [];
    var c_start = [];
    for (var i = 0; i < this.state.tables.length; i++) {
      props.Logger.log(
        "Checking for Cookie: co__" +
          props.eid.replaceAll(props.Refresher, "") +
          "__" +
          (props.output ? props.output : "") +
          "__" +
          (props.sproc ? props.sproc : ""),
        LoggingLevel.INFO,
      );
      const columnOptions = JSON.parse(
        localStorage.getItem(
          "co__" +
            props.eid.replaceAll(props.Refresher, "") +
            "__" +
            (props.output ? props.output : "") +
            "__" +
            (props.sproc ? props.sproc : ""),
        ) || "{}",
      );
      if (columnOptions) {
        props.Logger.log(
          "Loaded Column Options from Cookie: " + JSON.stringify(columnOptions),
          LoggingLevel.INFO,
        );
        c_sortBy[i] = columnOptions[2] || undefined;
        c_sortByDesc[i] = columnOptions[3] || undefined;
        c_filterBy[i] = columnOptions[4] || undefined;
        c_start[i] = columnOptions[5] || undefined;
      }
    }

    const search = new URLSearchParams(window.location.search);

    //  console.log('props.Alias');
    //  console.log(props.Alias);

    const params: IParams = {
      blackList: props.blackList,
      contentTypes: props.contentTypes,
      params: props.params,
      sproc: props.sproc,
      ucasno: props.ucasno as string,
      pgno: props.pgno as string,
      prid: props.prid as string,
      location: window.location.href,
      rename: props.rename,
      options: props.options,
      start: Number.parseInt(c_start.toString()) || props.start,
      limit: props.limit,
      columns: this.state.columns,
      selectcolumns: this.state.selectcolumns,
      sortBy: c_sortBy || this.state.sortBy,
      sortByDesc: c_sortByDesc || this.state.sortByDesc,
      filterBy: c_filterBy || this.state.filterBy,
      forceProxy: props.ForceProxy || false,
    };

    if (params.options !== undefined) {
      this.loadOptions(params.options);
    }

    await props.da.getCurrentUser().then(
      (data) => {
        params.user = data;
      },
      (reason) => {
        props.Logger.log(reason, LoggingLevel.DEBUG);
      },
    );

    if (params.sproc !== undefined) {
      if (props.output === "tasklist") {
        await props.da
          .getImp(
            "/api/tasklist",
            JSON.stringify(params),
            true,
            props.Alias,
            props.ViewAsAll,
          )
          .then(
            (data) => {
              if (data[0] === "ERROR") {
                console.log("ERR");
                console.log(params);
                console.log(data);
                props.RegisterError(props.eid, data[1]);
              } else {
                props.DeregisterError(props.eid);
                let p: string[] = [];
                //  console.log("data[3]");
                //  console.log(data[3]);
                //  console.log("this.props.URLParams");
                //  console.log(this.props.URLParams);
                //  console.log("this.props.params");
                //  console.log(this.props.params);
                //  console.log("this.props.RouteParam1");
                //  console.log(this.props.RouteParam1);
                //  console.log("this.props.RouteParam2");
                //  console.log(this.props.RouteParam2);

                data[3].split(";").forEach((ps, i) => {
                  if (ps.includes("=")) {
                    const paramname = ps.split("=")[0];
                    const paramval = ps.split("=")[1];
                    //  console.log("paramname");
                    //  console.log(paramname);
                    //  console.log("paramval");
                    //  console.log(paramval);
                    if (paramname.toLowerCase() === "@filter") {
                      p.push(paramname);
                      p.push(paramval);
                    } else if (paramname.toLowerCase() === "@overridefilter") {
                      p.push(paramname);
                      //  console.log("@overridefilter");
                      //  console.log(this.props.ViewAsAll);
                      if (this.props.ViewAsAll) {
                        p.push("--ALL--");
                      } else {
                        p.push(paramval);
                      }
                    } else {
                      const paramname = ps.split("=")[0];

                      if (
                        this.props.params !== undefined &&
                        this.props.params.indexOf(paramname) > -1
                      ) {
                        //  console.log(paramname);
                        //  console.log(this.props.params);
                        var f = this.props.params.indexOf(paramname);
                        //  console.log(f);

                        if (this.props.params.indexOf(paramname) > -1) {
                          p.push(this.props.params[f]);
                          p.push(this.props.params[f + 1]);
                        }
                      } else if (
                        this.props.URLParams !== undefined &&
                        this.props.URLParams !== ""
                      ) {
                        //  console.log("this.props.URLParams");
                        //  console.log(this.props.URLParams);

                        const pars = this.props.URLParams.split("&");
                        //  console.log('pars');
                        //  console.log(pars);
                        const par = pars.find(
                          (th) =>
                            th.split("=")[0].toLowerCase() ===
                            paramval
                              .toLowerCase()
                              .replace("{", "")
                              .replace("}", ""),
                        );
                        //  console.log('par');
                        //  console.log(par);
                        if (this.props.URLParams.indexOf("=") > -1) {
                          p.push(paramname);
                          p.push(par.split("=")[1]);
                        }
                        // haaaaax
                      } else if (this.props.RouteParam1 !== undefined) {
                        p.push(paramname);
                        p.push(this.props.RouteParam1);
                      } else if (this.props.RouteParam2 !== undefined) {
                        p.push(paramname);
                        p.push(this.props.RouteParam2);
                      } else if (this.props.RouteParam1 === undefined) {
                        var ind = p.indexOf(this.props.RouteParam1);
                        if (ind > 0) {
                          var ind2 = ind - 1;
                          p = p.filter((th, i) => {
                            i !== ind && i !== ind2;
                          });
                        }
                      } else if (this.props.RouteParam2 === undefined) {
                        var ind = p.indexOf(this.props.RouteParam2);
                        if (ind > 0) {
                          var ind2 = ind - 1;
                          p = p.filter((th, i) => {
                            i !== ind && i !== ind2;
                          });
                        }
                      }
                    }
                  }
                });
                //  console.log(p);
                this.setState({
                  title: data[0],
                  subtitle: data[1],
                  initialOptions: data[4],
                });
                this.loadOptions(data[4]);
                params.sproc = data[2];
                params.params = p; //props.params;
                window.document.title = data[0];
              }
            },
            (reason) => {
              //  console.log('ERR');
              //  console.log(params);
              //  console.log(reason);
              props.RegisterError(props.eid, reason);
            },
          );
      }
      params.rename = this.state.rename;
      if (
        params.sproc !== undefined &&
        this.state.ErrorMessages.entries.length === 0
      ) {
        let multiSprocs = params.sproc.split(";");
        if (multiSprocs.length > 1) {
          this.setState({ dataToReceive: multiSprocs.length });
          multiSprocs.forEach((sproc, i) => {
            let singleSproc = params;
            singleSproc.sproc = sproc;
            props.da
              .getImp(
                "/sproc",
                JSON.stringify(singleSproc),
                true,
                props.Alias,
                props.ViewAsAll,
              )
              .then(
                (data) => {
                  props.DeregisterError(singleSproc.sproc);
                  this.handleData(data);
                },
                (reason) => {
                  //  console.log('ERR');
                  //  console.log(params);
                  //  console.log(reason);
                  props.RegisterError(singleSproc.sproc, reason);
                },
              );
          });
        } else {
          props.da
            .getImp(
              "/sproc",
              JSON.stringify(params),
              true,
              props.Alias,
              props.ViewAsAll,
            )
            .then(
              (data) => {
                props.DeregisterError(params.sproc);
                this.handleData(data);
              },
              (reason) => {
                //  console.log('ERR');
                //  console.log(params);
                //  console.log(reason);
                props.RegisterError(params.sproc, reason);
              },
            );
        }
      }
    } else if (
      props.output !== undefined &&
      props.output.startsWith("extended_edocs")
    ) {
      params.HomeOfficeAudit = props.HomeOfficeAudit;
      params.spvals = props.SPVals;
      if (props.ForceProxy) {
        params.forceProxy = true;
      }

      props.da.get("/api/extendededocs", JSON.stringify(params)).then(
        (data) => {
          props.DeregisterError(props.eid);
          this.handleData(data);
        },
        (reason) => {
          //  console.log('ERR');
          //  console.log(params);
          //  console.log(reason);
          props.RegisterError(props.eid, reason);
        },
      );
    } else if (props.output !== undefined && props.output.startsWith("edocs")) {
      params.sproc = props.output;
      if (props.ForceProxy) {
        params.forceProxy = true;
      }
      //props.da.get('/api/edocs', JSON.stringify(params))
      props.da
        .getEdocs(
          params.prid ?? params.ucasno ?? params.pgno,
          props.Alias,
          params.contentTypes,
          params.blackList,
        )
        .then(
          (data) => {
            props.DeregisterError(props.eid);
            this.handleData(data);
          },
          (reason) => {
            //  console.log('ERR');
            //  console.log(params);
            //  console.log(reason);
            props.RegisterError(props.eid, reason);
          },
        );
    } else if (
      props.output !== undefined &&
      props.output.startsWith("audit-docs")
    ) {
      params.sproc = props.output;
      if (props.ForceProxy) {
        params.forceProxy = true;
      }
      //props.da.get('/api/edocs', JSON.stringify(params))
      props.da.getAuditDocs(params.prid ?? params.ucasno ?? params.pgno).then(
        (data) => {
          props.DeregisterError(props.eid);
          this.handleData(data);
        },
        (reason) => {
          //  console.log('ERR');
          //  console.log(params);
          //  console.log(reason);
          props.RegisterError(props.eid, reason);
        },
      );
    } else if (
      //false &&
      props.output !== undefined &&
      props.output.startsWith("aos-special-ucasapplicationform")
    ) {
      // We don't need a special endpoint for the ucas forms - just a little gentle handling on this end...
      props.da.getEdocs(params.ucasno, props.Alias, ["uaducasform"]).then(
        (data) => {
          if (data.length == 0 || data[0].length == 0) {
            let d: IItem = {};

            d["SUBHEADER"] = `No Application Print Views Available`;

            props.DeregisterError(props.eid);
            this.handleData_REPLACE([[d]]);

            return;
          }
          data.sort((a, b) => {
            let aDate = new Date(a);
            let bDate = new Date(b);
            if (aDate == bDate) {
              return 0;
            } else if (aDate > bDate) {
              return 1;
            } else {
              return -1;
            }
          });

          props.DeregisterError(props.eid);
          this.handleData_REPLACE([[data[0][0]]]);
        },
        (reason) => {
          //  console.log('ERR');
          //  console.log(params);
          //  console.log(reason);
          props.RegisterError(props.eid, reason);
        },
      );
    } else if (
      //false &&
      props.output !== undefined &&
      props.output.startsWith("special-ucasapplicationform")
    ) {
      // We don't need a special endpoint for the ucas forms - just a little gentle handling on this end...
      props.da.getEdocs(params.ucasno, props.Alias, ["uaducasform"]).then(
        (data) => {
          if (data.length == 0 || data[0].length == 0) {
            let d: IItem = {};

            d["SUBHEADER"] = `No Application Print Views Available`;

            props.DeregisterError(props.eid);
            this.handleData_REPLACE([[d]]);

            return;
          }
          data.sort((a, b) => {
            let aDate = new Date(a);
            let bDate = new Date(b);
            if (aDate == bDate) {
              return 0;
            } else if (aDate > bDate) {
              return 1;
            } else {
              return -1;
            }
          });

          let d: IItem = {};

          d["SUBHEADER"] = `<a href='${
            data[0][0]["Filename_URL"]
          }' target='_blank'><img src='${
            data[0][0]["Filename_ICON"]
          }'/>Application Form <small>(${new Date(
            data[0][0]["Created Date"],
          ).toLocaleDateString("en-GB")}, ${new Date(
            data[0][0]["Created Date"],
          ).toLocaleTimeString("en-GB")})</small></a>`;

          props.DeregisterError(props.eid);
          this.handleData_REPLACE([[d]]);
        },
        (reason) => {
          //  console.log('ERR');
          //  console.log(params);
          //  console.log(reason);
          props.RegisterError(props.eid, reason);
        },
      );
    } else if (
      props.output !== undefined &&
      props.output.startsWith("special")
    ) {
      params.sproc = props.output;
      props.da.get("/api/special", JSON.stringify(params)).then(
        (data) => {
          props.DeregisterError(props.eid);
          this.handleData_REPLACE(data);
        },
        (reason) => {
          //  console.log('ERR');
          //  console.log(params);
          //  console.log(reason);
          props.RegisterError(props.eid, reason);
        },
      );
    } else {
      //  console.log('ERR');
      //  console.log(params);
      //  console.log('Invalid Configuration');
      props.RegisterError(props.eid, "Invalid Configuration");
    }
  }

  qsSquidge(value: string | string[]): string {
    if (value === undefined || value === null) {
      return undefined;
    } else if (value === value.toString()) {
      return value;
    } else if ((value as string[]).forEach !== undefined) {
      var returner: string = "";
      (value as string[]).forEach((th: string) => {
        returner = returner + th + " ";
      });
      returner = returner.trim();
      returner = returner.replace(" ", ",");
      return returner;
    }
  }

  //  public refresh() {
  //    //  const da = new DataAccess(this.state.PCA, this.state.Environment);
  //    const params: IParams = {
  //      blackList: this.props.blackList,
  //      contentTypes: this.props.contentTypes,
  //      //imp: this.props.imp,
  //      params: this.props.da.getQSParams(this.props.params),
  //      sproc: this.props.sproc,
  //      ucasno: this.props.ucasno as string,
  //      pgno: this.props.pgno as string,
  //      prid: this.props.prid as string,
  //      location: window.location.href,
  //      rename: this.props.rename,
  //      start: this.state.start,
  //      limit: this.state.limit,
  //      columns: this.state.columns,
  //      selectcolumns: this.state.selectcolumns,
  //      sortBy: this.state.sortBy,
  //      sortByDesc: this.state.sortByDesc,
  //      filterBy: this.state.filterBy,
  //      options: this.props.options
  //    }
  //
  //    params.sproc = this.props.output;
  //    this.props.da
  //      .get('/api/special', JSON.stringify(params))
  //      .then(
  //        (data) => {
  //          this.props.DeregisterError(params.sproc);
  //          this.handleData_REPLACE(data);
  //        },
  //        (reason) => {
  //  console.log('ERR');
  //  console.log(params);
  //  console.log(reason);
  //          this.props.RegisterError(params.sproc, reason);
  //        }
  //      );
  //  }

  // state updates asynchronously! how frustrating.
  public refresh_with_new_page(newstart: number) {
    //  const da = new DataAccess(this.state.PCA, this.state.Environment);
    //  const qs = queryString.parse(window.location.search);

    const params: IParams = {
      blackList: this.props.blackList,
      contentTypes: this.props.contentTypes,
      //imp: this.props.imp,
      //  imp: (qs.imp || qs.user || qs.agent || qs.advisor || qs.adviser || qs.selector || qs.filter || qs.overridefilter || undefined) as string,
      params: this.props.params,
      sproc: this.props.sproc,
      ucasno: this.props.ucasno as string,
      pgno: this.props.pgno as string,
      prid: this.props.prid as string,
      location: window.location.href,
      rename: this.props.rename,
      start: newstart,
      limit: this.state.limit,
      columns: this.state.columns,
      selectcolumns: this.state.selectcolumns,
      sortBy: this.state.sortBy,
      sortByDesc: this.state.sortByDesc,
      filterBy: this.state.filterBy,
      options: this.props.options,
    };

    params.sproc = this.props.output;
    this.props.da.get("/api/special", JSON.stringify(params)).then(
      (data) => {
        this.props.DeregisterError(params.sproc);
        this.handleData_REPLACE(data);
      },
      (reason) => {
        //  console.log('ERR');
        //  console.log(params);
        //  console.log(reason);
        this.props.RegisterError(params.sproc, reason);
      },
    );
  }

  // state updates asynchronously! how frustrating.
  public refresh_with_new_filtersort(
    newfilters: string[][][],
    newsortby: string[][],
    newsortbydesc: boolean[][],
    newstart: number,
  ) {
    //  const da = new DataAccess(this.state.PCA, this.state.Environment);
    //  const qs = queryString.parse(window.location.search);

    const params: IParams = {
      blackList: this.props.blackList,
      contentTypes: this.props.contentTypes,
      //imp: this.props.imp,
      //  imp: (qs.imp || qs.user || qs.agent || qs.advisor || qs.adviser || qs.selector || qs.filter || qs.overridefilter || undefined) as string,
      params: this.props.params,
      sproc: this.props.sproc,
      ucasno: this.props.ucasno as string,
      pgno: this.props.pgno as string,
      prid: this.props.prid as string,
      location: window.location.href,
      rename: this.props.rename,
      start: newstart,
      limit: this.state.limit,
      columns: this.state.columns,
      selectcolumns: this.state.selectcolumns,
      sortBy: newsortby,
      sortByDesc: newsortbydesc,
      filterBy: newfilters,
      options: this.props.options,
    };

    params.sproc = this.props.output;
    this.props.da.get("/api/special", JSON.stringify(params)).then(
      (data) => {
        this.props.DeregisterError(params.sproc);
        this.handleData_REPLACE(data);
      },
      (reason) => {
        //  console.log('ERR');
        //  console.log(params);
        //  console.log(reason);
        this.props.RegisterError(params.sproc, reason);
      },
    );
  }

  public render() {
    this.props.Logger.log("Rendering GridAPI Part...", LoggingLevel.TRACE);

    return (
      <React.Fragment>
        {this.state.ErrorMessages &&
        this.state.ErrorMessages.entries &&
        (this.state.ErrorMessages.entries.length > 0 ||
          (this.state.ErrorMessages &&
            this.state.ErrorMessages[this.props.sproc]))
          ? this.state.ErrorMessages.forEach((th) => {
              return th !== "" ? <div className="ErrorBar">{th}</div> : "";
            })
          : ""}
        {this.state.dataHasReceived > 0 ? (
          <React.Fragment>
            {this.state.tables.length === 0 &&
            this.state.dataHasReceived >= this.state.dataToReceive ? (
              <div className={this.state.class}>
                {this.state.title ? (
                  <h1 className="title">{this.state.title}</h1>
                ) : (
                  ""
                )}
                {this.state.subtitle ? (
                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{ __html: this.state.subtitle }}
                  />
                ) : (
                  ""
                )}
                <MessageBar className="no-results">
                  {this.props.NoneMessage || "No results were returned."}
                </MessageBar>
              </div>
            ) : this.props.output === "data" || this.props.output === "list" ? (
              <div className={this.state.class}>
                {this.state.title ? (
                  <h1 className="title">{this.state.title}</h1>
                ) : (
                  ""
                )}
                {this.state.subtitle ? (
                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{ __html: this.state.subtitle }}
                  />
                ) : (
                  ""
                )}
                {this.state.tables.map((table, i) => (
                  <ListContent
                    key={i}
                    eid={this.props.eid}
                    items={table}
                    sort={this.state.sort}
                    sortdesc={this.state.sortdesc}
                    target={this.state.target}
                    ButtonsAndFunctions={this.props.ButtonsAndFunctions}
                  />
                ))}
              </div>
            ) : this.props.output === "accordion" ? (
              <div className={this.state.class}>
                {this.state.title ? (
                  <h1 className="title">{this.state.title}</h1>
                ) : (
                  ""
                )}
                {this.state.subtitle ? (
                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{ __html: this.state.subtitle }}
                  />
                ) : (
                  ""
                )}
                {this.state.tables.map((table, i) => (
                  <AccordionContent
                    key={i}
                    eid={this.props.eid}
                    items={table}
                    sort={this.state.sort}
                    sortdesc={this.state.sortdesc}
                    heading={this.props.heading!}
                    target={this.state.target}
                    ButtonsAndFunctions={this.props.ButtonsAndFunctions}
                  />
                ))}
              </div>
            ) : (
              <div className={this.state.class}>
                {this.state.title ? (
                  <h1 className="title">{this.state.title}</h1>
                ) : (
                  ""
                )}
                {this.state.subtitle ? (
                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{ __html: this.state.subtitle }}
                  />
                ) : (
                  ""
                )}
                {this.state.tables.map((table, i) =>
                  table.length === 1 &&
                  (table[0]["HEADER"] ||
                    table[0]["header"] ||
                    table[0]["Header"]) ? (
                    <h2 key={i} className="heading">
                      {table[0]["HEADER"]
                        ? table[0]["HEADER"]
                        : table[0]["header"]
                          ? table[0]["header"]
                          : table[0]["Header"]}
                    </h2>
                  ) : table.length === 1 &&
                    (table[0]["SUBHEADER"] ||
                      table[0]["subheader"] ||
                      table[0]["Subheader"]) ? (
                    <div
                      key={i}
                      className="subheading"
                      dangerouslySetInnerHTML={{
                        __html: table[0]["SUBHEADER"]
                          ? table[0]["SUBHEADER"]
                          : table[0]["subheader"]
                            ? table[0]["subheader"]
                            : table[0]["Subheader"],
                      }}
                    />
                  ) : table.length === 0 &&
                    this.state.dataHasReceived >= this.state.dataToReceive ? (
                    <MessageBar key={i} className="no-results">
                      No results were returned.
                    </MessageBar>
                  ) : (
                    <div key={i}>
                      {table.length > 0 ? (
                        <GridContent
                          Callouts={this.props.Callouts}
                          Logger={this.props.Logger}
                          eid={this.props.eid}
                          items={table}
                          sort={this.state.sort}
                          sortdesc={this.state.sortdesc}
                          group={this.state.group}
                          groupdesc={this.state.groupdesc}
                          groupcond={this.state.groupcond}
                          filter={this.state.filter}
                          columnclass={this.state.columnclass}
                          widths={this.state.widths}
                          format={this.state.format}
                          tooltips={this.state.tooltips}
                          reorder={this.state.reorder}
                          toprowcount={this.state.toprowcount}
                          bottomrowcount={this.state.bottomrowcount}
                          target={this.state.target}
                          funcPassUpFilterSortChange={
                            this.PassUpFilterSortChange
                          }
                          output={this.props.output}
                          sproc={this.props.sproc}
                          gridindex={i}
                          limit={this.state.limit}
                          start={this.state.start}
                          showAdvancedSort={this.state.showAdvancedSort}
                          showAdvancedGrouping={this.state.showAdvancedGrouping}
                          showAdvancedFiltration={
                            this.state.showAdvancedFiltration
                          }
                          showCookieBouncer={this.state.showCookieBouncer}
                          Refresher={this.props.Refresher}
                          ButtonsAndFunctions={this.props.ButtonsAndFunctions}
                        />
                      ) : (
                        ""
                      )}
                      {this.state.dataHasReceived < this.state.dataToReceive ? (
                        <ProgressIndicator className="loading" barHeight={10} />
                      ) : (
                        ""
                      )}
                    </div>
                  ),
                )}
                {this.state.tables.length === 0 &&
                this.state.dataHasReceived < this.state.dataToReceive ? (
                  <ProgressIndicator className="loading" barHeight={10} />
                ) : (
                  ""
                )}
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className={this.state.class}>
            {this.state.title ? (
              <h1 className="title">{this.state.title}</h1>
            ) : (
              ""
            )}
            {this.state.subtitle ? (
              <div
                className="subtitle"
                dangerouslySetInnerHTML={{ __html: this.state.subtitle }}
              />
            ) : (
              ""
            )}
            <ProgressIndicator
              className="loading"
              barHeight={10}
              label="Loading..."
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  private clearData() {
    this.setState({
      tables: [],
      dataHasReceived: 0,
      dataToReceive: 0,
    });
  }

  private handleData(data?: IItem[][]) {
    this.setState((oldState) => ({
      dataHasReceived: oldState.dataHasReceived + 1,
    }));
    this.props.Logger.log(
      "handleData -- Data Received: " +
        this.state.dataHasReceived +
        "/" +
        this.state.dataToReceive,
      LoggingLevel.DEBUG,
    );
    if (data !== undefined) {
      this.props.Logger.log(
        "Rows Received: " + data.length,
        LoggingLevel.DEBUG,
      );
      if (data.length === 1 && data[0].length === 1 && data[0][0]["ERROR"]) {
        //  console.log("ERR");
        //  console.log(this.props);
        //  console.log(data);
        //  console.log(data[0]);
        //  console.log(data[0][0]);
        //  console.log(data[0][0]["ERROR"]);
        this.props.RegisterError(this.props.eid, data[0][0]["ERROR"]);
      } else {
        this.props.DeregisterError(this.props.eid);
        if (this.props.ButtonsAndFunctions !== undefined) {
          for (let i = 0; i < data[0].length; i++) {
            let e = data[0][i];
            this.props.ButtonsAndFunctions.forEach((BAF) => {
              e[BAF.Name + "_FUNC"] = BAF.Name;
            });
          }
        }

        this.setState((oldState) => {
          let newTables = oldState.tables;
          data.map((table, i) => {
            //  console.log('checking recvd data');
            if (newTables[i] === undefined) newTables[i] = [];
            if (table.length === 1 && table[0]["HEADER"]) {
              //  console.log('overwriting undefined');
              newTables[i] = table;
            } else if (table.length === 1 && table[0]["SUBHEADER"]) {
              //  console.log('overwriting subheader');
              newTables[i] = table;
            } else {
              //  console.log('possible concat - checking');
              const newt = table;
              if (JSON.stringify(newTables[i]) === JSON.stringify(newt)) {
                //  console.log('already found');
                //  console.log(newTables[i]);
                //  console.log(newt);
              } else {
                //  console.log('not found');
                //  console.log(newTables[i]);
                //  console.log(newt);
                newTables[i] = newTables[i].concat(table);
              }
            }
            return null;
          });
          return { tables: newTables };
        });
      }
    }
  }

  private handleData_REPLACE(data?: IItem[][]) {
    this.setState((oldState) => ({
      dataHasReceived: oldState.dataHasReceived + 1,
    }));
    this.props.Logger.log(
      "handleData_REPLACE -- Data Received: " +
        this.state.dataHasReceived +
        "/" +
        this.state.dataToReceive,
      LoggingLevel.DEBUG,
    );
    if (data !== undefined) {
      this.props.Logger.log(
        "Tables Received: " + data.length,
        LoggingLevel.DEBUG,
      );
      if (data.length === 1 && data[0].length === 1 && data[0][0]["ERROR"]) {
        //  console.log('ERR');
        //  console.log(this.props);
        //  console.log(data);
        this.props.RegisterError(this.props.eid, data[0][0]["ERROR"]);
      } else {
        this.props.DeregisterError(this.props.sproc);
        this.setState((oldState) => {
          let newTables = oldState.tables;
          data.map((table, i) => {
            if (newTables[i] === undefined) newTables[i] = [];
            if (table.length === 1 && table[0]["HEADER"]) {
              newTables[i] = table;
            } else if (table.length === 1 && table[0]["SUBHEADER"]) {
              newTables[i] = table;
            } else {
              newTables[i] = table;
            }
            return null;
          });
          return { tables: newTables };
        });
      }
    } else {
      //  console.log('ERR');
      //  console.log(this.props);
      //  console.log('No data received.');
      this.props.RegisterError(this.props.eid, "No data received.");
    }
  }

  private loadOptions(options: string) {
    this.props.Logger.log("Loading Options: " + options, LoggingLevel.DEBUG);
    for (const op of options.split("|")) {
      if (op.includes("=")) {
        var newstart: number = 0;
        var newlimit: number = 100;
        if (op.split("=")[0].toLowerCase() === "start") {
          newstart = Number.parseInt(op.split("=")[1] || "0") || 0;
        }
        if (op.split("=")[0].toLowerCase() === "limit") {
          newlimit = Number.parseInt(op.split("=")[1] || "100") || 100;
        }

        if (op.split("=")[0].toLowerCase() === "class")
          this.setState({
            class: op.split("=")[1].replace("UoE-Grid", "").trim(),
          });
        else if (op.split("=")[0].toLowerCase() === "sort")
          this.setState({ sort: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "sortdesc")
          this.setState({ sortdesc: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "group")
          this.setState({ group: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "groupdesc")
          this.setState({ groupdesc: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "groupcollapsed")
          this.setState({ groupcond: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "filter")
          this.setState({ filter: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "columnclass")
          this.setState({ columnclass: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "widths")
          this.setState({ widths: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "format")
          this.setState({ format: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "tooltips")
          this.setState({ tooltips: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "rename")
          this.setState({ rename: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "reorder")
          this.setState({ reorder: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "toprowcount")
          this.setState({ toprowcount: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "bottomrowcount")
          this.setState({ bottomrowcount: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "target")
          this.setState({ target: op.split("=")[1] });
        else if (op.split("=")[0].toLowerCase() === "start")
          this.setState({ start: newstart });
        else if (op.split("=")[0].toLowerCase() === "limit")
          this.setState({ limit: newlimit });
        else if (op.split("=")[0].toLowerCase() === "showadvancedsort")
          this.setState({
            showAdvancedSort: op.split("=")[1].toLowerCase() === "true",
          });
        else if (op.split("=")[0].toLowerCase() === "showadvancedgrouping")
          this.setState({
            showAdvancedGrouping: op.split("=")[1].toLowerCase() === "true",
          });
        else if (op.split("=")[0].toLowerCase() === "showadvancedfltration")
          this.setState({
            showAdvancedFiltration: op.split("=")[1].toLowerCase() === "true",
          });
        else if (op.split("=")[0].toLowerCase() === "showcookiebouncer")
          this.setState({
            showCookieBouncer: op.split("=")[1].toLowerCase() === "true",
          });
      }
    }
    this.props.Logger.log("Loaded Options: " + this.state, LoggingLevel.DEBUG);
  }

  public async handlePageForward() {
    const q_Page = this.useQueryParam("page", "1")[0];
    const qn_Page = q_Page !== "" ? parseInt(q_Page) : 0;
    this.goToPage(qn_Page + 1);
  }

  public async handlePageBack() {
    const q_Page = this.useQueryParam("page", "1")[0];
    const qn_Page = q_Page !== "" ? parseInt(q_Page) : 0;
    this.goToPage(qn_Page - 1);
  }

  public async goToPage(pageno: number) {
    const q_setPage = this.useQueryParam("page", "1")[1];
    q_setPage(pageno.toString());
    var thislim = this.state.limit;
    this.setState(function () {
      return {
        start: (pageno - 1) * (thislim || 0),
      };
    });
    var newstart = (pageno - 1) * (thislim || 0);

    this.refresh_with_new_page(newstart);
  }

  private getQuery() {
    if (typeof window !== "undefined") {
      return new URLSearchParams(window.location.search);
    }
    return new URLSearchParams();
  }

  private getQueryStringVal(key: string): string | null {
    return this.getQuery().get(key);
  }

  // We've nicked the general approach from here:
  //  https://dev.to/brettfishy/the-easiest-way-to-use-query-parameters-in-react-1ioe
  // but ported it away from the useState Hook because this use case is very much Not a Functional Component.
  private useQueryParam(
    key: string,
    defaultVal: string,
  ): [string, (val: string) => void] {
    const query = this.state.urlparameters[key.toLowerCase()] || defaultVal;

    const updateUrl = (newVal: string) => {
      var c_urlvars = { ...this.state.urlparameters };
      c_urlvars[key.toLowerCase()] = newVal;
      this.setState({ urlparameters: c_urlvars });

      const query = this.getQuery();

      if (newVal.trim() !== "") {
        query.set(key.toLowerCase(), newVal);
      } else {
        query.delete(key.toLowerCase());
      }

      // This check is necessary if using the hook with Gatsby
      if (typeof window !== "undefined") {
        const { protocol, pathname, host } = window.location;
        const newUrl = `${protocol}//${host}${pathname}?${query.toString()}`;
        window.history.pushState({}, "", newUrl);
      }
    };

    return [query, updateUrl];
  }
}

export default Grid;
