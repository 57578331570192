import { FilterMaskType } from "./Enums/FilterMaskType";
import { MondrianColumn } from "./Types/MondrianStructuredData";

export interface GridVisibility {
  Column: string;
  Hidden: boolean;
}

export interface GridFilter {
  Column: string;
  SearchValue: string;
  Type: FilterMaskType;
  WholeWord: boolean;
}

export class GridRowData {
  _PROCESSING: boolean;
  CssClass: string; // _CSS, _CLASS, _ICONFLAG, _ICON, _FLAG
  Cells: GridCellData[];

  constructor(_proc: boolean, obj: any, cols: MondrianColumn[]) {
    this._PROCESSING = _proc;

    this.CssClass =
      obj._CSS || obj._CLASS || obj._ICONFLAG || obj._ICON || obj._FLAG || "";

    this.Cells = [];

    var objs: any[] = [];

    if (obj.forEach) {
      obj.forEach(objs.push(obj));
    } else {
      objs = [obj];
    }

    objs.forEach((obj) => {
      cols
        .filter(
          (th) =>
            !(
              th.MachineNames.length === 1 &&
              th.MachineNames[0] === "_ROW_PROCESSING"
            ),
        )
        .forEach((th) => {
          th.MachineNames.forEach((mn) => {
            this.Cells.push(new GridCellData(obj[mn]));
          });
        });
    });
  }
}

export class GridCellData {
  CellValues: GridCellDatum[];

  constructor(obj: any) {
    //  console.log(obj);
    if (obj === undefined || obj === null) {
      this.CellValues = [];
    } else if (obj.forEach) {
      this.CellValues = [];
      obj.forEach((ith) => {
        this.CellValues.push(new GridCellDatum(ith));
      });
    } else {
      this.CellValues = [new GridCellDatum(obj)];
    }
  }
}

export class GridCellDatum {
  Value: string | boolean | JSX.Element;
  InternalLink: string; // _URL, _LINK, _TARGET
  ExternalLink: string; // _URL, _LINK, _TARGET
  CssClass: string; // _CSS, _CLASS, _ICONFLAG, _ICON, _FLAG
  Tooltip: string; // _MORE, _TITLE
  Format: string; //_FORMAT

  constructor(obj: string | boolean | JSX.Element) {
    //  console.log("GridCellDatum const recvd:");
    //  console.log(obj);
    if (obj.toString() === obj) {
      this.Value = obj;
    } else {
      this.Value = obj as JSX.Element;
    }
  }
}
