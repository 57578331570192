import * as React from "react";

import "../assets/css/Article.css";
import { ScrollRestoration } from "react-router-dom";

interface IProps {
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
}

class UserGuide extends React.Component<IProps> {
  render() {
    window.document.title = "University of Essex - EDRM Help Pages";
    return (
      <div className="Article Help" id="pagetop">
        <ScrollRestoration />
        <div className="WelcomeBar">
          <h1>Welcome to the User Guide</h1>
          <div className="Subtitle">Article last updated 13th June 2024.</div>
          <div className="DescriptionBlock">
            This page has been set up as resource to help guide ESF/EAF/ECF
            users. We hope you find it useful. If you have suggestions for
            additional items to include, please send your suggestion to{" "}
            <a href="mailto:sphelp@essex.ac.uk">
              <strong>sphelp@essex.ac.uk</strong>
            </a>
            .
          </div>
          <ul>
            <li>
              <a href="#workflow">Workflows Panel</a>
            </li>
            <li>
              <a href="#otherinf">Other Information Tabs</a>
            </li>
            <li>
              <a href="#edocs">eDocs</a>
            </li>
            <li>
              <a href="#usingedocs">Using eDocs Sort/Group/Filter Options</a>
            </li>
            <li>
              <a href="#mytasks">MyTasks</a>
            </li>
            <li>
              <a href="#imperson">Impersonation</a>
            </li>
            <li>
              <a href="#stprog">Student Progress Team Screens</a>
            </li>
            <li>
              <a href="#feedback">Feedback</a>
            </li>
            <li>
              <a href="#browserhelp">Web Browser Direct Help Pages</a>
            </li>
          </ul>
        </div>
        <div className="Segmentary">
          <div className="Segments">
            <div className="Segment">
              <h2>ESF Student Details Page</h2>
              <br />
              <h3 id="workflow">Workflows Panel</h3>
              <p>
                Current workflows will appear in the section titled Current
                Workflows e.g.:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/CurrentWorkflow.png"}
                  alt={"ESF Current Workflow"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <p>
                Click on the first column hyperlink (the title of the workflow)
                to open the form.
              </p>
              <p>
                Click on the Workflow Summary hyperlink to view the history of
                whom, saved what, and when, for that workflow.
              </p>
              <p>If there are no current workflows, you will see:</p>
              <p>
                <img
                  src={"/UserGuideImages/NoWorkflow.png"}
                  alt={"No Workflow"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <p>
                Completed workflows will appear on the Completed Workflows tab
                (below Current Workflows), e.g.:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/ESFTabs.png"}
                  alt={"Completed Workflow Tabs"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <p>
                The tab that is active will be highlighted in blue; in the above
                example the Completed Workflows tab has been selected. When a
                student's ESF page first loads, the Completed Workflows tab will
                be selected by default.
              </p>
              <p>
                When a Collagen Workflow has been completed, you will see new
                feature appear in the Actions column under the Completed
                Workflows tab. If you have permissions to do so, that column
                will say "Re-Open", rather than "None". This means that
                super-users are able to re-open a completed form, to make
                changes, and resave it, if the need arises. When this happens
                the user reopening the form will be required to record a reason
                for reopening the form, and that will be printed on the final
                PDF of the form when the re-opened version is completed.
              </p>
              <br />
              <h2 id="otherinf">
                Other Information Tabs{" "}
                <small>
                  <a href="#pagetop">Return to Top</a>
                </small>
              </h2>
              <h3>Modules</h3>
              <p>
                To view the student's Modules, click on Modules, (and click to
                expand the Year of data you wish to view) e.g.:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/Modules.png"}
                  alt={"Module List"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <h3>Student Notes</h3>
              <p>
                To view the content of the note / edit the existing note, click
                on the View Note link.
              </p>
              <p>To compose a new note, click on the [+New Note] button.</p>
              <h3>Email Message History</h3>
              <p>
                To view the student's automated email Message History, click on
                Message History e.g.:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/EmailHistory.png"}
                  alt={"E-mail history window"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <h3>ECF file link</h3>
              <p>
                To view the student's Electronic Case File (ECF) click on the
                "ECF Exists" hyperlink e.g.:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/ECFexists.png"}
                  alt={"ECF Present"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                If no ECF exists for the student, the link will be deactivated,
                and look as below:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/NoECF.png"}
                  alt={"No ECF link"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <h3>All Tabs</h3>
              <p>If there is no data to be displayed, the tab will show: </p>
              <p>
                <img
                  src={"/UserGuideImages/NoData.png"}
                  alt={"No Data present"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <br />
              <hr></hr>
              <h2 id="edocs">
                eDocs
                <small>
                  <a href="#pagetop">Return to Top</a>
                </small>
              </h2>
              <p>
                We hope that you will like the changes we have made to the new
                eDocs section. Restricted files - previously known as "Registry
                Only Files" - will now appear with the subtitle: RESTRICTED in
                red, e.g.:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/eDocsRestricted.png"}
                  alt={"Restricted document prefix"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <p>
                This is because "Registry" no longer exists. However, the level
                of access-restriction for these files, remains unchanged.
              </p>
              <p>
                The eDocs Hover Panel is also slightly different. It is more
                responsive and has a more compact display than previously.
                However, the functionality remains unchanged.
              </p>
              <p>
                eDocs Accordion Display (show/hide) By default, the eDocs will
                display grouped by Document Type. Each Document Type will
                display in an "accordion", which can be expanded/contracted to
                show/hide the files grouped under it. Click on the Down Arrow /
                Right Arrow to activate this feature, e.g.:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/eDocsAccordion.png"}
                  alt={"eDocs accordion"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <br />
              <h2 id="usingedocs">
                Using eDocs Sort/Group/Filter Options
                <small>
                  <a href="#pagetop">Return to Top</a>
                </small>
              </h2>
              <p>Introducing Custom Sort, Grouping, and Filter Options </p>
              <p>
                The new eDocs table permits custom sort, grouping, and filtering
                - to allow users greater control over the way they view the
                returned files. Clearing Default Sort/Group/Filter Options.
              </p>
              <p>
                First it is important to note that some eDoc Displays will
                load-up with a default sort order and/or groupings and/or
                filters applied. If your eDoc Display has one or more of these
                already set, you will see an up or down arrow icon, and/or a
                grouping icon, and/or a filter icon, next to one or more of the
                columns (as shown in the examples below).
              </p>
              <p>Example 1: Sorted Ascending (up arrow) by Created Date</p>
              <p>
                <img
                  src={"/UserGuideImages/eDocsSortingUp.png"}
                  alt={"Sort Ascending "}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>Example 2: Filter applied to Uploaded By column</p>
              <p>
                <img
                  src={"/UserGuideImages/eDocsFilter.png"}
                  alt={"Filter By text box"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <br />
              <p>
                To clear the default sort/group/filter, click on any heading
                item (column title) for the list, and then click on one (or
                more, as appropriate) of the "Clear All" options on the pop-up:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/indivclearbuttons.png"}
                  alt={""}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                <ul>
                  <li>1 - Clear Sorting</li>
                  <li>2 - Clear Grouping</li>
                  <li>3 - Clear Filtering</li>
                  <li>4 - Clear All</li>
                </ul>
              </p>
              <br />
              <p>
                Alternatively sort/group/filter can also be changed by clicking
                on the toggle icons:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/onofftoggles.png"}
                  alt={"sort toggles"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                <ul>
                  <li>1 - On</li>
                  <li>2 - Off</li>
                </ul>
              </p>
              <p>On/Off toggles are offered for the Sort and Group features:</p>
              <p>
                <img
                  src={"/UserGuideImages/toggle.png"}
                  alt={"on off toggles"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                Any applied filters can be removed by clicking into, and
                deleting the text inside of, the Filtering text box:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/Filterbox.png"}
                  alt={"Filter Text box"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                (Top tip: pressing the X icon should also clear the Filtering
                text.)
              </p>
              <br />
              <p>
                Whilst viewing the list, click on the first heading item (column
                title) that you want to either sort/group/or filter by (see
                example below where "Document Type" has been selected).
              </p>
              <p>The custom Sort/Group/Filter pop-up should appear: </p>

              <p>
                <img
                  src={"/UserGuideImages/DocTypesort.png"}
                  alt={"Document type sort"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                The name of the column you chose will appear on the top left of
                the pop-up.
              </p>
              <p>
                The options you select in the pop-up will be automatically
                applied to the column of data that you have selected
              </p>
              <p>
                In this example: Document Type has been set as Sort Ascending:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/sortDocTypeAsc.png"}
                  alt={"Sorting by Document type ascending"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                <ul>
                  <li>1 - The Sort Ascending Upward Pointing Arrow is shown</li>
                  <li>
                    2 - The column to which the sort has been applied: Document
                    Type
                  </li>
                  <li>3 - The Sort Ascending toggle is set as ON</li>
                  <li>
                    4 - The Clear Sort button is now enabled, so that the sort
                    can be removed later, if required
                  </li>
                </ul>
              </p>
              <p>
                Once the Group by option has been selected the window will
                dissappear.
              </p>

              <p>
                To clear the grouping click the X next to the document type
                group.
              </p>
              <p>
                <img
                  src={"/UserGuideImages/cleargroup.png"}
                  alt={"Clear Grouping"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                In this example: Uploaded By has been set as the column to
                Filter on, and the Filtering text is set as "Baroni":
              </p>
              <p>
                <img
                  src={"/UserGuideImages/filterbybar.png"}
                  alt={"filter set"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                <ul>
                  <li>The Filter icon is shown</li>
                  <li>
                    The column to which the Filter has been applied: Department
                  </li>
                  <li>The Filtering text box contains: Economics#</li>
                  <li>
                    The Clear Filtering button is now enabled, so that the
                    filter can be removed later, if required
                  </li>
                </ul>
              </p>
              <h3>Combining Sort/Group/Filter Options</h3>
              <p>
                More than one sort/group/filter option can be applied at the
                same time.
              </p>
              <p>
                <ul>
                  <li>You may sort by multiple columns.</li>
                  <li>You may group by multiple columns.</li>
                  <li>You may filter multiple columns.</li>
                  <li>
                    You may combine features (e.g. sort + group + filter / sort
                    + group / group + filter) and so on.
                  </li>
                </ul>
              </p>
              <p>
                Each sort/group/filter is applied by repeating the above steps
                on each column, one after the other. Please note, the order in
                which you apply the sort/group/filter, will determine the
                hierarchy of how it is applied - see examples below. This will
                be a familiar concept to users of the Excel Spreadsheet
                software:
              </p>
              <p>
                Example 1: Group by Uploaded By (applied first), plus Sort by
                Created Date Descending (applied second):
              </p>
              <p>
                <img
                  src={"/UserGuideImages/ex1uploadedbysortbycreated.png"}
                  alt={"group by uploaded by, sort by date created"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <p>
                Example 2: Group by Document Type (applied first), plus Sort by
                Size Ascending (applied second):
              </p>
              <p>
                <img
                  src={"/UserGuideImages/ex2groupbydoctypesortbysize.png"}
                  alt={"group by document type, sort by size"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <p>
                You can apply as many group/sort/filter options as you require.
              </p>
              <p>The only limit is the number of data columns in your list.</p>
              <p>
                You can also apply group, sort AND filter options to a single
                column, should you so wish.
              </p>
              <br />
              <hr></hr>
              <h2 id="mytasks">
                MyTasks
                <small>
                  <a href="#pagetop">Return to Top</a>
                </small>
              </h2>
              <p>
                The RAG (red/amber/green) highlighting has been maintained - to
                show users when a form is due/overdue. All columns can be used
                to sort/filter/group results. So, for instance, a user could
                choose to view their tasks grouped by "type" - and that would
                group all UGs together, all PGT together, and all PGR together -
                e.g.:
              </p>
              <p>
                <img
                  src={"/UserGuideImages/ESFRag.png"}
                  alt={"Rag Highlights"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                For a full explanation of how to use the grouping/sorting/filter
                features - see the eDocs Sort/Group/Filter Options section
                above.
              </p>
              <p>The new MyTasks page is split into three sections:</p>
              <p>
                <img
                  src={"/UserGuideImages/MyTasksEmpty.png"}
                  alt={"My Tasks with No results showing sections"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                At first, these sections will be empty of tasks as shown here.
                They will populate as the user completes tasks.
              </p>
              <br />
              <h3>PG EAF Tasks</h3>
              <p>
                This section now includes a visual indicator on the selector
                task lists for PG Applicant Workflows that have been flagged as
                Priority Agent Services (PAS) which need to be processed more
                quickly than standard applications. This is a green highlight as
                shown in the previous image at the top of the page.
              </p>
              <br />
              <h3>ESF Tasks (Collagen)</h3>
              <p>
                <img
                  src={"/UserGuideImages/ESFTasksCollagen.png"}
                  alt={"Full page width for ESF collagen tasks"}
                  width={"100%"}
                  // height={"333"}
                />
              </p>
              <br />
              <h3>ESF Tasks (Collagen) On Hold</h3>
              <p>
                This section follows the same display and control settings as
                the previous sections.
              </p>
              <br />
              <h3>My Completed Tasks</h3>
              <p>
                For users who are particularly prolific, and who have hundreds
                of results to return, the page will load the first batch as soon
                as it can, and the rest will follow as the user scrolls
                downwards. This is called "lazy loading”, and it should be much
                faster for users.
              </p>
              <p>
                Your most recently completed tasks will appear at the top of the
                list.
              </p>

              <br />
              <h2 id="imperson">
                Impersonation
                <small>
                  <a href="#pagetop">Return to Top</a>
                </small>
              </h2>
              <p>
                As with the current pages, we have retained the ability for
                staff to impersonate other staff, to view the page as their
                colleague would see it (for covering absence, staff training,
                diagnosis of issues, etc). For these reasons, the ability to
                impersonate on these pages is permitted to all ESF users and is
                not as highly restricted as impersonating via the ESF Student
                Start Forms pages.
              </p>
              <p>
                <img
                  src={"/UserGuideImages/ImpCharm.png"}
                  alt={"impersonation charm"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                Select the above charm at the top right of the screen, (This
                will show your Initials, not those displayed here), insert the
                staff logon to be impersonated into the text box and click the
                View As button.
              </p>
              <p>
                <img
                  src={"/helpimages/impersonatetab.png"}
                  alt={"impersonation tab"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                This feature works on all ESF task lists including My Tasks and
                My Completed Tasks.
              </p>
              <p></p>
              <br />
              <h2 id="stprog">
                Student Progress Team Screens
                <small>
                  <a href="#pagetop">Return to Top</a>
                </small>
              </h2>
              <p>
                <strong>My Team Overview</strong>
              </p>
              <p>and</p>
              <p>
                <strong>My Team Completed (past 2 weeks)</strong>
              </p>
              <p>
                <strong>Access to these screens is restricted.</strong>
              </p>
              <p>
                These two pages have been designed specifically for use by staff
                of the Student Progress Teams and replace the older screens
                which failed to work correctly after the old Registry three team
                structure was discontinued.
              </p>
              <br />
              <hr></hr>
              <h2 id="feedback">
                Feedback
                <small>
                  <a href="#pagetop">Return to Top</a>
                </small>
              </h2>
              <p>
                The SharePoint Team welcome feedback, comments, and suggestions
                about the new ESF look and feel. If you would like to send us
                something, please pop it into an e-mail to{" "}
                <a href="mailto:sphelp@essex.ac.uk">
                  <strong>sphelp@essex.ac.uk</strong>
                </a>
                . Thank you.
              </p>
              <br />
              <hr></hr>
              <h2 id="browserhelp">
                Web Browser Direct Help Pages:
                <small>
                  <a href="#pagetop">Return to Top</a>
                </small>
              </h2>
              <ul>
                <li>
                  <a href="https://support.google.com/chrome/?hl=en-GB#topic=7439538">
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a href="https://support.mozilla.org/en-US/products/firefox">
                    Mozilla Firefox
                  </a>
                </li>
                <li>
                  <a href="https://support.apple.com/en-gb/safari">Safari</a>
                </li>
                <li>
                  <a href="https://support.microsoft.com/en-gb/microsoft-edge">
                    Microsoft Edge
                  </a>
                </li>
              </ul>
              <p></p>
              <p>Please Note: all links are correct at time of publication. </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserGuide;
