import {
  DocumentMultiple24Filled,
  LockClosed24Filled,
} from "@fluentui/react-icons";
import { ReactElement } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../../Logging";
import { DataAccess } from "../../../data/DataAccess";
import { PublicClientApplication } from "@azure/msal-browser";
import React from "react";

const _appsummCTs: string[] = [
  "Application Summary",
  "uaducasform",
  "Application form",
];
const _offdocCTs: string[] = ["Offer Document"];
const _casdocCTs: string[] = ["CAS Statement", "CASStatement"];
const _appdocCTs: string[] = [
  "Offer Document",
  "CAS Statement",
  "CASStatement",
  "Application Summary",
  "uaducasform",
  "Application form",
];

interface UgApplicantDocumentsProps {
  UcasNo: string;
  Logger: Logging;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
}

export function UgApplicantDocuments(
  props: UgApplicantDocumentsProps,
): ReactElement {
  let agentDetails: any = useRouteLoaderData("AgentRoot");

  var PassToAllGrids = {
    Callouts: false,
    Logger: props.Logger,
    da: props.da,
    PCA: props.PCA,
    GetLink: props.GetLink,
    URLRoot: props.URLRoot,
    URLParams: props.URLParams,
    ucasno: props.UcasNo.toUpperCase(),
    imp: props.Alias,
    class: "UoE-Grid AOSScreen",
    Refresher: props.Refresher,
    Alias: props.Alias,
    AliasChecked: props.AliasChecked,
    ViewAsAll: props.ViewAsAll,
    RegisterError: props.RegisterError,
    DeregisterError: props.DeregisterError,
    RegisterStatus: props.RegisterStatus,
    DeregisterStatus: props.DeregisterStatus,
  };

  return (
    <>
      {props.Embargo("UCAS") ? (
        <>
          <div className="ApplicantGrid">
            <h2>
              <span className="LeftIcon24">
                <LockClosed24Filled></LockClosed24Filled>
              </span>{" "}
              Application Summary
            </h2>
            {props.Notices(agentDetails.AgentIsPAS, ["Embargo", "Applicant"])}
          </div>
          <div className="ApplicantGrid">
            <h2>
              <span className="LeftIcon24">
                <LockClosed24Filled></LockClosed24Filled>
              </span>{" "}
              Offer Documents
            </h2>
            {props.Notices(agentDetails.AgentIsPAS, ["Embargo", "Applicant"])}
          </div>
          <div className="ApplicantGrid">
            <h2>
              <span className="LeftIcon24">
                <LockClosed24Filled></LockClosed24Filled>
              </span>{" "}
              CAS Documents
            </h2>
            {props.Notices(agentDetails.AgentIsPAS, ["Embargo", "Applicant"])}
          </div>
          <div className="ApplicantGrid">
            <h2>
              <span className="LeftIcon24">
                <LockClosed24Filled></LockClosed24Filled>
              </span>{" "}
              Applicant Documents
            </h2>
            {props.Notices(agentDetails.AgentIsPAS, ["Embargo", "Applicant"])}
          </div>
        </>
      ) : (
        <>
          <div className="ApplicantGrid">
            <h2>
              <span className="LeftIcon24">
                <DocumentMultiple24Filled></DocumentMultiple24Filled>
              </span>{" "}
              Application Summary
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"ApplicantInformationDocs_" + props.Refresher}
              output="aos-special-ucasapplicationform"
              class="UoE-Grid AOS AOS-eDocs"
              ForceProxy={true}
              Callouts={true}
            ></GridWrapper>
          </div>
          <div className="ApplicantGrid">
            <h2>
              <span className="LeftIcon24">
                <DocumentMultiple24Filled></DocumentMultiple24Filled>
              </span>{" "}
              Offer Documents
            </h2>
            <GridWrapper
              eid={"AOS_UG_OfferDocuments_" + props.Refresher}
              {...PassToAllGrids}
              class="UoE-Grid AOS AOS-eDocs"
              ForceProxy={true}
              output="edocs"
              contentTypes={_offdocCTs}
              Callouts={true}
            ></GridWrapper>
          </div>
          <div className="ApplicantGrid">
            <h2>
              <span className="LeftIcon24">
                <DocumentMultiple24Filled></DocumentMultiple24Filled>
              </span>{" "}
              CAS Documents
            </h2>
            <GridWrapper
              eid={"AOS_UG_CASDocuments_" + props.Refresher}
              {...PassToAllGrids}
              class="UoE-Grid AOS AOS-eDocs"
              ForceProxy={true}
              output="edocs"
              contentTypes={_casdocCTs}
              Callouts={true}
            ></GridWrapper>
          </div>
          <div className="ApplicantGrid">
            <h2>
              <span className="LeftIcon24">
                <DocumentMultiple24Filled></DocumentMultiple24Filled>
              </span>{" "}
              Applicant Documents
            </h2>
            <GridWrapper
              eid={"AOS_UG_ApplicantDocuments_" + props.Refresher}
              {...PassToAllGrids}
              class="UoE-Grid AOS AOS-eDocs"
              ForceProxy={true}
              output="edocs"
              contentTypes={_appdocCTs}
              blackList={true}
              Callouts={true}
            ></GridWrapper>
          </div>
        </>
      )}
    </>
  );
}
