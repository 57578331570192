/**
 * @description      : DiagnosticPanel - ingests data sets and Driver tree,
 *   and outputs a control that indicates whether or not the event will run.
 * @author           : jmcait
 * @group            :
 * @created          : 15/10/2024 - 13:05:08
 **/
import dayjs from "dayjs";
import { IItem } from "../../models/IItem";

/**
 * enum NodeType - used for binary search tree.
 * Or/And are nodes, Leaf is leaf.
 * @export
 * @enum {number}
 */
export enum NodeType {
  Or,
  And,
  Leaf,
}

export function DiagElement(input: DiagnosisNode) {
  //  console.log("DiagElement...");

  if (input.Type === NodeType.Leaf) {
    //  console.log("- leaf");
    //  console.log(input.LHS);
    //  console.log(input.RHS);
    return (
      <div className={"LEAF" + (input.WillRun ? " RUNNER" : " BLOCKER")}>
        {input.ScratchDefinition !== "" ? (
          <div className="Scratch">{input.ScratchDefinition}</div>
        ) : (
          <></>
        )}
        <div className="LHS">{input.LHS}</div>
        <div className="Comparator">{input.Comparator}</div>
        <div className="RHS">{input.RHS}</div>
      </div>
    );
  } else if (input.Type === NodeType.And) {
    //  console.log("- AND");
    const _lhs = input.LHS as DiagnosisNode;
    const _rhs = input.RHS as DiagnosisNode;
    const _dlhs = DiagElement(_lhs) as JSX.Element;
    const _drhs = DiagElement(_rhs) as JSX.Element;
    //  console.log(_lhs);
    //  console.log(_rhs);
    //  console.log(_dlhs);
    //  console.log(_drhs);
    return (
      <div
        className={
          "NODE" + (_lhs.WillRun && _rhs.WillRun ? " RUNNER" : " BLOCKER")
        }
      >
        {input.ScratchDefinition !== "" ? (
          <div className="Scratch">{input.ScratchDefinition}</div>
        ) : (
          <></>
        )}
        <div className="LHS">{_dlhs}</div>
        <div className="Comparator">AND</div>
        <div className="RHS">{_drhs}</div>
      </div>
    );
  } else if (input.Type === NodeType.Or) {
    //  console.log("- OR");
    const _lhs = input.LHS as DiagnosisNode;
    const _rhs = input.RHS as DiagnosisNode;
    const _dlhs = DiagElement(_lhs) as JSX.Element;
    const _drhs = DiagElement(_rhs) as JSX.Element;
    //  console.log(_lhs);
    //  console.log(_rhs);
    //  console.log(_dlhs);
    //  console.log(_drhs);
    return (
      <div
        className={
          "NODE" + (_lhs.WillRun || _rhs.WillRun ? " RUNNER" : " BLOCKER")
        }
      >
        {input.ScratchDefinition !== "" ? (
          <div className="Scratch">{input.ScratchDefinition}</div>
        ) : (
          <></>
        )}
        <div className="LHS">{_dlhs}</div>
        <div className="Comparator">OR</div>
        <div className="RHS">{_drhs}</div>
      </div>
    );
  } else {
    return <>-</>;
  }
}

/**
 * @class DiagnosisNode
 * Simplified underlying node structure for EventDriver BST (binary search tree).
 */
class DiagnosisNode {
  EDID: string;
  Type: NodeType;
  ScratchDefinition: string;
  WillRun: boolean;
  LHS: DiagnosisNode | string;
  Comparator: string;
  RHS: DiagnosisNode | string;
}

/**
 * @type RawEventDriver - maps to type returned from database.
 * TODO: Consider how this could be condensed down into a pertinent Response type.
 */
type RawEventDriver = {
  EventDriverID: string;
  LeftEventDriverID: string;
  _AND: string;
  _OR: string;
  RightEventDriverID: string;
  ScratchDefinition: string;
  SlipstreamEventID: string;
  ScheduleID: string;
  UIComponentID: string;
  DataColumn: string;
  Comparator: string;
  Comparatee_Value: string;
  Comparatee_UIComponentID: string;
  Comparatee_DataColumn: string;
  Comparatee_Variable: string;
  AssignmentDetectionSQL: string;
  AssignedInLastMinute: string;
  ChangedInLastMinute: string;
};

/**
 * Helper function to turn data sets into BST of DiagnosisNodes.
 *
 * @export
 * @param {IItem[]} diagnosis
 * @param {IItem[][]} InstanceData
 * @param {IItem[]} SprocData
 * @return {*}  {(DiagnosisNode | undefined)}
 */
export function ParseDiagnosis(
  diagnosis: IItem[],
  InstanceData: IItem[][],
  SprocData: IItem[],
): DiagnosisNode | undefined {
  const steve: RawEventDriver[] = [];
  let RootEDID: string;

  diagnosis.forEach((row) => {
    if (RootEDID === undefined) {
      RootEDID = row["EventDriverID"];
    }
    steve.push({
      EventDriverID: row.EventDriverID,
      LeftEventDriverID: row.LeftEventDriverID,
      _AND: row._AND,
      _OR: row._OR,
      RightEventDriverID: row.RightEventDriverID,
      ScratchDefinition: row.ScratchDefinition,
      SlipstreamEventID: row.SlipstreamEventID,
      ScheduleID: row.ScheduleID,
      UIComponentID: row.UIComponentID ?? "",
      DataColumn: row.DataColumn ?? "",
      Comparator: row.Comparator,
      Comparatee_Value: row.Comparatee_Value ?? "",
      Comparatee_UIComponentID: row.Comparatee_UIComponentID ?? "",
      Comparatee_DataColumn: row.Comparatee_DataColumn ?? "",
      Comparatee_Variable: row.Comparatee_Variable ?? "",
      AssignmentDetectionSQL: row.AssignmentDetectionSQL,
      AssignedInLastMinute: row.AssignedInLastMinute,
      ChangedInLastMinute: row.ChangedInLastMinute,
    });
  });

  let root: DiagnosisNode | undefined = DiagRawToNode(
    steve.find((th) => th.EventDriverID === RootEDID),
    InstanceData,
    SprocData,
  );

  if (root !== undefined) {
    root = AttachDiagNodes(root, steve, InstanceData, SprocData);
  }

  console.log("after AttachDiagNodes...");
  console.log(root);

  return root;
}

/**
 * Helper function to turn data sets into BST of DiagnosisNodes.
 * Works leaf-by-leaf.
 *
 * @param {RawEventDriver} input
 * @param {IItem[][]} InstanceData
 * @param {IItem[]} SprocData
 * @return {*}  {DiagnosisNode}
 */
function DiagRawToNode(
  input: RawEventDriver,
  InstanceData: IItem[][],
  SprocData: IItem[],
): DiagnosisNode {
  if (input === undefined) {
    return undefined;
  }

  let _lhs = "";
  let _rhs = "";

  if (input.Comparatee_Value ?? "" !== "") {
    _lhs = input.Comparatee_Value;
  }

  if (input.Comparatee_Variable ?? "" !== "") {
    _lhs = input.Comparatee_Variable;
  }

  if (input.Comparatee_DataColumn ?? "" !== "") {
    _lhs = SprocData[0][input.Comparatee_DataColumn];
  }

  if (input.DataColumn ?? "" !== "") {
    _rhs = SprocData[0][input.DataColumn];
  }

  if (
    (input.Comparatee_UIComponentID ?? "") !== "" &&
    (InstanceData?.length ?? 0) > 0 &&
    InstanceData[0].some(
      (th) => th.UIComponentID == input.Comparatee_UIComponentID,
    )
  ) {
    _lhs =
      (InstanceData?.length ?? 0) > 0
        ? InstanceData[0].find(
            (th) => th.UIComponentID == input.Comparatee_UIComponentID,
          ).Value
        : "null"; // find in instance data
  }

  if (
    (input.UIComponentID ?? "") !== "" &&
    (InstanceData?.length ?? 0) > 0 &&
    InstanceData[0].some((th) => th.UIComponentID == input.UIComponentID)
  ) {
    _rhs =
      (InstanceData?.length ?? 0) > 0
        ? InstanceData[0].find((th) => th.UIComponentID == input.UIComponentID)
            .Value
        : "null";
  }

  let _wiru = false;

  _lhs ??= "null";
  _rhs ??= "null";

  if (_lhs.startsWith("'") && _lhs.endsWith("'")) {
    _lhs = _lhs.substring(1, _lhs.length - 1);
  }

  if (_rhs.startsWith("'") && _rhs.endsWith("'")) {
    _rhs = _rhs.substring(1, _rhs.length - 1);
  }

  _lhs = _lhs.toLowerCase();

  _rhs = _rhs.toLowerCase();

  if (input.Comparator === "==") {
    _wiru = _lhs === _rhs;
  }

  if (input.Comparator === "!=") {
    _wiru = _lhs !== _rhs;
  }

  if (input.Comparator === "IN" || input.Comparator === "LIKE") {
    _wiru = _rhs.includes(_lhs);
  }
  if (input.Comparator === "NOT IN" || input.Comparator === "NOT LIKE") {
    _wiru = !_rhs.includes(_lhs);
  }

  if (input.Comparator === "IS") {
    _wiru = _rhs === "null" && _lhs === "null";
  }

  if (input.Comparator === "IS NOT") {
    _wiru = _rhs === "null" && _lhs !== "null";
  }

  if (input.Comparator === ">" || input.Comparator === "<") {
    const _rhsn = Number.parseInt(_rhs);
    const _lhsn = Number.parseInt(_rhs);
    const _rhsd = new dayjs.Dayjs(_rhs);
    const _lhsd = new dayjs.Dayjs(_rhs);
    if (_rhsn !== undefined && _lhsn !== undefined) {
      if (input.Comparator === ">") {
        _wiru = _lhsn > _rhsn;
      }
      if (input.Comparator === "<") {
        _wiru = _lhsn < _rhsn;
      }
    } else if (_lhsd.isValid() && _rhsd.isValid()) {
      if (input.Comparator === ">") {
        _wiru = _lhsd.isAfter(_rhsd);
      }
      if (input.Comparator === "<") {
        _wiru = _lhsd.isBefore(_rhsd);
      }
    }
  }

  if (input.Comparator === "REGEX") {
    const regex = new RegExp(_rhs);
    _wiru = regex.test(_lhs);
  }

  if (input.Comparator === "!REGEX") {
    const regex = new RegExp(_rhs);
    _wiru = !regex.test(_lhs);
  }

  return {
    EDID: input.EventDriverID,
    Type:
      input._OR === "True"
        ? NodeType.Or
        : input._AND === "True"
          ? NodeType.And
          : NodeType.Leaf,
    ScratchDefinition: input.ScratchDefinition ?? "",
    WillRun: _wiru,
    LHS:
      input.Comparatee_Value +
      input.Comparatee_Variable +
      input.Comparatee_DataColumn +
      input.Comparatee_UIComponentID +
      " (" +
      _lhs +
      ")",
    Comparator: input.Comparator,
    RHS: input.DataColumn + input.UIComponentID + " (" + _rhs + ")",
  };
}

/**
 * Take a node and attach its left and right child nodes from the data provided.
 * @param root
 * @param array
 * @param _instancedata
 * @param _sprocdata
 * @returns
 */
const AttachDiagNodes = (
  root: DiagnosisNode,
  array: RawEventDriver[],
  _instancedata: IItem[][],
  _sprocdata: IItem[],
) => {
  const _root = array.find((th) => th.EventDriverID === root.EDID);

  //  console.log("AttachDiagNodes...");
  //  console.log(_root);

  if (_root.LeftEventDriverID ?? "" !== "") {
    const _lhs = array.find(
      (th) => th.EventDriverID === _root.LeftEventDriverID,
    );
    //  console.log("- lhs detected...");
    //  console.log(_lhs);
    root.LHS = DiagRawToNode(_lhs, _instancedata, _sprocdata);
    root.LHS = AttachDiagNodes(root.LHS, array, _instancedata, _sprocdata);
  }

  if (_root.RightEventDriverID ?? "" !== "") {
    const _rhs = array.find(
      (th) => th.EventDriverID === _root.RightEventDriverID,
    );
    //  console.log("- rhs detected...");
    //  console.log(_rhs);
    root.RHS = DiagRawToNode(_rhs, _instancedata, _sprocdata);
    root.RHS = AttachDiagNodes(root.RHS, array, _instancedata, _sprocdata);
  }

  return root;
};

interface IProps {
  Diagnosis: IItem[];
  SprocDataTable: IItem[];
  InstanceDataTable: IItem[][];
}

/**
 * DiagnosticPanel - React Element that describes an EventDriver tree,
 * and the run state of each node and leaf.
 *
 * @export
 * @param {IProps} props
 * @return {*}
 */
export function DiagnosticPanel(props: IProps) {
  //  console.log("DiagnosticPanel");
  //  console.log(props.Diagnosis);
  //  console.log(props.SprocDataTable);
  //  console.log(props.InstanceDataTable);

  let StructuredDiagnosis = undefined;

  if (
    props.Diagnosis !== undefined &&
    props.SprocDataTable !== undefined &&
    props.Diagnosis.length > 0 &&
    props.SprocDataTable.length > 0
  ) {
    StructuredDiagnosis = ParseDiagnosis(
      props.Diagnosis,
      props.InstanceDataTable,
      props.SprocDataTable,
    );
  }

  //  console.log("after ParseDiagnosis...");
  //  console.log(StructuredDiagnosis);

  return (
    <div className="DiagnosticPanel">
      <div>
        Please note, panel only evaluates == and != operators. All others will
        have to be visually interpreted.
      </div>
      {StructuredDiagnosis !== undefined ? (
        <>
          <div
            className={
              "DiagnosticResult " +
              (StructuredDiagnosis.WillRun ? " RUNNER" : " BLOCKER")
            }
          >
            {StructuredDiagnosis.WillRun
              ? "This event WILL run"
              : "This event WILL NOT run"}
          </div>
          {DiagElement(StructuredDiagnosis)}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DiagnosticPanel;
