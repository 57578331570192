import { GridRowData } from "../MondrianStructs";

export class MondrianStructuredData {
  //Tables: MondrianTable[];
  Columns: MondrianColumn[];
  Rows: GridRowData[];
  Loading: boolean;
}

export class MondrianColumn {
  MachineNames: string[];
  HumanName: string;
}
