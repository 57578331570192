import "../../../assets/css/Compliance_LF/ComplianceAuditorScreen.css";
import { Link, useLinkClickHandler, useLoaderData } from "react-router-dom";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";

import { getIconClassName } from "@fluentui/react";
import { useApi } from "../../../Providers/ApiProvider";
import { useEffect, useState } from "react";
import { Button } from "@fluentui/react-components";

interface ComplianceAuditFlaggerProps {}

export function ComplianceAuditorScreen(props: ComplianceAuditFlaggerProps) {
  let loaderData: any = useLoaderData();
  let api = useApi();
  let [StuName, setStuName] = useState<string>();
  useEffect(() => {
    api.General.get(
      "/sproc",
      JSON.stringify({
        sproc: "sputilities.webui.Comply_studata_TitleName",
        params: ["@PRID", loaderData.ident],
      }),
    ).then((resp) => {
      setStuName(resp[0][0].Name);
    });
  }, []);

  return (
    <div className="CAS_Container">
      <div className="CAS_Row">
        <h1 className="CAS_PageTitle">
          Audit Information for <strong>{StuName}</strong>
        </h1>
      </div>
      <div className="CAS_Row">
        <div className="CAS_Column">
          <div>
            <h2>Student Data</h2>
            <GridWrapper
              eid={"CAS_Studata_Summary"}
              sproc="sputilities.webui.Comply_studata_summary"
              params={["@PRID", loaderData.ident.toUpperCase()]}
              Callouts={false}
              URLParams={""}
              NoneMessage="No student or applicant records found for this person. Check identifier and try again"
            ></GridWrapper>
          </div>
          <div>
            <h2>Current or Most Recent Course</h2>
            <GridWrapper
              eid={"CAS_CourseDetails_Summary"}
              sproc="sputilities.webui.Comply_studata_courseDetails"
              params={["@PRID", loaderData.ident]}
              Callouts={false}
              URLParams={""}
              NoneMessage="Not applicable to this student/applicant"
            ></GridWrapper>
          </div>
          <div className="JustASpacer"></div>
          <div>
            <GridWrapper
              eid={"CAS_ATAS_Banner"}
              sproc="sputilities.webui.COMPLY_studata_ATAS"
              params={["@PRID", loaderData.ident]}
              output="data"
              Callouts={false}
              URLParams={""}
              NoneMessage=""
            ></GridWrapper>
          </div>
          <Link
            className="CAS_Attendance_Button"
            to={"/compliance/Attendance/" + loaderData.ident}
            target="_blank"
          >
            Attendance Report
          </Link>

          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Contact Address Archive
          </h2>
          <GridWrapper
            eid={"CAS_Address_Summary"}
            sproc="sputilities.webui.Comply_studata_AddressHistory"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
            limit={10}
          ></GridWrapper>
        </div>
        <div className="CAS_Column" id="stu_idents_summary">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Student Identifiers
          </h2>
          <GridWrapper
            eid={"CAS_Studata_Summary"}
            sproc="sputilities.webui.Comply_list_all_idents"
            params={["@ident", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
      </div>

      {/* <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "}
            Course Details Summary
          </h2>
          <GridWrapper
            eid={"CAS_CourseDetails_Summary"}
            sproc="sputilities.webui.Comply_studata_courseDetails"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
          ></GridWrapper>
        </div>
      </div> */}

      <div className="CAS_Row">
        <h1>Immigration Information</h1>
      </div>

      <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Immigration Information
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_immigrationinfo"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>

        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Right to Study
          </h2>
          <GridWrapper
            eid={"CAS_RTS_Summary"}
            sproc="sputilities.webui.Comply_studata_RighttoStudy"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
      </div>

      <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Stay Permission
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_StayPermission"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>

        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Digital Immigration
          </h2>
          <GridWrapper
            eid={"CAS_RTS_Summary"}
            sproc="sputilities.webui.Comply_studata_RecDigImmStatus"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
      </div>

      <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            CAS History
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_CASHistory"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
        <div className="CAS_Column"></div>
      </div>

      {/* <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            Conditional Registrations
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_ConditionalReg"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
      </div> */}

      <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Postgraduate Research Student Contact Monitoring
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_PGR_RAG"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            limit={10}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
        <div className="CAS_Column"></div>
        {/* <div className="CAS_Column">
          <h2>
            Postgraduate Taught Student Contact Monitoring
          </h2>
          <div>
            <div className="ms-MessageBar ms-MessageBar-multiline no-results root-105">
              <div className="ms-MessageBar-content content-106">
                <div
                  className="ms-MessageBar-text text-109"
                  id="MessageBar414"
                  role="status"
                  aria-live="polite"
                >
                  <span className="ms-MessageBar-innerText innerText-110">
                    <span>&nbsp; This feature is not available yet</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="CAS_Row">
        <h1>Historic Information</h1>
      </div>

      <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Previous Registrations
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_PrevCourse"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
      </div>

      <div className="CAS_Row">
        <h1>Admissions Information</h1>
      </div>

      <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Undergraduate Applications
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_UGApp"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Undergraduate Applications Residency Information
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_UGAppResInf"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
      </div>

      <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Postgraduate Applications
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_PGAPP"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Postgraduate Applications Residency Information
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_PGAppResInf"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
      </div>

      <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Other Applications
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_OtherAPP"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Other Applications Residency Information
          </h2>
          <GridWrapper
            eid={"CAS_Immigration_Summary"}
            sproc="sputilities.webui.Comply_studata_OtherAppResInf"
            params={["@PRID", loaderData.ident]}
            Callouts={false}
            URLParams={""}
            NoneMessage="Not applicable to this student/applicant"
          ></GridWrapper>
        </div>
      </div>

      <div className="CAS_Row">
        <div className="CAS_Column">
          <h2>
            {/* <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "} */}
            Documents
          </h2>
          <GridWrapper
            eid={"EDocs_"}
            output="audit-docs"
            group="Source System"
            sort="Document Type,Created Date"
            sortdesc="true"
            Callouts={true}
            options="showcookiebouncer=true"
            URLParams={""}
            prid={loaderData.ident}
            NoneMessage="No audit documents found for this person"
          ></GridWrapper>
        </div>
      </div>
    </div>
  );
}
