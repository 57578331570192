import * as React from "react";
import Timestamp from "./Timestamp";
import { DataAccess } from "../../data/DataAccess";
import { Toggles } from "./Toggles";

interface IProps {
  FIGUID: string;
  DA: DataAccess;
  Toggles: Toggles;
  Object: any;
  PassthroughRequeue: (EID: Number, FIGUID: string) => void;
}

class TLEntryEventQueue extends React.Component<IProps> {
  public render() {
    if (this.props.Object["Subtype"] === "Queued") {
      if (this.props.Toggles.TechView === true) {
        return (
          <div className="EventEntry">
            <div className="Icon">&#127881;</div>
            <div className="Label">Event</div>
            <Timestamp
              Timestamp={this.props.Object["Timestamp"]}
              Toggles={this.props.Toggles}
            />
            Queued <b>{this.props.Object["EventName"]}</b>
          </div>
        );
      } else {
        return "";
      }
    } else {
      return (
        <div className="EventEntry">
          <div className="Icon">&#127881;</div>
          <div className="Label">Event</div>
          <Timestamp
            Timestamp={this.props.Object["Timestamp"]}
            Toggles={this.props.Toggles}
          />
          Enacted <b>{this.props.Object["EventName"]}</b>
          <button
            className="RedoButton"
            title={
              "Redo Event " +
              Number.parseInt(this.props.Object.EventID) +
              " for " +
              this.props.FIGUID
            }
            onClick={() => {
              this.props.PassthroughRequeue(
                Number.parseInt(this.props.Object.EventID),
                this.props.FIGUID,
              );
            }}
          >
            &#8635;
          </button>
        </div>
      );
    }
  }
}

export default TLEntryEventQueue;
