import { Dayjs } from "dayjs";

class Notice {
  NoticeID: string;
  Title: string;
  Body_Plain: string;
  Body_HTML: string;
  VisibleFrom: Dayjs | undefined;
  VisibleUntil: Dayjs | undefined;
  EmbargoID: string;
  _scope: string;
  _scopes: string[];
}

export default Notice;
