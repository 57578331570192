import * as React from "react";

import "../assets/css/Article.css";
import { ScrollRestoration } from "react-router-dom";

interface IProps {
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
}

class WhatsNew extends React.Component<IProps> {
  render() {
    window.document.title = "University of Essex - EDRM Help Pages";
    return (
      <div className="Article Help">
        <ScrollRestoration />
        <div className="WelcomeBar">
          <h1>What's new on EDRM? </h1>
          <div className="Subtitle">
            Article last updated 5th September 2024.
          </div>
          <div className="DescriptionBlock">
            This page has been set up to highlight what is new on EDRM for
            ESF/EAF/ECF users. We hope you find it useful.
          </div>
        </div>
        <div className="Segmentary">
          <div className="Segments">
            <div className="Segment">
              <h2>All New Colour Modes</h2>
              <p>
                A new functionality has been added to the complete platform.
                This is the ability to change colour themes for ease of use.
              </p>
              <p>These are the following:</p>
              <ul>
                <li>Automatic, which follows system settings</li>
                <li>
                  Light mode, with a white background, this is the original view
                </li>
                <li>
                  Dark mode, to reduce eye strain from working on bright screens
                </li>

                <li>
                  High Contrast Modes:
                  <ul>
                    <li>Yellow</li>
                    <li>Green</li>
                    <li>Pink</li>
                  </ul>
                </li>
              </ul>
              <p>
                <b>Please note:</b> the preference will need to be set for each
                EDRM service, as it does not apply globally to all services at
                once.
              </p>

              <p>Once set, the theme choice will say selected, unless:</p>
              <ul>
                <li>the user changes the setting;</li>
                <li>the cookies are cleared on the computer;</li>
                <li>
                  the device is a hot-desk/PC Lab machine (which self-clears
                  user data after logout);
                </li>
                <li>the user moves to a different computer or device.</li>
              </ul>
              <p>
                <img
                  src={"/helpimages/usercharmandpopup.png"}
                  alt={"User Charm circled in red with the menu open"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <p>
                The method of changing the theme is to select the User charm at
                the top right of the screen. On the pop up that appears is a
                dropdown menu that allows selection of your preferred mode.
              </p>
              <p>
                <img
                  src={"/helpimages/themeselector.png"}
                  alt={"Colour theme changer dropdown"}
                  // width={"569"}
                  // height={"333"}
                />
              </p>
              <br />
              <hr></hr>
              <h2>Why did EDRM have to change?</h2>
              <p>Here's why:</p>
              <ul>
                <li>
                  EDRM was hosted on SharePoint 2013 and Microsoft stopped
                  supporting that platform in 2023.
                </li>
                <li>
                  To Introduce security improvements to the code behind the
                  scenes.
                </li>
                <li>To make improvements to performance.</li>
                <li>To make the sites clearer, and more modern looking.</li>
                <li>
                  To improve and streamline the permissions security model used
                  to grant/deny access to the many sections of the EDRM system.
                </li>
                <li>
                  To futureproof the EDRM codebase by integrating it with the
                  SharePoint Online platform.
                </li>
                <li>
                  To increase the resilience of our EDRM data storage by moving
                  the 8 million+ files to the Cloud.
                </li>
              </ul>
              <br />
              <hr></hr>
              <p>
                All the sites have new URLs (web addresses). This is deliberate.
                You will gradually become acclimatised to the new locations, to
                help visually, these locations are also colour coded.
              </p>
              <p>
                ESF -{" "}
                <a href="https://esf.essex.ac.uk">
                  <strong>https://esf.essex.ac.uk</strong>
                </a>
                <p>
                  <img
                    src={"/helpimages/ESFheader.png"}
                    alt={"ESF Header"}
                    // width={"569"}
                    // height={"333"}
                  />
                </p>
              </p>
              <p>
                EAF (includes PGEAF, UGEAF, and Clearing) -{" "}
                <a href="https://eaf.essex.ac.uk">
                  <strong>https://eaf.essex.ac.uk</strong>
                </a>
                <p>
                  <img
                    src={"/helpimages/PGEAFheader.png"}
                    alt={"PGEAF header"}
                    // width={"569"}
                    // height={"333"}
                  />
                </p>
                <p>
                  <img
                    src={"/helpimages/UGEAFheader.png"}
                    alt={"UGEAF header"}
                    // width={"569"}
                    // height={"333"}
                  />
                </p>
                <p>
                  <img
                    src={"/helpimages/Clearingheader.png"}
                    alt={"Clearing header"}
                    // width={"569"}
                    // height={"333"}
                  />
                </p>
              </p>
              <p>
                ECF -{" "}
                <a href="https://ecf.essex.ac.uk">
                  <strong>https://ecf.essex.ac.uk</strong>
                </a>
                <p>
                  <img
                    src={"/helpimages/ECFheader.png"}
                    alt={"ECF header"}
                    // width={"569"}
                    // height={"333"}
                  />
                </p>
              </p>
              <br />
              <hr></hr>
              <br />
              <h2>A Tour of New EDRM Features</h2>
              <p>
                We very much hope that you will like our new web sites. They
                have been designed to be as intuitive as possible, whilst also
                keeping the core features reasonably similar to their
                predecessors on SharePoint 2013.
              </p>
              <p>
                Our aim has been to make these new web sites require as little
                training as possible.
              </p>
              <br />
              <h3>Where are the files now?</h3>
              <p>
                The files have been moved to SharePoint Online (part of
                Microsoft Office 365 in the Cloud). But you don't need to go to
                that URL to see the files - we've done the work for you. The
                files will appear in appropriate eDoc panels on the various
                websites (on student/applicant details pages). As on SharePoint
                2013, there are access-permissions in place, so you may not see
                the same lists of files as a colleague, if they have higher or
                lower permissions to the service than you. A good example of
                this is ECF, where some files are SWIS staff only restricted.
              </p>
              <br />
              <h3>Signing into the new sites</h3>
              <p>
                This is now all controlled by Single Sign On and Multi-Factor
                Authentication, with your usual Essex username and password. You
                will also need to be either on-campus or using Global Protect
                VPN to access the sites (off-campus or on-campus over wiFi).
                Occasionally, MS Auth credentials time-out (as they are only
                stored for designated periods of time), if they expire you will
                be re-prompted to login. Simply follow the on-screen prompts to
                regain access.
              </p>
              <br />
              <hr></hr>
              <br />
              <h3>Navigation</h3>
              <p>
                <ul>
                  <li>
                    All key areas are available as links from the service home
                    page(s).
                  </li>
                  <li>
                    Your home page may differ from a colleagues' view of the
                    same home page - as the sections it displays are based on
                    your level of access permissions. If you are not expected to
                    use an area of the site, you will not see links to it.
                  </li>
                  <li>
                    <p>
                      A hamburger menu will show you all the services you have
                      access to. If a service is missing, you do not have
                      access.
                    </p>
                    <img
                      src={"/helpimages/hamburger.png"}
                      alt={"hamburger menu"}
                      // width={"569"}
                      // height={"333"}
                    />
                  </li>
                  <li>
                    <p>
                      You can also use the "Breadcrumb" trail of hyperlinks at
                      the top of the screen to navigate around the site.
                    </p>
                    <img
                      src={"/helpimages/breadcrumbs.png"}
                      alt={"hamburger menu"}
                      // width={"569"}
                      // height={"333"}
                    />
                  </li>
                  <li>
                    <p>
                      All services (except ECF) have a Task Lists menu available
                      from the top header bar - the task list "charm" (our fancy
                      new word for picture/icon!) looks like this:
                    </p>
                    <img
                      src={"/helpimages/tasklistcharm.png"}
                      alt={"Tasklist charm"}
                      // width={"569"}
                      // height={"333"}
                    />
                  </li>
                </ul>
              </p>
              <br />
              <hr></hr>
              <br />
              <img
                className="FloatRight"
                src={"/helpimages/highlowtower.png"}
                alt={"tower with high at the top low at the bottom"}
                // width={"569"}
                // height={"333"}
              />
              <h3>A Brand New Permissions Model</h3>
              <p>
                All permissions (access to the web sites) have been audited and
                revised. We now only grant permissions on a "lowest level
                required" policy. This means that you will not have access to
                higher level features/functions unless your job role requires
                it.
              </p>
              <p>
                Broadly this is the model (there are a few extra ones for some
                services not detailed here)
              </p>
              <p>In descending order from highest permission to lowest:</p>
              <ul>
                <li>
                  <strong>Owner</strong> - SharePoint Team Only
                </li>
                <li>
                  <strong>Report Viewers</strong> - Restricted Report Access
                </li>
                <li>
                  <strong>Impersonate</strong> - Business & Service Owners
                </li>
                <li>
                  <strong>Teams</strong> - Admissions staff / Student Progress &
                  PGRE / SWIS
                </li>
                <li>
                  <strong>File Uploader</strong> User with upload permissions
                </li>
                <li>
                  <strong>Basic Access</strong> - read only general access, no
                  upload permission
                </li>
              </ul>
              <br />
              <h3>Requesting Access</h3>
              <p>
                If you find you have <strong>lost</strong> access to a service
                you could reach on SP2013,{" "}
                <strong>and you still need access</strong> (note: if you have
                changed role in the University, you may have deliberately been
                removed from some areas of the system to correlate with your
                current role) - please contact{" "}
                <a href="mailto:sphelp@essex.ac.uk">sphelp@essex.ac.uk</a>. We
                may ask you to support any access requests with confirmation
                from your Line Manager that specific permissions are needed.
              </p>
              <br />
              <hr></hr>
              <br />
              <h3>New and improved Search</h3>
              <p>
                <ul>
                  <li>
                    <p>
                      One search allows you to look for both current AND archive
                      students/applicants (depending on service).
                    </p>
                    <img
                      src={"/helpimages/currentarchive.png"}
                      alt={"current or archive search buttons"}
                      width={"100%"}
                      // height={"333"}
                    />
                  </li>
                  <li>
                    <p>
                      A Search bar is now continuously available the top right
                      header bar - regardless of what page you are viewing - as
                      well as from the home page.
                    </p>
                    <img
                      src={"/helpimages/searchIcon.png"}
                      alt={"header search icon"}
                      // width={"569"}
                      // height={"333"}
                    />
                  </li>
                </ul>
              </p>
              <br />
              <hr></hr>
              <br />
              <h3>New and Improved Uploads</h3>
              <p>
                The new style "Direct File Uploader" can upload single files one
                at a time, as well as multiple files as a "bulk upload" option.
                You will find links to this on the home page of each service -
                and ESF has two (it has a separate one for Registration files
                only).
                <ul>
                  <li>
                    The new file uploaders have vastly improved validation - you
                    should get error messages explaining any issues with your
                    file upload attempt which is blocking it from uploading.
                    These will need to be addressed before the system will let
                    you upload. Errors will be shown if:
                    <ul>
                      <li>your file is not an allowed format;</li>

                      <li>your file name needs to be shortened;</li>
                      <li>
                        your file name includes invalid characters which will be
                        removed on upload;
                      </li>
                      <li>
                        you have not supplied all required metadata to allow the
                        file to be sent to SharePoint Online.
                      </li>
                      <li>
                        you have supplied an invalid student/applicant
                        identifier (such as a PRID with a typo, or an applicant
                        number which is not in our databases).
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
              <h3>Drag and Drop files to upload</h3>
              <p>
                The new uploaders support drag and drop - particularly useful
                for bulk uploading of multiple files in one go.
              </p>
              <p>
                <img
                  src={"/helpimages/BulkUploaderdanddhighlight.png"}
                  alt={"drag and drop location highlighted"}
                  width={"100%"}
                  // height={"333"}
                />
                <ul>
                  <li>
                    Open Windows Explorer and naviagate to the folder with your
                    file(s) in
                  </li>
                  <li>Select the file(s) you want to upload</li>
                  <li>
                    Click on the selected files, and "drag" them using your
                    mouse onto the highlighted area on the image above
                  </li>
                </ul>
              </p>
              <h3>Multiple File upload (file browser method)</h3>
              <p>
                <img
                  src={"/helpimages/BulkUploaderbrowsehighlight.png"}
                  alt={"browse files button highlighted"}
                  width={"100%"}
                  // height={"333"}
                />
                <ul>
                  <li>Click on highlighted area to open file browser</li>
                  <li>
                    Once in file browser, select required files, then click the
                    Open button
                  </li>
                </ul>
              </p>
              <h3>Locations of File Uploaders</h3>
              <p>
                {" "}
                <ul>
                  <li>Service Home Pages</li>
                  <li>
                    Individual Applicant pages, when uploading files the
                    identification fields are pre-filled
                  </li>
                  <li>
                    Invividual Student pages, when uploading files the
                    identification fields are pre-filled
                  </li>
                </ul>
              </p>
              <br />
              <h3>Metadata Editing - data about the file upload</h3>
              <p>
                We still need you to tell us important information about the
                files you are uploading (such as which student/applicant the
                file is for, and what the file contains) - but there are new
                ways to do this. If you are using the "Direct Upload" or
                "In-Details Uploaders", the web page will guide you through the
                fields you need to complete. The Outlook AddIn will also do
                that.
              </p>
              <p>
                <strong>
                  But what if you make a mistake with the metadata and need to
                  edit it later?
                </strong>
              </p>
              <p>
                Not everyone can change metadata once a file has been uploaded.
                If you have that access permission, you will see a REFILE link
                on the document pop-up (hover-panel) on the student/applicant's
                details page, in the eDocs section, shown below.
              </p>
              <img
                src={"/helpimages/refilebutton.png"}
                alt={"refile button on pop up window"}
                // width={"569"}
                // height={"333"}
              />
              <p>
                If you click on REFILE you will get an input form, where you can
                alter the metadata.
              </p>
              <p>
                The refile link will be missing until the file has finished
                being (re)indexed by SharePoint Online.
              </p>
              <br />
              <h3>Dude - where's my file ?</h3>
              <p>
                When you successfully upload a file it will be reported to you
                for up to 24 hours (the lists are cleared nightly) on the main
                service Upload Page (see the "Your recently uploaded files"
                panel). This page will also report any failures to you, and
                those failures will be notified to the SharePoint Team
                automatically.
              </p>
              <p>
                <img
                  src={"/helpimages/ESFuploadedfileswitherror.png"}
                  alt={"uploaded files table"}
                  width={"100%"}
                  //height={"75%"}
                />
              </p>
              <p>
                Once you have uploaded a file, it shouldn't take more that 2 or
                3 minutes to be listed on the appropriate student/applicant
                details eDocs panel. You will need to REFRESH your page to see
                the file appear (press CTRL + F5 together to force a page
                refresh).
              </p>
              <br />
              <h3>File Stages</h3>
              <ul>
                <li>
                  <strong>Pending</strong> Files are awaiting upload.
                </li>
                <li>
                  <strong>Indexing</strong> This can take up to 90 mins to
                  complete this process. If, after this time and CTRL + F5 has
                  been pressed, and the file is not showing as new, at this
                  point contact{" "}
                  <a href="mailto:sphelp@essex.ac.uk">
                    {" "}
                    <strong>sphelp@essex.ac.uk</strong>
                  </a>{" "}
                  with all the details. Please note: the file has not been lost.
                </li>
                <li>
                  <strong>New!</strong> This will display for up to 24 hours, it
                  will then disappear.
                </li>
                <li>
                  File has no prefix. This is the final stage after the initial
                  24 hours has passed.
                </li>
                <li>
                  <strong>Error</strong> This will show if there is a problem
                  with the upload. If this is showing for your file, please
                  contact{" "}
                  <a href="mailto:sphelp@essex.ac.uk">
                    {" "}
                    <strong>sphelp@essex.ac.uk</strong>
                  </a>{" "}
                  ensuring all pertinent information is included in the email
                  (such as filename, any error seen, the student/applicant the
                  file was for, what time you tried to upload it, etc). If
                  possible, include screenshots to assist in diagnosing the
                  issue.
                </li>
              </ul>
              <br />
              <h3>Better eDocs File Previews (pop-ups)</h3>
              <p>
                <strong>Emails</strong> - previously you had to download these
                to view them. Download of files is discouraged for reasons of
                data protection. Therefore you can now preview .msg files &.eml
                files on the web sites directly, no need to download first.
              </p>
              <p>
                <strong>Hover panel (eDocs)</strong> - Continuing the theme of
                data protection and data integrity, editable files like Word
                documents (.docx files) will now be presented as uneditable PDFs
                to end users - preventing any chance of alteration.
              </p>
              <br />
              <h3>How do I download a file - there's no "Download" button?</h3>
              <p>
                You don't. Please don't. See above. We have designed the new
                system to protect the institution from accidental proliferation
                of data sources. If there is a good reason to download the file,
                please have a chat with the SharePoint Team{" "}
                <a href="mailto:sphelp@essex.ac.uk">
                  {" "}
                  <strong>sphelp@essex.ac.uk</strong>
                </a>
                .{" "}
              </p>
              <br />
              <h3>How do I delete a file?</h3>
              <p>
                You (probably) won't be able to. This access level is
                deliberately restricted to service and business owners only.
                Please contact the SharePoint Team for more
                information/assistance.
              </p>
              <br />
              <h3>ECF has a brand new "single page" approach</h3>
              <p>
                All student information is now available on a single, condensed
                page.
              </p>
              <br />
              <hr></hr>
              <h3>New User "Charms"</h3>
              <p>
                The available charms (our fancy new word for picture icons) vary
                dependent upon user permissions. The full array are shown here:
              </p>
              <p>
                <img
                  src={"/helpimages/charms.png"}
                  alt={"charms for page"}
                  width={"569"}
                  height={"333"}
                />
              </p>
              <ul>
                <li>
                  <strong>Search</strong> - Search for students/applicants
                </li>
                <li>
                  <strong>Task Lists</strong> - Available on ESF and EAF pages
                </li>
                <li>
                  <strong>Reports and Data Operations</strong> - Some services
                  have (restricted access) reports. If you have access to these,
                  you will see this charm on the top header and the reports you
                  can access will be listed as links.
                </li>
                <li>
                  <strong>Impersonate</strong> Only staff In the following
                  groups are able to impersonate on their systems:
                  <li>
                    <strong>ESF</strong> - PGR Team, Progress Team, Course
                    Records Team, Systems admin, Deans
                  </li>
                  <li>
                    <strong>UG EAF</strong> - UG admissions systems, UG
                    admissions staff, PG admissions staff
                  </li>
                  <li>
                    <strong>PG EAF</strong> - PG admission systems, PG
                    admissions staff
                  </li>
                  <li>
                    <strong>ECF</strong> - SWIS
                  </li>
                </li>
                <li>
                  <strong>Sign Out</strong> - excellent if you are hot-desking /
                  sharing a computer, this will sign you out of all EDRM
                  services completely.
                </li>
              </ul>
              <br />
              <h3>Using Impersonation</h3>
              <p>
                Click on the Impersonation charm, enter a valid username (login)
                into the "View As:" text block, press the "View As" button.
              </p>
              <img
                src={"/helpimages/impersonatetab.png"}
                alt={"impersonation tab"}
                // width={"569"}
                // height={"333"}
              />
              <p>
                To clear impersonated user click on the charm and select the
                "Clear" button. The site will revert to the permissions level of
                the user logged in.
              </p>
              <br />
              <hr></hr>
              <h2>ESF Only</h2>
              <h3>New ESF Form Workflow Assignees Look-up page</h3>
              <p>
                The new workflow assignees page can be found from the ESF home
                page, or by following this link{" "}
                <a href="https://esf.essex.ac.uk/Utilities/ReviewTaskAssignees">
                  <strong>Review Task Assignees</strong>
                </a>
              </p>
              <p>Select the Assignee Tab</p>
              <img
                src={"/helpimages/ESFassignee.png"}
                alt={"Workflow Assignee tab"}
                width={"100%"}
                // height={"333"}
              />
              <p>
                Once the assignee page is opened, select the department required
                using the buttons on the left which will open the dropdown menu.
              </p>
              <img
                src={"/helpimages/ESFworkflowselectors.png"}
                alt={"Selection controls"}
                // width={"569"}
                // height={"333"}
              />
              <br />
              <h3>Compliance</h3>
              <p>
                Previously, areas for Home Office and Data Protection Compliance
                were built into the ESF and EAF services. Now, these have been
                given their own area called "Compliance" with very restricted
                access to minimal staff. Separate training for this zone can be
                arranged with the SharePoint Team
              </p>
              <br />
              <hr></hr>
              <h3>Old InfoPath ESF Forms</h3>
              <p>
                SharePoint Online was not able to cope with displaying the old
                InfoPath forms as the technology was deprecated a few years ago
                by Microsoft. However, all historical InfoPath (.XML) files have
                been converted to PDFs and these are still available on the
                student/applicant pages in this new format.
              </p>
              <br />
              <h3>Accessibility menu</h3>
              <p>
                We have one - and we promise more features will be added soon -
                such as "high contrast mode", "dark mode", etc.{" "}
              </p>
              <br />
              <h3>Need Help?</h3>
              <p>Visit the Help Footer icon for details.</p>
              <br />
              <hr></hr>
              <h2>New Outlook App</h2>
              <p>
                The new Outlook App is fairly similar to its predecessor to use.
                However, there are a few key items to draw your attention to.
              </p>
              <p>
                1. Some fields (Reg Number / PRID) will not let you delete them
                once you have entered a valid PRID/Reg No. If you've put the
                wrong identifier in - for example by an accidental cut and
                paste, these can only be removed using the CLEAR IDENTIFIERS
                button
              </p>
              <p>
                2. There is no longer a "Cog" setting icon to click on to
                re-enter your username and password credentials. This is now all
                done automatically for you.
              </p>
              <p>
                3. The layout is a little more user-friendly and the upload
                panels have been colour-coded to match the service you are
                uploading to (orange, purple, blue or green).
              </p>
              <p>
                4. Validation has been improved to prevent upload of files with
                poor or missing metadata. Follow the on-screen errors to correct
                these errors. The upload button will stay disabled until the
                errors are corrected.
              </p>
              <p>
                For more information about how to install and use the New
                Outlook App please see:
              </p>
              <ul>
                <li>
                  {/* https://esf.essex.ac.uk/OutlookHelp/Outlook_App_Install_Guide_June_2024.pdf */}
                  <a href="/OutlookHelp/Outlook_App_Install_Guide_June_2024.pdf">
                    <strong>Installation Guide</strong>
                  </a>
                </li>
                <li>
                  {/* https://esf.essex.ac.uk/OutlookHelp/Outlook-App-UserGuide.pdf */}
                  <a href="/OutlookHelp/Outlook-App-UserGuide.pdf">
                    <strong>User Guide</strong>
                  </a>
                </li>
                <li>
                  {/* https://esf.essex.ac.uk/OutlookHelp/New-Outlook-App-User-Advice-June24.pdf */}
                  <a href="/OutlookHelp/New-Outlook-App-User-Advice-June24.pdf">
                    <strong>User Advice</strong>
                  </a>
                </li>
                <li>
                  {/* https://esf.essex.ac.uk/OutlookHelp/adding_a_shared_mailbox_on_outlook_webmail.pdf */}
                  <a href="/OutlookHelp/adding_a_shared_mailbox_on_outlook_webmail.pdf">
                    <strong>Outlook Adding a Shared Mailbox</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhatsNew;
