import { useState } from "react";
import "../assets/css/Integrations/StyledDropdown.css";

type StyledDropdownOption = {
  Name: string;
  ClassName: string;
  Action: () => void;
};

interface IProps {
  defaultSelectedIndex?: number;
  Options: StyledDropdownOption[];
}

export const StyledDropdown = (props: IProps) => {
  const [Show, setShow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    props.defaultSelectedIndex ?? 0,
  );
  const SelectedChild = props.Options[selectedIndex];

  return (
    <div
      className="StyledDropdown"
      onClick={() => {
        setShow(!Show);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
    >
      <div
        className="SelectedItemView"
        onClick={() => {
          setShow(!Show);
        }}
      >
        {SelectedChild ? (
          <div
            className={SelectedChild.ClassName}
            onClick={SelectedChild.Action}
          >
            <div className="GhostTriangle">▼</div>
            {SelectedChild.Name}
          </div>
        ) : (
          <></>
        )}
      </div>
      {Show ? (
        <div className="SelectableItems">
          {props.Options.map((ThisChild, i) => {
            return (
              <div
                key={"dd_" + i.toString()}
                className={
                  ThisChild.ClassName +
                  (i === selectedIndex ? " SelectedItem" : "")
                }
                onClick={() => {
                  setSelectedIndex(i);
                  ThisChild.Action();
                }}
              >
                {ThisChild.Name}

                {i === selectedIndex ? (
                  <div className="GhostCheckmark">✔</div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
