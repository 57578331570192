import * as React from "react";

import "../assets/css/Article.css";

interface IProps {
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
}

class OutlookHelp extends React.Component<IProps> {
  render() {
    window.document.title = "University of Essex - EDRM Help Pages";
    return (
      <div className="Article Help">
        <div className="WelcomeBar">
          <h1>Outlook App Help Files</h1>
          <div className="Subtitle">Article last updated 13th June 2024.</div>
          <div className="DescriptionBlock">
            This page contains links to the Outlook App Help files. We hope you
            find it useful.
          </div>
        </div>
        <div className="Segmentary">
          <div className="Segments">
            <div className="Segment">
              <br></br>
              <p>
                For more information about how to install and use the New
                Outlook App please see:
              </p>
              <ul>
                <li>
                  {/* https://esf.essex.ac.uk/OutlookHelp/Outlook_App_Install_Guide_June_2024.pdf */}
                  <a href="/OutlookHelp/Outlook_App_Install_Guide_June_2024.pdf">
                    <strong>Outlook App Installation Guide</strong>
                  </a>
                </li>
                <li>
                  {/* https://esf.essex.ac.uk/OutlookHelp/Outlook-App-UserGuide.pdf */}
                  <a href="/OutlookHelp/Outlook-App-UserGuide.pdf">
                    <strong>Outlook App User Guide</strong>
                  </a>
                </li>
                <li>
                  {/* https://esf.essex.ac.uk/OutlookHelp/adding_a_shared_mailbox_on_outlook_webmail.pdf */}
                  <a href="/OutlookHelp/adding_a_shared_mailbox_on_outlook_webmail.pdf">
                    <strong>Outlook Adding a Shared Mailbox</strong>
                  </a>
                </li>
                <li>
                  {/* https://esf.essex.ac.uk/OutlookHelp/Uninstalling_outlook_app_June2024.pdf */}
                  <a href="/OutlookHelp/Uninstalling_outlook_app_June2024.pdf">
                    <strong>Outlook App Removal</strong>
                  </a>
                </li>
                <li>
                  {/* https://esf.essex.ac.uk/OutlookHelp/New-Outlook-App-User-Advice-June24.pdf */}
                  <a href="/OutlookHelp/New-Outlook-App-User-Advice-June24.pdf">
                    <strong>
                      Outlook App User Advice for Launch June 2024
                    </strong>
                  </a>
                </li>
              </ul>
              <hr></hr>
              <p>
                The SharePoint Team welcome feedback, comments, and suggestions
                about the new Outlook App look and feel. If you would like to
                send us something, please pop it into an e-mail to{" "}
                <a href="mailto:sphelp@essex.ac.uk">
                  <strong>sphelp@essex.ac.uk</strong>
                </a>
                . Thank you.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OutlookHelp;
