import { useLoaderData } from "react-router-dom";
import { Logging } from "../../Logging";
import CollagenWorkflowSummary from "./CollagenWorkflowSummary";
import { useApi } from "../../Providers/ApiProvider";
import "../../assets/css/Integrations/WorkflowSummary.css";
import { useEffect, useState } from "react";

interface WorkflowSummaryProps {
  MODE_LOCAL: boolean;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
  Logger: Logging;
}

export class WorkflowSummaryGUIDs {
  figuid: string;
  wiguid: string;
}

export const WorkflowSummary = (props: WorkflowSummaryProps) => {
  const guid: string = useLoaderData() as string;
  const da = useApi();
  const [guids, setGuids] = useState(WorkflowSummaryGUIDs.prototype);

  useEffect(() => {
    if ((guid ?? "") !== "") {
      da.General.get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.SUMMARY_Disambiguate_GUIDs",
          params: ["@GUID", guid],
        }),
      ).then((resp) => {
        setGuids({
          figuid: resp[0][0].FIGUID,
          wiguid: resp[0][0].WIGUID,
        });
      });
    }
  }, [guid]);

  return (
    <div id="CollagenWorkflowSummary" className="CWS">
      {guids !== WorkflowSummaryGUIDs.prototype ? (
        <CollagenWorkflowSummary
          {...props}
          da={da.General}
          {...guids}
        ></CollagenWorkflowSummary>
      ) : (
        <></>
      )}
    </div>
  );
};
