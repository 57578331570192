import {
  DefaultButton,
  Dropdown,
  getIconClassName,
  IDropdownOption,
  TextField,
} from "@fluentui/react";
import { MetaDataField } from "./MetaDataField";
import { DataAccess } from "../../data/DataAccess";
import { SPOFileState, SPOUploadFile } from "./SPInterfacing/SPOUploadFile";
import { SPOContentType } from "./SPInterfacing/SPOContentType";
import { Logging } from "../../Logging";
import { SPOMValState } from "./SPInterfacing/SPOMetadata";

interface IProps {
  da: DataAccess;
  Logger: Logging;
  Files: SPOUploadFile[];
  StatefullySetFiles: (files: SPOUploadFile[]) => void;
  StatefullySetFile: (file: SPOUploadFile) => void;
  StatefullyClearFileMetadata: (file: SPOUploadFile) => void;
  StatefullyUpdateFileMetadata: (
    file: SPOUploadFile,
    name: string,
    value: string,
  ) => void;
  StatefullyUpdateFileName: (
    _uf: SPOUploadFile,
    name: string,
    extension: string,
  ) => void;
  StatefullyUpdateFileContentType: (file: SPOUploadFile, ctid: string) => void;
  StatefullyUploadFile: (file: SPOUploadFile) => void;
  StatefullyRemoveFile: (file: SPOUploadFile) => void;
  StatefullyUploadValidFiles: () => void;
  Autofill?: { Type: string; Value: string };
  UploadConveyance: string;
  ContentTypes: SPOContentType[];
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  ReferentiallyExamineFileName: (FileName: string) => string;
}

export function FileUploadQueue(props: IProps) {
  let ctOptions: IDropdownOption[] = [{ key: "-", text: "-" }].concat(
    props.ContentTypes?.map((ct) => ({
      key: ct.contentTypeId,
      text: ct.contentType,
    })),
  );

  return props.Files !== undefined &&
    props.Files.length > 0 &&
    !props.Files.every((th) => th.State === SPOFileState.Completed) ? (
    <div className="FileMetadataAreas">
      <div className="SummaryButtonBar">
        <DefaultButton
          className="SummaryButton"
          disabled={props.Files.every(
            (file) =>
              file.State === SPOFileState.Completed ||
              file.Errors.filter((ith) => ith.ErrorName !== "FileName").length >
                0 ||
              !file.MetaData.some((ith) => ith.DataName === "ContentTypeId") ||
              file.MetaData.some(
                (th) =>
                  th.DataName === "ContentTypeId" && th.DataValue === undefined,
              ) ||
              file.MetaDataFields === undefined ||
              (file.MetaDataFields !== undefined &&
                file.MetaDataFields.some(
                  (mdf) =>
                    mdf.required &&
                    !file.MetaData.some(
                      (th) =>
                        th.DataName === mdf.internalName &&
                        th.DataValue !== undefined &&
                        th.DataValue !== "",
                    ),
                )),
          )}
          onClick={() => {
            props.StatefullyUploadValidFiles();
          }}
          text="Upload all valid files"
        />
      </div>
      <div className="JustASpacer"></div>
      {props.Files?.map((file, i) => (
        <div
          hidden={file.State === SPOFileState.Completed}
          className={
            "FileMetadataArea" +
            (file.State === SPOFileState.InPreparation
              ? " InPreparation"
              : file.State === SPOFileState.Processing
                ? " Processing"
                : file.State === SPOFileState.Errored
                  ? " Errored"
                  : file.State === SPOFileState.Completed
                    ? " Completed"
                    : "") +
            (file.Errors.filter((ith) => ith.ErrorName !== "FileName").length >
              0 ||
            !file.MetaData.some(
              (ith) =>
                ith.DataName === "ContentTypeId" ||
                file.MetaData.some(
                  (th) =>
                    th.DataName === "ContentTypeId" &&
                    th.DataValue === undefined,
                ),
            )
              ? " ErrorState"
              : "")
          }
          key={i}
        >
          <i
            className={getIconClassName("ComplianceAudit") + " Icon"}
            aria-hidden="true"
          ></i>

          {file.Errors.some(
            (th) =>
              th.ErrorValue === "System file names are prohibited" ||
              th.ErrorValue === "This file has no extension" ||
              th.ErrorValue === "This file extension is prohibited",
          ) ? (
            <>
              <div className="metadata-field">
                <TextField
                  required
                  label="File Name"
                  suffix={file.Overridden_Ext}
                  value={file.Overridden_Name}
                  errorMessage={file.Errors.filter(
                    (th) =>
                      th.ErrorValue === "System file names are prohibited" ||
                      th.ErrorValue === "This file has no extension" ||
                      th.ErrorValue === "This file extension is prohibited",
                  )
                    .map((ith) => {
                      return ith.ErrorValue;
                    })
                    .join(" ")}
                  disabled={true}
                />
              </div>
              <div className="Controls">
                <div className="Buttons">
                  <DefaultButton
                    onClick={() => props.StatefullyRemoveFile(file)}
                    text="Remove"
                    disabled={file.State !== SPOFileState.InPreparation}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="metadata-field">
                <TextField
                  required
                  label="File Name"
                  suffix={file.Overridden_Ext}
                  value={file.Overridden_Name}
                  onChange={(_, val) =>
                    props.StatefullyUpdateFileName(
                      file,
                      val!,
                      file.Overridden_Ext,
                    )
                  }
                  errorMessage={file.Errors.filter(
                    (th) => th.ErrorName === "OverrideFileName",
                  )
                    ?.map((th) => th.ErrorValue)
                    .join(",")}
                />
                {file.File_Name !== file.Suggested_Name ||
                file.Errors.filter((th) => th.ErrorName === "FileName").length >
                  0 ? (
                  <ul className="NewFileNameReasoning">
                    <li>
                      Original file name was
                      <div className="NameTooLong">{file.File_Name}</div>
                    </li>
                    {file.Errors.filter(
                      (th) =>
                        th.ErrorName === "FileName" &&
                        th.ErrorValue !== "Multiple files have this name" &&
                        th.ErrorValue !== "System file names are prohibited" &&
                        th.ErrorValue !== "This file has no extension" &&
                        th.ErrorValue !== "This file extension is prohibited",
                    )?.map((th, i) => {
                      return (
                        <li key={"FNE_" + i.toString()} className="PlainError">
                          {th.ErrorValue}
                        </li>
                      );
                    })}
                    {file.Suggested_Name ===
                    file.File_Name.replaceAll(" ", "_") ? (
                      <li>Underscores are preferred to spaces.</li>
                    ) : (
                      <></>
                    )}
                    <li>File name has been changed to meet file name rules.</li>
                    <li>
                      You may further alter the suggested new file name before
                      uploading, if appropriate.
                    </li>
                    {file.Errors.filter(
                      (th) =>
                        th.ErrorName === "FileName" &&
                        th.ErrorValue === "Multiple files have this name",
                    )?.map((th, i) => {
                      return (
                        <li key={"FNEL_" + i.toString()} className="PlainError">
                          {th.ErrorValue}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
              <Dropdown
                placeholder="Content Type"
                required
                label="Content Type"
                options={ctOptions!}
                selectedKey={
                  file.MetaData.some(
                    (th) =>
                      th.DataName === "ContentTypeId" &&
                      th.DataValue !== undefined,
                  )
                    ? file.MetaData.find(
                        (th) => th.DataName === "ContentTypeId",
                      ).DataValue.toString()
                    : ""
                }
                onChange={(_, ctx) => {
                  //  console.log(file);
                  //  console.log(ctx);
                  //  console.log(ctx!.key);

                  props.StatefullyUpdateFileContentType(
                    file,
                    ctx!.key.toString(),
                  );
                }}
              />

              {
                // Display the identifier fields at the top
              }
              {props.ContentTypes != undefined &&
              file.MetaData?.find((mdf) => mdf.DataName == "ContentTypeId") !=
                undefined ? (
                <>
                  {file.MetaDataFields?.filter(
                    (mdf) =>
                      props.ContentTypes.find(
                        (ct) =>
                          ct.contentTypeId ==
                          file.MetaData?.find(
                            (mdf) => mdf.DataName == "ContentTypeId",
                          ).DataValue,
                      ).preferredIdent.find((s) => s == mdf.internalName) !=
                      undefined,
                  )
                    .sort((a, b) => a.displayName.localeCompare(b.displayName))
                    .map((m) => (
                      <MetaDataField
                        file={file}
                        field={m}
                        UpdateField={props.StatefullyUpdateFileMetadata}
                        key={m.internalName}
                        errorText={file.Errors.filter(
                          (th) => th.ErrorName === m.internalName,
                        )
                          ?.map((th) => th.ErrorValue)
                          .join(",")}
                        value={
                          file.MetaData.find(
                            (th) => th.DataName === m.internalName,
                          )?.DataValue
                        }
                      />
                    ))}

                  {
                    // Display the required fields second
                  }
                  {file.MetaDataFields?.filter(
                    (mdf) =>
                      !(
                        props.ContentTypes.find(
                          (ct) =>
                            ct.contentTypeId ==
                            file.MetaData?.find(
                              (mdf) => mdf.DataName == "ContentTypeId",
                            ).DataValue,
                        ).preferredIdent.find((s) => s == mdf.internalName) !=
                        undefined
                      ),
                  )
                    .filter((mdf) => mdf.required)
                    .sort((a, b) => a.displayName.localeCompare(b.displayName))
                    .map((m) => (
                      <MetaDataField
                        file={file}
                        field={m}
                        UpdateField={props.StatefullyUpdateFileMetadata}
                        key={m.internalName}
                        errorText={file.Errors.filter(
                          (th) => th.ErrorName === m.internalName,
                        )
                          ?.map((th) => th.ErrorValue)
                          .join(",")}
                        value={
                          file.MetaData.find(
                            (th) => th.DataName === m.internalName,
                          )?.DataValue
                        }
                      />
                    ))}
                  {
                    // Finally display the optional fields
                  }
                  {file.MetaDataFields?.filter(
                    (mdf) =>
                      !(
                        props.ContentTypes.find(
                          (ct) =>
                            ct.contentTypeId ==
                            file.MetaData?.find(
                              (mdf) => mdf.DataName == "ContentTypeId",
                            ).DataValue,
                        ).preferredIdent.find((s) => s == mdf.internalName) !=
                        undefined
                      ),
                  )
                    .filter((mdf) => !mdf.required)
                    .sort((a, b) => a.displayName.localeCompare(b.displayName))
                    .map((m) => (
                      <MetaDataField
                        file={file}
                        field={m}
                        UpdateField={props.StatefullyUpdateFileMetadata}
                        key={m.internalName}
                        errorText={file.Errors.filter(
                          (th) => th.ErrorName === m.internalName,
                        )
                          ?.map((th) => th.ErrorValue)
                          .join(",")}
                        value={
                          file.MetaData.find(
                            (th) => th.DataName === m.internalName,
                          )?.DataValue
                        }
                      />
                    ))}
                </>
              ) : (
                <></>
              )}
              <div className="Controls">
                <ul className="Errors">
                  {file.MetaData.filter((th) => th.DataName === "ContentTypeId")
                    .length === 0 ||
                  file.MetaData.filter(
                    (th) =>
                      th.DataName === "ContentTypeId" &&
                      th.DataValue === undefined,
                  ).length > 0 ? (
                    <li className="Error Local">Content type is required.</li>
                  ) : (
                    ""
                  )}
                  {file.MetaDataFields?.map((mdf) =>
                    mdf.required &&
                    file.MetaData.some(
                      (th) => th.DataName === mdf.internalName,
                    ) ? (
                      file.MetaData.find(
                        (th) => th.DataName === mdf.internalName,
                      ).DataValue === undefined ? (
                        <li className="Error Meta" key={mdf.internalName}>
                          {mdf.displayName} is required.
                        </li>
                      ) : file.MetaData.find(
                          (th) => th.DataName === mdf.internalName,
                        ).DataValue === "" ? (
                        <li className="Error Meta" key={mdf.internalName}>
                          {mdf.displayName} is required.
                        </li>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    ),
                  )}
                  {file.Errors.some((th) => th.ErrorName === "") ? (
                    <li className="Error Meta">
                      {file.Errors.find((th) => th.ErrorName === "").ErrorValue}
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              {file.AlreadyUploadedStruct !== undefined ? (
                file.AlreadyUploadedStruct.PreviousUploads.length > 0 ? (
                  <div className="Advisory">
                    <p>
                      <b>Possible Overwrite Detected</b>
                    </p>
                    {file.AlreadyUploadedStruct.PreviousUploads.map((ith) => {
                      return (
                        <div>
                          <p>
                            <b>{file.Overridden_Name + file.Overridden_Ext}</b>{" "}
                            was last uploaded to{" "}
                            {(ith.MetaData.esf_prid ?? "") !== "" ? (
                              <span>
                                <b>esf_prid</b> {ith.MetaData.esf_prid}
                              </span>
                            ) : (
                              <></>
                            )}
                            {(ith.MetaData.edocs_PGNO ?? "") !== "" ? (
                              <span>
                                <b>edocs_PGNO</b> {ith.MetaData.edocs_PGNO}
                              </span>
                            ) : (
                              <></>
                            )}
                            {(ith.MetaData.edocs_UCASNo ?? "") !== "" ? (
                              <span>
                                <b>edocs_UCASNo</b> {ith.MetaData.edocs_UCASNo}
                              </span>
                            ) : (
                              <></>
                            )}
                            {(ith.MetaData.edocs_ClearingRefNo ?? "") !== "" ? (
                              <span>
                                <b>edocs_ClearingRefNo</b>{" "}
                                {ith.MetaData.edocs_ClearingRefNo}
                              </span>
                            ) : (
                              <></>
                            )}
                            {(ith.MetaData.edocs_RegNo ?? "") !== "" ? (
                              <span>
                                <b>edocs_RegNo</b> {ith.MetaData.edocs_RegNo}
                              </span>
                            ) : (
                              <></>
                            )}{" "}
                            at {new Date(ith.UploadedAt).toLocaleString()}.
                          </p>
                          <p>
                            Click <b>Upload</b> to proceed anyway, or{" "}
                            <b>Remove</b> to cancel this upload.
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <div className="Controls">
                <div className="Buttons">
                  <DefaultButton
                    className="RemoveButton"
                    onClick={() => props.StatefullyRemoveFile(file)}
                    text={
                      file.State === SPOFileState.InPreparation
                        ? "Remove"
                        : file.State === SPOFileState.Processing
                          ? "Processing"
                          : file.State === SPOFileState.Errored
                            ? "Failed"
                            : ""
                    }
                    disabled={file.State !== SPOFileState.InPreparation}
                  />
                  <DefaultButton
                    className="UploaderButton"
                    disabled={
                      file.State === SPOFileState.Processing ||
                      file.State === SPOFileState.Errored ||
                      file.MetaData.some(
                        (ith) => ith.Validation === SPOMValState.Pending,
                      ) ||
                      !file.MetaData.some(
                        (th) => th.DataName === "ContentTypeId",
                      ) ||
                      file.MetaData.some(
                        (th) =>
                          th.DataName === "ContentTypeId" &&
                          th.DataValue === undefined,
                      ) ||
                      file.MetaDataFields?.findIndex(
                        (mdf) =>
                          mdf.required &&
                          file.MetaData.some(
                            (th) => th.DataName === mdf.internalName,
                          ) &&
                          (file.MetaData.find(
                            (th) => th.DataName === mdf.internalName,
                          ).DataValue === undefined ||
                            file.MetaData.find(
                              (th) => th.DataName === mdf.internalName,
                            ).DataValue === ""),
                      ) !== -1 ||
                      file.Errors.filter(
                        (ith) =>
                          ith.ErrorName !== "FileName" &&
                          ith.ErrorValue !== "This identifier is preferred.",
                      ).length > 0 ||
                      // hideous. enforce at least one preferred identifier.
                      (file.MetaData.length > 0 &&
                        file.MetaData.some(
                          (th) => th.DataName === "ContentTypeId",
                        ) &&
                        file.Errors.filter(
                          (ith) =>
                            ith.ErrorValue === "This identifier is preferred.",
                        ).length ===
                          props.ContentTypes.find(
                            (th) =>
                              th.contentTypeId ===
                                (file.MetaData.find(
                                  (th) => th.DataName === "ContentTypeId",
                                ).DataValue as string) ?? "",
                          ).preferredIdent.length)
                    }
                    onClick={() => {
                      props.StatefullyUploadFile(file);
                    }}
                    text={
                      file.State === SPOFileState.InPreparation
                        ? "Upload"
                        : file.State === SPOFileState.Processing
                          ? "Processing"
                          : file.State === SPOFileState.Errored
                            ? "Failed"
                            : ""
                    }
                  />
                  <DefaultButton
                    className="ClearButton"
                    onClick={() => props.StatefullyClearFileMetadata(file)}
                    text={
                      file.State === SPOFileState.InPreparation
                        ? "Clear Data"
                        : file.State === SPOFileState.Processing
                          ? "Processing"
                          : file.State === SPOFileState.Errored
                            ? "Failed"
                            : ""
                    }
                    disabled={file.State !== SPOFileState.InPreparation}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      ))}
      <div className="JustASpacer"></div>
      <div className="SummaryButtonBar">
        <DefaultButton
          className="SummaryButton"
          disabled={props.Files.every(
            (file) =>
              file.State === SPOFileState.Completed ||
              file.Errors.filter(
                (ith) =>
                  ith.ErrorName !== "FileName" &&
                  ith.ErrorValue !== "This identifier is preferred.",
              ).length > 0 ||
              // hideous. enforce at least one preferred identifier.
              (file.MetaData.length > 0 &&
                file.MetaData.some((th) => th.DataName === "ContentTypeId") &&
                file.Errors.filter(
                  (ith) => ith.ErrorValue === "This identifier is preferred.",
                ).length ===
                  props.ContentTypes.find(
                    (th) =>
                      th.contentTypeId ===
                        (file.MetaData.find(
                          (th) => th.DataName === "ContentTypeId",
                        ).DataValue as string) ?? "",
                  ).preferredIdent.length) ||
              !file.MetaData.some((ith) => ith.DataName === "ContentTypeId") ||
              file.MetaData.some(
                (th) =>
                  th.DataName === "ContentTypeId" && th.DataValue === undefined,
              ) ||
              file.MetaDataFields === undefined ||
              (file.MetaDataFields !== undefined &&
                file.MetaDataFields.some(
                  (mdf) =>
                    mdf.required &&
                    !file.MetaData.some(
                      (th) =>
                        th.DataName === mdf.internalName &&
                        th.DataValue !== undefined &&
                        th.DataValue !== "",
                    ),
                )),
          )}
          onClick={() => {
            props.StatefullyUploadValidFiles();
          }}
          text="Upload all valid files"
        />
      </div>
    </div>
  ) : (
    <div className="FileMetadataAreas">
      <div className="AdvisoryArea">Waiting for files.</div>
    </div>
  );
}
