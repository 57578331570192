import { PublicClientApplication } from "@azure/msal-browser";
import { IMsalContext } from "@azure/msal-react";
import { Logging } from "../../Logging";
import { DataAccess, User } from "../../data/DataAccess";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import ModeStruct from "../../models/ModeStruct";
import SPOUploadLocation from "../../models/SPOUploadLocation";
import UGEAF from "./UGEAF";
import { useLoaderData } from "react-router-dom";

interface IProps {
  MSAL: IMsalContext;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Logger: Logging;
  da: DataAccess;
  IDA: InvarDataAccess;
  PCA: PublicClientApplication;
  GraphAccessToken: string;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  AuthedUser: User;
  ViewAsUser: User;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export const UGEAFWrapper = (props: IProps) => {
  const loader: any = useLoaderData();

  return <UGEAF {...props} UCASNo={loader.UCASNo || ""} />;
};
