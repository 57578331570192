import {
  AuditFlagEditor,
  PersonType,
} from "../Components/AuditFlagEditor/AuditFlagEditor";

import "../../../assets/css/Compliance_LF/ComplianceAuditFlagger.css";
import "../../../assets/css/Compliance_LF/AuditFlagEditor.css";
import { useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApi } from "../../../Providers/ApiProvider";

interface ComplianceAuditFlaggerProps {}

export function ComplianceAuditFlagger(props: ComplianceAuditFlaggerProps) {
  let loaderData: any = useLoaderData();
  let [StuName, setStuName] = useState<string>();
  let api = useApi();

  useEffect(() => {
    api.General.get(
      "/sproc",
      JSON.stringify({
        sproc: "sputilities.webui.Comply_studata_TitleName",
        params: ["@PRID", loaderData.ident],
      }),
    ).then((resp) => {
      setStuName(resp[0][0].Name);
    });
  }, []);

  let sprocDetails = {};

  switch (loaderData.personType) {
    case PersonType.PGAPPLICANT:
      sprocDetails = {
        sproc: "sputilities.webui.PGEAF_SUMMARY_applicant_header_top",
        params: ["@pgno", loaderData.ident],
      };
      break;
    case PersonType.UGAPPLICANT:
      sprocDetails = {
        sproc: "sputilities.webui.UGEAF_SUMMARY_applicant_header_top",
        params: ["@ucasno", loaderData.ident],
      };
      break;
    case PersonType.STUDENT:
      sprocDetails = {
        sproc: "sputilities.webui.ESF_SUMMARY_basic_info_top",
        params: ["@prid", loaderData.ident],
      };
      break;
  }

  return (
    <div className="AFE_Container">
      <h1>Flag Documents for Home Office Audit</h1>
      <h2>Instructions</h2>

      <p className="blurb">
        This page can be used to set (or remove) the 'Include for Audit' flag
        from multiple documents simultaneously. Use the toggles to select the
        files which should be shown to a Home Office Auditor, and unselect any
        which are not relevant. Hovering your mouse cursor over any document
        will bring up a preview window to the right-hand side of the page so you
        do not have to open every document to see what it contains.
      </p>
      <p>
        <b>Please Note:</b> Changes made on this screen will be applied
        immediately but may take a short period of time to show correctly on
        here and the student profile.
      </p>
      <div className="AFE_PersonDetails">
        <strong>Student/Applicant:</strong> {StuName}
      </div>

      <AuditFlagEditor
        personType={loaderData.personType}
        personIdentifier={loaderData.ident}
      />
    </div>
  );
}
