import { FilterMaskType } from "../Structs/Enums/FilterMaskType";
import { FilterColumnOptions } from "../Structs/Interfaces/FilterColumnOptions";
import { MondrianColumn } from "../Structs/Types/MondrianStructuredData";

interface MondrianFiltererProps {
  Filters: FilterColumnOptions[];
  SetFilters: (FO: FilterColumnOptions[]) => void;
  Filterables: string[];
  DataColumns: MondrianColumn[];
}

export const MondrianFilterer = (props: MondrianFiltererProps) => {
  return props.Filters.length > 0 ? (
    <div className="Mondrian__Grid_Filters">
      <div className="Mondrian__Grid_Filters_Header">Filters</div>
      <table className="Mondrian__Grid_Filters_Table">
        {props.Filters.map((th, i) => {
          const ind = props.Filters.findIndex(
            (ith) => ith.Column === th.Column,
          );
          return (
            <tr key={"MGF_" + i.toString()}>
              /* column name */
              <td>
                <div className="Mondrian__Grid_Filters_ColumnName">
                  {th.Column}
                </div>
              </td>
              /* filter value */
              <td>
                <input className="Mondrian__Grid_Filters_FilterValue"></input>
              </td>
              /* hide this column */
              <td>
                <button
                  className="Mondrian__Grid_Filters_Like"
                  hidden={th.Type === FilterMaskType.Like}
                  onClick={() => {
                    var newvisis = [...props.Filters];
                    newvisis[ind].Type = FilterMaskType.Like;
                    props.SetFilters(newvisis);
                  }}
                ></button>
              </td>
              /* show this column */
              <td>
                <button
                  className="Mondrian__Grid_Filters_Not_Like"
                  hidden={th.Type === FilterMaskType.NotLike}
                  onClick={() => {
                    var newfilts = [...props.Filters];
                    newfilts[ind].Type === FilterMaskType.NotLike;
                    props.SetFilters(newfilts);
                  }}
                ></button>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  ) : (
    <></>
  );
};
