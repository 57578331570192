import { ReactElement, useEffect, useState } from "react";
import { useApi } from "../../../../Providers/ApiProvider";
import GridContent from "../../../../integrations/UoE-Grid/GridContent";
import { IItem } from "../../../../models/IItem";
import { Logging } from "../../../../Logging";
import { MessageBar, ProgressIndicator } from "@fluentui/react";

import "../../../../assets/css/Compliance_LF/AuditFlagEditor.css";

export enum PersonType {
  STUDENT,
  PGAPPLICANT,
  UGAPPLICANT,
}

enum MessageType {
  NORMAL,
  SUCCESS,
  ERROR,
}

type Message = {
  type: MessageType;
  message: String;
  show: Boolean;
};

interface AuditFlagEditorProps {
  personType: PersonType;
  personIdentifier: string;
}

export function AuditFlagEditor(props: AuditFlagEditorProps): ReactElement {
  let api = useApi();
  let [loading, setLoading] = useState<boolean>(true);
  let [data, setData] = useState<IItem[]>([]);
  let [hasMutations, setMutations] = useState<boolean>(false);
  let [message, setMessage] = useState<Message>({
    type: MessageType.NORMAL,
    message: "",
    show: false,
  });

  let funcs = [
    {
      Name: "Audit Doc_CHECKBOX",
      ColumnKey: "_id",
      Function: (id: any) => {
        let updates: IItem[] = [];
        //data.forEach((val) => updates.push({ ...val }));
        updates = data.map((d) => {
          if (d["_id"] == id) {
            // I know. I know. It's nasty - but this is a reliable way of
            // parsing the required strings to bools, flipping them and converting back
            return {
              ...d,
              "Audit Doc_CHECKBOX": JSON.stringify(
                d["Audit Doc_CHECKBOX"].toLowerCase() == "false",
              ),
              _mutated: "true",
            };
          } else {
            return d;
          }
        });
        console.debug(updates);
        setData(updates);
        setMessage({
          type: MessageType.NORMAL,
          message: "",
          show: false,
        });
        setMutations(true);
      },
    },
  ];

  useEffect(() => {
    switch (props.personType) {
      case PersonType.STUDENT:
        api.General.getEsfDocsForFlagging(props.personIdentifier.toUpperCase())
          .then((respData) => {
            setData(respData);
            setMutations(false);
            setLoading(false);
          })
          .catch((err) => {
            setMessage({
              type: MessageType.ERROR,
              message: "Failed to load documents - " + err,
              show: true,
            });
          });
        break;
      case PersonType.UGAPPLICANT:
        api.General.getUgEafDocsForFlagging(
          props.personIdentifier.toUpperCase(),
        )
          .then((respData) => {
            setData(respData);
            setMutations(false);
            setLoading(false);
          })
          .catch((err) => {
            setMessage({
              type: MessageType.ERROR,
              message: "Failed to load documents - " + err,
              show: true,
            });
          });
        break;
      case PersonType.PGAPPLICANT:
        api.General.getPgEafDocsForFlagging(
          props.personIdentifier.toUpperCase(),
        )
          .then((respData) => {
            setData(respData);
            setMutations(false);
            setLoading(false);
          })
          .catch((err) => {
            setMessage({
              type: MessageType.ERROR,
              message: "Failed to load documents - " + err,
              show: true,
            });
          });
        break;
    }
  }, [props.personIdentifier, props.personType]);

  function saveData(): Promise<any> {
    let edited = data.filter((d) => d._mutated == "true");
    return api.General.saveAuditDocsFlag(edited);
  }

  function reset() {
    setLoading(true);
    (async () => {
      let respData: IItem[] = [];
      switch (props.personType) {
        case PersonType.STUDENT:
          respData = await api.General.getEsfDocsForFlagging(
            props.personIdentifier.toUpperCase(),
          );
          break;
        case PersonType.UGAPPLICANT:
          respData = await api.General.getUgEafDocsForFlagging(
            props.personIdentifier.toUpperCase(),
          );
          break;
        case PersonType.PGAPPLICANT:
          respData = await api.General.getPgEafDocsForFlagging(
            props.personIdentifier.toUpperCase(),
          );
          break;
      }

      setData(respData);
      setMutations(false);
      setLoading(false);
    })();
  }

  if (loading) {
    return (
      <>
        <div className="AFE_Main">
          <div className="AFE_Controls">
            <button className="AFE_Primary AFE_Button disabled" disabled>
              Save Changes
            </button>
            <button className="AFE_Primary AFE_Button disabled" disabled>
              Clear Changes
            </button>
            {message.show ? (
              <div
                className={
                  "AFE_Message" +
                  (message.type === MessageType.SUCCESS ? " AFE_Success" : "") +
                  (message.type === MessageType.ERROR ? " AFE_Fail" : "")
                }
              >
                {message.message}
              </div>
            ) : (
              <></>
            )}
          </div>
          <ProgressIndicator className="loading" barHeight={10} />
        </div>
      </>
    );
  } else if (data == undefined || data.length == 0) {
    return (
      <>
        <MessageBar className="no-results">
          No results were returned.
        </MessageBar>
      </>
    );
  } else {
    return (
      <div className="AFE_Main">
        <div className="AFE_Controls">
          <button
            className={
              "AFE_Primary AFE_Button " + (hasMutations ? "" : "disabled")
            }
            disabled={!hasMutations}
            onClick={() => {
              setMutations(false);
              setMessage({
                type: MessageType.NORMAL,
                message: "Saving...",
                show: true,
              });
              saveData()
                .then((_) => {
                  setLoading(false);
                  setMessage({
                    type: MessageType.SUCCESS,
                    message: "Changes Saved",
                    show: true,
                  });
                })
                .catch((err) => {
                  setMessage({
                    type: MessageType.ERROR,
                    message: "Failed to save metadata - " + err,
                    show: true,
                  });
                  setMutations(true);
                  console.error(JSON.stringify(err));
                });
            }}
          >
            Save Changes
          </button>
          <button
            className={
              "AFE_Warning AFE_Button " + (hasMutations ? "" : "disabled")
            }
            disabled={!hasMutations}
            onClick={() => {
              reset();
              setMessage({
                type: MessageType.NORMAL,
                message: "Changes Reset",
                show: true,
              });
            }}
          >
            Reset Changes
          </button>
          {message.show ? (
            <div
              className={
                "AFE_Message" +
                (message.type === MessageType.SUCCESS ? " AFE_Success" : "") +
                (message.type === MessageType.ERROR ? " AFE_Fail" : "")
              }
            >
              {message.message}
            </div>
          ) : (
            <></>
          )}
        </div>
        <GridContent
          eid="AFE"
          items={data}
          Logger={new Logging("LIVE")}
          gridindex={undefined}
          start={0}
          limit={200}
          showAdvancedSort={true}
          showAdvancedGrouping={true}
          showAdvancedFiltration={true}
          showCookieBouncer={false}
          Refresher=""
          Callouts={true}
          ButtonsAndFunctions={funcs}
          sort="Document Type,Filename"
          group="Document Type"
        ></GridContent>
        <div className="AFE_Controls">
          <button
            className={
              "AFE_Primary AFE_Button " + (hasMutations ? "" : "disabled")
            }
            disabled={!hasMutations}
            onClick={() => {
              setMutations(false);
              setMessage({
                type: MessageType.NORMAL,
                message: "Saving...",
                show: true,
              });
              saveData()
                .then((_) => {
                  setLoading(false);
                  setMessage({
                    type: MessageType.SUCCESS,
                    message: "Changes Saved",
                    show: true,
                  });
                })
                .catch((err) => {
                  setMessage({
                    type: MessageType.ERROR,
                    message: "Failed to save metadata - " + err,
                    show: true,
                  });
                  setMutations(true);
                  console.error(JSON.stringify(err));
                });
            }}
          >
            Save Changes
          </button>
          <button
            className={
              "AFE_Warning AFE_Button " + (hasMutations ? "" : "disabled")
            }
            disabled={!hasMutations}
            onClick={() => {
              reset();
              setMessage({
                type: MessageType.NORMAL,
                message: "Changes Reset",
                show: true,
              });
            }}
          >
            Reset Changes
          </button>
          {message.show ? (
            <div
              className={
                "AFE_Message" +
                (message.type === MessageType.SUCCESS ? " AFE_Success" : "") +
                (message.type === MessageType.ERROR ? " AFE_Fail" : "")
              }
            >
              {message.message}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
