export class EnqueueRequest {
  ConveyanceID: number;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  User: string;
  InitialValue: string;
  InitialMetadata: EnqueueMetadataRequest[];
}

export class EnqueueMetadataRequest {
  Name: string;
  Value: string;
}
