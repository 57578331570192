import { useLoaderData } from "react-router-dom";
import { getIconClassName } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useApi } from "../../Providers/ApiProvider";
import { GridWrapper } from "../../integrations/UoE-Grid/GridWrapper";
import { ESFLink } from "../EsfLink";
import "../../assets/css/ECF.css";
import { useFunction } from "../../Providers/FunctionProvider";
import IfPermitted from "../IfPermitted";
import { UploadManager } from "../../integrations/SPUploadManager/UploadManager";
import { useUser } from "../../Providers/UserProvider";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import SPOUploadLocation from "../../models/SPOUploadLocation";
import { RegistrationPhoto } from "../../integrations/UoE-Grid/RegistrationPhoto";

interface ECFProps {
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  IDA: InvarDataAccess;
  GraphAccessToken: string;
  Refresh: () => void;
  GetSPUL: (shortname: string) => SPOUploadLocation;
}

export function ECF(props: ECFProps) {
  let loaderData: any = useLoaderData();
  // let PRID: string = loaderData.prid;
  let user = useUser();
  let api = useApi();
  let functions = useFunction();
  let [StuName, setStuName] = useState<string>();
  let [ShowIA, setShowIA] = useState<boolean>(false);
  let [ShowDC, setShowDC] = useState<boolean>(false);
  let [ShowAN, setShowAN] = useState<boolean>(false);
  let [ShowHA, setShowHA] = useState<boolean>(false);
  let [ShowUploader, setShowUploader] = useState<boolean>(false);
  let PassToAllGrids = {
    Callouts: false,
    Logger: functions.Logger,
    da: api.General,
    prid: loaderData.PRID,
    GetLink: functions.GetLink,
    URLRoot: "",
    class: "UoE-Grid ECF",
    Refresher: functions.Refresher,
    Alias: user.Alias,
    AliasChecked: user.AliasChecked,
    RegisterError: functions.RegisterError,
    DeregisterError: functions.DeregisterError,
    RegisterStatus: functions.RegisterStatus,
    DeregisterStatus: functions.DeregisterStatus,
    ViewAsAll: false,
  };
  useEffect(() => {
    api.General.get(
      "/sproc",
      JSON.stringify({
        // sproc: "sputilities.webui.Comply_studata_TitleName",
        sproc: "sputilities.webui.ECF_SUMMARY_name_and_status",
        params: ["@PRID", loaderData.PRID],
      }),
    ).then((resp) => {
      setStuName(resp[0][0].Name);
    });
  }, []);

  return (
    <div className="ECF_Container">
      {/* top section */}
      <div className="ECF_Subcontainer" id="NameBlock_Cont">
        <div className="ECF_Row" id="NameData">
          <span id="NameBlock">
            <GridWrapper
              // {...PassToAllGrids}
              // sproc from esf page to consolidate data
              eid={"NameBlock_" + functions.Refresher}
              sproc="sputilities.webui.ECF_SUMMARY_name_and_status"
              params={["@prid", loaderData.PRID.toUpperCase()]}
              output="data"
              Callouts={false}
              URLParams={""}
            ></GridWrapper>
          </span>
        </div>
      </div>
      <hr />
      <div className="ECF_Subcontainer" id="StudentInfo_Cont">
        <div className="ECF_Row" id="StudentInfoData">
          <div className="ECF_Column" id="PhotoBlock">
            <span id="RegPhoto">
              <RegistrationPhoto ident={loaderData.PRID} />
            </span>
            <span id="ESFLink_Button">
              <ESFLink
                prid={loaderData.PRID.toUpperCase()}
                Environment={props.Environment}
              />
            </span>
          </div>
          <div className="ECF_Column" id="BasicDataBlockLeft">
            {/* column 1 */}
            <span id="BasicInfoLeft">
              <GridWrapper
                //   {...PassToAllGrids}
                eid={"BasicInfoLeft_" + functions.Refresher}
                //  arrrg csm..v_ecf_basic_info_left
                sproc="sputilities.webui.ECF_SUMMARY_basic_info_left"
                params={["@PRID", loaderData.PRID.toUpperCase()]}
                output="data"
                rename="photo,_photo;age_flag,age_CSS"
                Callouts={false}
                URLParams={""}
              ></GridWrapper>
            </span>

            <span id="FinanceLeft">
              <GridWrapper
                eid={"FinanceLeft_" + functions.Refresher}
                sproc="sputilities.webui.ECF_SUMMARY_finance_info"
                params={["@PRID", loaderData.PRID.toUpperCase()]}
                output="data"
                Callouts={false}
                URLParams={""}
              ></GridWrapper>
            </span>
          </div>
          <div className="ECF_Column" id="BasicDataBlockRight">
            <span id="BasicInfoRight">
              <GridWrapper
                eid={"BasicInfoRight_" + functions.Refresher}
                sproc="sputilities.webui.ECF_SUMMARY_basic_info_right"
                params={["@PRID", loaderData.PRID.toUpperCase()]}
                output="data"
                Callouts={false}
                URLParams={""}
              ></GridWrapper>
            </span>

            <span id="BasicIdentsRight">
              <GridWrapper
                eid={"CAS_Studata_Summary"}
                sproc="sputilities.webui.ECF_SUMMARY_list_idents"
                params={["@ident", loaderData.PRID.toUpperCase()]}
                output="data"
                Callouts={false}
                URLParams={""}
              ></GridWrapper>
            </span>
          </div>
        </div>
      </div>
      {/* end top section */}
      {/* Accordian section */}
      <hr />
      <div className="ECF_Subcontainer" id="Accordian_Cont">
        <div className="ECF_Row" id="IA">
          <h2
            onClick={() => {
              setShowIA(!ShowIA);
            }}
          >
            <i
              className={getIconClassName("UserOptional")}
              aria-hidden="true"
            ></i>{" "}
            Individual Arrangements
          </h2>
          {ShowIA ? (
            <GridWrapper
              //   {...PassToAllGrids}
              eid={"Individual_Arrangements_" + functions.Refresher}
              sproc="sputilities.webui.ECF_SUMMARY_get_ind_arr"
              params={["@PRID", loaderData.PRID.toUpperCase()]}
              output="grid"
              Callouts={false}
              URLParams={""}
              NoneMessage="No records found for this person"
            ></GridWrapper>
          ) : (
            <></>
          )}
        </div>

        <hr />
        <div className="ECF_Row" id="DC">
          <h2
            onClick={() => {
              setShowDC(!ShowDC);
            }}
          >
            <i
              className={getIconClassName("CustomList")}
              aria-hidden="true"
            ></i>{" "}
            Casework
          </h2>
          {ShowDC ? (
            <GridWrapper
              eid={"Disability_" + functions.Refresher}
              sproc="sputilities.webui.ECF_SUMMARY_get_disability"
              params={["@PRID", loaderData.PRID.toUpperCase()]}
              output="data"
              Callouts={false}
              URLParams={""}
              NoneMessage="No records found for this person"
            ></GridWrapper>
          ) : (
            <></>
          )}
        </div>
        <hr />
        <div className="ECF_Row" id="AN">
          <h2
            onClick={() => {
              setShowAN(!ShowAN);
            }}
          >
            <i
              className={getIconClassName("CustomList")}
              aria-hidden="true"
            ></i>{" "}
            Activity Notes Archive
          </h2>
          {ShowAN ? (
            <GridWrapper
              eid={"ActivityNotes_Archive_" + functions.Refresher}
              sproc="sputilities.webui.ECF_SUMMARY_Activity_Logs_Read"
              params={["@PRID", loaderData.PRID.toUpperCase()]}
              output="grid"
              Callouts={true}
              URLParams={""}
              NoneMessage="No records found for this person"
            ></GridWrapper>
          ) : (
            <></>
          )}
        </div>
        <IfPermitted Whitelist={["ECF.Teams"]}>
          <hr />
          <div className="ECF_Row" id="HA">
            <h2
              onClick={() => {
                setShowHA(!ShowHA);
              }}
            >
              <i
                className={getIconClassName("CustomList")}
                aria-hidden="true"
              ></i>{" "}
              Hardship Archive
            </h2>
            {ShowHA ? (
              <GridWrapper
                eid={"Hardship_Archive_" + functions.Refresher}
                sproc="sputilities.webui.ECF_SUMMARY_hardship"
                params={["@PRID", loaderData.PRID.toUpperCase()]}
                output="grid"
                Callouts={false}
                URLParams={""}
                NoneMessage="No records found for this person"
              ></GridWrapper>
            ) : (
              <></>
            )}
          </div>
        </IfPermitted>
      </div>
      {/* END Accordian section */}
      {/* Start Bottom Section */}
      <hr />
      <div className="ECF_Subcontainer" id="Bottom_Cont">
        <div className="ECF_Row" id="BottomRow">
          <div className="ECF_Column" id="eDocs">
            <span id="EDocs_span">
              <h2>
                <i
                  className={getIconClassName("CustomList")}
                  aria-hidden="true"
                ></i>{" "}
                ECF eDocs
              </h2>
              <IfPermitted Whitelist={["ECF.Uploader"]}>
                <div
                  className="UploadButton"
                  onClick={() => {
                    setShowUploader(!ShowUploader);
                  }}
                >
                  <i className={getIconClassName("Add")} aria-hidden="true"></i>{" "}
                  Upload New Document(s)
                </div>
              </IfPermitted>
              <div className="JustASpacer"></div>
              {ShowUploader === false ? (
                <GridWrapper
                  eid={"EDocs_" + functions.Refresher}
                  output="edocs-ecf"
                  group="Case Type,Query Type"
                  sort="Created Date"
                  sortdesc="true"
                  Callouts={true}
                  options="showcookiebouncer=false"
                  URLParams={""}
                  prid={loaderData.PRID.toUpperCase()}
                  NoneMessage="No documents found for this person"
                ></GridWrapper>
              ) : (
                <UploadManager
                  UploadConveyance={"ECF Direct Upload --> SPO"}
                  Autofill={{ Type: "PRID", Value: loaderData.PRID }}
                  SPUL={props.GetSPUL("ECF")}
                  IncludeRUG={false}
                  SwitchBack={() => {
                    setShowUploader(!ShowUploader);
                  }}
                  {...PassToAllGrids}
                  URLParams={""}
                  IDA={props.IDA}
                  Environment={props.Environment}
                  GraphAccessToken={props.GraphAccessToken}
                  Refresh={props.Refresh}
                />
              )}
            </span>
          </div>
          <div className="ECF_Column" id="EmergencyContact">
            {" "}
            <h2>
              <i
                className={getIconClassName("PeopleAlert")}
                aria-hidden="true"
              ></i>{" "}
              Emergency Contact
            </h2>
            {}
            <GridWrapper
              eid={"EmergencyContact_" + functions.Refresher}
              sproc="sputilities.webui.ECF_SUMMARY_emergency_contact_details"
              params={["@PRID", loaderData.PRID.toUpperCase()]}
              output="data"
              rename="_status,status_;_approved_for,approved_for_;_last_updated,last_updated_"
              Callouts={false}
              URLParams={""}
            ></GridWrapper>
          </div>
        </div>
      </div>
      {/* END Bottom Section */}
    </div>
  );
}
