import { ReactElement } from "react";

export function InactiveAgent(): ReactElement {
  return (
    <div className="AOSPanel Notices">
      <div className="PanelBar Title Errors">
        <div className="Left">Error</div>
        <div className="Breaker"></div>
        <div className="Right">Inactive Account</div>
      </div>
      <div className="PanelBody">
        This International Agent account is inactive.
      </div>
    </div>
  );
}
