import { ReactElement } from "react";

interface NonAgentUserProps {
  MODE_DEVELOPMENT: Boolean;
  MODE_STAGING: Boolean;
}

export function NonAgentUser(props: NonAgentUserProps): ReactElement {
  return (
    <div className="AOSPanel Notices">
      <div className="PanelBar Title Errors">
        <div className="Left">Error</div>
        <div className="Breaker"></div>
        <div className="Right">Non-Agent User</div>
      </div>
      <div className="PanelBody">
        {props.MODE_DEVELOPMENT
          ? "Please log in as, or View As, an Agent."
          : props.MODE_STAGING
            ? "Please log in as, or View As, an Agent."
            : "You are not currently logged in as an Agent."}
      </div>
    </div>
  );
}
