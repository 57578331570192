import React from "react";
import { DataAccess, User } from "../data/DataAccess";
import { GridWrapper } from "../integrations/UoE-Grid/GridWrapper";
import "../assets/css/TimetableSearch.css";
import ModeStruct from "../models/ModeStruct";
import SPOUploadLocation from "../models/SPOUploadLocation";
import { PublicClientApplication } from "@azure/msal-browser";
import { Logging } from "../Logging";
import { NavigateFunction } from "react-router-dom";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Location: any;
  Mode: string[];
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  Navigation: NavigateFunction;
}

interface IState {
  _LECsearchterm: string;
  LECsearchterm: string;
  _MODsearchterm: string;
  MODsearchterm: string;
  Searching: boolean;
  _newSearchString: string;
}

class TimetableSearch extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      _LECsearchterm: "",
      LECsearchterm: "",
      _MODsearchterm: "",
      MODsearchterm: "",
      Searching: false,
      _newSearchString: props.SearchTerm,
    };
  }

  render() {
    var PassToAllGrids = {
      Callouts: true,
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <div className="TimetableSearch">
        <div className="WarningBar">
          <strong>WARNING:</strong> Zoom links and timetable data are subject to
          change until 22:30pm on the day before the event is scheduled. We
          cannot recommend distribution of the Zoom Links until this time-window
          is reached.
        </div>
        <div className="InputRack">
          <div className="InputBlock Lefty">
            <label htmlFor="LecturerInput">
              Search by Lecturer by PRID or Lecturer email/username:
            </label>
            <input
              id="LecturerInput"
              type="text"
              value={this.state._LECsearchterm}
              onChange={(e) => {
                this.setState({
                  _LECsearchterm: e.target.value,
                  _MODsearchterm: "",
                  _newSearchString: "",
                });
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  this.setState({
                    MODsearchterm: this.state._MODsearchterm,
                    LECsearchterm: this.state._LECsearchterm,
                  });
                  // this.props.Navigation(
                  //   (this.state._newSearchString === "" &&
                  //   !(this.props.Location.pathname as string).endsWith("/")
                  //     ? this.props.Location.pathname +
                  //       "/" +
                  //       this.state._LECsearchterm.trim()
                  //     : this.state._newSearchString === "" &&
                  //         (this.props.Location.pathname as string).endsWith("/")
                  //       ? this.props.Location.pathname +
                  //         this.state._LECsearchterm.trim()
                  //       : this.props.Location.pathname.replace(
                  //           "/" + this.state._newSearchString,
                  //           "/" + this.state._LECsearchterm.trim(),
                  //         )
                  //   ).replace("//", "/"),
                  // );
                }
              }}
            ></input>
          </div>
          <div className="OrBlock">OR</div>
          <div className="InputBlock Righty">
            <label htmlFor="ModuleCodeInput">
              Search by module code (full or partial):
            </label>
            <input
              id="ModuleCodeInput"
              type="text"
              value={this.state._MODsearchterm}
              onChange={(e) => {
                this.setState({
                  _MODsearchterm: e.target.value,
                  _LECsearchterm: "",
                });
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  this.setState({
                    MODsearchterm: this.state._MODsearchterm,
                    LECsearchterm: this.state._LECsearchterm,
                  });
                  // this.props.Navigation(
                  //   (this.state._MODsearchterm === "" &&
                  //   !(this.props.Location.pathname as string).endsWith("/")
                  //     ? this.props.Location.pathname +
                  //       "/" +
                  //       this.state._newSearchString.trim()
                  //     : this.state._MODsearchterm === "" &&
                  //         (this.props.Location.pathname as string).endsWith("/")
                  //       ? this.props.Location.pathname +
                  //         this.state._newSearchString.trim()
                  //       : this.props.Location.pathname.replace(
                  //           "/" + this.state._MODsearchterm,
                  //           "/" + this.state._newSearchString.trim(),
                  //         )
                  //   ).replace("//", "/"),
                  // );
                }
              }}
            ></input>
          </div>

          <button
            onClick={(e) => {
              this.setState({
                MODsearchterm: this.state._MODsearchterm,
                LECsearchterm: this.state._LECsearchterm,
              });
            }}
          >
            Search
          </button>
        </div>
        <div className="Instructions">
          Only events due to start between today's date, and 14 days from
          today's date will be returned.
        </div>
        <hr />
        {this.state.MODsearchterm !== undefined &&
        this.state.MODsearchterm !== "" ? (
          <React.Fragment>
            <GridWrapper
              {...PassToAllGrids}
              key={"TLP_" + this.props.Refresher}
              eid="TLP"
              sproc="TT_MODULE_2024"
              params={["@module", this.state.MODsearchterm.trim()]}
              output="tasklist"
              class="UoE-Grid ESF"
              NoneMessage="No tasks found."
            />
          </React.Fragment>
        ) : (
          ""
        )}
        {this.state.LECsearchterm !== undefined &&
        this.state.LECsearchterm !== "" ? (
          <React.Fragment>
            <GridWrapper
              {...PassToAllGrids}
              key={"TLP_" + this.props.Refresher}
              eid="TLP"
              sproc="TT_LECTURER_2024"
              params={["@lecturer", this.state.LECsearchterm.trim()]}
              output="tasklist"
              class="UoE-Grid ESF"
              NoneMessage="No tasks found."
            />
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TimetableSearch;
