import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import { useApi } from "../../Providers/ApiProvider";
import { ConveyanceResponse } from "../../data/InvarDataModels/ConveyanceResponse";
import { ConveyanceSegmentResponse } from "../../data/InvarDataModels/ConveyanceSegmentResponse";
import { BrokerageResponse } from "../../data/InvarDataModels/BrokerageResponse";
import { ConveyanceSegmentConfigResponse } from "../../data/InvarDataModels/ConveyanceSegmentConfigResponse";
import { ActionResponse } from "../../data/InvarDataModels/ActionResponse";
import { InvarAdminConveyanceSegment } from "./Topology/InvarAdminConveyanceSegment";
import { InvarAdminConveyanceBrokerages } from "./Topology/InvarAdminConveyanceBrokerages";

interface IProps {
  IDA: NonNullable<InvarDataAccess>;
  ConveyanceID?: Number;
}

export type InvarConveyanceLoader = {
  ConveyanceID: Number;
};

export const InvarConveyance = (props: IProps) => {
  const loader: InvarConveyanceLoader =
    useLoaderData() as InvarConveyanceLoader;

  const CID = props.ConveyanceID || loader.ConveyanceID;

  const API = useApi();
  const IDA = API.Invar;

  const [thisConveyance, setThisConveyance] = useState(
    ConveyanceResponse.prototype,
  );
  const [theseSegments, setTheseSegments] = useState(
    Array<ConveyanceSegmentResponse>,
  );
  const [theseBrokerages, setTheseBrokerages] = useState(
    Array<BrokerageResponse>,
  );
  const [theseSegmentConfigs, setTheseSegmentConfigs] = useState(
    Array<ConveyanceSegmentConfigResponse>,
  );
  const [theseActions, setTheseActions] = useState(Array<ActionResponse>);

  const [newBrokerage, setNewBrokerage] = useState("");

  const [editConvDesc, setEditConvDesc] = useState(false);
  const [newConvDesc, setNewConvDesc] = useState("");

  const [_ncsname, setNCSName] = useState("");
  const [_ncsid, setNCSID] = useState(0);

  useEffect(() => {
    if (IDA !== undefined) {
      if (thisConveyance === ConveyanceResponse.prototype) {
        IDA.GetConveyance(CID).then((data) => {
          setThisConveyance(data);
        });
      }
      if (theseBrokerages.length === 0) {
        IDA.GetBrokeragesForConveyance(CID).then((data) => {
          setTheseBrokerages(data);
        });
      }
      if (theseSegments.length === 0) {
        IDA.GetConveyanceSegmentsForConveyance(CID).then((data) => {
          setTheseSegments(data);
        });
      }
      if (theseSegmentConfigs.length === 0) {
        IDA.GetConveyanceSegmentConfigsForConveyance(CID).then((data) => {
          setTheseSegmentConfigs(data);
        });
      }
      if (theseActions.length === 0) {
        IDA.GetActions().then((data) => {
          setTheseActions(data);
        });
      }
    }
  }, [IDA]);

  //  console.log("InvarConveyance -->");
  //  console.log(conveyance);
  //  console.log(segments);
  //  console.log(brokerages);
  //  console.log(segmentconfigs);

  function PromoteConveyanceSegment(CSID: number): void {
    IDA.PromoteConveyanceSegment(CSID).then((data) => {
      setTheseSegments(data);
    });
  }

  function DemoteConveyanceSegment(CSID: number): void {
    IDA.DemoteConveyanceSegment(CSID).then((data) => {
      setTheseSegments(data);
    });
  }

  function CreateConveyanceSegmentConfig(
    CSID: number,
    name: string,
    value: string,
  ): void {
    IDA.CreateConveyanceSegmentConfig(CSID, name, value).then(() => {
      IDA.GetConveyanceSegmentConfigsForConveyance(CID).then((data) => {
        setTheseSegmentConfigs(data);
      });
    });
  }

  function EditConveyanceSegmentConfig(
    CSCID: number,
    CSID: number,
    newName: string,
    newValue: string,
  ): void {
    IDA.UpdateConveyanceSegmentConfig(CSCID, CSID, newName, newValue).then(
      () => {
        IDA.GetConveyanceSegmentConfigsForConveyance(CID).then((data) => {
          setTheseSegmentConfigs(data);
        });
      },
    );
  }

  //  console.log(theseSegments);

  return (
    <div className="InvarAdminPanel">
      <div className="InvarConveyance">
        <div className="ConvHead">
          <div className="ConvHeadTitle">
            <div className="Title">{thisConveyance?.Name ?? ""}</div>
            <div className="ID">
              {thisConveyance?.ConveyanceID?.toString() ?? ""}
            </div>
            <div
              className={
                "Status" + (!thisConveyance?.Active ? " Inactive" : " Active")
              }
              onClick={async () => {
                if (thisConveyance.Active) {
                  setThisConveyance(
                    await props.IDA.DisableConveyance(
                      thisConveyance.ConveyanceID,
                    ),
                  );
                } else {
                  setThisConveyance(
                    await props.IDA.EnableConveyance(
                      thisConveyance.ConveyanceID,
                    ),
                  );
                }
              }}
            >
              {!thisConveyance.Active ? <>&#9632;</> : <>&#9654;</>}
            </div>
          </div>
          <div className="ConvHeadPrecis">
            <div className="ConvHeadRoute">
              <div className="From">{thisConveyance.From}</div>
              <div className="Arrow">&#129034;</div>
              <div className="To">{thisConveyance.To}</div>
            </div>
            <div className="ConvHeadDescription">
              {editConvDesc ? (
                <>
                  <div
                    className="ConvHeadDescEditText"
                    contentEditable="true"
                    onInput={(
                      ev: React.BaseSyntheticEvent<
                        Event,
                        EventTarget & HTMLDivElement
                      >,
                    ) => {
                      setNewConvDesc(ev.target.innerText);
                    }}
                  >
                    {thisConveyance.Description}
                  </div>
                  <div
                    className="ConvHeadDescEditSave"
                    onClick={async () => {
                      setThisConveyance(
                        await props.IDA.UpdateConveyance(
                          thisConveyance.ConveyanceID,
                          thisConveyance.Name,
                          thisConveyance.From,
                          thisConveyance.To,
                          newConvDesc,
                        ),
                      );
                      setNewConvDesc("");
                      setEditConvDesc(false);
                    }}
                  >
                    Save
                  </div>
                  <div
                    className="ConvHeadDescEditCancel"
                    onClick={() => {
                      setNewConvDesc(thisConveyance.Description);
                      setEditConvDesc(false);
                    }}
                  >
                    Cancel
                  </div>
                </>
              ) : (
                <>
                  <div className="ConvHeadDescJustText">
                    {thisConveyance.Description || "- no description -"}
                  </div>
                  <div
                    className="ConvHeadDescEdit"
                    onClick={() => {
                      setEditConvDesc(true);
                    }}
                  >
                    Edit
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <InvarAdminConveyanceBrokerages
          IDA={IDA}
          Conveyance={thisConveyance}
          theseBrokerages={theseBrokerages}
          setTheseBrokerages={setTheseBrokerages}
        ></InvarAdminConveyanceBrokerages>
        <div className="ConvSegs">
          {theseSegments
            .sort((l, r) => {
              return l.Ordinal - r.Ordinal;
            })
            .map((th) => {
              return (
                <span key={"IACS_" + th.Ordinal.toString()}>
                  <InvarAdminConveyanceSegment
                    ConveyanceSegment={th}
                    ConveyanceSegmentConfigs={
                      theseSegmentConfigs === undefined
                        ? []
                        : theseSegmentConfigs.filter(
                            (ith) =>
                              ith.ConveyanceSegmentID ===
                              th.ConveyanceSegmentID,
                          )
                    }
                    Action={theseActions?.find(
                      (ith) => ith.ActionID === th.ActionID,
                    )}
                    PromoteConveyanceSegment={PromoteConveyanceSegment}
                    CanDemote={th.Ordinal === theseSegments?.length}
                    DemoteConveyanceSegment={DemoteConveyanceSegment}
                    CreateConveyanceSegmentConfig={
                      CreateConveyanceSegmentConfig
                    }
                    EditConveyanceSegmentConfig={EditConveyanceSegmentConfig}
                    IDA={props.IDA}
                  ></InvarAdminConveyanceSegment>
                </span>
              );
            })}
          <div className="ConvSegCreate">
            <label>Name</label>
            <input
              type="text"
              value={_ncsname}
              onChange={(ev) => {
                setNCSName(ev.target.value);
              }}
            ></input>
            <label>Action</label>
            <select
              value={_ncsid}
              onChange={(ev) => {
                setNCSID(Number.parseInt(ev.target.value));
              }}
            >
              {theseActions?.map((ith) => {
                return (
                  <option
                    key={"Action" + ith.ActionID.toString()}
                    value={ith.ActionID.toString()}
                  >
                    {ith.Name}
                  </option>
                );
              })}
            </select>
            <div
              className="ConvSegCreateButton"
              onClick={async (ith) => {
                setTheseSegments([
                  ...theseSegments.concat(
                    await props.IDA.CreateConveyanceSegment(
                      thisConveyance.ConveyanceID,
                      _ncsname,
                      theseSegments.length + 1,
                      _ncsid,
                    ),
                  ),
                ]);
                setNCSName("");
                setNCSID(0);
              }}
            >
              &#43;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
