import { PublicClientApplication } from "@azure/msal-browser";
import React from "react";
import { DataAccess, User } from "../../../data/DataAccess";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../../Logging";

import "../../../assets/css/AOS2/AOS2_ListPage.css";
import { AgentSidebar } from "../Components/AgentSidebar";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;

  AgentName: string;
  AgentEmail: string;
  AgentReference: string;
  AgentContactName: string;
  AgentAddress: string;
  AgentCountry: string;
  AgentIsPAS: boolean;
}

class AOS2_UG_RECENT extends React.Component<IProps> {
  render() {
    if (this.props.Embargo("UCAS")) {
      return (
        <React.Fragment>
          <AgentSidebar />
          <div className="ResultsPanel UG">
            <h2>Your Current Undergraduate Applicants</h2>
            {this.props.Notices(this.props.AgentIsPAS, [
              "Embargo",
              "CurrentApps",
            ])}
          </div>
        </React.Fragment>
      );
    } else {
      var PassToAllGrids = {
        Callouts: false,
        Logger: this.props.Logger,
        da: this.props.da,
        PCA: this.props.PCA,
        GetLink: this.props.GetLink,
        URLRoot: this.props.URLRoot,
        URLParams: this.props.URLParams,
        prid: this.props.Mode[1],
        class: "UoE-Grid AOS-AppList",
        Refresher: this.props.Refresher,
        Alias: this.props.Alias,
        AliasChecked: this.props.AliasChecked,
        ViewAsAll: this.props.ViewAsAll,
        RegisterError: this.props.RegisterError,
        DeregisterError: this.props.DeregisterError,
        RegisterStatus: this.props.RegisterStatus,
        DeregisterStatus: this.props.DeregisterStatus,
      };

      return (
        <React.Fragment>
          <AgentSidebar />
          <div className="ResultsPanel UG">
            <h2>Your Current Undergraduate Applicants</h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"AOS_UG_InFlight_" + this.props.Refresher}
              sproc="sputilities.webui.AOSv2_GetUGApplicantsInFlight"
              params={[
                "@username",
                // "{%u}",
                // "@agent",
                "{%iu}",
              ]}
            ></GridWrapper>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default AOS2_UG_RECENT;
