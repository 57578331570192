import { MondrianGrid } from "../Grid/MondrianGrid";
import { MondrianList } from "../List/MondrianList";
import { MondrianDisplayType } from "../Structs/Enums/MondrianDisplayType";
import { FilterColumnOptions } from "../Structs/Interfaces/FilterColumnOptions";
import { HideabilityColumnOptions } from "../Structs/Interfaces/HideabilityColumnOptions";
import { KeyItem } from "../Structs/Interfaces/KeyItem";
import { MondrianDisplayOptions } from "../Structs/Interfaces/MondrianDisplayOptions";
import { SoupColumnOptions } from "../Structs/Interfaces/SoupColumnOptions";
import { MondrianStructuredData } from "../Structs/Types/MondrianStructuredData";

interface IProps {
  Display: MondrianDisplayType;
  DisplayOptions: MondrianDisplayOptions;
  FilterOptions: FilterColumnOptions[];
  SoupOptions: SoupColumnOptions[];
  HideabilityOptions: HideabilityColumnOptions[];
  KeyOptions: KeyItem[];
  Data: MondrianStructuredData;
}

export const MondrianDataSurface = (props: IProps) => {
  return (
    <div className="Mondrian__Data_Surface">
      {props.Display === MondrianDisplayType.Grid ? (
        <MondrianGrid {...props}></MondrianGrid>
      ) : (
        <></>
      )}
      {props.Display === MondrianDisplayType.List ? (
        <MondrianList {...props} Template={undefined}></MondrianList>
      ) : (
        <></>
      )}
    </div>
  );
};
