import { useNavigate, useParams } from "react-router-dom";
import { DataAccess, IParams, User } from "../data/DataAccess";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { MetaDataField } from "../integrations/MetaEditor/MetaDataField";
import { useEffect, useState } from "react";
import { SPOContentType } from "../integrations/SPUploadManager/SPInterfacing/SPOContentType";
import "../assets/css/MetaEditor.css";
import { SPOMetadataField } from "../integrations/SPUploadManager/SPInterfacing/SPOMetadataField";

interface SingleFileMetaEditorProps {
  authedUser: User;
  da: DataAccess;
  addBreadcrumbMapping: (MachineName: string, HumanName: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
}
export function SingleFileMetaEditor(props: SingleFileMetaEditorProps) {
  let { fileIdent } = useParams();
  const navigate = useNavigate();
  let [contentTypes, setContentTypes] = useState<SPOContentType[]>();
  let [currentContentType, setCurrentContentType] = useState<SPOContentType>();
  let [metadata, setMetadata] =
    useState<Map<string, string | boolean | Date>>();
  let [errors, setErrors] = useState<Map<string, string>>(
    new Map<string, string>(),
  );
  let [updatedMetadata, setUpdatedMetadata] = useState<Array<string>>([]);
  let [message, setMessage] = useState<string>();

  useEffect(() => {
    props.da
      .getFileMetadata(fileIdent)
      .then((resp) => {
        setContentTypes((ct) => [...resp.validContentTypes]);
        setCurrentContentType((cct) => {
          let ct = resp.validContentTypes.filter(
            (ct) => ct.contentTypeId == resp.metadataValues["ContentTypeId"],
          )[0];
          if (
            resp.metadataValues["esf_prid"] == undefined &&
            resp.metadataValues["edocs_pgno"] == undefined &&
            resp.metadataValues["edocs_ucasno"] == undefined &&
            resp.metadataValues["edocs_clearingrefno"] == undefined &&
            resp.metadataValues["edocs_regno"] == undefined
          ) {
            let error = new Map<string, string>();
            error["esf_prid"] == "At least one identifier must be filled in";
            error["edocs_pgno"] == "At least one identifier must be filled in";
            error["edocs_ucasno"] ==
              "At least one identifier must be filled in";
            error["edocs_clearingrefno"] ==
              "At least one identifier must be filled in";
            error["edocs_regno"] == "At least one identifier must be filled in";
            setErrors({ ...error });
          }

          return { ...ct };
        });
        setMetadata((md) => {
          return { ...resp.metadataValues };
        });
        props.addBreadcrumbMapping(
          fileIdent,
          resp.metadataValues["LinkFilename"],
        );

        let ct = resp.validContentTypes.filter(
          (ct) => ct.contentTypeId == resp.metadataValues["ContentTypeId"],
        )[0];
        ct.metadataFields.forEach((mdf) => {
          if (mdf.required) {
            if (
              resp.metadataValues[mdf.internalName] == undefined ||
              resp.metadataValues[mdf.internalName].toString().trim() == ""
            ) {
              setErrors((errs) => {
                errs[mdf.internalName] = mdf.displayName + " is required";
                return { ...errs };
              });
            }
          }
        });
      })
      .catch((err) => {
        console.error(JSON.stringify(err));
        props.RegisterError("Meta70", "Unable to get Metadata");
      });
  }, [fileIdent]);

  function validateField(field: string, value: string | boolean | Date) {
    const params: IParams = {
      sproc: "sputilities.spuploader.validatefield",
      params: ["@Field", field, "@Value", value.toString()],
    };

    let mdf: SPOMetadataField = currentContentType.metadataFields.filter(
      (f) => f.internalName == field,
    )[0];
    if (mdf.required) {
      if (value.toString().trim() == "") {
        setErrors((errs) => {
          errs[field] = mdf.displayName + " is required";
          return { ...errs };
        });
      } else {
        if (errors[field] == mdf.displayName + " is required") {
          setErrors((errs) => {
            errs[field] = "";
            return { ...errs };
          });
        }
      }
    }

    props.da.get("/sproc", JSON.stringify(params)).then((resp) => {
      if (resp != undefined) {
        if (resp[0][0].Error != undefined) {
          setErrors((errs) => {
            errs[field] = resp[0][0].Error;
            return { ...errs };
          });
        }

        if (resp[0][0].Success != undefined) {
          setErrors((errs) => {
            errs[field] = "";
            return { ...errs };
          });
          Object.keys(resp[1][0]).forEach((key) => {
            if (
              metadata[key] != undefined &&
              updatedMetadata.indexOf(key) == -1
            ) {
              setMetadata((m) => {
                m[key] = resp[1][0][key];
                return { ...m };
              });
              setUpdatedMetadata((umd) => {
                umd.push(key);
                return [...umd];
              });
            }
          });
        }
      }
    });
  }

  function isValidated(err): boolean {
    let validated = true;
    Object.keys(err ?? new Map<string, string>()).forEach((key) => {
      if (err[key] !== undefined && err[key] !== "") {
        validated = false;
      }
    });

    return validated;
  }

  function saveData(): Promise<void> {
    setMessage("Saving...");
    let payload = new Map<string, string | boolean | Date>();

    updatedMetadata.map((key) => {
      payload[key] = metadata[key];
    });

    payload["ContentTypeId"] = currentContentType.contentTypeId;

    return props.da
      .setFileMetadata(fileIdent, payload)
      .then((_) => setMessage("Metadata saved Successfully"))
      .catch((_) => props.RegisterError("Meta136", "Unable to set Metadata"));
  }

  let ctOptions: IDropdownOption[];
  if (contentTypes != null) {
    ctOptions = contentTypes.map((ct) => ({
      key: ct.contentTypeId,
      text: ct.contentType,
    }));
  }

  if (
    currentContentType == undefined ||
    contentTypes == undefined ||
    metadata == undefined
  ) {
    return (
      <div className="UploadManager">
        <div className="FileMetadataArea Processing">
          <h2>Metadata</h2>
          Metadata Loading...
        </div>
      </div>
    );
  }

  return (
    <div className="UploadManager">
      <div
        className={
          "FileMetadataArea " + (isValidated(errors) ? "" : "ErrorState")
        }
      >
        <h2>Metadata</h2>
        <h3>{metadata["LinkFilename"]}</h3>
        <Dropdown
          placeholder="Content Type"
          required
          label="Content Type"
          selectedKey={currentContentType.contentTypeId}
          options={ctOptions!}
          onChange={(_, ctx) => {
            setCurrentContentType(
              contentTypes.filter((ct) => ct.contentTypeId == ctx.key)[0],
            );
          }}
        />
        {Array.from(
          currentContentType.metadataFields.map((key) => {
            return (
              <MetaDataField
                field={key}
                UpdateField={(k, _val) => {
                  var trimmed = _val;
                  if (_val.toString() === _val) {
                    if (currentContentType.preferredIdent.includes(k)) {
                      trimmed = _val.trim();
                    }
                  }
                  let md = { ...metadata };
                  md[k] = trimmed;

                  let umd = [...updatedMetadata];
                  if (umd.find((u) => u === key.internalName) === undefined) {
                    umd.push(key.internalName);
                  }

                  if (k == "Title") {
                    trimmed = trimmed
                      .toString()
                      .replaceAll(" ", "_")
                      .replaceAll(/[^\w\-\+\.\[\]\_]/g, "")
                      .replaceAll(/[.]+/g, ".");

                    md["FileLeafRef"] = trimmed;
                    if (umd.find((u) => u === "FileLeafRef") === undefined) {
                      umd.push("FileLeafRef");
                    }
                  }

                  setUpdatedMetadata(umd);
                  setMetadata(md);

                  validateField(key.internalName, trimmed);
                }}
                key={key.internalName}
                errorText={errors[key.internalName] ?? ""}
                value={metadata[key.internalName]}
              />
            );
          }),
        )}
        <div className="ME_MetaEditorControls">
          <div className="ME_MetaEditorButtons">
            <button
              className={"ME_Warning ME_MetaEditorButton"}
              onClick={() => navigate("../..", { relative: "path" })}
            >
              Cancel
            </button>

            <button
              className={
                "ME_Primary ME_MetaEditorButton " +
                (isValidated(errors) ? "" : "disabled")
              }
              disabled={!isValidated(errors)}
              onClick={() => {
                saveData()
                  .then((_) => navigate("../..", { relative: "path" }))
                  .catch((err) => {
                    setMessage("Failed to save metadata - " + err);
                    console.error(JSON.stringify(err));
                  });
              }}
            >
              Save and Exit
            </button>
          </div>
          <div className="ME_MetaEditorMessage">
            {isValidated(errors) ? (
              ""
            ) : (
              <div className="errorMessage-140">
                Fix the validation errors above to save
              </div>
            )}
            {message}
          </div>
        </div>
      </div>
    </div>
  );
}
