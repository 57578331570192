import React, { PropsWithChildren, useContext } from "react";
import { ReactNode, createContext } from "react";

export enum SURFACE {
  NONE,
  AOS,
  EAF,
  EAF_UGEAF,
  EAF_PGEAF,
  EAF_CLEARING,
  ECF,
  ESF,
  ESF_COMPLIANCE,
  ESF_ADMIN,
}

export type SurfaceAttributes = {
  SURFACE: SURFACE;
  CSSClass: string;
  Title: string;
  PermissionsWhitelist: string[];
  RootSurface: SURFACE;
};

export const SurfaceIndex: SurfaceAttributes[] = [
  {
    SURFACE: SURFACE.NONE,
    CSSClass: "",
    Title: "",
    PermissionsWhitelist: ["Collagen.Admins", "Admin.All"],
    RootSurface: SURFACE.NONE,
  },
  {
    SURFACE: SURFACE.AOS,
    CSSClass: "AOS2",
    Title: "Agent Online Services",
    PermissionsWhitelist: ["AOS.Access"],
    RootSurface: SURFACE.AOS,
  },
  {
    SURFACE: SURFACE.EAF,
    CSSClass: "EAF_LF",
    Title: "Electronic Applicant Files",
    PermissionsWhitelist: ["PGEAF.Reader", "UGEAF.Reader", "Clearing.Reader"],
    RootSurface: SURFACE.EAF,
  },
  {
    SURFACE: SURFACE.EAF_UGEAF,
    CSSClass: "UGEAF_LF",
    Title: "Undergraduate Electronic Applicant Files",
    PermissionsWhitelist: ["UGEAF.Reader"],
    RootSurface: SURFACE.EAF,
  },
  {
    SURFACE: SURFACE.EAF_PGEAF,
    CSSClass: "PGEAF_LF",
    Title: "Postgraduate Electronic Applicant Files",
    PermissionsWhitelist: ["PGEAF.Reader"],
    RootSurface: SURFACE.EAF,
  },
  {
    SURFACE: SURFACE.EAF_CLEARING,
    CSSClass: "Clearing_LF",
    Title: "Clearing Documents",
    PermissionsWhitelist: ["Clearing.Reader"],
    RootSurface: SURFACE.EAF,
  },
  {
    SURFACE: SURFACE.ECF,
    CSSClass: "ECF_LF",
    Title: "Electronic Case Files",
    PermissionsWhitelist: ["ECF.BasicAccess"],
    RootSurface: SURFACE.ECF,
  },
  {
    SURFACE: SURFACE.ESF,
    CSSClass: "ESF_LF",
    Title: "Electronic Student Files",
    PermissionsWhitelist: ["ESF.Reader", "Collagen.Admins", "Admin.All"],
    RootSurface: SURFACE.ESF,
  },
  {
    SURFACE: SURFACE.ESF_COMPLIANCE,
    CSSClass: "Compliance_LF",
    Title: "Compliance ePortal",
    PermissionsWhitelist: ["Compliance.View"],
    RootSurface: SURFACE.ESF,
  },
  {
    SURFACE: SURFACE.ESF_ADMIN,
    CSSClass: "Admin_LF",
    Title: "Admin Portal",
    PermissionsWhitelist: [
      "Collagen.Admins",
      "Admin.All",
      "Admin.Collagen",
      "Admin.WEBUI",
      "Admin.Invar",
    ],
    RootSurface: SURFACE.ESF,
  },
];

export const SurfaceLookup = (_surf: SURFACE) => {
  return SurfaceIndex.find((th) => th.SURFACE === _surf);
};

interface IProps {
  TheseSurfaceAttributes: SurfaceAttributes;
}

const SurfaceContext = createContext(SurfaceIndex[0]);

export const useSurface = () => useContext(SurfaceContext);

export const SurfaceProvider = (props: PropsWithChildren<IProps>) => {
  return (
    <SurfaceContext.Provider value={props.TheseSurfaceAttributes}>
      {props.children}
    </SurfaceContext.Provider>
  );
};
