import { ReactElement } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../../Logging";
import { DataAccess } from "../../../data/DataAccess";
import { PublicClientApplication } from "@azure/msal-browser";

interface UgApplicantQualificationsProps {
  UcasNo: string;
  Logger: Logging;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
}

export function UgApplicantQualifications(
  props: UgApplicantQualificationsProps,
): ReactElement {
  let agentDetails: any = useRouteLoaderData("AgentRoot");

  var PassToAllGrids = {
    Callouts: false,
    Logger: props.Logger,
    da: props.da,
    PCA: props.PCA,
    GetLink: props.GetLink,
    URLRoot: props.URLRoot,
    URLParams: props.URLParams,
    ucasno: props.UcasNo,
    class: "UoE-Grid AOSScreen",
    Refresher: props.Refresher,
    Alias: props.Alias,
    AliasChecked: props.AliasChecked,
    ViewAsAll: props.ViewAsAll,
    RegisterError: props.RegisterError,
    DeregisterError: props.DeregisterError,
    RegisterStatus: props.RegisterStatus,
    DeregisterStatus: props.DeregisterStatus,
  };

  return (
    <>
      <div id="Qualifications">
        {props.Embargo("UCAS") ? (
          props.Notices(agentDetails.AgentIsPAS, ["Embargo", "Applicant"])
        ) : (
          <GridWrapper
            eid={"AOS_UG_Qualifications_" + props.Refresher}
            {...PassToAllGrids}
            sproc="sputilities.webui.AOSv2_GetUGApplicantQualifications"
            params={[
              "@ucasno",
              props.UcasNo.toUpperCase(),
              "@username",
              // "{%u}",
              // "@agent",
              "{%iu}",
            ]}
          ></GridWrapper>
        )}
      </div>
    </>
  );
}
