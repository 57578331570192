import * as React from "react";
import Timestamp from "./Timestamp";
import { Toggles } from "./Toggles";
import { FunctionalIFrameComponent } from "./FunctionalIFrameComponent";

interface IProps {
  Toggles: Toggles;
  Object: any;
  Squished?: boolean;
  SquishToggle: any;
}

class TLEntryMailRequest extends React.Component<IProps> {
  public render() {
    if (
      !this.props.Toggles.TechView &&
      this.props.Object["Subtype"] === "Queued"
    ) {
      return "";
    } else {
      return (
        <div className="MailRequestEntry">
          <div className="Icon">&#128269;</div>
          <div className="Label">Email</div>
          <Timestamp
            Timestamp={this.props.Object["Timestamp"]}
            Toggles={this.props.Toggles}
          />
          Email
          {this.props.Object["Subtype"] === "Queued" ? (
            " queued..."
          ) : (
            <React.Fragment>
              {" "}
              sent! <b>{this.props.Object["Subject"]}</b>{" "}
              <button
                className={
                  "SquishButton" +
                  (this.props.Squished === false ? "" : " Squished")
                }
                onClick={() => this.props.SquishToggle()}
              >
                {this.props.Squished === false ? (
                  <React.Fragment>&#8722;</React.Fragment>
                ) : (
                  <React.Fragment>&#43;</React.Fragment>
                )}
              </button>
              {this.props.Squished === undefined ||
              this.props.Squished === true ? (
                ""
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <th>Recipients</th>
                      <td>{this.props.Object["Recipients"]}</td>
                    </tr>
                    {this.props.Object["CCs"] !== "" ? (
                      <tr>
                        <th>CCs</th>
                        <td>{this.props.Object["CCs"]}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {this.props.Object["CCs"] !== "" ? (
                      <tr>
                        <th>BCCs</th>
                        <td>{this.props.Object["BCCs"]}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    <tr>
                      <th>From</th>
                      <td>{this.props.Object["FromAddress"]}</td>
                    </tr>
                    {this.props.Object["ReplyTo"] !==
                    this.props.Object["FromAddress"] ? (
                      <tr>
                        <th>Reply</th>
                        <td>{this.props.Object["ReplyTo"]}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    <tr>
                      <th>Subject</th>
                      <td>
                        <b>{this.props.Object["Subject"]}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        {this.props.Object["BodyFormat"] === "HTML" ? (
                          <FunctionalIFrameComponent
                            className="EmailRenderPane"
                            title="functional-iframe"
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: this.props.Object["Body"],
                              }}
                            />
                          </FunctionalIFrameComponent>
                        ) : (
                          <textarea
                            className="EmailRenderPane"
                            value={this.props.Object["Body"]}
                            readOnly={true}
                          ></textarea>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </React.Fragment>
          )}
        </div>
      );
    }
  }
}

export default TLEntryMailRequest;
