import SearchResults from "./SearchResults";
import {
  NavigateFunction,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { Logging } from "../Logging";
import { DataAccess } from "../data/DataAccess";
import ModeStruct from "../models/ModeStruct";
import SPOUploadLocation from "../models/SPOUploadLocation";
import { useState } from "react";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Modes: ModeStruct[];
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  Navigation: NavigateFunction;
}

export const SearchResultWrapper = (props: IProps) => {
  const _loader: { SearchType: string; SearchTerm: string } =
    useLoaderData() as { SearchType: string; SearchTerm: string };
  const _searchtype = _loader?.SearchType ?? "";
  const _searchterm =
    _loader?.SearchTerm?.replaceAll("%20", " ")?.replaceAll("_", " ") ?? "";

  //  console.log("SearchResultWrapper state...");
  //  console.log(_searchtype);
  //  console.log(_searchterm);

  const location = useLocation();
  const navigation = useNavigate();
  const [error, setError] = useState("");

  const RegErr = (Ref: string, Mess: string) => {
    //  console.log("error recvd");
    //  console.log(Ref);
    //  console.log(Mess);
    if (Mess.toLowerCase().indexOf("timeout") > 0) {
      setError(
        "This search timed out. Please try again with a longer search term.",
      );
    } else {
      setError(
        "There was an error with this search. Please check your inputs and try again.",
      );
    }
  };

  if (location.pathname.toLowerCase().indexOf("compliance") > 0) {
    var prp = { ...props };

    prp.RegisterError = RegErr;

    return (
      <SearchResults
        {...prp}
        SearchType={_searchtype}
        SearchTerm={_searchterm}
        Navigation={navigation}
        Location={location}
        Error={error}
      />
    );
  } else {
    return (
      <SearchResults
        {...props}
        SearchType={_searchtype}
        SearchTerm={_searchterm}
        Location={location}
        Navigation={navigation}
      />
    );
  }
};
