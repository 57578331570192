import * as React from "react";
import Timestamp from "./Timestamp";
import { Toggles } from "./Toggles";

interface IProps {
  Toggles: Toggles;
  Object: any;
}

class TLEntryOtherHistory extends React.Component<IProps> {
  public render() {
    return (
      <div className="OtherEntry">
        <div className="Icon">&#127874;</div>
        <div className="Label">Lifecycle</div>
        <Timestamp
          Timestamp={this.props.Object["Timestamp"]}
          Toggles={this.props.Toggles}
        />
        <b>{this.props.Object["Description"]}</b>
      </div>
    );
  }
}

export default TLEntryOtherHistory;
