import * as React from "react";
import Timestamp from "./Timestamp";
import { Toggles } from "./Toggles";

interface IProps {
  Toggles: Toggles;
  Object: any;
}

class TLEntryAssignmentHistory extends React.Component<IProps> {
  public render() {
    return (
      <div className="AssignmentEntry">
        <div className="Icon">&#128101;</div>
        <div className="Label">Assignment</div>
        <Timestamp
          Timestamp={this.props.Object["Timestamp"]}
          Toggles={this.props.Toggles}
        />
        Assigned to{" "}
        <b title={this.props.Object["RelevantUser"]}>
          {this.props.Object["RelevantUserLabel"]}
        </b>
      </div>
    );
  }
}

export default TLEntryAssignmentHistory;
