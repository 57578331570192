import BurgerMenuIcon from "../assets/icons/burger";
import IfPermitted from "./IfPermitted";
import { User } from "../data/DataAccess";

interface IProps {
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;
  MenuVisibilities: Map<string, boolean>;
  AuthedUser: User;
  ViewAsUser: User;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
}

export const QuickNav = (props: IProps) => {
  var burgerTimer: string | number | NodeJS.Timeout;

  return (
    <div
      className="QuickJump"
      onMouseEnter={() => {
        props.SetMenuVisibility("Burger", true);
      }}
      onMouseLeave={() => {
        props.SetMenuVisibility("Burger", false);
      }}
    >
      {
        <a
          href="#"
          className="Burger"
          tabIndex={0}
          title="Service Navigation Menu"
          onClick={() => {
            props.SetMenuVisibility(
              "Burger",
              !props.MenuVisibilities.get("Burger"),
            );
          }}
        >
          <BurgerMenuIcon />
        </a>
      }
      <div
        className={
          "QuickJumpLocations" +
          (props.MenuVisibilities.get("Burger") ? " open" : "")
        }
        id="burgerM"
        aria-expanded={props.MenuVisibilities.get("Burger")}
      >
        <ul>
          <IfPermitted Whitelist={["ESF.Reader"]}>
            <li>
              {props.Environment === "LOCAL" ? (
                <div
                  className="MenuItem Inert Warning"
                  title="Environment switch not possible on local; change auto-environment in code."
                >
                  ESF
                </div>
              ) : (
                ""
              )}
              {props.Environment === "TEST" ? (
                <a href="https://esftest.essex.ac.uk/" className="MenuItem">
                  ESF
                </a>
              ) : (
                ""
              )}
              {props.Environment === "LIVE" ? (
                <a href="https://esf.essex.ac.uk/" className="MenuItem">
                  ESF
                </a>
              ) : (
                ""
              )}
            </li>
          </IfPermitted>
          <IfPermitted Whitelist={["PGEAF.Reader"]}>
            <li>
              {props.Environment === "LOCAL" ? (
                <div
                  className="MenuItem Inert Warning"
                  title="Environment switch not possible on local; change auto-environment in code."
                >
                  PGEAF
                </div>
              ) : (
                ""
              )}
              {props.Environment === "TEST" ? (
                <a
                  href="https://eaftest.essex.ac.uk/PGEAF/"
                  className="MenuItem"
                >
                  PGEAF
                </a>
              ) : (
                ""
              )}
              {props.Environment === "LIVE" ? (
                <a href="https://eaf.essex.ac.uk/PGEAF/" className="MenuItem">
                  PGEAF
                </a>
              ) : (
                ""
              )}
            </li>
          </IfPermitted>
          <IfPermitted Whitelist={["UGEAF.Reader"]}>
            <li>
              {props.Environment === "LOCAL" ? (
                <div
                  className="MenuItem Inert Warning"
                  title="Environment switch not possible on local; change auto-environment in code."
                >
                  UGEAF
                </div>
              ) : (
                ""
              )}
              {props.Environment === "TEST" ? (
                <a
                  href="https://eaftest.essex.ac.uk/UGEAF/"
                  className="MenuItem"
                >
                  UGEAF
                </a>
              ) : (
                ""
              )}
              {props.Environment === "LIVE" ? (
                <a href="https://eaf.essex.ac.uk/UGEAF/" className="MenuItem">
                  UGEAF
                </a>
              ) : (
                ""
              )}
            </li>
          </IfPermitted>
          <IfPermitted Whitelist={["ECF.BasicAccess"]}>
            <li>
              {props.Environment === "LOCAL" ? (
                <div
                  className="MenuItem Inert Warning"
                  title="Environment switch not possible on local; change auto-environment in code."
                >
                  ECF
                </div>
              ) : (
                ""
              )}
              {props.Environment === "TEST" ? (
                <a href="https://ecftest.essex.ac.uk/" className="MenuItem">
                  ECF
                </a>
              ) : (
                ""
              )}
              {props.Environment === "LIVE" ? (
                <a href="https://ecf.essex.ac.uk/" className="MenuItem">
                  ECF
                </a>
              ) : (
                ""
              )}
            </li>
          </IfPermitted>
          <IfPermitted Whitelist={["AOS.Access"]}>
            <li>
              {props.Environment === "LOCAL" ? (
                <div
                  className="MenuItem Inert Warning"
                  title="Environment switch not possible on local; change auto-environment in code."
                >
                  AOS
                </div>
              ) : (
                ""
              )}
              {props.Environment === "TEST" ? (
                <a href="https://agentstest.essex.ac.uk/" className="MenuItem">
                  AOS
                </a>
              ) : (
                ""
              )}
              {props.Environment === "LIVE" ? (
                <a href="https://agents.essex.ac.uk/" className="MenuItem">
                  AOS
                </a>
              ) : (
                ""
              )}
            </li>
          </IfPermitted>
          <IfPermitted Whitelist={["Clearing.Reader"]}>
            <li>
              {props.Environment === "LOCAL" ? (
                <div
                  className="MenuItem Inert Warning"
                  title="Environment switch not possible on local; change auto-environment in code."
                >
                  Clearing
                </div>
              ) : (
                ""
              )}
              {props.Environment === "TEST" ? (
                <a
                  href="https://eaftest.essex.ac.uk/Clearing/"
                  className="MenuItem"
                >
                  Clearing
                </a>
              ) : (
                ""
              )}
              {props.Environment === "LIVE" ? (
                <a
                  href="https://eaf.essex.ac.uk/Clearing/"
                  className="MenuItem"
                >
                  Clearing
                </a>
              ) : (
                ""
              )}
            </li>
          </IfPermitted>
          <IfPermitted Whitelist={["Compliance.View"]}>
            <li>
              {props.Environment === "LOCAL" ? (
                <div
                  className="MenuItem Inert Warning"
                  title="Environment switch not possible on local; change auto-environment in code."
                >
                  Compliance
                </div>
              ) : (
                ""
              )}
              {props.Environment === "TEST" ? (
                <a
                  href="https://esftest.essex.ac.uk/Compliance/"
                  className="MenuItem"
                >
                  Compliance
                </a>
              ) : (
                ""
              )}
              {props.Environment === "LIVE" ? (
                <a
                  href="https://esf.essex.ac.uk/Compliance/"
                  className="MenuItem"
                >
                  Compliance
                </a>
              ) : (
                ""
              )}
            </li>
          </IfPermitted>
          <IfPermitted Whitelist={["Collagen.Admins", "Admin.All"]}>
            <li>
              {props.Environment === "LOCAL" ? (
                <div
                  className="MenuItem Inert Warning"
                  title="Environment switch not possible on local; change auto-environment in code."
                >
                  Admin Portal
                </div>
              ) : (
                ""
              )}
              {props.Environment === "TEST" ? (
                <a
                  href="https://esftest.essex.ac.uk/AdminPortal/"
                  className="MenuItem"
                >
                  Admin Portal
                </a>
              ) : (
                ""
              )}
              {props.Environment === "LIVE" ? (
                <a
                  href="https://esf.essex.ac.uk/AdminPortal/"
                  className="MenuItem"
                >
                  Admin Portal
                </a>
              ) : (
                ""
              )}
            </li>
          </IfPermitted>
        </ul>
      </div>
    </div>
  );
};
