import React from "react";
import { DataAccess, IParams, User } from "../../data/DataAccess";
import { Logging, LoggingLevel } from "../../Logging";
import { GridWrapper } from "../UoE-Grid/GridWrapper";
import "../../assets/css/ESFNotes.css";
import { PublicClientApplication } from "@azure/msal-browser";
import IfPermitted from "../../components/IfPermitted";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  prid: string;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface IState {
  NowEditing: boolean; // 'editing', 'button'

  currentNote_ID: number;
  currentNote_Importance: string;
  currentNote_Type: string;
  currentNote_Body: string;
  currentNote_Author: string;
  currentNote_Date: Date;
}

class ESFNotes extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      NowEditing: false,

      currentNote_ID: 0,
      currentNote_Importance: "Normal",
      currentNote_Type: "General",
      currentNote_Body: "",
      currentNote_Author: props.ActualUser,
      currentNote_Date: undefined,
    };
  }

  render() {
    return (
      <IfPermitted Whitelist={["ESF.Teams"]}>
        <div className="ESFNotes" key={this.props.Refresher}>
          <GridWrapper
            Callouts={true}
            eid={"Assessment_" + this.props.Refresher}
            URLParams={this.props.URLParams}
            ButtonsAndFunctions={[
              {
                Name: "Edit",
                ColumnKey: "_id",
                Function: (id: number) => {
                  this.LoadNoteForEditing(id);
                },
              },
            ]}
            sproc="studb.dbo.esf_GetStudentNotes"
            params={["@prid", this.props.prid.toUpperCase(), "@user", "{%iu}"]}
            NoneMessage="No notes for this student."
          ></GridWrapper>
          <hr />

          <div className="Operations">
            {this.state.NowEditing === false ? (
              <div className="ButtonRack">
                <div
                  id="CreateNewNoteButton"
                  onClick={() => {
                    this.setState({ NowEditing: true });
                  }}
                >
                  New Note
                </div>
              </div>
            ) : this.state.NowEditing === true ? (
              <div className="EditNoteArea">
                <div className="RackedInput First">
                  <label
                    id="EditNoteImportanceLabel"
                    htmlFor="EditNoteImportance"
                  >
                    Importance
                  </label>
                  <select
                    id="EditNoteImportance"
                    value={this.state.currentNote_Importance.toString()}
                    onChange={({ target: { value } }) =>
                      this.setState({ currentNote_Importance: value })
                    }
                  >
                    <option className="Importance_Normal">Normal</option>
                    <option className="Importance_High">High</option>
                  </select>
                </div>
                <div className="RackedInput">
                  <label id="EditNoteTypeLabel" htmlFor="EditNoteType">
                    Note Type
                  </label>
                  <select
                    id="EditNoteType"
                    value={this.state.currentNote_Type.toString()}
                    onChange={({ target: { value } }) =>
                      this.setState({ currentNote_Type: value })
                    }
                  >
                    <option className="Note_Type_General">General</option>
                    <option className="Note_Type_To_Do">To Do</option>
                    <option className="Note_Type_Communication_Log">
                      Communication Log
                    </option>
                  </select>
                </div>
                <br />
                <label id="EditNoteTextLabel" htmlFor="EditNoteText">
                  Note Text
                </label>
                <textarea
                  rows={7}
                  className="ms-TextField-field"
                  id="EditNoteText"
                  value={this.state.currentNote_Body.toString()}
                  onChange={({ target: { value } }) =>
                    this.setState({ currentNote_Body: value })
                  }
                ></textarea>

                <div className="ButtonRack">
                  <div
                    id="CancelNoteButton"
                    onClick={() => {
                      this.setState({
                        NowEditing: false,
                        currentNote_ID: 0,
                        currentNote_Importance: "Normal",
                        currentNote_Type: "General",
                        currentNote_Body: "",
                      });
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    id="SaveNoteButton"
                    onClick={() => {
                      this.SaveNote();
                    }}
                  >
                    {this.state.currentNote_ID !== 0 ? "Save" : "Add Note"}
                  </div>
                </div>
              </div>
            ) : (
              <div className="ErrorBar">
                ESF Notes control is in an unknown state.
              </div>
            )}
          </div>
        </div>
      </IfPermitted>
    );
  }

  async SaveNote() {
    const params: IParams = {
      sproc: "studb.dbo.esf_AddOrUpdateStudentNote",
      params: [
        "@ID",
        this.state.currentNote_ID.toString(),
        "@prid",
        this.props.prid.toString(),
        "@note",
        this.state.currentNote_Body.toString(),
        "@user",
        "{%u}",
        "@type",
        this.state.currentNote_Type.toString(),
        "@importance",
        this.state.currentNote_Importance.toString(),
      ],
      user: "--NOT UPDATED--",
    };

    await this.props.da
      .post("/sproc", JSON.stringify(params), "POST", true)
      .then(
        (data) => {
          this.setState({
            NowEditing: false,
            currentNote_ID: 0,
            currentNote_Importance: "Normal",
            currentNote_Type: "General",
            currentNote_Body: "",
          });
        },
        (reason) => {
          this.props.Logger.log(
            reason,
            LoggingLevel.EXCEPTION,
            "ESFNotes.SaveNote()",
          );
        },
      );

    this.props.Refresh();
  }

  async LoadNoteForEditing(id: number) {
    const params: IParams = {
      sproc: "studb.dbo.esf_GetSingleStudentNote",
      params: [
        "@id",
        id.toString(),
        "@prid",
        this.props.prid.toString(),
        "@user",
        "{%u}",
      ],
      user: "--NOT UPDATED--",
    };

    await this.props.da.get("/sproc", JSON.stringify(params)).then((data) => {
      this.setState({
        NowEditing: true,
        currentNote_ID: id,
        currentNote_Importance: data[0][0].Importance,
        currentNote_Type: data[0][0].Type,
        currentNote_Body: data[0][0].Body,
      });
    });
  }
}

export default ESFNotes;
