import "core-js";
import "react-app-polyfill/ie11";
import * as queryString from "query-string";
import { initializeIcons } from "@fluentui/react";
import {
  AccountInfo,
  BrowserAuthError,
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import {
  LOCAL_AOS_graphRequest_Silent,
  LOCAL_EAF_graphRequest_Silent,
  LOCAL_ECF_graphRequest_Silent,
  LOCAL_ESF_graphRequest_Silent,
  DEV_AOS_graphRequest_Silent,
  DEV_EAF_graphRequest_Silent,
  DEV_ECF_graphRequest_Silent,
  DEV_ESF_graphRequest_Silent,
  STAG_AOS_graphRequest_Silent,
  STAG_EAF_graphRequest_Silent,
  STAG_ECF_graphRequest_Silent,
  STAG_ESF_graphRequest_Silent,
  LIVE_AOS_graphRequest_Silent,
  LIVE_EAF_graphRequest_Silent,
  LIVE_ECF_graphRequest_Silent,
  LIVE_ESF_graphRequest_Silent,
  LOCAL_AOS_graphRequest_Redirect,
  LOCAL_EAF_graphRequest_Redirect,
  LOCAL_ECF_graphRequest_Redirect,
  LOCAL_ESF_graphRequest_Redirect,
  DEV_AOS_graphRequest_Redirect,
  DEV_EAF_graphRequest_Redirect,
  DEV_ECF_graphRequest_Redirect,
  DEV_ESF_graphRequest_Redirect,
  STAG_AOS_graphRequest_Redirect,
  STAG_EAF_graphRequest_Redirect,
  STAG_ECF_graphRequest_Redirect,
  STAG_ESF_graphRequest_Redirect,
  LIVE_AOS_graphRequest_Redirect,
  LIVE_EAF_graphRequest_Redirect,
  LIVE_ECF_graphRequest_Redirect,
  LIVE_ESF_graphRequest_Redirect,
  LOCAL_AOS_msalConfig,
  LOCAL_EAF_msalConfig,
  LOCAL_ECF_msalConfig,
  LOCAL_ESF_msalConfig,
  DEV_AOS_msalConfig,
  DEV_EAF_msalConfig,
  DEV_ECF_msalConfig,
  DEV_ESF_msalConfig,
  STAG_AOS_msalConfig,
  STAG_EAF_msalConfig,
  STAG_ECF_msalConfig,
  STAG_ESF_msalConfig,
  LIVE_AOS_msalConfig,
  LIVE_EAF_msalConfig,
  LIVE_ECF_msalConfig,
  LIVE_ESF_msalConfig,
} from "./src/authConfig";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { SPOWebUIOnPremMSALWrapper } from "./src/SPOWebUIOnPremMSALWrapper";
import { Logging } from "./src/Logging";
import AgentLoginSpiel from "./src/components/AgentLoginSpiel";
import ReactDOM from "react-dom";
import { ThemeProvider, PartialTheme } from "@fluentui/react";
import {
  SURFACE,
  SurfaceLookup,
  SurfaceProvider,
} from "./src/Providers/SurfaceProvider";

initializeIcons();

const URL = document.URL.toLowerCase();

var _MODE_LOCAL: boolean = URL.indexOf("localhost") > -1;

var _MODE_DEVELOPMENT: boolean =
  URL.indexOf("mode=development") > -1 || URL.indexOf("dev.essex.ac.uk") > -1;

var _MODE_STAGING: boolean =
  URL.indexOf("mode=staging") > -1 || URL.indexOf("test.essex.ac.uk") > -1;

var _MODE_LIVE: boolean =
  URL.indexOf("mode=staging") === -1 &&
  URL.indexOf("mode=development") === -1 &&
  URL.indexOf("test.essex.ac.uk") === -1 &&
  URL.indexOf("localhost") === -1;

const Logger = new Logging(
  _MODE_LIVE
    ? "LIVE"
    : _MODE_STAGING
      ? "STAGING"
      : _MODE_DEVELOPMENT
        ? "DEVELOPMENT"
        : "LOCAL",
);

// it's currently doing this on every single rerender. needs to become a single-load context operation.

var __surface: SURFACE = SURFACE.NONE;

if (_MODE_LIVE) {
  if (URL.startsWith("https://agents.essex.ac.uk")) {
    __surface = SURFACE.AOS;
  } else if (URL.startsWith("https://eaf.essex.ac.uk")) {
    if (URL.startsWith("https://eaf.essex.ac.uk/pgeaf")) {
      __surface = SURFACE.EAF_PGEAF;
    } else if (URL.startsWith("https://eaf.essex.ac.uk/ugeaf")) {
      __surface = SURFACE.EAF_UGEAF;
    } else if (URL.startsWith("https://eaf.essex.ac.uk/clearing")) {
      __surface = SURFACE.EAF_CLEARING;
    } else {
      __surface = SURFACE.EAF;
    }
  } else if (URL.startsWith("https://ecf.essex.ac.uk")) {
    __surface = SURFACE.ECF;
  } else if (URL.startsWith("https://esf.essex.ac.uk")) {
    if (URL.startsWith("https://esf.essex.ac.uk/adminportal")) {
      __surface = SURFACE.ESF_ADMIN;
    } else if (URL.startsWith("https://esf.essex.ac.uk/compliance")) {
      __surface = SURFACE.ESF_COMPLIANCE;
    } else {
      __surface = SURFACE.ESF;
    }
  }
} else if (_MODE_STAGING) {
  if (URL.startsWith("https://agentstest.essex.ac.uk")) {
    __surface = SURFACE.AOS;
  } else if (URL.startsWith("https://eaftest.essex.ac.uk")) {
    if (URL.startsWith("https://eaftest.essex.ac.uk/pgeaf")) {
      __surface = SURFACE.EAF_PGEAF;
    } else if (URL.startsWith("https://eaftest.essex.ac.uk/ugeaf")) {
      __surface = SURFACE.EAF_UGEAF;
    } else if (URL.startsWith("https://eaftest.essex.ac.uk/clearing")) {
      __surface = SURFACE.EAF_CLEARING;
    } else {
      __surface = SURFACE.EAF;
    }
  } else if (URL.startsWith("https://ecftest.essex.ac.uk")) {
    __surface = SURFACE.ECF;
  } else if (URL.startsWith("https://esftest.essex.ac.uk")) {
    if (URL.startsWith("https://esftest.essex.ac.uk/adminportal")) {
      __surface = SURFACE.ESF_ADMIN;
    } else if (URL.startsWith("https://esftest.essex.ac.uk/compliance")) {
      __surface = SURFACE.ESF_COMPLIANCE;
    } else {
      __surface = SURFACE.ESF;
    }
  }
} else if (_MODE_DEVELOPMENT) {
  if (URL.startsWith("https://agentsdev.essex.ac.uk")) {
    __surface = SURFACE.AOS;
  } else if (URL.startsWith("https://eafdev.essex.ac.uk")) {
    if (URL.startsWith("https://eafdev.essex.ac.uk/pgeaf")) {
      __surface = SURFACE.EAF_PGEAF;
    } else if (URL.startsWith("https://eafdev.essex.ac.uk/ugeaf")) {
      __surface = SURFACE.EAF_UGEAF;
    } else if (URL.startsWith("https://eafdev.essex.ac.uk/clearing")) {
      __surface = SURFACE.EAF_CLEARING;
    } else {
      __surface = SURFACE.EAF;
    }
  } else if (URL.startsWith("https://ecfdev.essex.ac.uk")) {
    __surface = SURFACE.ECF;
  } else if (URL.startsWith("https://esfdev.essex.ac.uk")) {
    if (URL.startsWith("https://esfdev.essex.ac.uk/adminportal")) {
      __surface = SURFACE.ESF_ADMIN;
    } else if (URL.startsWith("https://esfdev.essex.ac.uk/compliance")) {
      __surface = SURFACE.ESF_COMPLIANCE;
    } else {
      __surface = SURFACE.ESF;
    }
  }
} else {
  // has to bounce surface correctly on dev even when
  // default might otherwise override it
  if (URL.startsWith("http://localhost:6227/pgeaf")) {
    __surface = SURFACE.EAF_PGEAF;
  } else if (URL.startsWith("http://localhost:6227/ugeaf")) {
    __surface = SURFACE.EAF_UGEAF;
  } else if (URL.startsWith("http://localhost:6227/clearing")) {
    __surface = SURFACE.EAF_CLEARING;
  } else if (URL.startsWith("http://localhost:6227/adminportal")) {
    __surface = SURFACE.ESF_ADMIN;
  } else if (URL.startsWith("http://localhost:6227/compliance")) {
    __surface = SURFACE.ESF_COMPLIANCE;
  } else {
    // here you're declaring the DEFAULT DEV SURFACE.

    // If the dev surface is not declared in an environment variable, set it to a static value
    // If it is declared, use that declaration
    // Declarations are managed in package.json and used by running
    // npm run esf
    // in place of just using npm start or npm run dev
    // See package.json for the list of options
    // npm start and npm run dev have not had their behaviour changed
    // and will fall into the unset/default case
    if (import.meta.env.VITE_DEV_SURFACE == undefined) {
      __surface = SURFACE.ESF;
    } else {
      __surface = Number.parseInt(import.meta.env.VITE_DEV_SURFACE);
    }
  }
}

const TheseSurfaceAttributes = SurfaceLookup(__surface);

const qs = queryString.parse(window.location.search);
var _imp = qsSquidge(
  qs.imp ||
    qs.user ||
    qs.agent ||
    qs.advisor ||
    qs.adviser ||
    qs.selector ||
    qs.filter ||
    qs.overridefilter ||
    undefined,
);

if (TheseSurfaceAttributes.SURFACE === SURFACE.AOS) {
  var agentised = window.location.href
    .replace("overridefilter=", "agent=")
    .replace("filter=", "agent=")
    .replace("selector=", "agent=")
    .replace("adviser=", "agent=")
    .replace("advisor=", "agent=")
    .replace("user=", "agent=")
    .replace("imp=", "agent=");
  if (agentised !== window.location.href) {
    window.location.href = agentised;
  }
}

if (!_MODE_LIVE) {
  var endpoint = "http://localhost:4444/collect";
  if (_MODE_STAGING) endpoint = "https://edrmapitest.essex.ac.uk:4444/collect";
}

var ThisPCA: PublicClientApplication;
var ThisGraphRequest_Silent: SilentRequest;
var ThisGraphRequest_Redirect: RedirectRequest;
var ThisMSALConfig: Configuration;

if (_MODE_LIVE === true) {
  if (TheseSurfaceAttributes.RootSurface === SURFACE.AOS) {
    Logger.log("env = prd | surface = aos | ==> config = LIVE_AOS_msalConfig");
    ThisGraphRequest_Silent = LIVE_AOS_graphRequest_Silent;
    ThisGraphRequest_Redirect = LIVE_AOS_graphRequest_Redirect;
    ThisMSALConfig = LIVE_AOS_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.EAF) {
    Logger.log("env = prd | surface = eaf | ==> config = LIVE_EAF_msalConfig");
    ThisGraphRequest_Silent = LIVE_EAF_graphRequest_Silent;
    ThisGraphRequest_Redirect = LIVE_EAF_graphRequest_Redirect;
    ThisMSALConfig = LIVE_EAF_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.ECF) {
    Logger.log("env = prd | surface = ecf | ==> config = LIVE_ECF_msalConfig");
    ThisGraphRequest_Silent = LIVE_ECF_graphRequest_Silent;
    ThisGraphRequest_Redirect = LIVE_ECF_graphRequest_Redirect;
    ThisMSALConfig = LIVE_ECF_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.ESF) {
    Logger.log("env = prd | surface = esf | ==> config = LIVE_ESF_msalConfig");
    ThisGraphRequest_Silent = LIVE_ESF_graphRequest_Silent;
    ThisGraphRequest_Redirect = LIVE_ESF_graphRequest_Redirect;
    ThisMSALConfig = LIVE_ESF_msalConfig;
  }
} else if (_MODE_STAGING === true) {
  if (TheseSurfaceAttributes.RootSurface === SURFACE.AOS) {
    Logger.log("env = stg | surface = aos | ==> config = STAG_AOS_msalConfig");
    ThisGraphRequest_Silent = STAG_AOS_graphRequest_Silent;
    ThisGraphRequest_Redirect = STAG_AOS_graphRequest_Redirect;
    ThisMSALConfig = STAG_AOS_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.EAF) {
    Logger.log("env = stg | surface = eaf | ==> config = STAG_EAF_msalConfig");
    ThisGraphRequest_Silent = STAG_EAF_graphRequest_Silent;
    ThisGraphRequest_Redirect = STAG_EAF_graphRequest_Redirect;
    ThisMSALConfig = STAG_EAF_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.ECF) {
    Logger.log("env = stg | surface = ecf | ==> config = STAG_ECF_msalConfig");
    ThisGraphRequest_Silent = STAG_ECF_graphRequest_Silent;
    ThisGraphRequest_Redirect = STAG_ECF_graphRequest_Redirect;
    ThisMSALConfig = STAG_ECF_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.ESF) {
    Logger.log("env = stg | surface = esf | ==> config = STAG_ESF_msalConfig");
    ThisGraphRequest_Silent = STAG_ESF_graphRequest_Silent;
    ThisGraphRequest_Redirect = STAG_ESF_graphRequest_Redirect;
    ThisMSALConfig = STAG_ESF_msalConfig;
  }
} else if (_MODE_DEVELOPMENT === true) {
  if (TheseSurfaceAttributes.RootSurface === SURFACE.AOS) {
    Logger.log("env = dev | surface = aos | ==> config = DEV_AOS_msalConfig");
    ThisGraphRequest_Silent = DEV_AOS_graphRequest_Silent;
    ThisGraphRequest_Redirect = DEV_AOS_graphRequest_Redirect;
    ThisMSALConfig = DEV_AOS_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.EAF) {
    Logger.log("env = dev | surface = eaf | ==> config = DEV_EAF_msalConfig");
    ThisGraphRequest_Silent = DEV_EAF_graphRequest_Silent;
    ThisGraphRequest_Redirect = DEV_EAF_graphRequest_Redirect;
    ThisMSALConfig = DEV_EAF_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.ECF) {
    Logger.log("env = dev | surface = ecf | ==> config = DEV_ECF_msalConfig");
    ThisGraphRequest_Silent = DEV_ECF_graphRequest_Silent;
    ThisGraphRequest_Redirect = DEV_ECF_graphRequest_Redirect;
    ThisMSALConfig = DEV_ECF_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.ESF) {
    Logger.log("env = dev | surface = esf | ==> config = DEV_ESF_msalConfig");
    ThisGraphRequest_Silent = DEV_ESF_graphRequest_Silent;
    ThisGraphRequest_Redirect = DEV_ESF_graphRequest_Redirect;
    ThisMSALConfig = DEV_ESF_msalConfig;
  }
} else if (_MODE_LOCAL === true) {
  if (TheseSurfaceAttributes.RootSurface === SURFACE.AOS) {
    Logger.log(
      "env = local | surface = aos | ==> config = LOCAL_AOS_msalConfig",
    );
    ThisGraphRequest_Silent = LOCAL_AOS_graphRequest_Silent;
    ThisGraphRequest_Redirect = LOCAL_AOS_graphRequest_Redirect;
    ThisMSALConfig = LOCAL_AOS_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.EAF) {
    Logger.log(
      "env = local | surface = eaf | ==> config = LOCAL_EAF_msalConfig",
    );
    ThisGraphRequest_Silent = LOCAL_EAF_graphRequest_Silent;
    ThisGraphRequest_Redirect = LOCAL_EAF_graphRequest_Redirect;
    ThisMSALConfig = LOCAL_EAF_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.ECF) {
    Logger.log(
      "env = local | surface = ecf | ==> config = LOCAL_ECF_msalConfig",
    );
    ThisGraphRequest_Silent = LOCAL_ECF_graphRequest_Silent;
    ThisGraphRequest_Redirect = LOCAL_ECF_graphRequest_Redirect;
    ThisMSALConfig = LOCAL_ECF_msalConfig;
  } else if (TheseSurfaceAttributes.RootSurface === SURFACE.ESF) {
    Logger.log(
      "env = local | surface = esf | ==> config = LOCAL_ESF_msalConfig",
    );
    ThisGraphRequest_Silent = LOCAL_ESF_graphRequest_Silent;
    ThisGraphRequest_Redirect = LOCAL_ESF_graphRequest_Redirect;
    ThisMSALConfig = LOCAL_ESF_msalConfig;
  }
} else {
  Logger.log("## WARNING: FALLTHROUGH DEFAULT CONFIG LOADED");
  Logger.log("env = local | surface = esf | ==> config = LOCAL_ESF_msalConfig");
  ThisGraphRequest_Silent = LOCAL_ESF_graphRequest_Silent;
  ThisGraphRequest_Redirect = LOCAL_ESF_graphRequest_Redirect;
  ThisMSALConfig = LOCAL_ESF_msalConfig;
}

const thisURL = _MODE_LOCAL
  ? "http://localhost:6227/"
  : _MODE_DEVELOPMENT && TheseSurfaceAttributes.RootSurface === SURFACE.AOS
    ? "https://agentstest.essex.ac.uk/"
    : _MODE_DEVELOPMENT && TheseSurfaceAttributes.RootSurface === SURFACE.EAF
      ? "https://eaftest.essex.ac.uk/"
      : _MODE_DEVELOPMENT && TheseSurfaceAttributes.RootSurface === SURFACE.ECF
        ? "https://ecftest.essex.ac.uk/"
        : _MODE_DEVELOPMENT &&
            TheseSurfaceAttributes.RootSurface === SURFACE.ESF
          ? "https://esftest.essex.ac.uk/"
          : _MODE_STAGING && TheseSurfaceAttributes.RootSurface === SURFACE.AOS
            ? "https://agentstest.essex.ac.uk/"
            : _MODE_STAGING &&
                TheseSurfaceAttributes.RootSurface === SURFACE.EAF
              ? "https://eaftest.essex.ac.uk/"
              : _MODE_STAGING &&
                  TheseSurfaceAttributes.RootSurface === SURFACE.ECF
                ? "https://ecftest.essex.ac.uk/"
                : _MODE_STAGING &&
                    TheseSurfaceAttributes.RootSurface === SURFACE.ESF
                  ? "https://esftest.essex.ac.uk/"
                  : _MODE_LIVE &&
                      TheseSurfaceAttributes.RootSurface === SURFACE.AOS
                    ? "https://agents.essex.ac.uk/"
                    : _MODE_LIVE &&
                        TheseSurfaceAttributes.RootSurface === SURFACE.EAF
                      ? "https://eaf.essex.ac.uk/"
                      : _MODE_LIVE &&
                          TheseSurfaceAttributes.RootSurface === SURFACE.ECF
                        ? "https://ecf.essex.ac.uk/"
                        : _MODE_LIVE &&
                            TheseSurfaceAttributes.RootSurface === SURFACE.ESF
                          ? "https://esf.essex.ac.uk/"
                          : "http://localhost:6227/";

var fullURL = document.URL;

ThisGraphRequest_Redirect.state = fullURL;
ThisGraphRequest_Redirect.redirectUri = thisURL;

ThisPCA = new PublicClientApplication(ThisMSALConfig);
ThisPCA.enableAccountStorageEvents();

var _thisAccount: AccountInfo;

var AttemptSilentLogin = false;
var AttemptRedirectLogin = false;

// Oh look, a 100% critical method that appears in precisely one (1) of Microsoft's MSAL tutorials.
// Not one of the React tutorials though. That would be silly.

await ThisPCA.handleRedirectPromise()
  .then((RedirectedLoginResponse) => {
    if (RedirectedLoginResponse !== null) {
      Logger.log("RedirectedLoginResponse");
      Logger.log(RedirectedLoginResponse);
      ThisPCA.setActiveAccount(RedirectedLoginResponse.account);
      _thisAccount = RedirectedLoginResponse.account;
    } else {
      Logger.log("RedirectedLoginResponse is falsy...");
      AttemptSilentLogin = true;
    }
  })
  .catch((e) => {
    Logger.log(e);
  });

const accounts = ThisPCA.getAllAccounts();
var userHints = [];
var tenant_accounts = [];

accounts.forEach((th) => {
  if (ThisMSALConfig.auth.authority.indexOf(th.tenantId) > 0) {
    userHints = userHints.concat(th.username);
    tenant_accounts = tenant_accounts.concat(th);
  }
});

if (userHints.length === 1) {
  var clone_Redirect = { ...ThisGraphRequest_Redirect };
  clone_Redirect.loginHint = userHints[0];
  ThisGraphRequest_Redirect = clone_Redirect;
}

if (tenant_accounts.length > 0) {
  ThisPCA.setActiveAccount(tenant_accounts[0]);

  AttemptSilentLogin = false;
  AttemptRedirectLogin = false;
}

if (AttemptSilentLogin) {
  //  ThisPCA.addEventCallback((event) => {
  //    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload['account']) {
  //      const account = event.payload['account'];
  //      ThisPCA.setActiveAccount(account);
  //      _thisAccount = account;
  //    }
  //  });

  var _NestedLoginResponse: any = undefined;

  try {
    _NestedLoginResponse = await ThisPCA.ssoSilent(ThisGraphRequest_Silent);
    ThisPCA.setActiveAccount(_NestedLoginResponse.account);
    _thisAccount = _NestedLoginResponse.account;
  } catch (e) {
    Logger.log(e);
    AttemptRedirectLogin = true;
  }
}

if (AttemptRedirectLogin && TheseSurfaceAttributes.SURFACE !== SURFACE.AOS) {
  try {
    _NestedLoginResponse = await ThisPCA.loginRedirect(
      ThisGraphRequest_Redirect,
    );
    ThisPCA.setActiveAccount(_NestedLoginResponse.account);
    _thisAccount = _NestedLoginResponse.account;
  } catch (e) {
    Logger.log(e);
    Logger.log("All login attempts have failed.");
  }
}

var _GraphTokenResponse: any = undefined;
var _GraphAccessToken: string = "";

try {
  _GraphTokenResponse = await ThisPCA.acquireTokenSilent(
    ThisGraphRequest_Silent,
  );
  _GraphAccessToken = _GraphTokenResponse.accessToken;
} catch (e) {
  if (
    e instanceof InteractionRequiredAuthError &&
    TheseSurfaceAttributes.SURFACE !== SURFACE.AOS
  ) {
    _GraphTokenResponse = await ThisPCA.acquireTokenRedirect(
      ThisGraphRequest_Redirect,
    ).catch((e) => {
      Logger.log(e);
    });
  } else {
    Logger.log(e);
  }
}

var _APITokenResponse: any = undefined;
var _APIAccessToken: string = "";
let _APITokenRequest: SilentRequest = {
  account: _thisAccount ?? undefined,
  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
};

try {
  _APITokenResponse = await ThisPCA.acquireTokenSilent(_APITokenRequest);
  _APIAccessToken = _APITokenResponse.accessToken;
} catch (e) {
  if (
    TheseSurfaceAttributes.SURFACE !== SURFACE.AOS &&
    (e instanceof InteractionRequiredAuthError || e instanceof BrowserAuthError)
  ) {
    _APITokenResponse = await ThisPCA.acquireTokenRedirect(
      _APITokenRequest,
    ).catch((e) => {
      Logger.log(e);
    });
    _APIAccessToken = _APITokenResponse.accessToken;
  } else {
    Logger.log(e);
  }
}

const STRUCT = {
  MODE_LOCAL: _MODE_LOCAL,
  MODE_DEVELOPMENT: _MODE_DEVELOPMENT,
  MODE_STAGING: _MODE_STAGING,
  MODE_LIVE: _MODE_LIVE,
  APIAccessToken: _APIAccessToken,
  GraphAccessToken: _GraphAccessToken,
  ActiveAccount: _thisAccount,
  Logger: Logger,
  URL: thisURL,
  PCA: ThisPCA,
  Imp: _imp,
};

const appTheme: PartialTheme = {
  palette: {
    themePrimary: "black",
  },
};

// Wher we're on AOS, we allow an UnauthenticatedTemplate.
const ApplyUnauthenticatedTemplate =
  TheseSurfaceAttributes.SURFACE === SURFACE.AOS;

ReactDOM.render(
  <SurfaceProvider TheseSurfaceAttributes={TheseSurfaceAttributes}>
    <ThemeProvider theme={appTheme}>
      <MsalProvider instance={ThisPCA}>
        <AuthenticatedTemplate>
          <SPOWebUIOnPremMSALWrapper {...STRUCT}></SPOWebUIOnPremMSALWrapper>
        </AuthenticatedTemplate>
        {ApplyUnauthenticatedTemplate ? (
          <UnauthenticatedTemplate>
            <AgentLoginSpiel
              Request={ThisGraphRequest_Redirect}
            ></AgentLoginSpiel>
          </UnauthenticatedTemplate>
        ) : (
          <></>
        )}
      </MsalProvider>
    </ThemeProvider>
  </SurfaceProvider>,
  document.getElementById("SPOWebUIOnPremContainer"),
);

function qsSquidge(value: string | string[]): string {
  if (value === undefined || value === null) {
    return undefined;
  } else if (value === value.toString()) {
    return value;
  } else if ((value as string[]).forEach !== undefined) {
    var returner: string = "";
    (value as string[]).forEach((th: string) => {
      returner = returner + th + " ";
    });
    returner = returner.trim();
    returner = returner.replace(" ", ",");
    return returner;
  }
}
