import { ReactElement } from "react";
import { useRouteLoaderData } from "react-router-dom";

interface AgentNoticesProps {
  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
}

export function AgentNotices(props: AgentNoticesProps): ReactElement {
  let agentDetails: any = useRouteLoaderData("AgentRoot");

  return (
    <>
      {props.Embargo("UCAS") ? (
        props.HasNotices(agentDetails.AgentIsPAS, ["AOS", "Banner"]) ? (
          <div className="AOSPanel Notices">
            <div className="PanelBar Title">
              <div className="Left">Notices</div>
              <div className="Breaker"></div>
              <div className="Right"></div>
            </div>
            {props.Notices(agentDetails.AgentIsPAS, ["AOS", "Banner"])}
          </div>
        ) : (
          <></>
        )
      ) : props.HasNotices(agentDetails.AgentIsPAS, ["AOS", "Banner"]) ? (
        <div className="AOSPanel Notices">
          <div className="PanelBar Title">
            <div className="Left">Notices</div>
            <div className="Breaker"></div>
            <div className="Right"></div>
          </div>
          {props.Notices(agentDetails.AgentIsPAS, ["AOS", "Banner"])}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
