import * as queryString from "query-string";
import { Logging, LoggingLevel } from "../Logging";
import { IItem } from "../models/IItem";
import { IPublicClientApplication } from "@azure/msal-browser";
import { SPOContentType } from "../integrations/SPUploadManager/SPInterfacing/SPOContentType";
import { SPOMetadata } from "../integrations/SPUploadManager/SPInterfacing/SPOMetadata";
import { SURFACE, SurfaceAttributes } from "../Providers/SurfaceProvider";

export enum MODE {
  LOCAL,
  DEVELOPMENT,
  STAGING,
  PRODUCTION,
}

export interface IParams {
  sproc: string;
  params?: string[];
  user?: string;
  imp?: string;

  pgno?: string;
  ucasno?: string;
  prid?: string;
  contentTypes?: string[];
  blackList?: boolean;
  location?: string;
  rename?: string;
  start?: number;
  limit?: number;
  columns?: string;
  selectcolumns?: string;
  sortBy?: string[][];
  sortByDesc?: boolean[][];
  filterBy?: string[][][];
  options?: string;

  HomeOfficeAudit?: boolean;
  spvals?: string[];

  forceProxy?: boolean;
}

export class User {
  forename: string;
  surname: string;
  email: string;
  userPrincipalName: string;
  id: string;
  groups: {
    id: string;
    displayName: string;
    onPremSamName: string;
    mailNickname: string;
    description: string;
  }[];
  permissions: string[];
}

export class DataAccess {
  // Server URL Config
  private apiUrl_Live: string = "https://edrmapi.essex.ac.uk/legacy";
  private apiUrl_Staging: string = "https://edrmapitest.essex.ac.uk/legacy";
  private apiUrl_Development: string = "https://edrmapidev.essex.ac.uk/legacy";
  private apiUrl_Local: string = "https://localhost:7023";

  // RegPhoto URL Config
  private regPhoto_Live: string = "https://edrmapi.essex.ac.uk/regphoto";
  private regPhoto_Staging: string = "https://edrmapitest.essex.ac.uk/regphoto";
  private regPhoto_Development: string =
    "https://edrmapidev.essex.ac.uk/regphoto";
  private regPhoto_Local: string = "https://localhost:7116";

  //Access Log Endpoint
  private accesslogApiUrl_Live: string =
    "https://edrmapi.essex.ac.uk/accesslog";
  private accesslogApiUrl_Staging: string =
    "https://edrmapitest.essex.ac.uk/accesslog";
  private accesslogApiUrl_Development: string =
    "https://edrmapidev.essex.ac.uk/accesslog";
  private accesslogApiUrl_Local: string = "https://localhost:7102";

  //User Endpoint
  private userApiUrl_Live: string = "https://edrmapi.essex.ac.uk/user";
  private userApiUrl_Staging: string = "https://edrmapitest.essex.ac.uk/user";
  private userApiUrl_Development: string =
    "https://edrmapidev.essex.ac.uk/user";
  private userApiUrl_Local: string = "https://localhost:7229";
  //private userApiUrl_Development: string = 'https://edrmapitest.essex.ac.uk/user';

  //edocs Endpoint
  private edocsApiUrl_Live: string = "https://edrmapi.essex.ac.uk/edocs";
  private edocsApiUrl_Staging: string = "https://edrmapitest.essex.ac.uk/edocs";
  private edocsApiUrl_Development: string =
    "https://edrmapidev.essex.ac.uk/edocs";
  private edocsApiUrl_Local: string = "https://localhost:7250";

  private apiUrl: string = this.apiUrl_Live;
  private accesslogApiUrl: string = this.accesslogApiUrl_Live;
  private edocsApiUrl: string = this.edocsApiUrl_Live;
  private userApiUrl: string = this.userApiUrl_Live;
  private regPhotoUrl: string = this.regPhoto_Live;

  //  private hostname: string;
  private msal: IPublicClientApplication;
  private Logger: Logging;

  private Surface: SurfaceAttributes;
  private mode: MODE;

  constructor(
    _logger: Logging,
    _msal_PCA: IPublicClientApplication,
    currentMode: MODE,
    Surface: SurfaceAttributes,
  ) {
    this.Logger = _logger;
    this.msal = _msal_PCA;
    this.Surface = Surface;
    this.mode = currentMode;

    switch (currentMode) {
      case MODE.LOCAL:
        this.apiUrl = this.apiUrl_Local;
        this.accesslogApiUrl = this.accesslogApiUrl_Local;
        this.edocsApiUrl = this.edocsApiUrl_Local;
        this.userApiUrl = this.userApiUrl_Local;
        this.regPhotoUrl = this.regPhoto_Local;
        break;
      case MODE.DEVELOPMENT:
        this.apiUrl = this.apiUrl_Development;
        this.accesslogApiUrl = this.accesslogApiUrl_Development;
        this.edocsApiUrl = this.edocsApiUrl_Development;
        this.userApiUrl = this.userApiUrl_Development;
        this.regPhotoUrl = this.regPhoto_Development;
        break;
      case MODE.STAGING:
        this.apiUrl = this.apiUrl_Staging;
        this.accesslogApiUrl = this.accesslogApiUrl_Staging;
        this.edocsApiUrl = this.edocsApiUrl_Staging;
        this.userApiUrl = this.userApiUrl_Staging;
        this.regPhotoUrl = this.regPhoto_Staging;
        break;
      case MODE.PRODUCTION:
        this.apiUrl = this.apiUrl_Live;
        this.accesslogApiUrl = this.accesslogApiUrl_Live;
        this.edocsApiUrl = this.edocsApiUrl_Live;
        this.userApiUrl = this.userApiUrl_Live;
        this.regPhotoUrl = this.regPhoto_Live;
        break;
    }

    this.Logger.log("URL [API]: " + this.apiUrl, LoggingLevel.INFO);
    this.Logger.log(
      "URL [AccessLogAPI]: " + this.accesslogApiUrl,
      LoggingLevel.INFO,
    );
    this.Logger.log("URL [EDocsAPI]: " + this.edocsApiUrl, LoggingLevel.INFO);
    this.Logger.log("URL [UserAPI]: " + this.userApiUrl, LoggingLevel.INFO);
  }

  public async getAuditDocs(ident: string): Promise<IItem[][]> {
    try {
      let response = await fetch(
        this.edocsApiUrl + "/ESF/Audit/Documents/" + ident,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " +
              (
                await this.msal.acquireTokenSilent({
                  account: this.msal.getActiveAccount() ?? undefined,
                  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
                })
              ).accessToken,
          },
        },
      );

      let mdResponse: IItem[][] = await response.json();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getAuditDocs(" + ident + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async saveAuditDocsFlag(files: IItem[]): Promise<any> {
    try {
      let metadata = files.map((file) => {
        return {
          SiteId: file["_siteId"],
          LibraryId: file["_libraryId"],
          FileId: file["_fileId"],
          AuditDoc: file["Audit Doc_CHECKBOX"].toLowerCase() === "true",
        };
      });

      let response = await fetch(this.edocsApiUrl + "/metadata/flag", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
        body: JSON.stringify({ files: metadata }),
      });

      let mdResponse = await response.json();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "saveAuditDocsFlag(" + files + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async saveClearingDocsMetadata(files: IItem[]): Promise<any> {
    try {
      let metadata = files.map((file) => {
        return {
          SiteId: file["_siteId"],
          LibraryId: file["_libraryId"],
          FileId: file["_fileId"],
          UcasNo: file["UCAS No._TEXTENTRY"],
          UcasVerified: file["UCAS Verified_CHECKBOX"].toLowerCase() === "true",
        };
      });

      let response = await fetch(this.edocsApiUrl + "/clearing", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
        body: JSON.stringify({ Files: metadata }),
      });

      let mdResponse = await response.json();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "saveClearingDocsMetadata(" + files + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getRegPhoto(ident: string): Promise<any> {
    try {
      let response = await fetch(this.regPhotoUrl + "/" + ident, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
      });
      let mdResponse = await response.blob();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getRegPhoto(" + ident + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getDocument(
    ident: string,
    docid: string,
    filename: string,
  ): Promise<any> {
    try {
      let response = await fetch(
        this.edocsApiUrl + "/document/" + ident + "/" + docid + "/" + filename,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization:
              "Bearer " +
              (
                await this.msal.acquireTokenSilent({
                  account: this.msal.getActiveAccount() ?? undefined,
                  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
                })
              ).accessToken,
          },
        },
      );
      let mdResponse = await response.blob();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getDocument(" + ident + ", " + docid + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getWorkflow(ident: string, wfid: string): Promise<any> {
    try {
      let response = await fetch(
        this.edocsApiUrl + "/workflow/" + ident + "/" + wfid + "/report.pdf",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization:
              "Bearer " +
              (
                await this.msal.acquireTokenSilent({
                  account: this.msal.getActiveAccount() ?? undefined,
                  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
                })
              ).accessToken,
          },
        },
      );
      let mdResponse = await response.blob();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getWorkflow(" + ident + ", " + wfid + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getEsfDocsForFlagging(prid: string): Promise<IItem[]> {
    try {
      let response = await fetch(
        this.edocsApiUrl + "/ESF/Audit/Flagging/" + prid,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " +
              (
                await this.msal.acquireTokenSilent({
                  account: this.msal.getActiveAccount() ?? undefined,
                  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
                })
              ).accessToken,
          },
        },
      );

      let mdResponse: IItem[] = await response.json();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getEsfDocsForFlagging(" + prid + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getUgEafDocsForFlagging(ucas: string): Promise<IItem[]> {
    try {
      let response = await fetch(
        this.edocsApiUrl + "/UG/Audit/Flagging/" + ucas,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " +
              (
                await this.msal.acquireTokenSilent({
                  account: this.msal.getActiveAccount() ?? undefined,
                  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
                })
              ).accessToken,
          },
        },
      );

      let mdResponse: IItem[] = await response.json();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getUgEafDocsForFlagging(" + ucas + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getPgEafDocsForFlagging(pgno: string): Promise<IItem[]> {
    try {
      let response = await fetch(
        this.edocsApiUrl + "/PG/Audit/Flagging/" + pgno,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " +
              (
                await this.msal.acquireTokenSilent({
                  account: this.msal.getActiveAccount() ?? undefined,
                  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
                })
              ).accessToken,
          },
        },
      );

      let mdResponse: IItem[] = await response.json();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getPgEafDocsForFlagging(" + pgno + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getClearingDocsForFlagging(
    clearingno: string,
    impUser?: string,
  ): Promise<IItem[]> {
    try {
      let ep = "";

      if (impUser !== undefined && impUser !== null && impUser !== "") {
        ep = "?imp=" + impUser;
      }

      let response = await fetch(
        this.edocsApiUrl + "/Clearing/Review/" + clearingno + ep,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " +
              (
                await this.msal.acquireTokenSilent({
                  account: this.msal.getActiveAccount() ?? undefined,
                  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
                })
              ).accessToken,
          },
        },
      );

      let mdResponse: IItem[] = await response.json();
      if (mdResponse == undefined || mdResponse.length == 0) return [];
      // return [{ ERROR: "no data found" }];
      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getClearingDocsForFlagging(" + clearingno + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async hasEcf(Ident: string): Promise<boolean> {
    try {
      let response = await fetch(
        this.edocsApiUrl + "/oddities/ecflink/" + Ident,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " +
              (
                await this.msal.acquireTokenSilent({
                  account: this.msal.getActiveAccount() ?? undefined,
                  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
                })
              ).accessToken,
          },
        },
      );

      let hasLink = await response.json();

      return hasLink;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "hasEcf(" + Ident + ") -> " + this.accesslogApiUrl,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getEsfEmails(Ident: string): Promise<any[]> {
    try {
      let response = await fetch(this.edocsApiUrl + "/emails/esf/" + Ident, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
      });

      let emails = await response.json();

      return emails;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getEsfEmails(" + Ident + ") -> " + this.accesslogApiUrl,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getUgeafEmails(Ident: string): Promise<any[]> {
    try {
      let response = await fetch(this.edocsApiUrl + "/emails/ugeaf/" + Ident, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
      });

      let emails = await response.json();

      return emails;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getUgeafEmails(" + Ident + ") -> " + this.accesslogApiUrl,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getContentTypes(
    Site: string,
    DocumentLibrary: string,
    Conveyance?: string,
  ): Promise<SPOContentType[]> {
    try {
      let lib = DocumentLibrary;
      if (this.mode != MODE.PRODUCTION && this.Surface.SURFACE != SURFACE.ECF) {
        lib = "test";
      } else if (
        this.mode != MODE.PRODUCTION &&
        this.Surface.SURFACE == SURFACE.ECF
      ) {
        lib = "test" + DocumentLibrary;
      }

      let response = await fetch(this.edocsApiUrl + "/ContentTypes", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
        body: JSON.stringify({
          site: Site,
          documentLibrary: lib,
          destinationConveyance: Conveyance,
        }),
      });

      let SPOs: SPOContentType[] = await response.json();

      var retSPOs: SPOContentType[] = [];

      SPOs.forEach((th) => {
        var _spo: SPOContentType = th;
        _spo.metadataFields = th.metadataFields.filter(
          (ith) => ith.internalName !== "Title",
        );
        retSPOs.push(_spo);
      });

      return SPOs;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getContentTypes(" +
          Site +
          ", " +
          DocumentLibrary +
          ") -> " +
          this.accesslogApiUrl,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getFileMetadata(fileIdent: string): Promise<{
    validContentTypes: SPOContentType[];
    metadataValues: Map<string, string | boolean | Date>;
  }> {
    try {
      let response = await fetch(this.edocsApiUrl + "/metadata/" + fileIdent, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
      });

      let metadata = await response.json();

      return metadata;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getFileMetadata(" + fileIdent + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async setFileMetadata(
    fileIdent: string,
    metadata: Map<string, string | boolean | Date>,
  ): Promise<{
    validContentTypes: SPOContentType[];
    metadataValues: Map<string, string>;
  }> {
    try {
      let response = await fetch(this.edocsApiUrl + "/metadata/" + fileIdent, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
        body: JSON.stringify(metadata),
      });

      let mdResponse = await response.json();

      return mdResponse;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getFileMetadata(" + fileIdent + ")",
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async logAccess(application: string): Promise<void> {
    try {
      const additionalInfo = Object.fromEntries(
        new URLSearchParams(window.location.search).entries(),
      );
      additionalInfo["application"] = application;
      additionalInfo["git_sha"] = import.meta.env.VITE_GIT_COMMIT_HASH;
      additionalInfo["git_commit_date"] = import.meta.env.VITE_GIT_COMMIT_DATE;
      additionalInfo["git_commit_message"] =
        import.meta.env.VITE_GIT_LAST_COMMIT_MESSAGE;

      await fetch(this.accesslogApiUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
        body: JSON.stringify({
          url: window.location.origin + window.location.pathname,
          additionalInfo: additionalInfo,
        }),
      });
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "logAccess(" + application + ") -> " + this.accesslogApiUrl,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getUser(impUser: string): Promise<any> {
    try {
      let response = await fetch(this.userApiUrl + "/User/" + impUser, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
      });

      let user: User = await response.json();

      //  console.log(user);

      return user;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getUser(" + impUser + ") -> " + this.userApiUrl,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  //  having a ?string was causing issues with impuser being deliberately passed through as null
  public async getAuthedUser(): Promise<any> {
    try {
      let response = await fetch(this.userApiUrl + "/User", {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
      });

      let user: User = await response.json();

      //  console.log(user);

      return user;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getAuthedUser() -> " + this.userApiUrl,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getEdocs(
    identifier: string,
    impUser?: string,
    contentTypes?: string[],
    blockContentTypes?: boolean,
    elevation?: string,
  ): Promise<any> {
    let endpoint = this.edocsApiUrl;

    //  console.log("getEdocs");
    //  console.log(identifier);
    //  console.log(impUser);
    //  console.log(contentTypes);
    //  console.log(blockContentTypes);
    //  console.log(elevation);

    switch (this.Surface.RootSurface) {
      case SURFACE.AOS:
        endpoint = endpoint + "/AOS";
        if (window.location.pathname.toLocaleUpperCase().startsWith("/UG")) {
          endpoint = endpoint + "/UG/";
        } else {
          endpoint = endpoint + "/PG/";
        }
        break;
      case SURFACE.ESF:
        if (elevation === "GDPR") {
          endpoint = endpoint + "/ESF/GDPR/";
        } else if (elevation === "SV") {
          endpoint = endpoint + "/ESF/Audit/";
        } else {
          endpoint = endpoint + "/ESF/";
        }
        break;
      case SURFACE.ECF:
        endpoint = endpoint + "/ECF/";
        break;
      case SURFACE.EAF:
        if (window.location.pathname.toLocaleUpperCase().startsWith("/UG")) {
          endpoint = endpoint + "/UGEAF/";
        } else {
          endpoint = endpoint + "/PGEAF/";
        }

        break;
    }

    try {
      let ep = new URL(identifier, endpoint);

      if (impUser !== undefined && impUser !== null && impUser !== "") {
        ep.searchParams.append("imp", impUser);
      }

      if (
        contentTypes !== undefined &&
        contentTypes != null &&
        !blockContentTypes
      ) {
        ep.searchParams.append("ctRequest", JSON.stringify(contentTypes));
      }

      if (
        contentTypes !== undefined &&
        contentTypes != null &&
        blockContentTypes
      ) {
        ep.searchParams.append("ctReject", JSON.stringify(contentTypes));
      }

      let response = await fetch(ep, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status == 401 || response.status == 403) {
        return [
          [
            {
              ERROR: "Access Denied",
            },
          ],
        ];
      }

      return [
        [
          {
            ERROR: "Problem retrieving edocs",
          },
        ],
      ];
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "getEdocs(" +
          identifier +
          "," +
          (elevation || "null") +
          ") -> " +
          this.userApiUrl,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async getImp(
    endpoint: string,
    params: string,
    legacy: boolean,
    impUser: string,
    impAll: boolean,
  ): Promise<any> {
    var _params = {};

    //  console.log('getImp');
    //  console.log(endpoint);
    //  console.log(JSON.parse(params));
    //  console.log(legacy);
    //  console.log(impUser);

    // awful awful awful
    if (impUser !== undefined) {
      var _hurf = JSON.parse(params);
      _params = {
        ..._hurf,
        imp: impUser,
      };
    } else if (impAll === true) {
      var _hurf = JSON.parse(params);
      _params = {
        ..._hurf,
        imp: "--ALL--",
      };
    } else {
      _params = JSON.parse(params);
    }

    //  console.log(_params);

    var _re_par = JSON.stringify(_params);

    return this.get(endpoint, _re_par, legacy);
  }

  public async get(
    endpoint: string,
    params: string,
    legacy?: boolean,
  ): Promise<any> {
    var paramsObj = JSON.parse(params);

    var repoint = this.apiUrl;
    if (endpoint.startsWith("/user")) {
      repoint += endpoint;
    } else {
      if (!endpoint.startsWith("/")) {
        repoint += "/";
      }
      repoint +=
        endpoint +
        "?p=" +
        btoa(encodeURIComponent(params)) +
        "&cachebuster=" +
        btoa(encodeURIComponent(Date.now().toString()));
      if (paramsObj.imp != undefined) {
        repoint += "&imp=" + paramsObj.imp;
      }
    }

    if (legacy !== undefined && legacy !== true) {
      repoint = repoint.replace("/legacy/", "/");
    }

    //  console.log('--' + repoint);
    try {
      const response = await fetch(
        //  (legacy === false
        //      ?
        //      this.apiUrl.replace('/legacy', '')
        //      :
        //          (this.apiUrl.includes('/legacy')
        //          ?
        //          this.apiUrl
        //          :
        //          this.apiUrl + '/legacy')) +
        //  (endpoint.startsWith('/user') ? endpoint :
        //  endpoint + '?p=' + btoa(encodeURIComponent(params)) + '&cachebuster=' + btoa(encodeURIComponent(Date.now().toString())))
        repoint,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " +
              (
                await this.msal.acquireTokenSilent({
                  account: this.msal.getActiveAccount() ?? undefined,
                  scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
                })
              ).accessToken,
          },
          method: "GET",
          mode: "cors",
        },
      );
      const response_1 = await response.text();
      return await new Promise((resolve, reject) => {
        if (response_1.length === 0) {
          resolve(undefined);
        } else if (response_1.includes("is in restricted mode")) {
          reject("Database is locked.");
          //<div class="titleerror">SqlException: Database &#x27;studb&#x27; is in restricted mode. Only the database owner and members of the dbcreator and sysadmin roles can access it.</div>
        } else {
          try {
            resolve(JSON.parse(response_1));
          } catch (e) {
            // errors thrown at this level never returned anything useble to the frontend.
            reject(e);
            //  reject(response_1);
            // return response_1;
          }
        }
      });
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "get(" + endpoint + ", params)",
      );
      var outError = "Failed to communicate with API";
      if (error === "Database is locked.") {
        // this is an error we don't mind surfacing in this instance.
        outError += " - " + error;
      } else {
        outError += ".";
      }
      return await new Promise((resolve_1, reject_1) => {
        reject_1(outError);
      });
    }
  }

  public async post(
    endpoint: string,
    params: string,
    method: string = "POST",
    legacy: boolean,
  ): Promise<any> {
    try {
      const response = await fetch(this.apiUrl + endpoint, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
        method: method,
        mode: "cors",
        body: params,
      });
      const response_1 = await response.text();
      return await new Promise((resolve, reject) => {
        if (response_1.length === 0) resolve(undefined);
        try {
          resolve(JSON.parse(response_1));
        } catch (e) {
          return response_1;
        }
      });
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "post(" + endpoint + ", params, " + method + ")",
      );
      return await new Promise((resolve_1, reject_1) => {
        reject_1("Failed to communicate with API");
      });
    }
  }

  public async UploadFile(
    fileName: string,
    file: File,
    uploader: string,
    metadata: SPOMetadata[],
  ): Promise<Number | String> {
    try {
      const formData = new FormData();

      formData.append("file", file, fileName);
      formData.append("conveyanceName", uploader);
      formData.append("Title", fileName);
      metadata.forEach((th) => {
        if (Object.prototype.toString.call(th.DataValue) == "[object Date]") {
          formData.append(th.DataName, (th.DataValue as Date).toISOString());
        } else {
          formData.append(th.DataName, th.DataValue.toString());
        }
      });

      // formData.append() the rest as well
      // console.log('## UploadFile() =>');
      // console.log(fileName);
      // console.log(file);
      // console.log(uploader);
      // console.log(formData);
      // need to separate invar queueing and direct upload
      const jim: Number = await fetch(this.edocsApiUrl + "/UploadFile", {
        method: "POST",
        body: formData,
        headers: {
          Authorization:
            "Bearer " +
            (
              await this.msal.acquireTokenSilent({
                account: this.msal.getActiveAccount() ?? undefined,
                scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
              })
            ).accessToken,
        },
      }).then((r) => r.json());
      //  console.log(jim);
      return jim;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        "UploadFile(" + fileName + ")",
      );
      return await new Promise((resolve_1, reject_1) => {
        reject_1("Failed to communicate with API");
      });
    }
  }

  // Auth/User

  public async getCurrentUser(): Promise<any> {
    const endpoint = "/_api/web/currentuser";
    var siteUrl = undefined;
    const hostname = window && window.location && window.location.hostname;

    if (hostname === "edrm.essex.ac.uk") {
      siteUrl = window.location.protocol + "//" + window.location.host;
      if (window.location.pathname.startsWith("/esf/")) siteUrl += "/esf";
      if (window.location.pathname.startsWith("/ecf/")) siteUrl += "/ecf";
    }

    if (hostname === "essexuniversity.sharepoint.com") {
      siteUrl = window.location.protocol + "//" + window.location.host;
    }

    if (siteUrl === undefined) {
      return new Promise((resolve, reject) => {
        resolve(undefined);
      });
    }

    this.Logger.log("Auth with " + siteUrl + endpoint, LoggingLevel.DEBUG);

    try {
      const response = await fetch(siteUrl + endpoint, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
        },
        method: "GET",
        mode: "cors",
      });
      const response_1 = await response.text();
      return await new Promise((resolve_1, reject_1) => {
        if (response_1.length === 0) resolve_1(undefined);
        else {
          const result_1 = JSON.parse(response_1);
          var user = result_1["d"]["LoginName"];
          user = user.replace("i:0#.w|campus\\", "");
          user = user.replace("i:0#.f|membership|", "");
          user = user.replace("@essex.ac.uk", "");
          resolve_1(user);
        }
      });
    } catch (error) {
      this.Logger.log(error, LoggingLevel.EXCEPTION, "getCurrentUser()");
      return await new Promise((resolve_2, reject_2) => {
        reject_2("Failed to communicate with SharePoint API");
      });
    }
  }

  // Supporting Functions

  public __getQSParams(params: string[] | undefined): string[] | undefined {
    if (
      params === undefined ||
      params.length === 0 ||
      params.length % 2 !== 0
    ) {
      return undefined;
    } else {
      const qs = queryString.parse(window.location.search);
      for (let i = 0; i < params.length; i++) {
        const v = params[i];
        if (
          v !== "{%ui}" &&
          v !== "{%i}" &&
          v !== "{%ui}" &&
          v !== "{%iu}" &&
          v.charAt(0) === "{" &&
          v.charAt(v.length - 1) === "}"
        ) {
          params[i] = qs[v.substr(1, v.length - 2)] as string;
        }
      }
    }
    return params;
  }

  public getData(data?: IItem[][], table: number = 0): IItem[] {
    if (data !== undefined) {
      if (data.length === 1 && data[0].length === 1 && data[0][0]["ERROR"]) {
        this.Logger.log(
          data[0][0]["ERROR"],
          LoggingLevel.EXCEPTION,
          "getData()",
        );
        return data[table];
      } else {
        return data[table];
      }
    }
    return [];
  }
}
