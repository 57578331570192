import * as React from "react";

import "../assets/css/TaskLists.css";
import ModeStruct from "../models/ModeStruct";
import TaskListGroup from "../models/TaskListGroup";
import { GridWrapper } from "../integrations/UoE-Grid/GridWrapper";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../data/DataAccess";
import { Logging } from "../Logging";
import IfPermitted from "./IfPermitted";
import TaskList from "../models/TaskList";

interface IProps {
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  AuthedUser: User;
  ViewAsUser: User;
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  URLRoot: string;
  URLParams: string;
  Parameter1: string | undefined;
  Parameter2: string | undefined;
  TaskListGroups: TaskListGroup[] | undefined;
  TaskList: string;
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

class TaskLists extends React.Component<IProps> {
  componentDidUpdate(prevProps) {
    if (
      prevProps.TaskList !== this.props.TaskList ||
      prevProps.Parameter1 !== this.props.Parameter1 ||
      prevProps.Parameter2 !== this.props.Parameter2
    ) {
      //  console.log("TaskLists.Update ==> Refresh");
      this.props.Refresh();
    }
  }

  render() {
    var PassToAllGrids = {
      Callouts: true,
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      RouteParam1: this.props.Parameter1,
      RouteParam2: this.props.Parameter2,
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    var thisTLG: TaskListGroup = this.props.TaskListGroups?.find((th) =>
      th.TaskLists.some(
        (ith) => ith.TLRef.toLowerCase() === this.props.TaskList.toLowerCase(),
      ),
    );
    var thisTL: TaskList = thisTLG?.TaskLists.find(
      (th) => th.TLRef.toLowerCase() === this.props.TaskList.toLowerCase(),
    );

    return (
      <div className="TaskLists">
        <div className="TaskListPanel">
          {this.props.TaskList !== undefined ? (
            <IfPermitted Whitelist={thisTL?.Permitted?.split(",") || []}>
              <GridWrapper
                {...PassToAllGrids}
                key={"TLP_" + this.props.Refresher}
                eid={"TLPan_" + this.props.Refresher}
                output="tasklist"
                options="showAdvancedSort=true|showAdvancedGrouping=true|showAdvancedFiltration=true|showCookieBouncer=true"
                class={"UoE-Grid " + this.props.GetMode(thisTL?.TLRef)?.Style}
                sproc={this.props.GetMode(thisTL?.TLRef)?.Machine}
                NoneMessage="No tasks found."
                limit={100}
                params={["@Filter", "{%u}", "@OverrideFilter", "{%i}"]}
              />
            </IfPermitted>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default TaskLists;
