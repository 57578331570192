import { useEffect, useState } from "react";
import { useApi } from "../../Providers/ApiProvider";

interface PreviewImgProps {
  ident: string;
  docid: string;
  filename: string;

  visible: boolean;
}

export function PreviewImg(props: PreviewImgProps) {
  let api = useApi();
  let [document, setDocument] = useState<any>();

  useEffect(() => {
    (async () => {
      let doc = await api.General.getDocument(
        props.ident,
        props.docid,
        props.filename,
      );
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          // Chromium has a 2MB limit on dataURIs, so we have to convert to blobs.
          // BLOBBY BLOBBY BLOBBY!
          let blobby = dataURLtoBlob(reader.result);
          let blobbyUrl = window.URL.createObjectURL(blobby);

          // convert image file to base64 string
          setDocument(blobbyUrl);
        },
        false,
      );

      reader.readAsDataURL(doc);
    })();
  }, [props]);

  if (document == undefined) {
    return <></>;
  } else if (props.filename == "jpg") {
    return (
      <div
        style={{
          maxHeight: 390,
          maxWidth: 400,
          height: 390,
          width: 400,
          visibility: props.visible ? "visible" : "hidden",
        }}
      >
        <img
          src={document}
          style={{
            maxHeight: 390,
            maxWidth: 400,
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          maxHeight: 390,
          maxWidth: 400,
          height: 390,
          width: 400,
          visibility: props.visible ? "visible" : "hidden",
        }}
      >
        <object
          style={{
            width: "100%",
            height: "100%",
            maxHeight: 390,
            maxWidth: 400,
          }}
          className="Preview_Item"
          data={document}
        ></object>
      </div>
    );
  }
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
