export class MonitoredWorkOrderResponse {
  Active: boolean;
  BrokerID: number;
  Cancelled: boolean;
  Completed?: Date;
  ConveyanceID: number;
  Created: Date;
  CreatedBy: string;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Failures: number;
  InitialValue: string;
  NextAttempt?: Date;
  Poison: boolean;
  Retried: number;
  WorkOrderID: number;
}
