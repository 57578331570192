import { getIconClassName } from "@fluentui/react";
import { ReactElement, useEffect, useState } from "react";
import { DataAccess } from "../data/DataAccess";

export function EmailButton(props: {
  prid: string;
  da: DataAccess;
}): ReactElement {
  let [hasEmail, setHasEmail] = useState<boolean>(false);
  let [email, setEmail] = useState<string>("");

  useEffect(() => {
    (async () => {
      props.da
        .get(
          "/sproc",
          JSON.stringify({
            sproc: "sputilities.webui.ESF_SUMMARY_get_email",
            params: ["@pr_id", props.prid],
          }),
        )
        .then((resp) => {
          setEmail(resp[0][0].Email);
          if (resp[0][0].Email.includes("@")) {
            setHasEmail(true);
          }
        });
    })();
  }, [props.prid]);

  let test = "";
  if (
    window.location.hostname.includes("test") ||
    window.location.hostname.includes("localhost")
  ) {
    test = "test";
  }

  return (
    <>
      {hasEmail ? (
        <a href={"mailto:" + email}>
          <i className={getIconClassName("Mail")} aria-hidden="true"></i> Send
          Email
        </a>
      ) : (
        <a className="disabled">
          <i className={getIconClassName("Mail")} aria-hidden="true"></i> No
          Email
        </a>
      )}
    </>
  );
}
