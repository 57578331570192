import dayjs from "dayjs";
import { MonitoredWorkOrderResponse } from "../../data/InvarDataModels/MonitoredWorkOrderResponse";
import { ConveyanceResponse } from "../../data/InvarDataModels/ConveyanceResponse";

interface InvarMonitorCardProps {
  WorkOrder: MonitoredWorkOrderResponse;
  Conveyance: ConveyanceResponse;
  ShowView: boolean;
  Archive: (WOID: number) => Promise<void>;
  Clone: (WOID: number) => Promise<void>;
  Activate: (WOID: number) => Promise<void>;
}

export const InvarMonitorCard = (props: InvarMonitorCardProps) => {
  const thisWorkOrder = props.WorkOrder;
  const thisConveyance = props.Conveyance;
  return (
    <div className={"WOCard" + (thisWorkOrder.Poison ? " Poisoned" : "")}>
      <div className="WOCardTop">
        <div className="WOCardTop_WOID">
          <b>WOID</b>
          <span>{thisWorkOrder?.WorkOrderID?.toString() ?? ""}</span>
        </div>
        <div className="WOCardTop_ConveyanceName">
          <b>Conveyance</b>
          <span>{thisConveyance?.Name ?? ""}</span>
        </div>
        <div className="WOCardTop_Status">
          <b>Status</b>
          <span>
            {thisWorkOrder.Poison ? (
              "Poisoned"
            ) : thisWorkOrder.Cancelled ? (
              "Cancelled"
            ) : thisWorkOrder.Active ? (
              "In-Flight"
            ) : thisWorkOrder.Completed !== null ? (
              "Completed"
            ) : (
              <i>Ongoing</i>
            )}
          </span>
        </div>
      </div>
      <div className="WOCardBody">
        <span className="WOCardBody_InitialValue">
          {thisWorkOrder.InitialValue}
        </span>
      </div>
      <div className="WOCardBody">
        <span className="WOCardBody_ViewButton">
          {thisConveyance?.ConveyanceID === 92 ? (
            <a
              className="Mondrian_Button Split"
              type="button"
              href={
                "/AdminPortal/WorkflowSummary/" + thisWorkOrder.InitialValue
              }
              target="_blank"
            >
              <span className="Mondrian__Icon_Neutral Bump">⇗</span>
              <span>WF Summary</span>
            </a>
          ) : (
            <></>
          )}
          {props.ShowView === true ? (
            <a
              className="Mondrian_Button Split"
              type="button"
              href={
                "/AdminPortal/Invar/Monitor/" +
                thisWorkOrder.WorkOrderID.toString()
              }
              target="_blank"
            >
              <span className="Mondrian__Icon_Neutral Bump">⇗</span>
              <span>View</span>
            </a>
          ) : (
            <></>
          )}
          <div
            className="Mondrian_Button Split"
            onClick={() => {
              props.Archive(thisWorkOrder.WorkOrderID);
            }}
          >
            <span className="Mondrian__Icon_Neutral">🗑</span>
            <span>Archive</span>
          </div>
          <div
            className="Mondrian_Button Split"
            onClick={() => {
              props.Clone(thisWorkOrder.WorkOrderID);
            }}
          >
            <span className="Mondrian__Icon_Neutral Bump">🐑🐑</span>
            <span>Clone</span>
          </div>
          {!thisWorkOrder.Active &&
          thisWorkOrder.Completed === null &&
          !thisWorkOrder.Cancelled &&
          !thisWorkOrder.Poison ? (
            <div
              className="Mondrian_Button Split"
              onClick={() => {
                props.Activate(thisWorkOrder.WorkOrderID);
              }}
            >
              <span className="Mondrian__Icon_Neutral Bump">🗲</span>
              <span>Activate</span>
            </div>
          ) : (
            <></>
          )}
          <a
            type="button"
            className="Mondrian_Button Split"
            href={
              "/AdminPortal/Invar/Conveyance/" +
              thisWorkOrder?.ConveyanceID?.toString()
            }
            target="_blank"
          >
            <span className="Mondrian__Icon_Neutral Bump">⇗</span>
            <span>View Conveyance Structure</span>
          </a>
        </span>
      </div>
      <div className="WOCardTime">
        <div className="WOCardTime_CreatedBy">
          <b>Created By</b>
          <span>{thisWorkOrder.CreatedBy}</span>
        </div>
        <div className="WOCardTime_Enqueued">
          <b>Enqueued</b>
          <span>{thisWorkOrder.Created}</span>
        </div>
        <div className="WOCardTime_FromTo">
          <b>&gt;&gt;&gt;</b>
          <span>&gt;&gt;&gt;</span>
        </div>
        <div className="WOCardTime_Finished">
          <b>Finished</b>
          {thisWorkOrder.Completed === null ? (
            <i>Ongoing</i>
          ) : (
            <span>{thisWorkOrder.Completed}</span>
          )}
        </div>
        <div className="WOCardTime_Duration">
          <b>Duration</b>
          {thisWorkOrder.Completed === null ? (
            <i>Ongoing</i>
          ) : (
            <span>
              {dayjs(0)
                .utc()
                .add(
                  dayjs(thisWorkOrder.Completed)
                    .utc()
                    .diff(dayjs(thisWorkOrder.Created).utc()),
                  "millisecond",
                )
                .format("HH.mm.ss.SSS")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
