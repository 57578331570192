import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../../data/DataAccess";
import { Logging } from "../../../Logging";
// import "../../../assets/css/AOS2/AOS2.css";
import {
  ContactCard24Filled,
  Flowchart24Filled,
  Globe24Regular,
  Info24Regular,
  LearningApp24Filled,
  LockClosed24Filled,
  Money24Filled,
  Person24Filled,
} from "@fluentui/react-icons";
import { AgentSidebar } from "../Components/AgentSidebar";
import { UgApplicantChoicesAndOffers } from "../Components/UgApplicantChoicesAndOffers";
import { UgApplicantQualifications } from "../Components/UgApplicantQualifications";
import { UgApplicantDocuments } from "../Components/UgApplicantDocuments";
import { UgApplicantSidebarActions } from "../Components/UgApplicantSidebarActions";
import { useLoaderData } from "react-router-dom";

interface IProps {
  Logger: Logging;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  UcasNo: string;
  //Mode: string[];
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
}

interface IState {
  UploadModalVisible: boolean;
  UGApplicantUploader_Frame: string;
  UGApplicantUploader_Payload: string;
  UGApplicantPSEURL: string;
}

function AOS2_UG_APPLICANT(params): React.ReactElement {
  let applicantData: any = useLoaderData();
  return <AOS2_UG_APPLICANT_INNER {...params} UcasNo={applicantData.UcasNo} />;
}

class AOS2_UG_APPLICANT_INNER extends React.Component<IProps, IState> {
  constructor(params) {
    super(params);

    this.state = {
      UploadModalVisible: false,
      UGApplicantUploader_Frame: "",
      UGApplicantUploader_Payload: "",
      UGApplicantPSEURL: "",
    };
  }

  componentDidMount(): void {
    // if (!this.props.MODE_DEVELOPMENT) {
    this.getUGApplicantUploaderValues();
    this.getUGApplicantPSEURL();
    //}
  }

  render() {
    var PassToAllGrids = {
      Callouts: false,
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      ucasno: this.props.UcasNo,
      class: "UoE-Grid AOSScreen",
      Refresher: this.props.Refresher,
      imp: this.props.Alias,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <React.Fragment>
        <AgentSidebar>
          <UgApplicantSidebarActions
            UcasNo={this.props.UcasNo}
            ApplicantPSEURL={this.state.UGApplicantPSEURL}
            ShowUploadModal={this.ShowUploadModal}
          />
        </AgentSidebar>
        <div className="ResultsPanel UG">
          <div className="ApplicantGrid" id="HeaderTop">
            <div className="StudyLevelIdent UG">UG</div>
            <GridWrapper
              eid={"AOS_UG_HeaderTop_" + this.props.Refresher}
              {...PassToAllGrids}
              sproc="sputilities.webui.AOSv2_GetUGApplicantHeaderTop"
              params={["@ucasno", this.props.UcasNo]}
              output="data"
            ></GridWrapper>
          </div>
          <UgApplicantChoicesAndOffers
            UcasNo={this.props.UcasNo}
            Notices={this.props.Notices}
            Embargo={this.props.Embargo}
            {...PassToAllGrids}
          />

          <div className="ApplicantGrid">
            <Tabs selectedTabClassName="active">
              <TabList>
                <Tab>
                  <Person24Filled></Person24Filled> Information
                </Tab>
                <Tab>
                  <Money24Filled></Money24Filled> Fee Status
                </Tab>
                <Tab>
                  <ContactCard24Filled></ContactCard24Filled> Contact Details
                </Tab>
                <Tab>
                  {this.props.Embargo("UCAS") ? (
                    <LockClosed24Filled></LockClosed24Filled>
                  ) : (
                    <LearningApp24Filled></LearningApp24Filled>
                  )}{" "}
                  Qualifications
                </Tab>
                <Tab>
                  <LearningApp24Filled></LearningApp24Filled> Education
                </Tab>
                <Tab>
                  <Flowchart24Filled></Flowchart24Filled> Employment
                </Tab>
                <Tab>
                  <Flowchart24Filled></Flowchart24Filled> English Language
                </Tab>
                <Tab>
                  <Info24Regular></Info24Regular> Other Information
                </Tab>
                <Tab>
                  <Globe24Regular></Globe24Regular> CAS Information
                </Tab>
              </TabList>
              <TabPanel>
                <div id="FeeStatus">
                  <GridWrapper
                    eid={"AOS_UG_ApplicantInformation_" + this.props.Refresher}
                    {...PassToAllGrids}
                    class="UoE-Grid AOS"
                    sproc="sputilities.webui.AOSv2_GetUGApplicantHeader"
                    params={[
                      "@ucasno",
                      this.props.UcasNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                    rename="Nameheader,_N;app_origin,_AO"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="FeeStatus">
                  <GridWrapper
                    eid={"AOS_UG_FeeStatus_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetUGApplicantFeeStatus"
                    params={[
                      "@ucasno",
                      this.props.UcasNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="ContactDetails">
                  <GridWrapper
                    eid={"AOS_UG_ContactDetails_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetUGApplicantContacts"
                    params={[
                      "@ucasno",
                      this.props.UcasNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                    rename="E-mail,Email"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <UgApplicantQualifications
                  UcasNo={this.props.UcasNo}
                  MODE_DEVELOPMENT={this.props.MODE_DEVELOPMENT}
                  MODE_STAGING={this.props.MODE_STAGING}
                  MODE_LIVE={this.props.MODE_LIVE}
                  Notices={this.props.Notices}
                  Embargo={this.props.Embargo}
                  {...PassToAllGrids}
                />
              </TabPanel>
              <TabPanel>
                <div id="Education">
                  <GridWrapper
                    eid={"AOS_UG_Education_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetUGApplicantEducation"
                    params={[
                      "@ucasno",
                      this.props.UcasNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="Employment">
                  <GridWrapper
                    eid={"AOS_UG_Employment_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetUGApplicantEmployment"
                    params={[
                      "@ucasno",
                      this.props.UcasNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="EngLang">
                  <GridWrapper
                    eid={"AOS_UG_EnglishLanguage_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetUGApplicantEnglishLanguage"
                    params={[
                      "@ucasno",
                      this.props.UcasNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="OtherInformation">
                  <GridWrapper
                    eid={"AOS_UG_OtherInformation_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetUGApplicantOtherInfo"
                    params={[
                      "@ucasno",
                      this.props.UcasNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="CASInformation">
                  <h3>
                    <b>CAS Current Status</b>
                  </h3>
                  <GridWrapper
                    eid={"AOS_UG_CASInformation_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.Get_CAS_Tracker_Stage_Current"
                    params={["@ApplicationNo", this.props.UcasNo]}
                    output="data"
                  ></GridWrapper>
                  <p></p>
                  <h3>
                    <b>CAS History</b>
                  </h3>
                  <GridWrapper
                    eid={"AOS_UG_CASInformation_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.Get_CAS_Tracker_Stage_History"
                    params={["@ApplicationNo", this.props.UcasNo]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
            </Tabs>
          </div>

          <UgApplicantDocuments
            UcasNo={this.props.UcasNo}
            MODE_DEVELOPMENT={this.props.MODE_DEVELOPMENT}
            MODE_STAGING={this.props.MODE_STAGING}
            MODE_LIVE={this.props.MODE_LIVE}
            Notices={this.props.Notices}
            Embargo={this.props.Embargo}
            {...PassToAllGrids}
          />
        </div>
        <div
          className="UploaderModal"
          hidden={this.state.UploadModalVisible !== true}
        >
          <div className="modalContent">
            <span
              className="modalClose"
              onClick={() => {
                this.HideUploadModal();
              }}
            >
              &times;
            </span>
            <iframe
              src={
                this.state.UGApplicantUploader_Frame +
                "?payload=" +
                this.state.UGApplicantUploader_Payload
              }
            ></iframe>
          </div>
        </div>
      </React.Fragment>
    );
  }

  ShowUploadModal = () => {
    this.setState({ UploadModalVisible: true });
  };

  HideUploadModal = () => {
    this.setState({ UploadModalVisible: false });
  };

  private async getUGApplicantUploaderValues() {
    this.props.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.AOSv2_GetUGApplicantUploaderURL",
          params: [
            "@ucasno",
            this.props.UcasNo,
            "@username",
            // "{%u}",
            // "@agent",
            "{%iu}",
          ],
          imp: this.props.Alias,
        }),
      )
      .then(
        (data) => {
          var _error: string = "";
          if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
            _error = data[0][0]["ERROR"];
          } else if (data !== undefined) {
            if (data.length > 0) {
              if (data[0] !== undefined) {
                if (data[0].length === 1) {
                  this.setState({
                    UGApplicantUploader_Frame: data[0][0].Frame,
                    UGApplicantUploader_Payload: data[0][0].Payload,
                  });
                } else {
                  _error =
                    "AOSv2_GetUGApplicantUploaderURL - Data returned an unexpected number of rows.";
                }
              } else {
                _error =
                  "AOSv2_GetUGApplicantUploaderURL - Data returned a null singleton.";
              }
            }
            if (data.length <= 0) {
              _error =
                "AOSv2_GetUGApplicantUploaderURL - Data returned an unexpected number of tables.";
            }
            // } else {
            //   _error =
            //     "AOSv2_GetUGApplicantUploaderURL - Data returned undefined.";
            // Don't throw an error if data is undefined
            // The API will return an error value if there is a problem.
            // The main cause of this being undefined is the sproc being called
            // before impersonation has kicked in. For some reason waiting until
            // impersonation has kicked in is causing an infinite loop
            // so letting it run and accepting an empty response gracefully
            // is an easier option.
          }

          if (_error !== "") {
            this.props.RegisterError("AOSv2_GetUGApplicantUploaderURL", _error);
          }
        },
        (reason) => {
          this.props.RegisterError("AOSv2_GetUGApplicantUploaderURL", reason);
        },
      );
  }

  private async getUGApplicantPSEURL() {
    this.props.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.AOSv2_GetUGApplicantPSEURL",
          params: [
            "@ucasno",
            this.props.UcasNo,
            "@username",
            // "{%u}",
            // "@agent",
            "{%iu}",
          ],
          imp: this.props.Alias,
        }),
      )
      .then(
        (data) => {
          var _error: string = "";
          if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
            _error = data[0][0]["ERROR"];
          } else if (data !== undefined) {
            if (data.length > 0) {
              if (data[0] !== undefined) {
                if (data[0].length === 1) {
                  this.setState({ UGApplicantPSEURL: data[0][0].PSEUrl });
                } else {
                  _error =
                    "AOSv2_GetUGApplicantPSEURL - Data returned an unexpected number of rows.";
                }
              } else {
                _error =
                  "AOSv2_GetUGApplicantPSEURL - Data returned a null singleton.";
              }
            }
            if (data.length <= 0) {
              _error =
                "AOSv2_GetUGApplicantPSEURL - Data returned an unexpected number of tables.";
            }
            // } else {
            //   _error = "AOSv2_GetUGApplicantPSEURL - Data returned undefined.";
            // Don't throw an error if data is undefined
            // The API will return an error value if there is a problem.
            // The main cause of this being undefined is the sproc being called
            // before impersonation has kicked in. For some reason waiting until
            // impersonation has kicked in is causing an infinite loop
            // so letting it run and accepting an empty response gracefully
            // is an easier option.
          }

          if (_error !== "") {
            this.props.RegisterError("AOSv2_GetUGApplicantPSEURL", _error);
          }
        },
        (reason) => {
          this.props.RegisterError("AOSv2_GetUGApplicantPSEURL", reason);
        },
      );
  }
}

export default AOS2_UG_APPLICANT;
