import { ProtocolMode } from "@azure/msal-browser";
import { Logging, LoggingLevel } from "./Logging";

var SilentRequestTemplate = {
  prompt: "none",
  scopes: ["email", "openid", "profile", "User.Read", "Sites.Read.All"],
};

var RedirectRequestTemplate = {
  prompt: "select_account",
  scopes: ["email", "openid", "profile", "User.Read", "Sites.Read.All"],
};

var GraphAPIEndpoints = {
  sitesEndpoint: "https://graph.microsoft.com/v1.0/sites/",
};

const LOCAL_Logger = new Logging("LOCAL");
const DEV_Logger = new Logging("DEVELOPMENT");
const STAG_Logger = new Logging("STAGING");
const PROD_Logger = new Logging("LIVE");

var LOCAL_LoggerCB = (level: LoggingLevel, message: string, containsPii) => {
  if (!containsPii) {
    LOCAL_Logger.log(message, level);
  }
};

var DEV_LoggerCB = (level: LoggingLevel, message: string, containsPii) => {
  if (!containsPii) {
    DEV_Logger.log(message, level);
  }
};

var STAG_LoggerCB = (level: LoggingLevel, message: string, containsPii) => {
  if (!containsPii) {
    STAG_Logger.log(message, level);
  }
};

var PROD_LoggerCB = (level: LoggingLevel, message: string, containsPii) => {
  if (!containsPii) {
    PROD_Logger.log(message, level);
  }
};

export const LIVE_AOS_msalConfig = {
  auth: {
    clientId: "39928ad9-0915-4653-828c-2a4d99009ead",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://agents.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        PROD_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const STAG_AOS_msalConfig = {
  auth: {
    clientId: "39928ad9-0915-4653-828c-2a4d99009ead",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://agentstest.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        STAG_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const DEV_AOS_msalConfig = {
  auth: {
    clientId: "39928ad9-0915-4653-828c-2a4d99009ead",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://agentsdev.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        DEV_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const LOCAL_AOS_msalConfig = {
  auth: {
    clientId: "39928ad9-0915-4653-828c-2a4d99009ead",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "http://localhost:6227/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        LOCAL_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const LIVE_EAF_msalConfig = {
  auth: {
    clientId: "f749f7b4-d623-4ac2-9718-32beb4a05225",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://eaf.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        PROD_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const STAG_EAF_msalConfig = {
  auth: {
    clientId: "f749f7b4-d623-4ac2-9718-32beb4a05225",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://eaftest.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        STAG_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const DEV_EAF_msalConfig = {
  auth: {
    clientId: "f749f7b4-d623-4ac2-9718-32beb4a05225",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://eafdev.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        DEV_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const LOCAL_EAF_msalConfig = {
  auth: {
    clientId: "f749f7b4-d623-4ac2-9718-32beb4a05225",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "http://localhost:6227/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        LOCAL_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const LIVE_ECF_msalConfig = {
  auth: {
    clientId: "c4bff3da-7464-428d-99d1-62562993db63",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://ecf.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        PROD_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const STAG_ECF_msalConfig = {
  auth: {
    clientId: "c4bff3da-7464-428d-99d1-62562993db63",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://ecftest.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        STAG_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const DEV_ECF_msalConfig = {
  auth: {
    clientId: "c4bff3da-7464-428d-99d1-62562993db63",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://ecfdev.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        DEV_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const LOCAL_ECF_msalConfig = {
  auth: {
    clientId: "c4bff3da-7464-428d-99d1-62562993db63",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "http://localhost:6227/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        LOCAL_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const LIVE_ESF_msalConfig = {
  auth: {
    clientId: "20cfc777-bea5-45f7-aec3-f2f10bfb29f9",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://esf.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        PROD_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const STAG_ESF_msalConfig = {
  auth: {
    clientId: "20cfc777-bea5-45f7-aec3-f2f10bfb29f9",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://esftest.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        STAG_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const DEV_ESF_msalConfig = {
  auth: {
    clientId: "20cfc777-bea5-45f7-aec3-f2f10bfb29f9",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "https://esfdev.essex.ac.uk/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        DEV_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const LOCAL_ESF_msalConfig = {
  auth: {
    clientId: "20cfc777-bea5-45f7-aec3-f2f10bfb29f9",
    authority:
      "https://login.microsoftonline.com/a129f8cb-49b0-4d6b-8d79-889050da9198/",
    redirectUri: "http://localhost:6227/",
    protocolMode: ProtocolMode.AAD,
  },
  cacheOptions: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        LOCAL_LoggerCB(level, message, containsPii);
      },
    },
  },
};

export const LIVE_AOS_graphConfig = GraphAPIEndpoints;
export const STAG_AOS_graphConfig = GraphAPIEndpoints;
export const DEV_AOS_graphConfig = GraphAPIEndpoints;
export const LOCAL_AOS_graphConfig = GraphAPIEndpoints;
export const LIVE_EAF_graphConfig = GraphAPIEndpoints;
export const STAG_EAF_graphConfig = GraphAPIEndpoints;
export const DEV_EAF_graphConfig = GraphAPIEndpoints;
export const LOCAL_EAF_graphConfig = GraphAPIEndpoints;
export const LIVE_ECF_graphConfig = GraphAPIEndpoints;
export const STAG_ECF_graphConfig = GraphAPIEndpoints;
export const DEV_ECF_graphConfig = GraphAPIEndpoints;
export const LOCAL_ECF_graphConfig = GraphAPIEndpoints;
export const LIVE_ESF_graphConfig = GraphAPIEndpoints;
export const STAG_ESF_graphConfig = GraphAPIEndpoints;
export const DEV_ESF_graphConfig = GraphAPIEndpoints;
export const LOCAL_ESF_graphConfig = GraphAPIEndpoints;

export const LIVE_AOS_graphRequest_Silent = SilentRequestTemplate;
export const STAG_AOS_graphRequest_Silent = SilentRequestTemplate;
export const DEV_AOS_graphRequest_Silent = SilentRequestTemplate;
export const LOCAL_AOS_graphRequest_Silent = SilentRequestTemplate;
export const LIVE_EAF_graphRequest_Silent = SilentRequestTemplate;
export const STAG_EAF_graphRequest_Silent = SilentRequestTemplate;
export const DEV_EAF_graphRequest_Silent = SilentRequestTemplate;
export const LOCAL_EAF_graphRequest_Silent = SilentRequestTemplate;
export const LIVE_ECF_graphRequest_Silent = SilentRequestTemplate;
export const STAG_ECF_graphRequest_Silent = SilentRequestTemplate;
export const DEV_ECF_graphRequest_Silent = SilentRequestTemplate;
export const LOCAL_ECF_graphRequest_Silent = SilentRequestTemplate;
export const LIVE_ESF_graphRequest_Silent = SilentRequestTemplate;
export const STAG_ESF_graphRequest_Silent = SilentRequestTemplate;
export const DEV_ESF_graphRequest_Silent = SilentRequestTemplate;
export const LOCAL_ESF_graphRequest_Silent = SilentRequestTemplate;

export const LIVE_AOS_graphRequest_Redirect = RedirectRequestTemplate;
export const STAG_AOS_graphRequest_Redirect = RedirectRequestTemplate;
export const DEV_AOS_graphRequest_Redirect = RedirectRequestTemplate;
export const LOCAL_AOS_graphRequest_Redirect = RedirectRequestTemplate;
export const LIVE_EAF_graphRequest_Redirect = RedirectRequestTemplate;
export const STAG_EAF_graphRequest_Redirect = RedirectRequestTemplate;
export const DEV_EAF_graphRequest_Redirect = RedirectRequestTemplate;
export const LOCAL_EAF_graphRequest_Redirect = RedirectRequestTemplate;
export const LIVE_ECF_graphRequest_Redirect = RedirectRequestTemplate;
export const STAG_ECF_graphRequest_Redirect = RedirectRequestTemplate;
export const DEV_ECF_graphRequest_Redirect = RedirectRequestTemplate;
export const LOCAL_ECF_graphRequest_Redirect = RedirectRequestTemplate;
export const LIVE_ESF_graphRequest_Redirect = RedirectRequestTemplate;
export const STAG_ESF_graphRequest_Redirect = RedirectRequestTemplate;
export const DEV_ESF_graphRequest_Redirect = RedirectRequestTemplate;
export const LOCAL_ESF_graphRequest_Redirect = RedirectRequestTemplate;
