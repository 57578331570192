import { PublicClientApplication } from "@azure/msal-browser";
import React from "react";
import { DataAccess, User } from "../../../data/DataAccess";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../../Logging";

import "../../../assets/css/AOS2/AOS2_ListPage.css";
import { AgentSidebar } from "../Components/AgentSidebar";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  AgentName: string;
  AgentEmail: string;
  AgentReference: string;
  AgentContactName: string;
  AgentAddress: string;
  AgentCountry: string;
  AgentIsPAS: boolean;
}

class AOS2_UG_ARCHIVE extends React.Component<IProps> {
  render() {
    var PassToGrid = {
      Callouts: false,
      prid: this.props.Mode[1],
      class: "UoE-Grid AOS-AppList",
    };

    return (
      <React.Fragment>
        <AgentSidebar />
        <div className="ResultsPanel UG">
          <h2>Your Archived Undergraduate Applicants</h2>
          <GridWrapper
            {...this.props}
            {...PassToGrid}
            eid={"AOS_UG_Archive_" + this.props.Refresher}
            sproc="sputilities.webui.AOSv2_GetUGApplicantsArchive"
            params={[
              "@username",
              // "{%u}",
              // "@agent",
              "{%iu}",
            ]}
          ></GridWrapper>
        </div>
      </React.Fragment>
    );
  }
}

export default AOS2_UG_ARCHIVE;
