import { Logging, LoggingLevel } from "../Logging";
import {
  AuthenticationResult,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { MODE } from "./DataAccess";
import { CheckForReuploadResponse } from "./InvarDataModels/CheckForReuploadResponse";
import { CheckForReuploadRequest } from "./InvarDataModels/CheckForReuploadRequest";
import { ConveyanceInputResponse } from "./InvarDataModels/ConveyanceInputResponse";
import { ConveyanceSegmentConfigResponse } from "./InvarDataModels/ConveyanceSegmentConfigResponse";
import { GetWorkOrderResponse } from "./InvarDataModels/GetWorkOrderResponse";
import { ActionResponse } from "./InvarDataModels/ActionResponse";
import { CreateActionRequest } from "./InvarDataModels/CreateActionRequest";
import { GetMicroWorkOrdersRequest } from "./InvarDataModels/GetMicroWorkOrdersRequest";
import { GetMicroWorkOrdersResponse } from "./InvarDataModels/GetMicroWorkOrdersResponse";
import { GetWorkOrdersRequest } from "./InvarDataModels/GetWorkOrdersRequest";
import { CreateBrokerageRequest } from "./InvarDataModels/CreateBrokerageRequest";
import { BrokerageResponse } from "./InvarDataModels/BrokerageResponse";
import { CreateConveyanceRequest } from "./InvarDataModels/CreateConveyanceRequest";
import { ConveyanceResponse } from "./InvarDataModels/ConveyanceResponse";
import { ConveyanceSegmentResponse } from "./InvarDataModels/ConveyanceSegmentResponse";
import { CreateConveyanceSegmentRequest } from "./InvarDataModels/CreateConveyanceSegmentRequest";
import { CreateConveyanceSegmentConfigRequest } from "./InvarDataModels/CreateConveyanceSegmentConfigRequest";
import { UpdateActionRequest } from "./InvarDataModels/UpdateActionRequest";
import { UpdateConveyanceRequest } from "./InvarDataModels/UpdateConveyanceRequest";
import { UpdateConveyanceSegmentConfigRequest } from "./InvarDataModels/UpdateConveyanceSegmentConfigRequest";
import { UpdateConveyanceSegmentRequest } from "./InvarDataModels/UpdateConveyanceSegmentRequest";
import { AddDataToWorkOrderRequest } from "./InvarDataModels/AddDataToWorkOrderRequest";
import {
  EnqueueMetadataRequest,
  EnqueueRequest,
} from "./InvarDataModels/EnqueueRequest";
import { BasicWorkOrderRequest } from "./InvarDataModels/BasicWorkOrderRequest";
import { GetMergedWorkOrderResponse } from "./InvarDataModels/GetMergedWorkOrderResponse";
import { GetMergedWorkOrdersRequest } from "./InvarDataModels/GetMergedWorkOrdersRequest";
import { NamedObject } from "./InvarDataModels/NamedObject";
import { MonitorWorkOrdersRequest } from "./InvarDataModels/MonitorWorkOrdersRequest";
import { MonitoredWorkOrderStepResponse } from "./InvarDataModels/MonitoredWorkOrderStepResponse";
import { MonitoredWorkOrderDatumResponse } from "./InvarDataModels/MonitoredWorkOrderDatumResponse";
import { MonitoredWorkOrderEmailResponse } from "./InvarDataModels/MonitoredWorkOrderEmailResponse";
import { MonitoredWorkOrderExceptionResponse } from "./InvarDataModels/MonitoredWorkOrderExceptionResponse";
import { MonitoredWorkOrderResponse } from "./InvarDataModels/MonitoredWorkOrderResponse";
import { GetWorkOrderDatumResponse } from "./InvarDataModels/GetWorkOrderDatumResponse";
import { UpdateWorkOrderDatumRequest } from "./InvarDataModels/UpdateWorkOrderDatumRequest";

export enum InvarDomain {
  Admin,
  User,
  Utility,
}

export interface IParams {
  sproc: string;
  params?: string[];
  user?: string;
  imp?: string;

  pgno?: string;
  ucasno?: string;
  prid?: string;
  contentTypes?: string[];
  blackList?: boolean;
  location?: string;
  rename?: string;
  start?: number;
  limit?: number;
  columns?: string;
  selectcolumns?: string;
  sortBy?: string[][];
  sortByDesc?: boolean[][];
  filterBy?: string[][][];
  options?: string;

  HomeOfficeAudit?: boolean;
  spvals?: string[];

  forceProxy?: boolean;
}

export class InvarDataAccess {
  // Server URL Config
  private URLLive: string = "https://edrmapi.essex.ac.uk/invar";
  private URLStaging: string = "https://edrmapitest.essex.ac.uk/invar";
  private URLDevelopment: string = "https://edrmapitest.essex.ac.uk/invar";
  private URLLocal: string = "https://localhost:7176";
  private mode: MODE;

  /*
    #############################
    ## ┏┓     ┳┓     ┓┏┓┏┓┏┓┏┓ ##  [o.o] - i'm a funcy robot
    ## ┣ ┓┏┏┓┏┣┫┏┓╋  ┃┃┫┃┫┃┫┃┫ ##  -[#]-
    ## ┻ ┗┻┛┗┗┻┛┗┛┗  ┻┗┛┗┛┗┛┗┛ ##  _| |_
    #############################
    ## But why, Jason? Oh God, why?
    ##
    ## Predefining all of our API functions like this
    ##   lets us use TypeScript's rigid typing to
    ##   have API changes propagate out from FuncBot.
    ##
    ## Changes applied this way will automatically:
    ## 1 - propagate as far as they can to where-ever
    ##       they are being used in the codebase.
    ## 2 - highlight incompatibilities in
    ##       precompilation, before they turn into
    ##       :any issues in the wild.
    ##
    ## See the GetActions() function for details.
  */
  FuncBot = {
    CreateAction: {
      Domain: InvarDomain.Admin,
      Endpoint: "Actions/Create",
      Verb: "POST",
      RequestType: CreateActionRequest,
      ResponseType: ActionResponse,
    },
    CreateBrokerage: {
      Domain: InvarDomain.Admin,
      Endpoint: "Brokerages/Create",
      Verb: "POST",
      RequestType: CreateBrokerageRequest,
      ResponseType: BrokerageResponse,
    },
    CreateConveyance: {
      Domain: InvarDomain.Admin,
      Endpoint: "Conveyances/Create",
      Verb: "POST",
      RequestType: CreateConveyanceRequest,
      ResponseType: ConveyanceResponse,
    },
    CreateConveyanceSegment: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegments/Create",
      Verb: "POST",
      RequestType: CreateConveyanceSegmentRequest,
      ResponseType: ConveyanceSegmentResponse,
    },
    CreateConveyanceSegmentConfig: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegmentConfigs/Create",
      Verb: "POST",
      RequestType: CreateConveyanceSegmentConfigRequest,
      ResponseType: ConveyanceSegmentConfigResponse,
    },
    DeleteBrokerage: {
      Domain: InvarDomain.Admin,
      Endpoint: "Brokerages/{BrokerageID}",
      Verb: "DELETE",
      RequestType: undefined,
      ResponseType: Boolean,
    },
    DeleteConveyanceSegment: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegments/{ConveyanceSegmentID}",
      Verb: "DELETE",
      RequestType: undefined,
      ResponseType: Boolean,
    },
    DeleteConveyanceSegmentConfig: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegmentConfigs/{ConveyanceSegmentConfigID}",
      Verb: "DELETE",
      RequestType: undefined,
      ResponseType: Boolean,
    },
    DisableBrokerage: {
      Domain: InvarDomain.Admin,
      Endpoint: "Brokerages/{BrokerageID}/Disable",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: BrokerageResponse,
    },
    DisableConveyance: {
      Domain: InvarDomain.Admin,
      Endpoint: "Conveyances/{ConveyanceID}/Disable",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: ConveyanceResponse,
    },
    EnableBrokerage: {
      Domain: InvarDomain.Admin,
      Endpoint: "Brokerages/{BrokerageID}/Enable",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: BrokerageResponse,
    },
    EnableConveyance: {
      Domain: InvarDomain.Admin,
      Endpoint: "Conveyances/{ConveyanceID}/Enable",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: ConveyanceResponse,
    },
    GetActions: {
      Domain: InvarDomain.Admin,
      Endpoint: "Actions",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<ActionResponse>,
    },
    GetBrokerages: {
      Domain: InvarDomain.Admin,
      Endpoint: "Brokerages",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<BrokerageResponse>,
    },
    GetBrokeragesForConveyance: {
      Domain: InvarDomain.Admin,
      Endpoint: "Conveyances/{ConveyanceID}/Brokerages",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<BrokerageResponse>,
    },
    GetBrokeragesForMachine: {
      Domain: InvarDomain.Admin,
      Endpoint: "Machine/{MachineName}/Brokerages",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<BrokerageResponse>,
    },
    GetConveyance: {
      Domain: InvarDomain.Admin,
      Endpoint: "Conveyances/{ConveyanceID}",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: ConveyanceResponse,
    },
    GetConveyances: {
      Domain: InvarDomain.Admin,
      Endpoint: "Conveyances",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<ConveyanceResponse>,
    },
    GetConveyanceSegment: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegments/{ConveyanceSegmentID}",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: ConveyanceSegmentResponse,
    },
    GetConveyanceSegments: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegments",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<ConveyanceSegmentResponse>,
    },
    GetConveyanceSegmentsForConveyance: {
      Domain: InvarDomain.Admin,
      Endpoint: "Conveyances/{ConveyanceID}/ConveyanceSegments",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<ConveyanceSegmentResponse>,
    },
    GetConveyanceSegmentConfig: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegmentConfigs/{ConveyanceSegmentConfigID}",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: ConveyanceSegmentConfigResponse,
    },
    GetConveyanceSegmentConfigs: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegmentConfigs",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<ConveyanceSegmentConfigResponse>,
    },
    GetConveyanceSegmentConfigsForConveyance: {
      Domain: InvarDomain.Admin,
      Endpoint: "Conveyances/{ConveyanceID}/ConveyanceSegmentConfigs",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<ConveyanceSegmentConfigResponse>,
    },
    GetConveyanceSegmentConfigsForConveyanceSegment: {
      Domain: InvarDomain.Admin,
      Endpoint:
        "ConveyanceSegments/{ConveyanceSegmentID}/ConveyanceSegmentConfigs",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<ConveyanceSegmentConfigResponse>,
    },
    PromoteConveyanceSegment: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegments/{ConveyanceSegmentID}/Promote",
      Verb: "PATCH",
      RequestType: undefined,
      ResponseType: Array<ConveyanceSegmentResponse>,
    },
    DemoteConveyanceSegment: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegments/{ConveyanceSegmentID}/Demote",
      Verb: "PATCH",
      RequestType: undefined,
      ResponseType: Array<ConveyanceSegmentResponse>,
    },
    UpdateAction: {
      Domain: InvarDomain.Admin,
      Endpoint: "Actions/{ActionID}/Update",
      Verb: "PATCH",
      RequestType: UpdateActionRequest,
      ResponseType: ActionResponse,
    },
    UpdateConveyance: {
      Domain: InvarDomain.Admin,
      Endpoint: "Conveyances/{ConveyanceID}/Update",
      Verb: "PATCH",
      RequestType: UpdateConveyanceRequest,
      ResponseType: ConveyanceResponse,
    },
    UpdateConveyanceSegment: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegments/{ConveyanceSegmentID}/Update",
      Verb: "PATCH",
      RequestType: UpdateConveyanceSegmentRequest,
      ResponseType: ConveyanceSegmentResponse,
    },
    UpdateConveyanceSegmentConfig: {
      Domain: InvarDomain.Admin,
      Endpoint: "ConveyanceSegmentConfigs/{ConveyanceSegmentConfigID}/Update",
      Verb: "PATCH",
      RequestType: UpdateConveyanceSegmentConfigRequest,
      ResponseType: ConveyanceSegmentConfigResponse,
    },
    UpdateWorkOrderDatum: {
      Domain: InvarDomain.Admin,
      Endpoint: "WorkOrders/{WorkOrderID}/UpdateData",
      Verb: "PATCH",
      RequestType: UpdateWorkOrderDatumRequest,
      ResponseType: MonitoredWorkOrderDatumResponse,
    },
    GetMachines: {
      Domain: InvarDomain.Admin,
      Endpoint: "Machines",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<String>,
    },
    CreateMachine: {
      Domain: InvarDomain.Admin,
      Endpoint: "Machine/{MachineName}/Create",
      Verb: "PUT",
      RequestType: undefined,
      ResponseType: Array<String>,
    },
    DeleteMachine: {
      Domain: InvarDomain.Admin,
      Endpoint: "Machine/{MachineName}/Delete",
      Verb: "DELETE",
      RequestType: undefined,
      ResponseType: Array<String>,
    },
    CheckForReupload: {
      Domain: InvarDomain.User,
      Endpoint: "CheckForReupload",
      Verb: "POST",
      RequestType: CheckForReuploadRequest,
      ResponseType: CheckForReuploadResponse,
    },

    GetMergedWorkOrders: {
      Domain: InvarDomain.User,
      Endpoint: "WorkOrders/GetMerged",
      Verb: "POST",
      RequestType: GetMergedWorkOrdersRequest,
      ResponseType: Array<GetMergedWorkOrderResponse>,
    },

    GetAllWorkOrdersForUser: {
      Domain: InvarDomain.User,
      Endpoint: "User/Uploads/All",
      Verb: "POST",
      RequestType: GetWorkOrdersRequest,
      ResponseType: Array<GetWorkOrderResponse>,
    },
    GetMicroWorkOrdersForUser: {
      Domain: InvarDomain.User,
      Endpoint: "User/Uploads/All/Micro",
      Verb: "POST",
      RequestType: GetMicroWorkOrdersRequest,
      ResponseType: GetMicroWorkOrdersResponse,
    },
    GetFailedWorkOrdersForUser: {
      Domain: InvarDomain.User,
      Endpoint: "User/Uploads/Failed",
      Verb: "POST",
      RequestType: GetWorkOrdersRequest,
      ResponseType: Array<GetWorkOrderResponse>,
    },
    GetCompletedWorkOrdersForUser: {
      Domain: InvarDomain.User,
      Endpoint: "User/Uploads/Completed",
      Verb: "POST",
      RequestType: GetWorkOrdersRequest,
      ResponseType: Array<GetWorkOrderResponse>,
    },
    GetInFlightWorkOrdersForUser: {
      Domain: InvarDomain.User,
      Endpoint: "User/Uploads/InFlight",
      Verb: "POST",
      RequestType: GetWorkOrdersRequest,
      ResponseType: Array<GetWorkOrderResponse>,
    },

    GetConveyanceByName: {
      Domain: InvarDomain.Utility,
      Endpoint: "Conveyances/ByName/{ConveyanceName}",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: ConveyanceResponse,
    },
    GetConveyanceInputs: {
      Domain: InvarDomain.Utility,
      Endpoint: "Conveyances/{ConveyanceID}/ExpectedInputs",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<ConveyanceInputResponse>,
    },
    RefireWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "User/Uploads/Refire",
      Verb: "POST",
      RequestType: BasicWorkOrderRequest,
      ResponseType: GetWorkOrderResponse,
    },
    CancelWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "User/Uploads/Cancel",
      Verb: "POST",
      RequestType: BasicWorkOrderRequest,
      ResponseType: GetWorkOrderResponse,
    },
    Enqueue: {
      Domain: InvarDomain.Utility,
      Endpoint: "Conveyances/Enqueue",
      Verb: "POST",
      RequestType: EnqueueRequest,
      ResponseType: GetWorkOrderResponse,
    },
    GetExpectedInputsForConveyanceID: {
      Domain: InvarDomain.Utility,
      Endpoint: "Conveyances/{ConveyanceID}/ExpectedInputs",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Array<ConveyanceInputResponse>,
    },
    ActivateWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "WorkOrders/Activate",
      Verb: "POST",
      RequestType: BasicWorkOrderRequest,
      ResponseType: GetWorkOrderResponse,
    },
    GetWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "WorkOrders/Get",
      Verb: "POST",
      RequestType: BasicWorkOrderRequest,
      ResponseType: GetWorkOrderResponse,
    },
    AddDataToWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "WorkOrders/Add",
      Verb: "POST",
      RequestType: AddDataToWorkOrderRequest,
      ResponseType: GetWorkOrderResponse,
    },
    ValidateAnyIdent: {
      Domain: InvarDomain.Utility,
      Endpoint: "Validate/{Types}/{Ident}",
      Verb: "GET",
      RequestType: undefined,
      ResponseType: Boolean,
    },

    MonitorWorkOrders: {
      Domain: InvarDomain.User,
      Endpoint: "Monitor/WorkOrders",
      Verb: "POST",
      RequestType: MonitorWorkOrdersRequest,
      ResponseType: Array<MonitoredWorkOrderResponse>,
    },
    MonitorThisWorkOrder: {
      Domain: InvarDomain.User,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: MonitoredWorkOrderResponse,
    },
    MonitorThisWorkOrderData: {
      Domain: InvarDomain.User,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}/Data",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: Array<MonitoredWorkOrderDatumResponse>,
    },
    MonitorThisWorkOrderSteps: {
      Domain: InvarDomain.User,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}/Steps",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: Array<MonitoredWorkOrderStepResponse>,
    },
    MonitorThisWorkOrderExceptions: {
      Domain: InvarDomain.User,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}/Exceptions",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: Array<MonitoredWorkOrderExceptionResponse>,
    },
    MonitorThisWorkOrderEmails: {
      Domain: InvarDomain.User,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}/Emails",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: Array<MonitoredWorkOrderEmailResponse>,
    },
    RefireMonitoredWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}/Refire",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: MonitoredWorkOrderResponse,
    },
    CancelMonitoredWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}/Cancel",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: MonitoredWorkOrderResponse,
    },
    ArchiveMonitoredWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}/Archive",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: MonitoredWorkOrderResponse,
    },
    ActivateMonitoredWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}/Activate",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: MonitoredWorkOrderResponse,
    },
    CloneMonitoredWorkOrder: {
      Domain: InvarDomain.Utility,
      Endpoint: "Monitor/WorkOrders/{WorkOrderID}/Clone",
      Verb: "POST",
      RequestType: undefined,
      ResponseType: MonitoredWorkOrderResponse,
    },
    // ##################################################
    // ## NOTE: These are not for frontend use.        ##
    // ##   EDocs uses these endpoints to merge recent ##
    // ##   Invar uploads into edocs grid views.       ##
    // ##################################################
    //  GetRecentUploadsForPGNo: {
    //    Domain: InvarDomain.Utility,
    //    Endpoint: "RecentUploads/PG/{PGNo}",
    //    Verb: "GET",
    //  },
    //  GetRecentUploadsForUCASDA: {
    //    Domain: InvarDomain.Utility,
    //    Endpoint: "RecentUploads/UG/{UCASDA}",
    //    Verb: "GET",
    //  },
  };

  // it's just a one minute buffer but it _vastly_ reduces load time)
  BufferedToken: {
    Timestamp: Date;
    TK: AuthenticationResult;
  };

  private URL(): string {
    return this.mode === MODE.LOCAL
      ? this.URLLocal
      : this.mode === MODE.DEVELOPMENT
        ? this.URLDevelopment
        : this.mode === MODE.STAGING
          ? this.URLStaging
          : this.mode === MODE.PRODUCTION
            ? this.URLLive
            : "";
  }

  private msal: IPublicClientApplication;
  private Logger: Logging;

  constructor(
    _logger: Logging,
    _msal_PCA: IPublicClientApplication,
    currentMode: MODE,
  ) {
    this.Logger = _logger;
    this.msal = _msal_PCA;
    this.mode = currentMode;

    this.Logger.log("URL [INVAR API]: " + this.URL(), LoggingLevel.INFO);
  }

  public async CreateAction(
    Name: string,
    Description: string,
  ): Promise<ActionResponse> {
    // FuncBot gives us our endpoint. Thank you, FuncBot.
    const endpoint = this.URL() + "/" + this.FuncBot.CreateAction.Endpoint;

    try {
      // Get the function's Request Type from FuncBot.
      //   This lets us force cast our outgoing object to the right struct now,
      //   rather than get hit by :any problems when endpoints change.
      // We cannot use var foo:<variable>,
      //   but var foo:typeof variable.prototype is (thankfully) the same thing.
      const reqtype = this.FuncBot.CreateAction.RequestType.prototype;
      let _body: typeof reqtype = {
        Name: Name,
        Description: Description,
      };
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        // thank you FuncBot
        method: this.FuncBot.CreateAction.Verb,
        body: JSON.stringify(_body),
      });

      // Get the function's Response Type from FuncBot.
      //   This lets us force cast our incoming data to the right struct now,
      //   rather than get hit by :any problems in the app at large.
      // Notably, if we don't do this here, mismatching object properties
      //   can and will get through implicit casting. Better to hard error now
      //   than soft error at some unknown point in code.
      // We cannot use var foo:<variable>,
      //   but var foo:typeof variable.prototype is (thankfully) the same thing.
      const rsptype = this.FuncBot.CreateAction.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      // if boom then boom
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async CreateBrokerage(
    Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">,
    MachineName: string,
    ConveyanceID: number,
  ): Promise<BrokerageResponse> {
    const endpoint = this.URL() + "/" + this.FuncBot.CreateBrokerage.Endpoint;
    try {
      const reqtype = this.FuncBot.CreateBrokerage.RequestType.prototype;
      let _body: typeof reqtype = {
        Environment: Environment,
        MachineName: MachineName,
        ConveyanceID: ConveyanceID,
      };
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.CreateBrokerage.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.CreateBrokerage.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async CreateConveyance(
    Name: string,
    From: string,
    To: string,
    Description: string,
  ): Promise<ConveyanceResponse> {
    const endpoint = this.URL() + "/" + this.FuncBot.CreateConveyance.Endpoint;
    try {
      const reqtype = this.FuncBot.CreateConveyance.RequestType.prototype;
      let _body: typeof reqtype = {
        Name: Name,
        From: From,
        To: To,
        Description: Description,
      };
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.CreateConveyance.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.CreateConveyance.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async CreateConveyanceSegment(
    ConveyanceID: number,
    SegmentName: string,
    Ordinal: number,
    ActionID: number,
  ): Promise<ConveyanceSegmentResponse> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.CreateConveyanceSegment.Endpoint;
    try {
      const reqtype =
        this.FuncBot.CreateConveyanceSegment.RequestType.prototype;
      let _body: typeof reqtype = {
        ConveyanceID: ConveyanceID,
        SegmentName: SegmentName,
        Ordinal: Ordinal,
        ActionID: ActionID,
      };
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.CreateConveyanceSegment.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype =
        this.FuncBot.CreateConveyanceSegment.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async CreateConveyanceSegmentConfig(
    ConveyanceSegmentID: number,
    ConfigName: string,
    ConfigValue: string,
  ): Promise<ConveyanceSegmentConfigResponse> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.CreateConveyanceSegmentConfig.Endpoint;
    try {
      const reqtype =
        this.FuncBot.CreateConveyanceSegmentConfig.RequestType.prototype;
      let _body: typeof reqtype = {
        ConveyanceSegmentID: ConveyanceSegmentID,
        ConfigName: ConfigName,
        ConfigValue: ConfigValue,
      };
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.CreateConveyanceSegmentConfig.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype =
        this.FuncBot.CreateConveyanceSegmentConfig.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async DeleteBrokerage(BrokerageID: Number): Promise<Boolean> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.DeleteBrokerage.Endpoint.replace(
        "{BrokerageID}",
        BrokerageID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.DeleteBrokerage.Verb,
      });

      const rsptype = this.FuncBot.DeleteBrokerage.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async DeleteConveyanceSegment(
    ConveyanceSegmentID: Number,
  ): Promise<Boolean> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.DeleteConveyanceSegment.Endpoint.replace(
        "{ConveyanceSegmentID}",
        ConveyanceSegmentID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.DeleteConveyanceSegment.Verb,
      });

      const rsptype =
        this.FuncBot.DeleteConveyanceSegment.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async DeleteConveyanceSegmentConfig(
    ConveyanceSegmentConfigID: Number,
  ): Promise<Boolean> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.DeleteConveyanceSegmentConfig.Endpoint.replace(
        "{ConveyanceSegmentConfigID}",
        ConveyanceSegmentConfigID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.DeleteConveyanceSegmentConfig.Verb,
      });

      const rsptype =
        this.FuncBot.DeleteConveyanceSegmentConfig.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async DisableBrokerage(
    BrokerageID: Number,
  ): Promise<BrokerageResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.DisableBrokerage.Endpoint.replace(
        "{BrokerageID}",
        BrokerageID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.DisableBrokerage.Verb,
      });

      const rsptype = this.FuncBot.DisableBrokerage.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async DisableConveyance(
    ConveyanceID: Number,
  ): Promise<ConveyanceResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.DisableConveyance.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.DisableConveyance.Verb,
      });

      const rsptype = this.FuncBot.DisableConveyance.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async EnableBrokerage(
    BrokerageID: Number,
  ): Promise<BrokerageResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.EnableBrokerage.Endpoint.replace(
        "{BrokerageID}",
        BrokerageID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.EnableBrokerage.Verb,
      });

      const rsptype = this.FuncBot.EnableBrokerage.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async EnableConveyance(
    ConveyanceID: Number,
  ): Promise<ConveyanceResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.EnableConveyance.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.EnableConveyance.Verb,
      });

      const rsptype = this.FuncBot.EnableConveyance.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetActions(): Promise<ActionResponse[]> {
    const endpoint = this.URL() + "/" + this.FuncBot.GetActions.Endpoint;
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetActions.Verb,
      });

      const rsptype = this.FuncBot.GetActions.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetBrokerages(): Promise<BrokerageResponse[]> {
    const endpoint = this.URL() + "/" + this.FuncBot.GetBrokerages.Endpoint;
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetBrokerages.Verb,
      });

      const rsptype = this.FuncBot.GetBrokerages.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetBrokeragesForConveyance(
    ConveyanceID: Number,
  ): Promise<BrokerageResponse[]> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetBrokeragesForConveyance.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetBrokeragesForConveyance.Verb,
      });

      const rsptype =
        this.FuncBot.GetBrokeragesForConveyance.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetBrokeragesForMachine(
    MachineName: string,
  ): Promise<BrokerageResponse[]> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetBrokeragesForMachine.Endpoint.replace(
        "{MachineName}",
        MachineName,
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetBrokeragesForMachine.Verb,
      });

      const rsptype =
        this.FuncBot.GetBrokeragesForMachine.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyance(
    ConveyanceID: Number,
  ): Promise<ConveyanceResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetConveyance.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyance.Verb,
      });

      const rsptype = this.FuncBot.GetConveyance.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyanceByName(
    ConveyanceName: string,
  ): Promise<ConveyanceResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetConveyanceByName.Endpoint.replace(
        "{ConveyanceName}",
        ConveyanceName,
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyanceByName.Verb,
      });

      const rsptype = this.FuncBot.GetConveyanceByName.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyances(): Promise<ConveyanceResponse[]> {
    const endpoint = this.URL() + "/" + this.FuncBot.GetConveyances.Endpoint;
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyances.Verb,
      });

      const rsptype = this.FuncBot.GetConveyances.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyanceInputs(
    ConveyanceID: number,
  ): Promise<ConveyanceInputResponse[]> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetConveyanceInputs.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyanceInputs.Verb,
      });

      const rsptype = this.FuncBot.GetConveyanceInputs.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyanceSegment(
    ConveyanceID: Number,
  ): Promise<ConveyanceSegmentResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetConveyanceSegment.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyanceSegment.Verb,
      });

      const rsptype = this.FuncBot.GetConveyanceSegment.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyanceSegments(): Promise<ConveyanceSegmentResponse[]> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.GetConveyanceSegments.Endpoint;
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyanceSegments.Verb,
      });

      const rsptype = this.FuncBot.GetConveyanceSegments.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyanceSegmentsForConveyance(
    ConveyanceID: Number,
  ): Promise<ConveyanceSegmentResponse[]> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetConveyanceSegmentsForConveyance.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyanceSegmentsForConveyance.Verb,
      });

      const rsptype =
        this.FuncBot.GetConveyanceSegmentsForConveyance.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyanceSegmentConfig(
    ConveyanceSegmentConfigID: Number,
  ): Promise<ConveyanceSegmentConfigResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetConveyanceSegmentConfig.Endpoint.replace(
        "{ConveyanceSegmentConfigID}",
        ConveyanceSegmentConfigID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyanceSegmentConfig.Verb,
      });

      const rsptype =
        this.FuncBot.GetConveyanceSegmentConfig.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyanceSegmentConfigs(): Promise<
    ConveyanceSegmentConfigResponse[]
  > {
    const endpoint =
      this.URL() + "/" + this.FuncBot.GetConveyanceSegmentConfigs.Endpoint;
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyanceSegmentConfigs.Verb,
      });

      const rsptype =
        this.FuncBot.GetConveyanceSegmentConfigs.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyanceSegmentConfigsForConveyance(
    ConveyanceID: Number,
  ): Promise<ConveyanceSegmentConfigResponse[]> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetConveyanceSegmentConfigsForConveyance.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetConveyanceSegmentConfigsForConveyance.Verb,
      });

      const rsptype =
        this.FuncBot.GetConveyanceSegmentConfigsForConveyance.ResponseType
          .prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetConveyanceSegmentConfigsForConveyanceSegment(
    ConveyanceSegmentID: Number,
  ): Promise<ConveyanceSegmentConfigResponse[]> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetConveyanceSegmentConfigsForConveyanceSegment.Endpoint.replace(
        "{ConveyanceSegmentID}",
        ConveyanceSegmentID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method:
          this.FuncBot.GetConveyanceSegmentConfigsForConveyanceSegment.Verb,
      });

      const rsptype =
        this.FuncBot.GetConveyanceSegmentConfigsForConveyanceSegment
          .ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async PromoteConveyanceSegment(
    ConveyanceSegmentID: Number,
  ): Promise<ConveyanceSegmentResponse[]> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.PromoteConveyanceSegment.Endpoint.replace(
        "{ConveyanceSegmentID}",
        ConveyanceSegmentID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.PromoteConveyanceSegment.Verb,
      });

      // we force the cast here because otherwise,
      // a misshapen object can escape the try block and cause problems elsewhere.
      const rsptype =
        this.FuncBot.PromoteConveyanceSegment.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async DemoteConveyanceSegment(
    ConveyanceSegmentID: Number,
  ): Promise<Array<ConveyanceSegmentResponse>> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.DemoteConveyanceSegment.Endpoint.replace(
        "{ConveyanceSegmentID}",
        ConveyanceSegmentID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.DemoteConveyanceSegment.Verb,
      });

      const rsptype =
        this.FuncBot.DemoteConveyanceSegment.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async UpdateAction(
    ActionID: Number,
    Name: string,
    Description: string,
  ): Promise<ActionResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.UpdateAction.Endpoint.replace(
        "{ActionID}",
        ActionID.toString(),
      );
    try {
      const reqtype = this.FuncBot.UpdateAction.RequestType.prototype;
      let _body: typeof reqtype = {
        Name: Name,
        Description: Description,
      };
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.UpdateAction.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.UpdateAction.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async UpdateConveyance(
    ConveyanceID: Number,
    Name: string,
    From: string,
    To: string,
    Description: string,
  ): Promise<ConveyanceResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.UpdateConveyance.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      const reqtype = this.FuncBot.UpdateConveyance.RequestType.prototype;
      let _body: typeof reqtype = {
        Name: Name,
        From: From,
        To: To,
        Description: Description,
      };
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.UpdateConveyance.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.UpdateConveyance.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async UpdateConveyanceSegment(
    ConveyanceSegmentID: number,
    ConveyanceID: number,
    SegmentName: string,
    Ordinal: number,
    ActionID: number,
  ): Promise<ConveyanceSegmentResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.UpdateConveyanceSegment.Endpoint.replace(
        "{ConveyanceSegmentID}",
        ConveyanceSegmentID.toString(),
      );
    try {
      const reqtype =
        this.FuncBot.UpdateConveyanceSegment.RequestType.prototype;
      let _body: typeof reqtype = {
        ConveyanceID: ConveyanceID,
        SegmentName: SegmentName,
        Ordinal: Ordinal,
        ActionID: ActionID,
      };
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.UpdateConveyanceSegment.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype =
        this.FuncBot.UpdateConveyanceSegment.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async UpdateConveyanceSegmentConfig(
    ConveyanceSegmentConfigID: number,
    ConveyanceSegmentID: number,
    ConfigName: string,
    ConfigValue: string,
  ): Promise<ConveyanceSegmentConfigResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.UpdateConveyanceSegmentConfig.Endpoint.replace(
        "{ConveyanceSegmentConfigID}",
        ConveyanceSegmentConfigID.toString(),
      );
    try {
      const reqtype =
        this.FuncBot.UpdateConveyanceSegmentConfig.RequestType.prototype;
      let _body: typeof reqtype = {
        ConveyanceSegmentID: ConveyanceSegmentID,
        ConfigName: ConfigName,
        ConfigValue: ConfigValue,
      };
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.UpdateConveyanceSegmentConfig.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype =
        this.FuncBot.UpdateConveyanceSegmentConfig.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async UpdateWorkOrderDatum(
    WorkOrderID: number,
    DatumName: string,
    DatumValue: string,
  ): Promise<MonitoredWorkOrderDatumResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.UpdateWorkOrderDatum.Endpoint.replace(
        "{WorkOrderID}",
        WorkOrderID.toString(),
      );
    try {
      const reqtype = this.FuncBot.UpdateWorkOrderDatum.RequestType.prototype;

      let _body: typeof reqtype = {
        DatumName: DatumName,
        DatumValue: DatumValue,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.UpdateWorkOrderDatum.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.UpdateWorkOrderDatum.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetMachines(): Promise<string[]> {
    const endpoint = this.URL() + "/" + this.FuncBot.GetMachines.Endpoint;
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetMachines.Verb,
      });

      const rsptype = this.FuncBot.GetMachines.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async CreateMachine(MachineName: string): Promise<Array<String>> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.CreateMachine.Endpoint.replace("{MachineName}", MachineName);
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.CreateMachine.Verb,
      });

      const rsptype = this.FuncBot.CreateMachine.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async DeleteMachine(MachineName: string): Promise<Array<String>> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.DeleteMachine.Endpoint.replace("{MachineName}", MachineName);
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.DeleteMachine.Verb,
      });

      const rsptype = this.FuncBot.DeleteMachine.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async CheckForReupload(
    _conveyance: string,
    _filename: string,
    _fileext: string,
    _username: string,
    _esf_prid: string,
    _edocs_PGNO: string,
    _edocs_UCASNo: string,
    _edocs_ClearingRefNo: string,
    _edocs_RegNo: string,
  ): Promise<CheckForReuploadResponse> {
    const endpoint = this.URL() + "/" + this.FuncBot.CheckForReupload.Endpoint;
    try {
      const reqtype = this.FuncBot.CheckForReupload.RequestType.prototype;
      let _body: typeof reqtype = {
        ConveyanceName: _conveyance,
        InitialValue: _filename + _fileext,
        User: _username,
        ESFPRID: _esf_prid,
        EDocsPGNO: _edocs_PGNO,
        EDocsUCASNo: _edocs_UCASNo,
        EDocsClearingRefNo: _edocs_ClearingRefNo,
        EDocsRegNo: _edocs_RegNo,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.CheckForReupload.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.CheckForReupload.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetMergedWorkOrders(
    _environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">,
    _includeMetadata: string[],
    _conveyanceID: number,
    _sprocName: string,
    _sprocParameters: NamedObject[],
    _keyField: string,
    _olderThan?: Date,
    _newerThan?: Date,
  ): Promise<Array<GetMergedWorkOrderResponse>> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.GetMergedWorkOrders.Endpoint;
    try {
      const reqtype = this.FuncBot.GetMergedWorkOrders.RequestType.prototype;

      let _body: typeof reqtype = {
        Environment: _environment,
        ConveyanceID: _conveyanceID,
        IncludeMetadata: _includeMetadata,
        SprocName: _sprocName,
        SprocParameters: _sprocParameters,
        KeyField: _keyField,
        OlderThan: _olderThan,
        NewerThan: _newerThan,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetMergedWorkOrders.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.GetMergedWorkOrders.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetAllWorkOrdersForUser(
    _user: string,
    _environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">,
    _includeMetadata: string[],
    _conveyanceID?: number,
    _olderThan?: Date,
    _newerThan?: Date,
  ): Promise<Array<GetWorkOrderResponse>> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.GetAllWorkOrdersForUser.Endpoint;
    try {
      const reqtype =
        this.FuncBot.GetAllWorkOrdersForUser.RequestType.prototype;
      let _body: typeof reqtype = {
        User: _user,
        Environment: _environment,
        ConveyanceID: _conveyanceID,
        IncludeMetadata: _includeMetadata,
        OlderThan: _olderThan,
        NewerThan: _newerThan,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetAllWorkOrdersForUser.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype =
        this.FuncBot.GetAllWorkOrdersForUser.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetFailedWorkOrdersForUser(
    _user: string,
    _environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">,
    _conveyanceID?: number,
    _olderThan?: Date,
    _newerThan?: Date,
  ): Promise<Array<GetWorkOrderResponse>> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.GetFailedWorkOrdersForUser.Endpoint;
    try {
      const reqtype =
        this.FuncBot.GetFailedWorkOrdersForUser.RequestType.prototype;
      let _body: typeof reqtype = {
        User: _user,
        Environment: _environment,
        ConveyanceID: _conveyanceID,
        OlderThan: _olderThan,
        NewerThan: _newerThan,
        IncludeMetadata: [],
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetFailedWorkOrdersForUser.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype =
        this.FuncBot.GetFailedWorkOrdersForUser.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetCompletedWorkOrdersForUser(
    _user: string,
    _environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">,
    _conveyanceID?: number,
    _olderThan?: Date,
    _newerThan?: Date,
  ): Promise<Array<GetWorkOrderResponse>> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.GetCompletedWorkOrdersForUser.Endpoint;
    try {
      const reqtype =
        this.FuncBot.GetCompletedWorkOrdersForUser.RequestType.prototype;
      let _body: typeof reqtype = {
        User: _user,
        Environment: _environment,
        ConveyanceID: _conveyanceID,
        OlderThan: _olderThan,
        NewerThan: _newerThan,
        IncludeMetadata: ["Final.Download"],
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetCompletedWorkOrdersForUser.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype =
        this.FuncBot.GetCompletedWorkOrdersForUser.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetInFlightWorkOrdersForUser(
    _user: string,
    _environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">,
    _conveyanceID?: number,
    _olderThan?: Date,
    _newerThan?: Date,
  ): Promise<Array<GetWorkOrderResponse>> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.GetInFlightWorkOrdersForUser.Endpoint;
    try {
      const reqtype =
        this.FuncBot.GetInFlightWorkOrdersForUser.RequestType.prototype;
      let _body: typeof reqtype = {
        User: _user,
        Environment: _environment,
        ConveyanceID: _conveyanceID,
        OlderThan: _olderThan,
        NewerThan: _newerThan,
        IncludeMetadata: [],
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetInFlightWorkOrdersForUser.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype =
        this.FuncBot.GetInFlightWorkOrdersForUser.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetMicroWorkOrdersForUser(
    _user: string,
    _environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">,
  ): Promise<GetMicroWorkOrdersResponse> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.GetMicroWorkOrdersForUser.Endpoint;
    try {
      const reqtype =
        this.FuncBot.GetMicroWorkOrdersForUser.RequestType.prototype;
      let _body: typeof reqtype = {
        User: _user,
        Environment: _environment,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetMicroWorkOrdersForUser.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype =
        this.FuncBot.GetMicroWorkOrdersForUser.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async RefireWorkOrder(
    WorkOrderID: number,
    ConveyanceID: number,
    IncludeMetadata: string[],
  ): Promise<GetWorkOrderResponse> {
    const endpoint = this.URL() + "/" + this.FuncBot.RefireWorkOrder.Endpoint;
    try {
      const reqtype = this.FuncBot.RefireWorkOrder.RequestType.prototype;
      let _body: typeof reqtype = {
        WorkOrderID: WorkOrderID,
        ConveyanceID: ConveyanceID,
        IncludeMetadata: IncludeMetadata,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.RefireWorkOrder.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.RefireWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async CancelWorkOrder(
    WorkOrderID: number,
    ConveyanceID: number,
    IncludeMetadata: string[],
  ): Promise<GetWorkOrderResponse> {
    const endpoint = this.URL() + "/" + this.FuncBot.CancelWorkOrder.Endpoint;
    try {
      const reqtype = this.FuncBot.CancelWorkOrder.RequestType.prototype;
      let _body: typeof reqtype = {
        ConveyanceID: ConveyanceID,
        WorkOrderID: WorkOrderID,
        IncludeMetadata: IncludeMetadata,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.CancelWorkOrder.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.CancelWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async ValidateAnyIdent(
    Ident: string,
    Types: string,
  ): Promise<Boolean> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.ValidateAnyIdent.Endpoint.replace("{Ident}", Ident).replace(
        "{Types}",
        Types,
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.ValidateAnyIdent.Verb,
      });

      const rsptype = this.FuncBot.ValidateAnyIdent.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();
      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async Enqueue(
    _user: string,
    _conveyanceID: number,
    _environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">,
    _initialValue: string,
    _initialMetadata: EnqueueMetadataRequest[],
  ): Promise<GetWorkOrderResponse> {
    const endpoint = this.URL() + "/" + this.FuncBot.Enqueue.Endpoint;
    try {
      const reqtype = this.FuncBot.Enqueue.RequestType.prototype;
      let _body: typeof reqtype = {
        User: _user,
        ConveyanceID: _conveyanceID,
        Environment: _environment,
        InitialValue: _initialValue,
        InitialMetadata: _initialMetadata,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.Enqueue.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.Enqueue.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetExpectedInputsForConveyanceID(
    ConveyanceID: number,
  ): Promise<Array<ConveyanceInputResponse>> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.GetExpectedInputsForConveyanceID.Endpoint.replace(
        "{ConveyanceID}",
        ConveyanceID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetExpectedInputsForConveyanceID.Verb,
      });

      const rsptype =
        this.FuncBot.GetExpectedInputsForConveyanceID.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async ActivateWorkOrder(
    _workOrderID: number,
    _conveyanceID: number,
    _includeMetadata: string[],
  ): Promise<GetWorkOrderResponse> {
    const endpoint = this.URL() + "/" + this.FuncBot.ActivateWorkOrder.Endpoint;
    try {
      const reqtype = this.FuncBot.ActivateWorkOrder.RequestType.prototype;
      let _body: typeof reqtype = {
        WorkOrderID: _workOrderID,
        ConveyanceID: _conveyanceID,
        IncludeMetadata: _includeMetadata,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.ActivateWorkOrder.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.ActivateWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async GetWorkOrder(
    _workOrderID: number,
    _conveyanceID: number,
    _includeMetadata: string[],
  ): Promise<GetWorkOrderResponse> {
    const endpoint = this.URL() + "/" + this.FuncBot.GetWorkOrder.Endpoint;
    try {
      const reqtype = this.FuncBot.GetWorkOrder.RequestType.prototype;
      let _body: typeof reqtype = {
        WorkOrderID: _workOrderID,
        ConveyanceID: _conveyanceID,
        IncludeMetadata: _includeMetadata,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.GetWorkOrder.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.GetWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async AddDataToWorkOrder(
    _workOrderID: number,
    _conveyanceID: number,
    _dataName: string,
    _dataValue: string,
  ): Promise<GetWorkOrderResponse> {
    const endpoint =
      this.URL() + "/" + this.FuncBot.AddDataToWorkOrder.Endpoint;
    try {
      const reqtype = this.FuncBot.AddDataToWorkOrder.RequestType.prototype;
      let _body: typeof reqtype = {
        WorkOrderID: _workOrderID,
        ConveyanceID: _conveyanceID,
        DataName: _dataName,
        DataValue: _dataValue,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.AddDataToWorkOrder.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.AddDataToWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async MonitorWorkOrders(
    _orderBySlowest: boolean,
    _orderByRecent: boolean,
    _limit: number,
    _conveyanceIDs?: number[],
    _from?: Date,
    _until?: Date,
    _searchTerm?: string,
  ): Promise<Array<MonitoredWorkOrderResponse>> {
    const endpoint = this.URL() + "/" + this.FuncBot.MonitorWorkOrders.Endpoint;
    try {
      const reqtype = this.FuncBot.MonitorWorkOrders.RequestType.prototype;
      let _body: typeof reqtype = {
        ConveyanceIDs: _conveyanceIDs,
        OrderBySlowest: _orderBySlowest,
        OrderByRecent: _orderByRecent,
        Limit: _limit,
        From: _from,
        Until: _until,
        SearchTerm: _searchTerm,
      };

      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.MonitorWorkOrders.Verb,
        body: JSON.stringify(_body),
      });

      const rsptype = this.FuncBot.MonitorWorkOrders.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async MonitorThisWorkOrder(
    _workOrderID: number,
  ): Promise<MonitoredWorkOrderResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.MonitorThisWorkOrder.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.MonitorThisWorkOrder.Verb,
      });

      const rsptype = this.FuncBot.MonitorThisWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async RefireMonitoredWorkOrder(
    _workOrderID: number,
  ): Promise<MonitoredWorkOrderResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.RefireMonitoredWorkOrder.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.RefireMonitoredWorkOrder.Verb,
      });

      const rsptype =
        this.FuncBot.RefireMonitoredWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async CancelMonitoredWorkOrder(
    _workOrderID: number,
  ): Promise<MonitoredWorkOrderResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.CancelMonitoredWorkOrder.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.CancelMonitoredWorkOrder.Verb,
      });

      const rsptype =
        this.FuncBot.CancelMonitoredWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async ArchiveMonitoredWorkOrder(
    _workOrderID: number,
  ): Promise<undefined> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.ArchiveMonitoredWorkOrder.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.ArchiveMonitoredWorkOrder.Verb,
      });

      //  console.log(response);

      return;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async ActivateMonitoredWorkOrder(
    _workOrderID: number,
  ): Promise<MonitoredWorkOrderResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.ActivateMonitoredWorkOrder.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.ActivateMonitoredWorkOrder.Verb,
      });

      const rsptype =
        this.FuncBot.ActivateMonitoredWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async CloneMonitoredWorkOrder(
    _workOrderID: number,
  ): Promise<MonitoredWorkOrderResponse> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.CloneMonitoredWorkOrder.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.CloneMonitoredWorkOrder.Verb,
      });

      const rsptype =
        this.FuncBot.CloneMonitoredWorkOrder.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async MonitorThisWorkOrderData(
    _workOrderID: number,
  ): Promise<Array<MonitoredWorkOrderDatumResponse>> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.MonitorThisWorkOrderData.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.MonitorThisWorkOrderData.Verb,
      });

      const rsptype =
        this.FuncBot.MonitorThisWorkOrderData.ResponseType.prototype;

      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async MonitorThisWorkOrderSteps(
    _workOrderID: number,
  ): Promise<Array<MonitoredWorkOrderStepResponse>> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.MonitorThisWorkOrderSteps.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.MonitorThisWorkOrderSteps.Verb,
      });

      const rsptype =
        this.FuncBot.MonitorThisWorkOrderSteps.ResponseType.prototype;

      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async MonitorThisWorkOrderExceptions(
    _workOrderID: number,
  ): Promise<Array<MonitoredWorkOrderExceptionResponse>> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.MonitorThisWorkOrderExceptions.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.MonitorThisWorkOrderExceptions.Verb,
      });

      const rsptype =
        this.FuncBot.MonitorThisWorkOrderExceptions.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async MonitorThisWorkOrderEmails(
    _workOrderID: number,
  ): Promise<Array<MonitoredWorkOrderEmailResponse>> {
    const endpoint =
      this.URL() +
      "/" +
      this.FuncBot.MonitorThisWorkOrderEmails.Endpoint.replace(
        "{WorkOrderID}",
        _workOrderID.toString(),
      );
    try {
      let response = await fetch(endpoint, {
        ...(await this.BaseReticuleBody()),
        method: this.FuncBot.MonitorThisWorkOrderEmails.Verb,
      });

      const rsptype =
        this.FuncBot.MonitorThisWorkOrderEmails.ResponseType.prototype;
      var _response: typeof rsptype = await response.json();

      return _response;
    } catch (error) {
      this.Logger.log(
        error,
        LoggingLevel.EXCEPTION,
        this.URL() + "/" + endpoint,
      );
      return await new Promise((_, reject) => {
        reject("Failed to communicate with API");
      });
    }
  }

  public async BaseReticuleBody(): Promise<RequestInit> {
    var now = new Date();
    var onemin = new Date(now);
    onemin.setMinutes(onemin.getMinutes() - 1);

    //  console.log("Analysing Buffered Token:");
    //  console.log(this.BufferedToken);
    //  console.log("Token timestamp:");
    //  console.log(this.BufferedToken?.Timestamp);
    //  console.log("vs One Minute Ago...");
    //  console.log(onemin);

    if (this.BufferedToken === undefined) {
      this.BufferedToken = {
        Timestamp: new Date(0),
        TK: undefined,
      };
    }

    if (this.BufferedToken.Timestamp < onemin) {
      //  console.log("Replenishing stale Buffered Token");
      this.BufferedToken.TK = await this.msal.acquireTokenSilent({
        account: this.msal.getActiveAccount() ?? undefined,
        scopes: ["https://edrmapitest.essex.ac.uk/legacy"],
      });
      this.BufferedToken.Timestamp = now;
    } else {
      //  console.log("Buffered Token still fresh!");
    }

    return {
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.BufferedToken.TK.accessToken,
      },
    };
  }
}
