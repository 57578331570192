import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess } from "../../../data/DataAccess";
import { Logging } from "../../../Logging";
import {
  ContactCard24Filled,
  Flowchart24Filled,
  Info24Regular,
  LearningApp24Filled,
  Money24Filled,
  Person24Filled,
  Globe24Regular,
} from "@fluentui/react-icons";
import { AgentSidebar } from "../Components/AgentSidebar";
import { PgApplicantSidebarActions } from "../Components/PgApplicantSidebarActions";
import { useLoaderData } from "react-router";
import { PgApplicantChoicesAndOffers } from "../Components/PgApplicantChoicesAndOffers";
import { PgApplicantDocuments } from "../Components/PgApplicantDocuments";

interface IProps {
  PgNo: string;
  Logger: Logging;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
  da: DataAccess;
  PCA: PublicClientApplication;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface IState {
  UploadModalVisible: boolean;
  PGApplicantUploader_Frame: string;
  PGApplicantUploader_Payload: string;
  PGApplicantPSEURL: string;
}

function AOS2_PG_APPLICANT(params): React.ReactElement {
  let applicantData: any = useLoaderData();
  return <AOS2_PG_APPLICANT_INNER {...params} PgNo={applicantData.PgNo} />;
}

class AOS2_PG_APPLICANT_INNER extends React.Component<IProps, IState> {
  constructor(params) {
    super(params);

    this.state = {
      UploadModalVisible: false,
      PGApplicantUploader_Frame: "",
      PGApplicantUploader_Payload: "",
      PGApplicantPSEURL: "",
    };
  }

  // private _appsummCTs: string[] = [
  //   "Application Summary",
  //   "uaducasform",
  //   "Application form",
  // ];
  // private _offdocCTs: string[] = ["Offer Document"];
  // private _casdocCTs: string[] = ["CAS Statement", "CASStatement"];
  // private _appdocCTs: string[] = [
  //   "Offer Document",
  //   "CAS Statement",
  //   "CASStatement",
  //   "Application Summary",
  //   "uaducasform",
  //   "Application form",
  // ];

  componentDidMount(): void {
    //if (!this.props.MODE_DEVELOPMENT) {
    this.getPGApplicantUploaderValues();
    this.getPGApplicantPSEURL();
    //}
  }

  render() {
    var PassToAllGrids = {
      Callouts: false,
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      pgno: this.props.PgNo,
      class: "UoE-Grid AOSScreen",
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <React.Fragment>
        <AgentSidebar>
          <PgApplicantSidebarActions
            PgNo={this.props.PgNo}
            ApplicantPSEURL={this.state.PGApplicantPSEURL}
            ShowUploadModal={this.ShowUploadModal}
          />
        </AgentSidebar>
        <div className="ResultsPanel PG">
          <div className="ApplicantGrid" id="HeaderTop">
            <div className="StudyLevelIdent PG">PG</div>
            <GridWrapper
              {...PassToAllGrids}
              eid={"AOS_PG_HeaderTop_" + this.props.Refresher}
              sproc="sputilities.webui.AOSv2_GetPGApplicantHeaderTop"
              params={["@pgno", this.props.PgNo]}
              output="data"
            ></GridWrapper>
          </div>
          <PgApplicantChoicesAndOffers
            PgNo={this.props.PgNo}
            {...PassToAllGrids}
          />
          <div className="ApplicantGrid">
            <Tabs selectedTabClassName="active">
              <TabList>
                <Tab>
                  <Person24Filled></Person24Filled> Information
                </Tab>
                <Tab>
                  <Money24Filled></Money24Filled> Fee Status
                </Tab>
                <Tab>
                  <ContactCard24Filled></ContactCard24Filled> Contact Details
                </Tab>
                <Tab>
                  <LearningApp24Filled></LearningApp24Filled> Qualifications
                </Tab>
                <Tab>
                  <Flowchart24Filled></Flowchart24Filled> References
                </Tab>
                <Tab>
                  <Info24Regular></Info24Regular> Other Information
                </Tab>
                <Tab>
                  <Globe24Regular></Globe24Regular> CAS Information
                </Tab>
              </TabList>
              <TabPanel>
                <div id="Information">
                  <GridWrapper
                    eid={"AOS_Header_" + this.props.Refresher}
                    {...PassToAllGrids}
                    class="UoE-Grid AOS"
                    sproc="sputilities.webui.AOSv2_GetPGApplicantHeader"
                    params={[
                      "@pgno",
                      this.props.PgNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                    rename="Nameheader,_N;app_origin,_AO"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="FeeStatus">
                  <GridWrapper
                    eid={"AOS_FeeStatus_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetPGApplicantDetails"
                    params={[
                      "@pgno",
                      this.props.PgNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="ContactDetails">
                  <GridWrapper
                    eid={"AOS_ContactDetails_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetPGApplicantContacts"
                    params={[
                      "@pgno",
                      this.props.PgNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                    rename="E-mail,Email"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="Qualifications">
                  <GridWrapper
                    eid={"AOS_Qualifications_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetPGApplicantQualifications"
                    params={[
                      "@pgno",
                      this.props.PgNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="References">
                  <GridWrapper
                    eid={"AOS_References_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetPGApplicantReferences"
                    params={[
                      "@pgno",
                      this.props.PgNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="OtherInformation">
                  <GridWrapper
                    eid={"AOS_OtherInfo_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.AOSv2_GetPGApplicantOtherInfo"
                    params={[
                      "@pgno",
                      this.props.PgNo,
                      "@username",
                      // "{%u}",
                      // "@agent",
                      "{%iu}",
                    ]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
              <TabPanel>
                <div id="CASInformation">
                  <h3>
                    <b>CAS Current Status</b>
                  </h3>
                  <GridWrapper
                    eid={"AOS_PG_CASInformation_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.Get_CAS_Tracker_Stage_Current"
                    params={["@ApplicationNo", this.props.PgNo]}
                    output="data"
                  ></GridWrapper>
                  <p></p>
                  <h3>
                    <b>CAS History</b>
                  </h3>
                  <GridWrapper
                    eid={"AOS_PG_CASInformation_" + this.props.Refresher}
                    {...PassToAllGrids}
                    sproc="sputilities.webui.Get_CAS_Tracker_Stage_History"
                    params={["@ApplicationNo", this.props.PgNo]}
                    output="data"
                  ></GridWrapper>
                </div>
              </TabPanel>
            </Tabs>
          </div>

          <PgApplicantDocuments PgNo={this.props.PgNo} {...PassToAllGrids} />
        </div>
        <div
          className="UploaderModal"
          hidden={this.state.UploadModalVisible !== true}
        >
          <div className="modalContent">
            <span
              className="modalClose"
              onClick={() => {
                this.HideUploadModal();
              }}
            >
              &times;
            </span>
            <iframe
              src={
                this.state.PGApplicantUploader_Frame +
                "?payload=" +
                this.state.PGApplicantUploader_Payload
              }
            ></iframe>
          </div>
        </div>
      </React.Fragment>
    );
  }

  ShowUploadModal = () => {
    this.setState({ UploadModalVisible: true });
  };

  HideUploadModal = () => {
    this.setState({ UploadModalVisible: false });
  };

  private async getPGApplicantUploaderValues() {
    this.props.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.AOSv2_GetPGApplicantUploaderURL",
          params: [
            "@pgno",
            this.props.PgNo,
            "@username",
            // "{%u}",
            // "@agent",
            "{%iu}",
          ],
          imp: this.props.Alias,
        }),
      )
      .then(
        (data) => {
          var _error: string = "";
          if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
            _error = data[0][0]["ERROR"];
          } else if (data !== undefined) {
            if (data.length > 0) {
              if (data[0] !== undefined) {
                if (data[0].length === 1) {
                  this.setState({
                    PGApplicantUploader_Frame: data[0][0].Frame,
                    PGApplicantUploader_Payload: data[0][0].Payload,
                  });
                } else {
                  _error =
                    "AOSv2_GetPGApplicantUploaderURL - Data returned an unexpected number of rows.";
                }
              } else {
                _error =
                  "AOSv2_GetPGApplicantUploaderURL - Data returned a null singleton.";
              }
            }
            if (data.length <= 0) {
              _error =
                "AOSv2_GetPGApplicantUploaderURL - Data returned an unexpected number of tables.";
            }
            // } else {
            //   _error =
            //     "AOSv2_GetPGApplicantUploaderURL - Data returned undefined.";
            // Don't throw an error if data is undefined
            // The API will return an error value if there is a problem.
            // The main cause of this being undefined is the sproc being called
            // before impersonation has kicked in. For some reason waiting until
            // impersonation has kicked in is causing an infinite loop
            // so letting it run and accepting an empty response gracefully
            // is an easier option.
          }

          if (_error !== "") {
            this.props.RegisterError("AOSv2_GetPGApplicantUploaderURL", _error);
          }
        },
        (reason) => {
          this.props.RegisterError("AOSv2_GetPGApplicantUploaderURL", reason);
        },
      );
  }

  private async getPGApplicantPSEURL() {
    this.props.da
      .get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.AOSv2_GetPGApplicantPSEURL",
          params: [
            "@pgno",
            this.props.PgNo,
            "@username",
            //"{%u}",
            // "@agent",
            "{%iu}",
          ],
          imp: this.props.Alias,
        }),
      )
      .then(
        (data) => {
          var _error: string = "";
          if (data && data[0] && data[0][0] && data[0][0]["ERROR"]) {
            _error = data[0][0]["ERROR"];
          } else if (data !== undefined) {
            if (data.length > 0) {
              if (data[0] !== undefined) {
                if (data[0].length === 1) {
                  this.setState({ PGApplicantPSEURL: data[0][0].PSEUrl });
                } else {
                  _error =
                    "AOSv2_GetPGApplicantPSEURL - Data returned an unexpected number of rows.";
                }
              } else {
                _error =
                  "AOSv2_GetPGApplicantPSEURL - Data returned a null singleton.";
              }
            }
            if (data.length <= 0) {
              _error =
                "AOSv2_GetPGApplicantPSEURL - Data returned an unexpected number of tables.";
            }
            // } else {
            //   _error = "AOSv2_GetPGApplicantPSEURL - Data returned undefined.";
            // Don't throw an error if data is undefined
            // The API will return an error value if there is a problem.
            // The main cause of this being undefined is the sproc being called
            // before impersonation has kicked in. For some reason waiting until
            // impersonation has kicked in is causing an infinite loop
            // so letting it run and accepting an empty response gracefully
            // is an easier option.
          }

          if (_error !== "") {
            this.props.RegisterError("AOSv2_GetPGApplicantPSEURL", _error);
          }
        },
        (reason) => {
          this.props.RegisterError("AOSv2_GetPGApplicantPSEURL", reason);
        },
      );
  }
}

export default AOS2_PG_APPLICANT;
