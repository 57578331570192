import { PropsWithChildren, ReactElement } from "react";
import { Link } from "react-router-dom";

interface LinkPanelProps {
  url?: string;
  title?: string;
  subtitle?: string;
  target?: string;
}
export function LinkPanel(
  props: PropsWithChildren<LinkPanelProps>,
): ReactElement {
  return (
    <Link className="StructPanel" to={props.url} target={props.target}>
      <div className="PanelBar Title">
        <div className="Left">{props.title}</div>
        <div className="Breaker"></div>
        <div className="Right">{props.subtitle}</div>
      </div>
      <div className="PanelBody">{props.children}</div>
    </Link>
  );
}
