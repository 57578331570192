import { PublicClientApplication } from "@azure/msal-browser";
import { Logging } from "../Logging";
import { DataAccess } from "../data/DataAccess";
import { WorkflowViewInfoPath } from "./WorkFlowViewInfoPath";
import { useParams } from "react-router-dom";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export const WorkflowViewInfoPathWrapper = (props: IProps) => {
  let { PRID, wfid } = useParams();

  return (
    <WorkflowViewInfoPath {...props} PRID={PRID || ""} wfid={wfid || ""} />
  );
};
