import { Dayjs } from "dayjs";

class Embargo {
  EmbargoID: string;
  EnforcedFrom: Dayjs | undefined;
  EnforcedUntil: Dayjs | undefined;
  _scope: string;
  _scopes: string[];
  Author: string;
}

export default Embargo;
