import { getIconClassName } from "@fluentui/react";
import { DragEvent } from "react";
import React from "react";

interface IProps {
  SetDrag: (newstate: boolean) => void;
  AddFiles: (files: FileList) => void;
  DragState: boolean;
}

export function DropZone(props: IProps) {
  function DragOver(event: DragEvent) {
    props.SetDrag(true);
    event.stopPropagation();
    event.preventDefault();
  }

  function DragExit(event: DragEvent) {
    props.SetDrag(false);
    event.stopPropagation();
    event.preventDefault();
  }

  function DragDrop(event: DragEvent) {
    props.SetDrag(false);
    event.stopPropagation();
    event.preventDefault();

    props.AddFiles(event.dataTransfer.files);
  }

  return (
    <div
      className={"FileDropArea" + (props.DragState ? " Drag" : " NoDrag")}
      onDragEnter={DragOver}
      onDragExit={DragExit}
      onDrop={DragDrop}
    >
      <div className="FileDropZone">
        <div className="Underlay">
          <p className="FileDropZoneText">
            <i
              className={getIconClassName("BulkUpload")}
              aria-hidden="true"
            ></i>{" "}
            Drag and drop file(s) here
          </p>
        </div>
      </div>
    </div>
  );
}
