import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./Header/Header";
import { FooterWrapper } from "./FooterWrapper";
import IfPermitted from "./IfPermitted";
import { DB_UserResponse } from "../models/DB_UserResponse";
import { DataAccess, User } from "../data/DataAccess";
import ErrorMessage from "../models/ErrorMessage";
import StatusMessage from "../models/StatusMessage";
import {
  PublicClientApplication,
  EndSessionRequest,
} from "@azure/msal-browser";
import { IMsalContext } from "@azure/msal-react";
import ModeStruct from "../models/ModeStruct";
import TaskListGroup from "../models/TaskListGroup";
import SPOUploadLocation from "../models/SPOUploadLocation";
import { SurfaceAttributes, useSurface } from "../Providers/SurfaceProvider";
import { InvarDataAccess } from "../data/InvarDataAccess";

interface IProps {
  IDA: InvarDataAccess;
  da: DataAccess;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Header_AuthedCharmData?: DB_UserResponse;
  Header_ViewAsCharmData?: DB_UserResponse;
  AuthedUser: User;
  ViewAsUser: User;
  //TODO: Remove this when not required
  Permission: (surface: string, scope: string, impish: boolean) => boolean;
  HiVis: boolean;
  ErrorMessages: ErrorMessage[];
  StatusMessages: StatusMessage[];
  SetHiVis: (val: boolean) => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  MSAL: IMsalContext;
  PCA: PublicClientApplication;
  TailoredLogoutRequest: EndSessionRequest;
  ViewAsNotFound: boolean;
  ViewAsAll: boolean;
  Modes: ModeStruct[];
  TaskListGroups: TaskListGroup[] | undefined;
  Refresher: string;
  Refresh: () => void;
  Mode: string[];
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetTaskList: (TLRef: string) => void;
  SetImpersonation: (alias?: string) => void;
  ThemeName: string;
  SetThemeName: (input: string) => void;
}

export const MainBracketWrapper = (props: IProps) => {
  const Surface: SurfaceAttributes = useSurface();
  const loc = useLocation();

  //  console.log(props);

  useEffect(() => {
    if (
      loc !== undefined &&
      loc.pathname !== undefined &&
      loc.pathname !== ""
    ) {
      props.da.logAccess(Surface.Title);
    }
  }, [loc.pathname]);

  const init: Map<string, boolean> = new Map([
    ["TaskLists", false],
    ["Reports", false],
    ["User", false],
    ["Burger", false],
    ["Environment", false],
    ["Accessibility", false],
    ["Help", false],
    ["Focus", false],
  ]);

  const [menuVisis, setMenuVisis] = useState(init);
  const [menuTimer, setMenuTimer] = useState(undefined);

  const SetSpecificVisi = (name: string | undefined, newval: boolean) => {
    // console.log("SetSpecificVisi");
    // console.log(name);
    // console.log(newval);
    if (newval === true) {
      //console.log(menuVisis);
      var _nmv = new Map(menuVisis);
      //  console.log(_nmv);
      _nmv.forEach((nm, th) => {
        _nmv.set(th, false);
      });
      if (name !== undefined) {
        _nmv.set(name, true);
      }
      //  console.log(_nmv);
      clearTimeout(menuTimer);
      setMenuVisis(_nmv);
    } else {
      var _nmv = new Map(menuVisis);
      //  console.log(_nmv);
      _nmv.forEach((nm, th) => {
        _nmv.set(th, false);
      });
      // console.log(_nmv);
      setMenuTimer(
        setTimeout(() => {
          setMenuVisis(_nmv);
        }, 5000),
      );
    }
  };

  return (
    <div id="SPOWebUIOnPrem" className={props.ThemeName}>
      <Header
        {...props}
        Title={Surface.Title || ""}
        Permission={props.Permission}
        Header_AuthedCharmData={props.Header_AuthedCharmData}
        Header_ViewAsCharmData={props.Header_ViewAsCharmData}
        MenuVisibilities={menuVisis}
        SetMenuVisibility={SetSpecificVisi}
        SetThemeName={props.SetThemeName}
      />
      <IfPermitted
        ShowDisallowed
        Whitelist={Surface.PermissionsWhitelist || []}
      >
        <div
          className={
            "Body " + ((props.HiVis ? "HiVis " : "") + Surface.CSSClass)
          }
        >
          <Outlet />
        </div>
      </IfPermitted>
      <FooterWrapper
        {...props}
        MenuVisibilities={menuVisis}
        SetMenuVisibility={SetSpecificVisi}
      ></FooterWrapper>
    </div>
  );
};
