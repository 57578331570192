import { PublicClientApplication } from "@azure/msal-browser";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { DataAccess } from "../../data/DataAccess";
import { GridWrapper } from "../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../Logging";
import "../../assets/css/AOS2/AOS2_Agent_Access_Page.css";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface IState {
  agentname: string;
  agentnameInput: string;
  fromInput: Dayjs;
  From: Dayjs;
  toInput: Dayjs;
  To: Dayjs;
}

class AOS2_ADMIN_Agent_Access extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      agentname: "",
      agentnameInput: "",
      fromInput: dayjs().subtract(1, "day"),
      From: dayjs().subtract(1, "day"),
      toInput: dayjs(),
      To: dayjs(),
    };
  }

  render() {
    var PassToAllGrids = {
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      NoneMessage: "No results were returned.",
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <div className="AOS2_ADMIN_Agent_Access">
        <h2>Agent Access AOS Tracker</h2>
        <div className="RequestRow">
          <label className="inputLabel" htmlFor="AgentValue">
            Agent User Name:
          </label>
          <input
            className="UserNameInput"
            id="UserNameInput"
            type="text"
            value={
              this.state.agentnameInput !== undefined
                ? this.state.agentnameInput
                : undefined
            }
            onChange={(e) => {
              this.setState({ agentnameInput: e.target.value });
              console.log(e.target.value);
            }}
          />
          <label className="inputLabel" htmlFor="DateFrom">
            Start Date from:
          </label>
          <input
            className="dateinput"
            id="DateFrom"
            type="date"
            value={
              this.state.fromInput !== undefined
                ? this.state.fromInput.format("YYYY-MM-DD")
                : undefined
            }
            onChange={(e) => {
              if (e.target.value === "") {
                this.setState({ fromInput: dayjs().subtract(1, "day") });
              } else {
                this.setState({ fromInput: dayjs(e.target.value) });
              }
            }}
          />
          <label className="inputLabel" htmlFor="DateTo">
            Date to:
          </label>
          <input
            className="dateinput"
            id="DateTo"
            type="date"
            value={
              this.state.toInput !== undefined
                ? this.state.toInput.format("YYYY-MM-DD")
                : undefined
            }
            onChange={(e) => {
              if (e.target.value === "") {
                this.setState({ toInput: dayjs() });
              } else {
                this.setState({ toInput: dayjs(e.target.value) });
              }
            }}
          />
          <input
            id="submit"
            type="button"
            value="Search"
            onClick={() => {
              this.Search();
            }}
          />
        </div>
        <div className="JustASpacer"></div>
        <p>
          <b>NOTE:</b>{" "}
        </p>
        <p>Limited to 1000 rows maximum.</p>
        <p>If no date selected, the table returns the last 24 hours.</p>
        <p>
          Just use the user name in the search box, it does not require
          '@essex.ac.uk'.
        </p>
        <div className="JustASpacer"></div>
        <div className="AOS2_ADMIN_Agent_Access_Table">
          <GridWrapper
            legacyURLs={true}
            Callouts={false}
            {...PassToAllGrids}
            key={"SPOWUI_AOSAGENTACCESSLOG_" + this.props.Refresher}
            eid="SPOWUI_AOSAGENTACCESSLOG"
            sproc="sputilities.webui.AOSv2_ADMIN_AgentPageAccessViewer"
            params={[
              "@AGENT",
              this.state.agentname,
              "@DATEFROM",
              this.state.From === undefined
                ? ""
                : this.state.From.format("YYYY-MM-DD"),
              "@DATETO",
              this.state.To === undefined
                ? ""
                : this.state.To.add(1, "day").format("YYYY-MM-DD"),
            ]}

            // class="UoE-Grid AOS"
          />
        </div>
      </div>
    );
  }
  Search() {
    this.setState(
      {
        agentname: this.state.agentnameInput,
        From: this.state.fromInput,
        To: this.state.toInput,
      },
      () => {
        this.props.Refresh();
      },
    );
  }
}

export default AOS2_ADMIN_Agent_Access;
