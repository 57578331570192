import { ClipboardTaskListLtrRegular } from "@fluentui/react-icons";
import { Link } from "react-router-dom";
import { SURFACE, useSurface } from "../../Providers/SurfaceProvider";
import TaskList from "../../models/TaskList";
import TaskListGroup from "../../models/TaskListGroup";
import IfPermitted from "../IfPermitted";
import { User } from "../../data/DataAccess";
import { IfSurface } from "../IfSurface";

export type TaskListCharmProps = {
  Permission: (surface: string, scope: string, impish: boolean) => boolean;
  AuthedUser: User;
  ViewAsUser: User;

  MenuVisibilities: Map<string, boolean>;
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;

  TaskListGroups: TaskListGroup[] | undefined;

  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
};

export function TaskListCharm(props: TaskListCharmProps) {
  const Surface = useSurface();

  return (
    <div
      className="TaskListMenu"
      onMouseEnter={() => {
        props.SetMenuVisibility("TaskLists", true);
      }}
    >
      <a href="#" tabIndex={0} className="TaskListMenuCharm">
        <ClipboardTaskListLtrRegular
          height={30}
          width={30}
        ></ClipboardTaskListLtrRegular>
      </a>
      <div
        className={
          "TaskListMenuReveal" +
          (props.MenuVisibilities.get("TaskLists") ? " Visible" : "")
        }
        aria-expanded={props.MenuVisibilities.get("TaskLists")}
      >
        <div className="Container">
          <div className="MenuTitle">Task Lists</div>
          {props.TaskListGroups !== undefined
            ? props.TaskListGroups.map((th: TaskListGroup, index: number) => {
                var rend: boolean = false;

                if (th.GroupName === "ESF" && Surface.SURFACE === SURFACE.ESF) {
                  rend = true;
                }
                if (
                  th.GroupName === "PGEAF" &&
                  Surface.SURFACE === SURFACE.EAF_PGEAF
                ) {
                  rend = true;
                }
                if (
                  th.GroupName === "UGEAF" &&
                  Surface.SURFACE === SURFACE.EAF_UGEAF
                ) {
                  rend = true;
                }
                if (rend) {
                  return (
                    <IfPermitted
                      key={"tsklgr_" + index.toString()}
                      Whitelist={
                        th.GroupName === "ESF"
                          ? [
                              "ESF.Teams",
                              "ESF.CourseRecordsTeam",
                              "ESF.Reader",
                              "ESF.Uploader",
                            ]
                          : th.GroupName === "PGEAF"
                            ? ["PGEAF.Admissions", "PGEAF.Reader"]
                            : th.GroupName === "UGEAF"
                              ? ["UGEAF.Admissions", "UGEAF.Reader"]
                              : []
                      }
                    >
                      <div className="TaskListGroup">{th.GroupName}</div>
                      {th.TaskLists.filter((th) => th.Display).map(
                        (ith: TaskList, iindex: number) => {
                          return (
                            <IfPermitted
                              key={"tskl_" + iindex.toString()}
                              Whitelist={ith.Permitted.split(",")}
                            >
                              <Link
                                key={"tsklgrtls_" + iindex.toString()}
                                to={
                                  th.GroupName === "ESF"
                                    ? props.GetLink(
                                        ith.Params !== ""
                                          ? [
                                              {
                                                Mode: "Tasklists",
                                                Index: 0,
                                              },
                                              {
                                                Mode: ith.TLRef,
                                                Index: 1,
                                              },
                                              {
                                                // to allow for value of all to be passed
                                                // into sprocs for All ESF Workflows etc
                                                Mode: ith.Params.split(",")[1],
                                                Index: 2,
                                              },
                                            ]
                                          : [
                                              {
                                                Mode: "Tasklists",
                                                Index: 0,
                                              },
                                              {
                                                Mode: ith.TLRef,
                                                Index: 1,
                                              },
                                            ],
                                        [],
                                      )
                                    : props.GetLink(
                                        [
                                          {
                                            Mode: th.GroupName,
                                            Index: 0,
                                          },
                                          {
                                            Mode: "Tasklists",
                                            Index: 1,
                                          },
                                          {
                                            Mode: ith.TLRef,
                                            Index: 2,
                                          },
                                        ],
                                        [],
                                      )
                                }
                                className="TaskList"
                              >
                                {ith.Name}
                              </Link>
                            </IfPermitted>
                          );
                        },
                      )}
                      {th.GroupName === "ESF" ? (
                        <IfPermitted Whitelist={["ESF.Uploader"]}>
                          <Link
                            to={props.GetLink(
                              [{ Mode: "Upload", Index: 0 }],
                              [],
                            )}
                            className="TaskList"
                          >
                            Upload a File
                          </Link>
                        </IfPermitted>
                      ) : (
                        <></>
                      )}
                    </IfPermitted>
                  );
                }
              })
            : ""}
        </div>
      </div>
    </div>
  );
}
