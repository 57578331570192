export interface SPOMetadata {
  DataName: string;
  DataValue: string | boolean | Date;
  Validation: SPOMValState;
}

export enum SPOMValState {
  None,
  Pending,
  Complete,
}
