import { getIconClassName } from "@fluentui/react";
import * as React from "react";

import "../../assets/css/PGEAF.css";
import { GridWrapper } from "../../integrations/UoE-Grid/GridWrapper";
import ModeStruct from "../../models/ModeStruct";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, IParams, User } from "../../data/DataAccess";
import SPOUploadLocation from "../../models/SPOUploadLocation";
import { Logging } from "../../Logging";
import { UploadManager } from "../../integrations/SPUploadManager/UploadManager";
import IfPermitted from "../IfPermitted";
import { IMsalContext } from "@azure/msal-react";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import { SURFACE } from "../../Providers/SurfaceProvider";

interface IProps {
  MSAL: IMsalContext;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Logger: Logging;
  da: DataAccess;
  IDA: InvarDataAccess;
  PCA: PublicClientApplication;
  GraphAccessToken: string;
  URLRoot: string;
  URLParams: string;
  PGNo: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  AuthedUser: User;
  ViewAsUser: User;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface IState {
  Tab: string;
  ShowUploader: boolean;
}

class PGEAF extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      Tab: "ApplicantDetails",
      ShowUploader: false,
    };
  }

  private _appformdocCTs: string[] = ["Application Summary"];

  private _casdocCTs: string[] = ["CAS Statement"];

  private _offdocCTs: string[] = [
    "Offer Document",
    "padedocacceptance",
    "pad001am",
    "pad001bm",
    "pad001bm2",
    "pad001cm",
    "pad001dm2",
    "pad009m",
    "pad013am",
    "pad013cm",
    "pad013dm",
    "pad013m",
    "pad014m",
    "pad015m",
    "pad017am",
    "pad017bm",
    "pad017m",
    "pad021am",
    "pad012bm",
    "pad021m",
    "pad023m",
    "pad046am",
    "pad046bm",
    "pad046cm",
    "pad046dm",
    "pad047m",
    "pad048m",
  ];

  private _appdocCTs: string[] = [
    "Application Summary",
    "Offer Document",
    "padedocacceptance",
    "pad001am",
    "pad001bm",
    "pad001bm2",
    "pad001cm",
    "pad001dm2",
    "pad009m",
    "pad013am",
    "pad013cm",
    "pad013dm",
    "pad013m",
    "pad014m",
    "pad015m",
    "pad017am",
    "pad017bm",
    "pad017m",
    "pad021am",
    "pad012bm",
    "pad021m",
    "pad023m",
    "pad046am",
    "pad046bm",
    "pad046cm",
    "pad046dm",
    "pad047m",
    "pad048m",
    "CAS Statement",
  ];

  render() {
    var PassToAllGrids = {
      Callouts: true,
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      pgno: this.props.PGNo.toUpperCase(),
      class: "UoE-Grid EAF",
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <div className="PGEAF">
        <div className="SectionRow BigHeader HideCol">
          <GridWrapper
            {...PassToAllGrids}
            eid={"ApplicantHeaderTop_" + this.props.Refresher}
            sproc="sputilities.webui.PGEAF_SUMMARY_applicant_header_top"
            params={["@pgno", this.props.PGNo.toUpperCase()]}
            output="data"
          ></GridWrapper>
        </div>
        <div className="ColumnLeft">
          <div className="SectionRow">
            <div className="Segmentary">
              <div className="Segments">
                <h2 className="Segment NoBreak">
                  <i
                    className={getIconClassName("GroupedList")}
                    aria-hidden="true"
                  ></i>{" "}
                  Choices and Offers
                </h2>
                <div className="Segment Righter">
                  <span className="CurrentExample PASExample">
                    Current Priority Agent Application
                  </span>
                  <span className="CurrentExample">Current Choice</span>
                  <span className="PastExample PASExample">
                    Past Priority Agent Application
                  </span>
                  <span className="PastExample">Past Choice</span>
                </div>
              </div>
            </div>
            <span id="ChoicesOffers">
              <GridWrapper
                {...PassToAllGrids}
                eid={"ChoicesOffers_" + this.props.Refresher}
                sproc="sputilities.webui.PGEAF_SUMMARY_Choices_And_Offers"
                params={[
                  "@pgno",
                  this.props.PGNo.toUpperCase(),
                  "@username",
                  "{%iu}",
                ]}
                output="accordion"
                heading="Choice_Summary"
                sort="Choice_Summary"
                ButtonsAndFunctions={[
                  {
                    Name: "Mark Choice as Processed",
                    ColumnKey: "_choiceID",
                    Function: (id: string) => {
                      var gleeb = id.split(",");
                      this.MarkChoiceAsProcessed(gleeb[0], gleeb[1], gleeb[2]);
                    },
                  },
                ]}
              ></GridWrapper>
            </span>
          </div>
          <div className="SectionRow Tabbed">
            <div className="HorizontalTabZone">
              <div
                className={
                  "Tab " +
                  (this.state.Tab === "ApplicantDetails" ? "Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "ApplicantDetails" });
                }}
              >
                <i
                  className={getIconClassName("Contact")}
                  aria-hidden="true"
                ></i>{" "}
                Applicant Details
              </div>
              <div
                className={
                  "Tab " + (this.state.Tab === "ContactDetails" ? "Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "ContactDetails" });
                }}
              >
                <i
                  className={getIconClassName("ContactCard")}
                  aria-hidden="true"
                ></i>{" "}
                Contact Details
              </div>
              <div
                className={
                  "Tab " + (this.state.Tab === "Qualifications" ? "Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "Qualifications" });
                }}
              >
                <i
                  className={getIconClassName("Education")}
                  aria-hidden="true"
                ></i>{" "}
                Qualifications
              </div>
              <div
                className={
                  "Tab " + (this.state.Tab === "References" ? "Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "References" });
                }}
              >
                <i className={getIconClassName("Flow")} aria-hidden="true"></i>{" "}
                References
              </div>
              <div
                className={
                  "Tab " + (this.state.Tab === "Residency" ? "Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "Residency" });
                }}
              >
                <i
                  className={getIconClassName("MapPin")}
                  aria-hidden="true"
                ></i>{" "}
                Residency
              </div>
              <div
                className={
                  "Tab " +
                  (this.state.Tab === "OtherInformation" ? "Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "OtherInformation" });
                }}
              >
                <i className={getIconClassName("Info")} aria-hidden="true"></i>{" "}
                Other Information
              </div>
              <div
                className={
                  "Tab " +
                  (this.state.Tab === "ApplicationForms" ? "Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "ApplicationForms" });
                }}
              >
                <i
                  className={getIconClassName("TextDocument")}
                  aria-hidden="true"
                ></i>{" "}
                Application Forms
              </div>
            </div>
            <div
              id="ApplicantDetails"
              hidden={this.state.Tab !== "ApplicantDetails"}
              className="TabContent"
            >
              <GridWrapper
                {...PassToAllGrids}
                eid={"ApplicantDetails_" + this.props.Refresher}
                sproc="sputilities.webui.PGEAF_SUMMARY_applicant_details"
                params={["@pgno", this.props.PGNo.toUpperCase()]}
                output="data"
              ></GridWrapper>
            </div>
            <div
              id="ContactDetails"
              hidden={this.state.Tab !== "ContactDetails"}
              className="TabContent"
            >
              <GridWrapper
                {...PassToAllGrids}
                eid={"ContactDetails_" + this.props.Refresher}
                sproc="sputilities.webui.PGEAF_SUMMARY_applicant_contact_details"
                params={["@pgno", this.props.PGNo.toUpperCase()]}
                output="data"
                rename="E-mail,Email"
              ></GridWrapper>
            </div>
            <div
              id="Qualifications"
              hidden={this.state.Tab !== "Qualifications"}
              className="TabContent"
            >
              <GridWrapper
                {...PassToAllGrids}
                eid={"Qualifications_" + this.props.Refresher}
                sproc="sputilities.webui.PGEAF_SUMMARY_applicant_quals"
                params={["@pgno", this.props.PGNo.toUpperCase()]}
                output="data"
              ></GridWrapper>
            </div>
            <div
              id="References"
              hidden={this.state.Tab !== "References"}
              className="TabContent"
            >
              <GridWrapper
                {...PassToAllGrids}
                eid={"References_" + this.props.Refresher}
                sproc="sputilities.webui.PGEAF_SUMMARY_applicant_references_api"
                params={["@pgno", this.props.PGNo.toUpperCase()]}
                output="data"
              ></GridWrapper>
            </div>
            <div
              id="Residency"
              hidden={this.state.Tab !== "Residency"}
              className="TabContent"
            >
              <GridWrapper
                {...PassToAllGrids}
                eid={"Residency_" + this.props.Refresher}
                sproc="sputilities.webui.PGEAF_SUMMARY_applicant_residency"
                params={["@pgno", this.props.PGNo.toUpperCase()]}
                output="data"
              ></GridWrapper>
            </div>
            <div
              id="OtherInformation"
              hidden={this.state.Tab !== "OtherInformation"}
              className="TabContent"
            >
              <GridWrapper
                {...PassToAllGrids}
                eid={"ApplicantDetails_" + this.props.Refresher}
                sproc="sputilities.webui.PGEAF_SUMMARY_applicant_otherinfo"
                params={["@pgno", this.props.PGNo.toUpperCase()]}
                output="data"
              ></GridWrapper>
              <div className="JustASpacer"></div>
              <GridWrapper
                {...PassToAllGrids}
                eid={"East15Details_" + this.props.Refresher}
                sproc="sputilities.webui.PGEAF_SUMMARY_east15suppl"
                params={["@pgno", this.props.PGNo.toUpperCase()]}
                output="data"
              ></GridWrapper>
            </div>
            <div
              id="ApplicationForms"
              hidden={this.state.Tab !== "ApplicationForms"}
              className="TabContent"
            >
              <div className="UoE-DataList">
                <ul className="DataList">
                  <li className="DataRow HeaderChoice APPLICATION FORMS">
                    <em className="column-name HeaderChoice">Headerchoice:</em>
                    <span className="column-value">APPLICATION FORMS</span>
                  </li>
                </ul>
              </div>
              <GridWrapper
                {...PassToAllGrids}
                eid={"ApplicationFormsDocs_" + this.props.Refresher}
                ForceProxy={false}
                output="edocs"
                sort="Created Date"
                sortdesc="true"
                target="_blank"
                contentTypes={this._appformdocCTs}
              ></GridWrapper>
            </div>
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Documentation")}
                aria-hidden="true"
              ></i>{" "}
              Applicant Documents
            </h2>
            <IfPermitted Whitelist={["PGEAF.Uploader"]}>
              <div
                className="UploadButton"
                onClick={() => {
                  this.setState({ ShowUploader: !this.state.ShowUploader });
                }}
              >
                <i className={getIconClassName("Add")} aria-hidden="true"></i>{" "}
                Upload New Document(s)
              </div>
            </IfPermitted>
            <p className="JustASpacer"></p>
            {this.state.ShowUploader === false ? (
              <GridWrapper
                {...PassToAllGrids}
                eid={"ApplicantDocuments_" + this.props.Refresher}
                ForceProxy={false}
                output="edocs"
                sort="Created Date"
                sortdesc="true"
                target="_blank"
                contentTypes={this._appdocCTs}
                blackList={true}
              ></GridWrapper>
            ) : (
              <UploadManager
                UploadConveyance={"PGEAF Direct Upload --> SPO"}
                Autofill={{ Type: "PGNo", Value: PassToAllGrids.pgno }}
                SPUL={this.props.GetSPUL("PGEAF")}
                SwitchBack={() => {
                  this.setState({ ShowUploader: false });
                }}
                IncludeRUG={false}
                {...PassToAllGrids}
                IDA={this.props.IDA}
                Environment={this.props.Environment}
                GraphAccessToken={this.props.GraphAccessToken}
                Refresh={this.props.Refresh}
              />
            )}
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Documentation")}
                aria-hidden="true"
              ></i>{" "}
              Offer Documents
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"OfferDocuments_" + this.props.Refresher}
              ForceProxy={false}
              output="edocs"
              sort="Created Date"
              sortdesc="true"
              target="_blank"
              contentTypes={this._offdocCTs}
            ></GridWrapper>
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Documentation")}
                aria-hidden="true"
              ></i>{" "}
              CAS Documents
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"CASDocuments_" + this.props.Refresher}
              ForceProxy={false}
              output="edocs"
              sort="Created Date"
              sortdesc="true"
              target="_blank"
              contentTypes={this._casdocCTs}
            ></GridWrapper>
          </div>
        </div>
        <div className="ColumnRight">
          <div className="SectionRow">
            <h2>
              <i className={getIconClassName("Contact")} aria-hidden="true"></i>{" "}
              Applicant Information
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"ApplicantInformation_" + this.props.Refresher}
              sproc="sputilities.webui.PGEAF_SUMMARY_applicant_header"
              params={["@pgno", this.props.PGNo.toUpperCase()]}
              output="data"
              rename="Nameheader,_N;app_origin,_AO"
            ></GridWrapper>
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={
                  getIconClassName("EngineeringGroup") + " .ColourInFlight"
                }
                aria-hidden="true"
              ></i>{" "}
              Workflows in Progress
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"WorkflowsInProgress_" + this.props.Refresher}
              sproc="sputilities.webui.PGEAF_SUMMARY__Task_Pane_In_Progress"
              params={["@EK1", this.props.PGNo.toUpperCase()]}
            ></GridWrapper>
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Completed") + " .ColourCompleted"}
                aria-hidden="true"
              ></i>{" "}
              Workflows Completed
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"WorkflowsCompleted_" + this.props.Refresher}
              sproc="sputilities.webui.PGEAF_SUMMARY_Task_Pane_Complete"
              params={["@EK1", this.props.PGNo.toUpperCase(), "@user", "{%iu}"]}
              columnclass="Workflow,WorkflowCompleted"
              sort="Completed"
              sortdesc="true"
            ></GridWrapper>
          </div>
        </div>
      </div>
    );
  }

  MarkChoiceAsProcessed(pgno: string, asc: string, choiceno: string) {
    //  console.log("MarkChoiceAsProcessed");
    //  console.log(pgno);
    //  console.log(asc);
    //  console.log(choiceno);

    const params: IParams = {
      sproc: "sputilities.webui.PGEAF_SUMMARY_MarkChoiceAsProcessed",
      params: ["@pg_no", pgno, "@app_scheme_code", asc, "@choice_no", choiceno],
    };

    //  console.log(params);

    this.props.da.get("sproc", JSON.stringify(params), true);

    //  console.log(this.props);
    //  console.log(this.props.Refresh);

    this.props.Refresh();
  }
}

export default PGEAF;
