import { IItem } from "../../models/IItem";

export function toTitleCase(str: string): string {
  if (str === str.toUpperCase()) return str;
  var alwaysUpperCase = ["ID", "PRID", "UCAS", "UK", "FT", "PT"];
  var alwaysLowerCase = [
    "in",
    "of",
    "or",
    "on",
    "to",
    "and",
    "at",
    "is",
    "for",
    "if",
    "as",
    "from",
  ];
  var newStr = "";
  var split = str.split(" ");
  split.forEach((word) => {
    if (word === word.toUpperCase()) {
      newStr += word;
    } else if (alwaysUpperCase.includes(word.toUpperCase())) {
      newStr += word.toUpperCase();
    } else if (
      split.length > 1 &&
      split[0] !== word &&
      alwaysLowerCase.includes(word.toLowerCase())
    ) {
      newStr += word.toLowerCase();
    } else {
      newStr += word.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    newStr += " ";
  });
  return newStr.trim();
}

export function sortItems(
  items: IItem[],
  sort?: string,
  sortdesc?: string,
): IItem[] {
  let sortBy: string[] = [];
  let sortDesc: boolean[] = [];
  if (sort !== undefined) {
    sort.split(",").forEach((c, i) => {
      sortBy.push(c);
      sortDesc.push(
        sortdesc !== undefined &&
          sortdesc.split(",").length > i &&
          sortdesc.split(",")[i].toLowerCase() === "true",
      );
    });
  }
  return items.sort((a: IItem, b: IItem) => {
    for (let i = 0; i < sortBy.length; i++) {
      const col = sortBy[i];

      if (isNaN(+a[col]) || isNaN(+b[col])) {
        if (a[col] < b[col]) return sortDesc[sortBy.indexOf(col)] ? 1 : -1;
        if (a[col] > b[col]) return sortDesc[sortBy.indexOf(col)] ? -1 : 1;
      } else {
        if (+a[col] < +b[col]) return sortDesc[sortBy.indexOf(col)] ? 1 : -1;
        if (+a[col] > +b[col]) return sortDesc[sortBy.indexOf(col)] ? -1 : 1;
      }
    }
    return 0;
  });
}
