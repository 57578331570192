import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../data/DataAccess";
import { Logging } from "../../Logging";
import "../../assets/css/UGEAF_LF/UGEAF_LF.css";
import { Link } from "react-router-dom";
import IfPermitted from "../IfPermitted";
import dayjs from "dayjs";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<string>;
  AuthedUser: User;
  ViewAsUser: User;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;

  Navigation: (url: string) => void;
}

interface IState {
  // functional state parts
  Errors: string[];
  _SearchFor: string;
}

const StringRegexIdentifier = /^[a-zA-Z0-9]*$/;
const StringRegexName = /^[a-zA-Z -]*$/;
const StringRegexEmail = /^[a-zA-Z0-9\.-]*@essex\.ac\.uk$/;
const DateRegexESIS = /^[0-9]{2}\/[a-zA-Z]{3}\/[0-9]{4}$/;
const DateRegexHyphen = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
const DateRegexSlash = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;

class UGEAF_LF extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      _SearchFor: "",
      Errors: [],
    };
  }

  public async componentDidMount() {
    window.document.title =
      "University of Essex - Undergraduate Electronic Applicant Files";
  }

  GetValidDOB = (term: string): dayjs.Dayjs | undefined => {
    if (DateRegexSlash.test(term)) {
      // dayjs constructor has parameters for format and locale,
      // but either doesn't respect them or has an undocumented gotcha
      // that I'm sure exists on the other end of two days' googling
      // or something equally insufferable

      //  let ret = dayjs();
      //  let splits = term.split("/").map((th) => Number.parseInt(th));
      //  ret = ret
      //    .set("date", splits[0])
      //    .set("month", splits[1] - 1) // good old zero-indexed months
      //    .set("year", splits[2]);
      //  if (ret.isValid()) {
      //    return ret;
      //  }

      // just so; the parameters don't work at all unless you import a
      // specific extra sublibrary. the method does not tell you they don't work.
      // it just blithely goes on about its day as though it respected all your
      // inputs. this industry is driving me to insanity.

      if (dayjs(term, "DD/MM/YYYY", true).isValid()) {
        return dayjs(term, "DD/MM/YYYY", true);
      }
    }

    if (DateRegexHyphen.test(term)) {
      if (dayjs(term, "DD-MM-YYYY", true).isValid()) {
        return dayjs(term, "DD-MM-YYYY", true);
      }
    }

    if (DateRegexESIS.test(term)) {
      // haha, this one doesn't work in strict! Very odd.
      if (dayjs(term).isValid()) {
        return dayjs(term);
      }
    }

    return undefined;
  };

  IsValidStringSearch = (term: string): boolean => {
    return (
      StringRegexEmail.test(term) ||
      StringRegexName.test(term) ||
      StringRegexIdentifier.test(term)
    );
  };

  IsValidSearch = (term: string): boolean => {
    return (
      StringRegexEmail.test(term) ||
      StringRegexName.test(term) ||
      StringRegexIdentifier.test(term) ||
      DateRegexESIS.test(term) ||
      DateRegexHyphen.test(term) ||
      DateRegexSlash.test(term)
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="StructPanels">
          <div className="StructPanelCluster Row Halved">
            <IfPermitted Whitelist={["UGEAF.Reader"]}>
              <div className="StructPanelBlock">
                <div className="StructPanel Cassette">
                  <div className="PanelBar Title">
                    <div className="Left">
                      Search Current/Archived Applicants
                    </div>
                    <div className="Breaker"></div>
                    <div className="Right">UGEAF</div>
                  </div>
                  {/* <div className='PanelBar Action Search'>Search Current or Archived Applicants</div> */}
                  <div className="PanelBody">
                    <div id="Search">
                      <p className="InputRow Wide">
                        <label htmlFor="Searcher">
                          Enter a UCAS ID, surname, PRID, or DA Number.
                        </label>
                      </p>
                      <p className="InputRow Wide">
                        <input
                          className={
                            !this.IsValidSearch(this.state._SearchFor)
                              ? "InvalidSearch"
                              : undefined
                          }
                          type="text"
                          value={this.state._SearchFor}
                          id="Searcher"
                          name="Searcher"
                          onChange={(event) => {
                            const pattern = /^[0-9A-Za-z\/ '\-@\.]*$/g;
                            const inp = event.target.value;
                            this.setState({
                              _SearchFor:
                                inp === ""
                                  ? ""
                                  : pattern.test(inp)
                                    ? inp
                                    : this.state._SearchFor,
                            });
                          }}
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              const sterm =
                                this.state._SearchFor ??
                                "".trim().replaceAll(" ", "_");

                              const dterm = this.GetValidDOB(sterm);

                              if (dterm !== undefined) {
                                this.props.Navigation(
                                  "/UGEAF/Search/CurrentUGApplicantsbyDOB/" +
                                    dterm.format("DD-MM-YYYY"),
                                );
                              } else {
                                this.props.Navigation(
                                  "/UGEAF/Search/CurrentUGApplicants/" + sterm,
                                );
                              }
                            }
                          }}
                          onPaste={(e) => {
                            const sterm = e.clipboardData
                              .getData("text")
                              .trim();

                            if (DateRegexESIS.test(sterm)) {
                              const dterm = this.GetValidDOB(sterm);
                              if (dterm !== undefined) {
                                // Only accept values in format 01/JAN/2021 or 01-01-1999
                                this.props.Navigation(
                                  "/UGEAF/Search/CurrentUGApplicantsbyDOB/" +
                                    dterm.format("DD-MM-YYYY"),
                                );
                              }
                            }
                          }}
                        />
                        {this.state._SearchFor === "" ? (
                          <></>
                        ) : this.IsValidSearch(this.state._SearchFor) ? (
                          <></>
                        ) : this.GetValidDOB(this.state._SearchFor) !==
                          undefined ? (
                          <></>
                        ) : (
                          <div className="InvalidSearch">
                            Invalid or incomplete input. This field accepts
                            dates, names, logins, or UoE reference codes (PRID,
                            UCAS No, etc).
                          </div>
                        )}
                      </p>
                      <p className="SubmitRow Left">
                        {this.IsValidStringSearch(this.state._SearchFor) ? (
                          <Link
                            to={this.props.GetLink(
                              [
                                { Mode: "UGEAF", Index: 0 },
                                { Mode: "Search", Index: 1 },
                                {
                                  Mode: "CurrentUGApplicants",
                                  Index: 2,
                                },
                                {
                                  Mode: this.state._SearchFor
                                    .trim()
                                    .replaceAll(" ", "_"),
                                  Index: 3,
                                },
                              ],
                              [],
                            )}
                          >
                            Search Current Applicants
                          </Link>
                        ) : this.GetValidDOB(this.state._SearchFor) !==
                          undefined ? (
                          <Link
                            className="CurrentSearch"
                            to={this.props.GetLink(
                              [
                                { Mode: "UGEAF", Index: 0 },
                                { Mode: "Search", Index: 1 },
                                {
                                  Mode: "CurrentUGApplicantsbyDOB",
                                  Index: 2,
                                },
                                {
                                  Mode: this.GetValidDOB(
                                    this.state._SearchFor,
                                  ).format("DD-MM-YYYY"),
                                  Index: 3,
                                },
                              ],
                              [],
                            )}
                          >
                            Search Current Applicants
                          </Link>
                        ) : (
                          <span className="CurrentSearch Disabled">
                            Search Current Applicants
                          </span>
                        )}
                        {this.IsValidStringSearch(this.state._SearchFor) ? (
                          <Link
                            className="ArchiveSearch"
                            to={this.props.GetLink(
                              [
                                { Mode: "UGEAF", Index: 0 },
                                { Mode: "Search", Index: 1 },
                                { Mode: "ArchivedUGApplicants", Index: 2 },
                                {
                                  Mode: this.state._SearchFor.trim(),
                                  Index: 3,
                                },
                              ],
                              [],
                            )}
                          >
                            Search Archived Applicants
                          </Link>
                        ) : (
                          <span className="ArchiveSearch Disabled">
                            Search Archived Students
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </IfPermitted>
            <div className="StructPanelBlock">
              <IfPermitted Whitelist={["UGEAF.Uploader"]}>
                <Link
                  className="StructPanel"
                  to={this.props.GetLink(
                    [
                      { Mode: "UGEAF", Index: 0 },
                      { Mode: "Upload", Index: 1 },
                    ],
                    [],
                  )}
                >
                  <div className="PanelBar Title">
                    <div className="Left">Upload Documents</div>
                    <div className="Breaker"></div>
                    <div className="Right">UGEAF</div>
                  </div>
                  {/* <div className='PanelBar Action UG'>Upload Documents</div> */}
                  <div className="PanelBody">
                    Upload supporting documents to applications.
                  </div>
                </Link>
              </IfPermitted>
              <IfPermitted Whitelist={["UGEAF.Admissions"]}>
                <Link
                  className="StructPanel"
                  to={this.props.GetLink(
                    [
                      { Mode: "UGEAF", Index: 0 },
                      { Mode: "Utilities", Index: 1 },
                      { Mode: "RequestPrintView", Index: 2 },
                    ],
                    [],
                  )}
                >
                  <div className="PanelBar Title">
                    <div className="Left">Request a Print View</div>
                    <div className="Breaker"></div>
                    <div className="Right">UGEAF</div>
                  </div>
                  {/* <div className='PanelBar Action UG'>Request a Print View</div> */}
                  <div className="PanelBody">
                    Use this tool to re-generate a UGEAF Print View application
                    summary when required.
                  </div>
                </Link>
              </IfPermitted>
            </div>
          </div>
          <div className="StructPanelCluster Row Thirded">
            <IfPermitted Whitelist={["UGEAF.Admissions"]}>
              <div className="StructPanelBlock">
                <h2>For Admissions Staff Only</h2>
                <div className="StructPanel Cassette">
                  <div className="PanelBar Title">
                    <div className="Left">
                      Standard application task screens
                    </div>
                    <div className="Breaker"></div>
                    <div className="Right"></div>
                  </div>
                  <div className="PanelBody"></div>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_UGDRF_1_UNPROCESSED", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    1: Unprocessed Applications
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_2A_APPS_WITH_SELECTOR",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    2a: Applicants with Selector
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_UGDRF_2D_PENDING", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    2d: Pending Applications
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_3_INTERVIEW_INVITE_PENDING_APPROVAL",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    3: Interview Invite Pending Approval
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_4V_VIDEO_INTERVIEW_PENDING",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    4i: Interview Pending
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_5A_PROCESSED_PENDING",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    5a: Applicants Processed by Selector Pending Approval
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_5B_APPROVED_PENDING_TRANSACTION",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    5b: Approved & Pending Transaction
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_5C_APPROVED_PENDING_UCAS",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    5c: Approved & Pending UCAS
                  </Link>
                </div>
                <IfPermitted Whitelist={["UGEAF.Admissions"]}>
                  <div className="StructPanel UsefulLinks Cassette">
                    <div className="PanelBar Title">
                      <div className="Left">Useful Links</div>
                      <div className="Breaker"></div>
                      <div className="Right"></div>
                    </div>
                    <div className="PanelBody">
                      <p>These links will open in a new tab.</p>
                      <p>
                        <span>
                          <a
                            href="https://essexuniversity.sharepoint.com/sites/EDRM/"
                            target="_blank"
                          >
                            EDRM Search Hub
                          </a>
                        </span>
                      </p>
                      <p>
                        <span>
                          <a
                            href="https://amp.essex.ac.uk/UEIC/PreSessionalEnglishChoice/Index"
                            target="_blank"
                          >
                            AMP
                          </a>
                        </span>
                      </p>
                      <p>
                        <span>
                          <a href="https://eds.essex.ac.uk/" target="_blank">
                            Electronic Documentation System
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                </IfPermitted>
              </div>

              <div className="StructPanelBlock">
                <h2>&nbsp;</h2>

                {
                  //  {Name: '2b: UG Selector Tasks', TLRef: 'T_UGDRF_2B_SELECTOR_TASKS'}
                  //  {Name: '4e: East15 Awaiting Audition', TLRef: 'T_UGDRF_4E_EAST15_AWAITING_AUDITION'}
                  //  {Name: '1: HSC - Unprocessed Applications', TLRef: 'T_UGDRF_HSC_1_UNPROCESSED'}
                }
                <div className="StructPanel Cassette">
                  <div className="PanelBar Title">
                    <div className="Left">
                      Non-standard application task screens
                    </div>
                    <div className="Breaker"></div>
                    <div className="Right"></div>
                  </div>
                  <div className="PanelBody"></div>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_UGDRF_PAS_1_UNPROCESSED", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    PAS 1: Unprocessed Applications
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_PAS_2A_APPS_WITH_SELECTOR",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    PAS 2a: Applicants with Selector
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_UGDRF_PAS_2D_PENDING", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    PAS 2d: Pending Applications
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_PAS_5A_PROCESSED_PENDING",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    PAS 5a: Applicants Processed by Selector Pending Approval
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_PAS_5B_APPROVED_PENDING_TRANSACTION",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    PAS 5b: Approved & Pending Transaction
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_PAS_5C_APPROVED_PENDING_UCAS",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    PAS 5c: Approved & Pending UCAS
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_UGDRF_HSC_1_UNPROCESSED", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    HSC 1: Apprenticeships Unprocessed
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_UGDRF_UEIC_1_UNPROCESSED", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    UEIC 1: Unprocessed
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_UEIC_APPROVED_PENDING",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    UEIC 5b: Approved & Pending Transaction
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_UGDRF_PSE_1_UNPROCESSED", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    PSE 1: Unprocessed
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_UGDRF_PSE_2D_PENDING", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    PSE 2d: Pending
                  </Link>
                </div>
              </div>
            </IfPermitted>
            <IfPermitted Whitelist={["UGEAF.Reader"]}>
              <div className="StructPanelBlock">
                <h2>For Academic Department Staff, Selectors, & Admissions</h2>
                {/* <br /> */}
                {/* <Link
                  className="StructPanel Cassette"
                  to={this.props.GetLink(
                    [
                      { Mode: "pg", Index: 0 },
                      { Mode: "current", Index: 1 },
                    ],
                    [],
                  )}
                > */}
                <div className="StructPanel Cassette">
                  <div className="PanelBar Title">
                    <div className="Left">
                      Non-standard application task screens
                    </div>
                    <div className="Breaker"></div>
                    <div className="Right"></div>
                  </div>
                  <div className="PanelBody"></div>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        { Mode: "WEBUI_T_UGDRF_2B_SELECTOR_TASKS", Index: 2 },
                      ],
                      [],
                    )}
                  >
                    2b: Admissions UG Selector Tasks
                  </Link>
                  <Link
                    className="PanelBar Action"
                    to={this.props.GetLink(
                      [
                        { Mode: "UGEAF", Index: 0 },
                        { Mode: "Tasklists", Index: 1 },
                        {
                          Mode: "WEBUI_T_UGDRF_4E_EAST15_AWAITING_AUDITION",
                          Index: 2,
                        },
                      ],
                      [],
                    )}
                  >
                    4e: East15 Awaiting Audition
                  </Link>
                </div>
                {/* </Link> */}
              </div>
            </IfPermitted>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UGEAF_LF;
