import { FolderArrowRight20Regular } from "@fluentui/react-icons";

interface IProps {
  prid: string;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
}

export function ESFLink(props: IProps) {
  var link: string = "";
  if (props.Environment === "LOCAL") {
    link = "http://localhost:6227/ESF/" + props.prid;
  } else if (props.Environment === "DEV") {
    link = "https://esfdev.essex.ac.uk/ESF/" + props.prid;
  } else if (props.Environment === "TEST") {
    link = "https://esftest.essex.ac.uk/ESF/" + props.prid;
  } else if (props.Environment === "LIVE") {
    link = "https://esf.essex.ac.uk/ESF/" + props.prid;
  }

  return props.Environment === "LOCAL" ? (
    <a title="Environment switch not possible on local; change auto-environment in code.">
      <FolderArrowRight20Regular
        color="Red"
        font-size="x-large"
        font-weight="Bolder"
      ></FolderArrowRight20Regular>
      {"  "}
      <span id="gtesf">Go To ESF Record</span>
    </a>
  ) : (
    <a href={link}>
      <FolderArrowRight20Regular
        color="white"
        font-size="x-large"
        font-weight="Bolder"
      ></FolderArrowRight20Regular>
      {"  "}
      <span id="gtesf">Go To ESF Record</span>
    </a>
  );
}
