import * as React from "react";
import { Logging, LoggingLevel } from "../../Logging";
import "../../assets/css/WorkflowAssignmentGroupEditor.css";
import ModeStruct from "../../models/ModeStruct";
import { getIconClassName } from "@fluentui/react";
import {
  MailAddRegular,
  MailDismissRegular,
  MailMultipleRegular,
  MailSettingsRegular,
} from "@fluentui/react-icons";
import { IItem } from "../../models/IItem";
import { DataAccess, IParams, MODE } from "../../data/DataAccess";
import ErrorMessage from "../../models/ErrorMessage";
import StatusMessage from "../../models/StatusMessage";
import { PublicClientApplication } from "@azure/msal-browser";
import { SurfaceAttributes } from "../../Providers/SurfaceProvider";
import { useEffect, useState } from "react";
import { useApi } from "../../Providers/ApiProvider";

enum TriState {
  Unset,
  True,
  False,
}

export const WorkflowAssignmentGroupEditor = (props: WAGEProps) => {
  const API = useApi();
  const [possibleUsername, setPossibleUsername] = useState("");
  const [usernameRegexMatch, setUsernameRegexMatch] = useState(TriState.Unset);
  const [usernameValid, setUsernameValid] = useState(TriState.Unset);

  const usernameRegex = /^[a-zA-Z0-9]*$/;

  // whenever Username is set to a non-"" value, regex check it.
  useEffect(() => {
    if (possibleUsername !== "") {
      if (usernameRegex.test(possibleUsername)) {
        setUsernameRegexMatch(TriState.True);
      } else {
        setUsernameRegexMatch(TriState.False);
      }
    }
  }, [possibleUsername]);

  // whenever Username is a string of 3 or more characters,
  // and has passed regex check,
  // then validate it against db.
  // (we have to validate at 3 letters because some staff genuinely have 3-letter usernames)
  useEffect(() => {
    if (usernameRegexMatch && possibleUsername.length > 2) {
      console.log("post-regex effect...");
      console.log(usernameRegexMatch);
      console.log(possibleUsername);
      API.General.get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.WAGE_ValidateUsername",
          params: ["@ValidateUsername", possibleUsername],
        }),
      ).then((resp) => {
        console.log(resp);
        console.log(resp[0]);
        console.log(resp[0][0]);
        console.log(resp[0][0].Valid);
        if (resp[0][0].Valid === "1") {
          setUsernameValid(TriState.True);
        } else {
          setUsernameValid(TriState.False);
        }
      });
    }
  }, [possibleUsername, usernameRegexMatch]);

  return (
    <InnerWorkflowAssignmentGroupEditor
      {...props}
      PossibleUsername={possibleUsername}
      SetPossibleUsername={setPossibleUsername}
      UsernameRegexMatch={usernameRegexMatch}
      UsernameValid={usernameValid}
    />
  );
};

enum QueuedOperationType {
  NotificationsToOverrideOnly,
  NotificationsToMembershipAndOverride,
  NotificationsToMembershipOnly,
  DeleteMember,
  MergeGroups,
  SplitGroups,
  NotificationsToMuted,
  AddMember,
  SetGroupNotifier,
}

interface WAGEProps {
  Logger: Logging;
  Environment: NonNullable<string>;
  PCA: PublicClientApplication;
  Surface: SurfaceAttributes;
  da: DataAccess;
  Mode: string[];
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
}

interface InnerWAGEProps {
  Logger: Logging;
  Environment: NonNullable<string>;
  PCA: PublicClientApplication;
  Surface: SurfaceAttributes;
  da: DataAccess;
  Mode: string[];
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;

  PossibleUsername: string;
  SetPossibleUsername: (input: string) => void;
  UsernameRegexMatch: TriState;
  UsernameValid: TriState;
}

interface InnerWAGEState {
  LiveDA: DataAccess;
  _connTest: boolean;
  _connLive: boolean;

  ErrorMessages: ErrorMessage[];
  StatusMessages: StatusMessage[];

  _workflowSystemFilters: string[];
  _departmentFacultyFilters: string[];

  UserFilter: string;
  _userFilter: string;

  _showWorkflowFilters: boolean;
  _showCampusFilters: boolean;
  _showStudyFilters: boolean;
  _showFacultyFilters: boolean;
  _showDepartmentFilters: boolean;
  _showStageFilters: boolean;
  _showUserFilter: boolean;

  _expandGO_AddUser: boolean;
  _expandGO_Split: boolean;
  _expandGO_Merge: boolean;

  _processingOperations: boolean;

  AllWorkflowFilters: WorkflowFilter[];
  AllCampusFilters: CampusFilter[];
  AllStudyFilters: StudyFilter[];
  AllFacultyFilters: FacultyFilter[];
  AllDepartmentFilters: DepartmentFilter[];
  AllWorkflowStageFilters: WorkflowStageFilter[];

  SelectedWorkflowFilters: WorkflowFilter[];
  SelectedCampusFilters: CampusFilter[];
  SelectedStudyFilters: StudyFilter[];
  SelectedFacultyFilters: FacultyFilter[];
  SelectedDepartmentFilters: DepartmentFilter[];
  SelectedWorkflowStageFilters: WorkflowStageFilter[];

  ActiveWorkflowFilters: WorkflowFilter[];
  ActiveCampusFilters: CampusFilter[];
  ActiveStudyFilters: StudyFilter[];
  ActiveFacultyFilters: FacultyFilter[];
  ActiveDepartmentFilters: DepartmentFilter[];
  ActiveWorkflowStageFilters: WorkflowStageFilter[];

  _loadingGroups: boolean;
  RetrievedWorkflowAssignmentGroups: WorkflowAssignmentGroup[];
  SelectedGroups: AssignmentGroup[];
  QueuedOperations: QueuedOperation[];
}

class InnerWorkflowAssignmentGroupEditor extends React.Component<
  any,
  InnerWAGEState
> {
  constructor(props: InnerWAGEProps) {
    super(props);

    var lda: DataAccess = undefined;

    if (props.Environment !== "LIVE") {
      lda = new DataAccess(
        props.Logger,
        props.PCA,
        MODE.PRODUCTION,
        props.Surface,
      );
    }

    this.state = {
      _connTest: true,
      _connLive: false,
      LiveDA: lda,

      ErrorMessages: [],
      StatusMessages: [],

      _workflowSystemFilters: [],
      _departmentFacultyFilters: [],

      UserFilter: "",
      _userFilter: "",

      _showWorkflowFilters: false,
      _showCampusFilters: false,
      _showStudyFilters: false,
      _showFacultyFilters: false,
      _showDepartmentFilters: false,
      _showStageFilters: false,
      _showUserFilter: false,

      _expandGO_AddUser: false,
      _expandGO_Split: false,
      _expandGO_Merge: false,

      _processingOperations: false,

      AllWorkflowFilters: [],
      AllCampusFilters: [],
      AllStudyFilters: [],
      AllFacultyFilters: [],
      AllDepartmentFilters: [],
      AllWorkflowStageFilters: [],

      SelectedWorkflowFilters: [],
      SelectedCampusFilters: [],
      SelectedStudyFilters: [],
      SelectedFacultyFilters: [],
      SelectedDepartmentFilters: [],
      SelectedWorkflowStageFilters: [],

      ActiveWorkflowFilters: [],
      ActiveCampusFilters: [],
      ActiveStudyFilters: [],
      ActiveFacultyFilters: [],
      ActiveDepartmentFilters: [],
      ActiveWorkflowStageFilters: [],

      _loadingGroups: true,
      RetrievedWorkflowAssignmentGroups: [],
      SelectedGroups: [],
      QueuedOperations: [],
    };
  }

  public async componentDidMount() {
    this.Load();
  }

  public async Load() {
    await this.RegisterStatus("Workflow Assignment Groups", "Scanning...").then(
      () => {
        this.GetGroups().then(async () => {
          await this.DeregisterStatus("Workflow Assignment Groups");
          await this.DeregisterError("Workflow Assignment Groups");
          //  console.log('View As User Permissions');
          //  console.log(this.state.ViewAsUserPermissions);
        });
      },
      async (error) => {
        await this.RegisterStatus("Workflow Assignment Groups", "Failed.");
        await this.RegisterError("Workflow Assignment Groups", error);
      },
    );

    await this.RegisterStatus("WAGE Workflow Filters", "Retrieving...").then(
      () => {
        this.GetWorkflowWorkflows().then(async () => {
          await this.DeregisterStatus("WAGE Workflow Filters");
          await this.DeregisterError("WAGE Workflow Filters");
          //  console.log('View As User Permissions');
          //  console.log(this.state.ViewAsUserPermissions);
        });
      },
      async (error) => {
        await this.RegisterStatus("WAGE Workflow Filters", "Failed.");
        await this.RegisterError("WAGE Workflow Filters", error);
      },
    );

    await this.RegisterStatus("WAGE Campus Filters", "Retrieving...").then(
      () => {
        this.GetWorkflowCampuses().then(async () => {
          await this.DeregisterStatus("WAGE Campus Filters");
          await this.DeregisterError("WAGE Campus Filters");
          //  console.log('View As User Permissions');
          //  console.log(this.state.ViewAsUserPermissions);
        });
      },
      async (error) => {
        await this.RegisterStatus("WAGE Campus Filters", "Failed.");
        await this.RegisterError("WAGE Campus Filters", error);
      },
    );

    await this.RegisterStatus("WAGE Study Filters", "Retrieving...").then(
      () => {
        this.GetWorkflowStudyNatureLevels().then(async () => {
          await this.DeregisterStatus("WAGE Study Filters");
          await this.DeregisterError("WAGE Study Filters");
          //  console.log('View As User Permissions');
          //  console.log(this.state.ViewAsUserPermissions);
        });
      },
      async (error) => {
        await this.RegisterStatus("WAGE Study Filters", "Failed.");
        await this.RegisterError("WAGE Study Filters", error);
      },
    );

    await this.RegisterStatus("WAGE Faculty Filters", "Retrieving...").then(
      () => {
        this.GetWorkflowFaculties().then(async () => {
          await this.DeregisterStatus("WAGE Faculty Filters");
          await this.DeregisterError("WAGE Faculty Filters");
          //  console.log('View As User Permissions');
          //  console.log(this.state.ViewAsUserPermissions);
        });
      },
      async (error) => {
        await this.RegisterStatus("WAGE Faculty Filters", "Failed.");
        await this.RegisterError("WAGE Faculty Filters", error);
      },
    );

    await this.RegisterStatus("WAGE Department Filters", "Retrieving...").then(
      () => {
        this.GetWorkflowDepartments().then(async () => {
          await this.DeregisterStatus("WAGE Department Filters");
          await this.DeregisterError("WAGE Department Filters");
          //  console.log('View As User Permissions');
          //  console.log(this.state.ViewAsUserPermissions);
        });
      },
      async (error) => {
        await this.RegisterStatus("WAGE Department Filters", "Failed.");
        await this.RegisterError("WAGE Department Filters", error);
      },
    );

    await this.RegisterStatus("WAGE Stage Filters", "Retrieving...").then(
      () => {
        this.GetWorkflowStages().then(async () => {
          await this.DeregisterStatus("WAGE Stage Filters");
          await this.DeregisterError("WAGE Stage Filters");
          //  console.log('View As User Permissions');
          //  console.log(this.state.ViewAsUserPermissions);
        });
      },
      async (error) => {
        await this.RegisterStatus("WAGE Stage Filters", "Failed.");
        await this.RegisterError("WAGE Stage Filters", error);
      },
    );
  }

  RegisterError = (_ref: string, _mess: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          ErrorMessages: [...this.state.ErrorMessages].concat({
            Reference: _ref,
            Message: _mess,
          }),
        },
        () => {
          if (
            this.state.ErrorMessages.filter((th) => th.Reference === _ref)
              .length === 0
          ) {
            reject("error did not register");
          } else {
            resolve(true);
          }
        },
      );
    });
  };

  DeregisterError = (_ref: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          ErrorMessages: [...this.state.ErrorMessages].filter(
            (th) => th.Reference !== _ref,
          ),
        },
        () => {
          if (
            this.state.ErrorMessages.filter((th) => th.Reference === _ref)
              .length > 0
          ) {
            reject("error was not deregistered");
          } else {
            resolve(true);
          }
        },
      );
    });
  };

  RegisterStatus = (_ref: string, _mess: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          StatusMessages: [...this.state.StatusMessages].concat({
            Reference: _ref,
            Message: _mess,
          }),
        },
        () => {
          if (
            this.state.StatusMessages.filter((th) => th.Reference === _ref)
              .length === 0
          ) {
            reject("status did not register");
          } else {
            resolve(true);
          }
        },
      );
    });
  };

  DeregisterStatus = (_ref: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          StatusMessages: [...this.state.StatusMessages].filter(
            (th) => th.Reference !== _ref,
          ),
        },
        () => {
          if (
            this.state.StatusMessages.filter((th) => th.Reference === _ref)
              .length > 0
          ) {
            reject("status was not deregistered");
          } else {
            resolve(true);
          }
        },
      );
    });
  };

  public render() {
    //  console.log(this.state.StatusMessages);

    return (
      <div className="WorkflowAssignmentGroupEditor">
        <div
          className={
            "CrudeInputBlocker" +
            (this.state._processingOperations ? " Blocking" : "")
          }
        ></div>
        <div className="HeaderPanel">
          <h1>Workflow Assignment Group Editor</h1>
        </div>
        <div className="SearchBlock">
          <div className="Filtering">
            <div className="PickFilterTypes">
              <div
                onClick={() => {
                  this.ToggShowWFFilts();
                }}
                className={
                  "FilterHead" +
                  (this.state._showWorkflowFilters ? " Active" : "")
                }
              >
                <div className="FilterName">Workflow</div>
              </div>
              <div
                onClick={() => {
                  this.ToggShowCampFilts();
                }}
                className={
                  "FilterHead" +
                  (this.state._showCampusFilters ? " Active" : "")
                }
              >
                <div className="FilterName">Campus</div>
              </div>
              <div
                onClick={() => {
                  this.ToggShowStudFilts();
                }}
                className={
                  "FilterHead" + (this.state._showStudyFilters ? " Active" : "")
                }
              >
                <div className="FilterName">Level of Study</div>
              </div>
              <div
                onClick={() => {
                  this.ToggShowFacFilts();
                }}
                className={
                  "FilterHead" +
                  (this.state._showFacultyFilters ? " Active" : "")
                }
              >
                <div className="FilterName">Faculty</div>
              </div>
              <div
                onClick={() => {
                  this.ToggShowDeptFilts();
                }}
                className={
                  "FilterHead" +
                  (this.state._showDepartmentFilters ? " Active" : "")
                }
              >
                <div className="FilterName">Department</div>
              </div>
              <div
                onClick={() => {
                  this.ToggShowStageFilts();
                }}
                className={
                  "FilterHead" + (this.state._showStageFilters ? " Active" : "")
                }
              >
                <div className="FilterName">Workflow Stage</div>
              </div>
              <div
                onClick={() => {
                  this.ToggShowUserFilt();
                }}
                className={
                  "FilterHead" + (this.state._showUserFilter ? " Active" : "")
                }
              >
                <div className="FilterName">Username</div>
              </div>
            </div>
          </div>
          <div
            className={
              "PickFilters" +
              (this.state._showDepartmentFilters ||
              this.state._showWorkflowFilters
                ? " MinHeighted"
                : "")
            }
          >
            {this.state._showWorkflowFilters ? (
              <React.Fragment>
                <div className="SubFilter">
                  <div className="Desc">Quick Select</div>
                  <div
                    className={
                      "FilterOption" +
                      (this.IsFiltWFBySys("Collagen") ? " Active" : "")
                    }
                    onClick={() => {
                      this.SelectESFWorkflows();
                    }}
                  >
                    ESF Workflows
                  </div>
                </div>
                {this.state.AllWorkflowFilters.map((th, i) => {
                  return (
                    <div
                      key={"filter_wfl_" + i.toString()}
                      className={
                        "FilterOption" +
                        (this.IsFiltWF(th.Machine) ? " Active" : "") +
                        (this.state._workflowSystemFilters.length === 0 ||
                        this.IsFiltWFBySys(th.System)
                          ? ""
                          : " Hidden")
                      }
                      onClick={() => {
                        this.ToggFiltWF(th.Machine);
                      }}
                    >
                      {th.Human}
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              ""
            )}
            {this.state._showCampusFilters
              ? this.state.AllCampusFilters.map((th, i) => {
                  return (
                    <div
                      key={"filter_cam_" + i.toString()}
                      className={
                        "FilterOption" +
                        (this.IsFiltCam(th.Machine) ? " Active" : "")
                      }
                      onClick={() => {
                        this.ToggFiltCam(th.Machine);
                      }}
                    >
                      {th.Human}
                    </div>
                  );
                })
              : ""}
            {this.state._showStudyFilters
              ? this.state.AllStudyFilters.map((th, i) => {
                  return (
                    <div
                      key={"filter_std_" + i.toString()}
                      className={
                        "FilterOption" +
                        (this.IsFiltStud(th.Machine) ? " Active" : "")
                      }
                      onClick={() => {
                        this.ToggFiltStud(th.Machine);
                      }}
                    >
                      {th.Human}
                    </div>
                  );
                })
              : ""}
            {this.state._showFacultyFilters
              ? this.state.AllFacultyFilters.map((th, i) => {
                  return (
                    <div
                      key={"filter_fac_" + i.toString()}
                      className={
                        "FilterOption" +
                        (this.IsFiltFac(th.Machine) ? " Active" : "")
                      }
                      onClick={() => {
                        this.ToggFiltFac(th.Machine);
                      }}
                    >
                      {th.Human}
                    </div>
                  );
                })
              : ""}
            {this.state._showDepartmentFilters ? (
              <React.Fragment>
                <div className="SubFilter">
                  <div className="Desc">Filter on Faculty</div>
                  <div
                    className={
                      "FilterOption" +
                      (this.IsFiltDeptByFac("SOS") ? " Active" : "")
                    }
                    onClick={() => {
                      this.ToggFiltDeptByFac("SOS");
                    }}
                  >
                    SOS
                  </div>
                  <div
                    className={
                      "FilterOption" +
                      (this.IsFiltDeptByFac("SCH") ? " Active" : "")
                    }
                    onClick={() => {
                      this.ToggFiltDeptByFac("SCH");
                    }}
                  >
                    SCH
                  </div>
                  <div
                    className={
                      "FilterOption" +
                      (this.IsFiltDeptByFac("HUM") ? " Active" : "")
                    }
                    onClick={() => {
                      this.ToggFiltDeptByFac("HUM");
                    }}
                  >
                    HUM
                  </div>
                </div>
                {this.state.AllDepartmentFilters.map((th, i) => {
                  return (
                    <div
                      key={"filter_dep_" + i.toString()}
                      className={
                        "FilterOption" +
                        (this.IsFiltDept(th.Machine) ? " Active" : "") +
                        (this.state._departmentFacultyFilters.length === 0 ||
                        this.IsFiltDeptByFac(th.Faculty)
                          ? ""
                          : " Hidden")
                      }
                      onClick={() => {
                        this.ToggFiltDept(th.Machine);
                      }}
                    >
                      {th.Human}
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              ""
            )}
            {this.state._showStageFilters
              ? this.state.AllWorkflowStageFilters.map((th, i) => {
                  return (
                    <div
                      key={"filter_stg_" + i.toString()}
                      className={
                        "FilterOption" +
                        (this.IsFiltWFST(th.Machine) ? " Active" : "")
                      }
                      onClick={() => {
                        this.ToggFiltWFST(th.Machine);
                      }}
                    >
                      {th.Human}
                    </div>
                  );
                })
              : ""}
            {this.state._showUserFilter ? (
              <div id="UserFilter">
                <label htmlFor="UserFilterText">Username:</label>
                <input
                  type="text"
                  id="UserFilterText"
                  value={this.state._userFilter}
                  onChange={(ev) => {
                    this.setState({ _userFilter: ev.target.value });
                  }}
                ></input>
                <button
                  onClick={() => {
                    this.setState({ UserFilter: this.state._userFilter });
                  }}
                >
                  Set Filter
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          {this.state.SelectedCampusFilters.length > 0 ||
          this.state.SelectedDepartmentFilters.length > 0 ||
          this.state.SelectedFacultyFilters.length > 0 ||
          this.state.SelectedStudyFilters.length > 0 ||
          this.state.SelectedWorkflowFilters.length > 0 ||
          this.state.SelectedWorkflowStageFilters.length > 0 ||
          this.state.UserFilter !== "" ? (
            <div className="SelectedFilters">
              <div
                className="FilterOption Clear"
                onClick={() => {
                  this.ClearFilters();
                }}
              >
                Clear Filters{" "}
                <i className={getIconClassName("StatusCircleErrorX")}></i>
              </div>
              <div
                className={
                  "FilterOption Apply" +
                  (this.state._loadingGroups ? " Disabled" : "")
                }
                onClick={() => {
                  if (!this.state._loadingGroups) {
                    this.ApplyFilters();
                  }
                }}
              >
                {this.state._loadingGroups
                  ? "Loading Groups... "
                  : "Apply Filters "}
                {this.state._loadingGroups ? (
                  <i className={getIconClassName("ProgressRingDots")}></i>
                ) : (
                  <i className={getIconClassName("Search")}></i>
                )}
              </div>
              {this.state.SelectedWorkflowFilters.map((th, i) => {
                return (
                  <div
                    key={"picked_filter_wfl_" + i.toString()}
                    className="FilterOption"
                    onClick={() => {
                      this.ToggFiltWF(th.Machine);
                    }}
                  >
                    {th.Human}
                  </div>
                );
              })}
              {this.state.SelectedCampusFilters.map((th, i) => {
                return (
                  <div
                    key={"picked_filter_cam_" + i.toString()}
                    className="FilterOption"
                    onClick={() => {
                      this.ToggFiltCam(th.Machine);
                    }}
                  >
                    {th.Human}
                  </div>
                );
              })}
              {this.state.SelectedStudyFilters.map((th, i) => {
                return (
                  <div
                    key={"picked_filter_std_" + i.toString()}
                    className="FilterOption"
                    onClick={() => {
                      this.ToggFiltStud(th.Machine);
                    }}
                  >
                    {th.Human}
                  </div>
                );
              })}
              {this.state.SelectedFacultyFilters.map((th, i) => {
                return (
                  <div
                    key={"picked_filter_fac_" + i.toString()}
                    className="FilterOption"
                    onClick={() => {
                      this.ToggFiltFac(th.Machine);
                    }}
                  >
                    {th.Human}
                  </div>
                );
              })}
              {this.state.SelectedDepartmentFilters.map((th, i) => {
                return (
                  <div
                    key={"picked_filter_dep_" + i.toString()}
                    className="FilterOption"
                    onClick={() => {
                      this.ToggFiltDept(th.Machine);
                    }}
                  >
                    {th.Human}
                  </div>
                );
              })}
              {this.state.SelectedWorkflowStageFilters.map((th, i) => {
                return (
                  <div
                    key={"picked_filter_stg_" + i.toString()}
                    className="FilterOption"
                    onClick={() => {
                      this.ToggFiltWFST(th.Machine);
                    }}
                  >
                    {th.Human}
                  </div>
                );
              })}
              {this.state.UserFilter !== "" ? (
                <div
                  className="FilterOption UserFilter"
                  onClick={() => {
                    this.setState({ UserFilter: "" });
                  }}
                >
                  {this.state.UserFilter}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div className="CGAGroups">
            <div className="LoadingErrorMessages">
              {this.state.ErrorMessages !== undefined ? (
                <React.Fragment>
                  {this.state.ErrorMessages.map((th, i) => {
                    return (
                      <div
                        className="LoadingErrorMessage"
                        key={"Error_" + i.toString()}
                      >
                        <div className="ErrorReference">{th.Reference} : </div>
                        <div className="ErrorMessage">{th.Message}</div>
                      </div>
                    );
                  })}
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
            <div className="LoadingStatusMessages">
              {this.state.StatusMessages !== undefined ? (
                <React.Fragment>
                  {this.state.StatusMessages.map((th, i) => {
                    return (
                      <div
                        className="LoadingStatusMessage"
                        key={"Error_" + i.toString()}
                      >
                        <div className="StatusReference">{th.Reference} : </div>
                        <div className="StatusMessage">{th.Message}</div>
                      </div>
                    );
                  })}
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
            {this.state.ActiveCampusFilters.length > 0 ||
            this.state.ActiveDepartmentFilters.length > 0 ||
            this.state.ActiveFacultyFilters.length > 0 ||
            this.state.ActiveStudyFilters.length > 0 ||
            this.state.ActiveWorkflowFilters.length > 0 ||
            this.state.ActiveWorkflowStageFilters.length > 0 ||
            this.state.SelectedGroups.length > 0 ||
            this.state.UserFilter !== ""
              ? this.state.RetrievedWorkflowAssignmentGroups.filter(
                  (th) => th.Visible,
                ).map((th, i: number) => {
                  return (
                    <React.Fragment key={"GroupBox" + i.toString()}>
                      {th.Groups.filter((ith) => ith.Visible).map((ith) => {
                        return (
                          <div
                            className={
                              "GroupBox" +
                              (this.IsGroupSelected(ith) ? " Selected" : "")
                            }
                            key={th.System + "_" + ith.Machine}
                          >
                            <div
                              className="GroupTitle"
                              onClick={() => {
                                this.ToggleGroupSelect(ith);
                              }}
                              title={
                                th.System +
                                " Group #ID: " +
                                ith.Machine +
                                " - Membership derived from: " +
                                ith.Mechanism
                              }
                            >
                              {ith.Human}
                            </div>
                            <div className="WorkflowTitle">{th.Workflow}</div>
                            <div className="TagsTitle">Tags</div>
                            {ith.Campus.filter((iith) => iith !== "ALL").map(
                              (iith, ii) => {
                                return (
                                  <div
                                    key={"charm_cam_" + ii.toString()}
                                    className="Charm Campus"
                                  >
                                    {iith}
                                  </div>
                                );
                              },
                            )}
                            {ith.Study.filter((iith) => iith !== "ALL").map(
                              (iith, ii) => {
                                return (
                                  <div
                                    key={"charm_cam_" + ii.toString()}
                                    className="Charm Study"
                                  >
                                    {iith}
                                  </div>
                                );
                              },
                            )}
                            {ith.Department.filter(
                              (iith) => iith !== "ALL",
                            ).map((iith, ii) => {
                              return (
                                <div
                                  key={"charm_cam_" + ii.toString()}
                                  className="Charm Department"
                                >
                                  {iith}
                                </div>
                              );
                            })}
                            {ith.Faculty.filter((iith) => iith !== "ALL").map(
                              (iith, ii) => {
                                return (
                                  <div
                                    key={"charm_fac_" + ii.toString()}
                                    className="Charm Faculty"
                                  >
                                    {iith}
                                  </div>
                                );
                              },
                            )}
                            {ith.Stage.filter((iith) => iith !== "ALL").map(
                              (iith, ii) => {
                                return (
                                  <div
                                    key={"charm_stg_" + ii.toString()}
                                    className="Charm Stage"
                                  >
                                    {iith}
                                  </div>
                                );
                              },
                            )}
                            <div className="GroupMembers">
                              <div className="GroupMembersTitle">
                                Membership
                              </div>
                              {ith.Mechanism === "Membership"
                                ? ith.Members.map((iith, ii) => {
                                    return (
                                      <div
                                        className={
                                          "MemberBox" +
                                          (this.state.QueuedOperations.findIndex(
                                            (th) =>
                                              th.Group1 === ith &&
                                              th.Value1 === iith.Username,
                                          ) > -1
                                            ? " QueuedDelete"
                                            : "")
                                        }
                                        key={
                                          th.System +
                                          "_" +
                                          ith.Machine +
                                          "_" +
                                          ii.toString()
                                        }
                                      >
                                        <div
                                          className="MemberLogin"
                                          title={
                                            iith.UserFullName +
                                            (this.state.QueuedOperations.findIndex(
                                              (th) =>
                                                th.Group1 === ith &&
                                                th.Value1 === iith.Username,
                                            ) > -1
                                              ? " (queued for removal)"
                                              : "")
                                          }
                                        >
                                          {iith.Username}
                                        </div>
                                        <div className="Deleter">
                                          <div className="DeleterCharm">
                                            <i
                                              className={getIconClassName(
                                                "UserRemove",
                                              )}
                                            ></i>
                                          </div>
                                          <div className="DeleterContent">
                                            <div className="InnerDeleterCharm">
                                              <i
                                                className={getIconClassName(
                                                  "UserRemove",
                                                )}
                                              ></i>
                                            </div>
                                            <div className="DeleterTitle">
                                              Remove {iith.Username} from...
                                            </div>
                                            <div className="ThisGroup">
                                              <div
                                                className="Title"
                                                onClick={(ev) => {
                                                  this.QueueDeleteFromThisGroup(
                                                    ith,
                                                    iith,
                                                  );
                                                }}
                                              >
                                                ...this group
                                                <div className="ConfirmDelete">
                                                  <i
                                                    className={getIconClassName(
                                                      "DoubleChevronRight8",
                                                    )}
                                                  ></i>
                                                  <i
                                                    className={getIconClassName(
                                                      "UserRemove",
                                                    )}
                                                  ></i>
                                                </div>
                                              </div>
                                              <div className="Desc">
                                                Delete from this group only.
                                              </div>
                                            </div>
                                            <div className="SelectedGroups">
                                              <div
                                                className="Title"
                                                onClick={(ev) => {
                                                  this.QueueDeleteFromSelectedGroups(
                                                    iith,
                                                  );
                                                }}
                                              >
                                                ...selected groups
                                                <div className="ConfirmDelete">
                                                  <i
                                                    className={getIconClassName(
                                                      "DoubleChevronRight8",
                                                    )}
                                                  ></i>
                                                  <i
                                                    className={getIconClassName(
                                                      "UserRemove",
                                                    )}
                                                  ></i>
                                                </div>
                                              </div>
                                              <div className="Desc">
                                                Delete from all the groups you
                                                currently have selected.
                                              </div>
                                            </div>
                                            <div className="VisibleGroups">
                                              <div
                                                className="Title"
                                                onClick={(ev) => {
                                                  this.QueueDeleteFromVisibleGroups(
                                                    iith,
                                                  );
                                                }}
                                              >
                                                ...visible groups
                                                <div className="ConfirmDelete">
                                                  <i
                                                    className={getIconClassName(
                                                      "DoubleChevronRight8",
                                                    )}
                                                  ></i>
                                                  <i
                                                    className={getIconClassName(
                                                      "UserRemove",
                                                    )}
                                                  ></i>
                                                </div>
                                              </div>
                                              <div className="Desc">
                                                Delete user from all groups
                                                currently visible on this
                                                screen. Best used to remove a
                                                user from a faculty or other
                                                broad category.
                                              </div>
                                            </div>
                                            <div className="AllGroups">
                                              <div
                                                className="Title"
                                                onClick={(ev) => {
                                                  this.QueueDeleteFromAllGroups(
                                                    iith,
                                                  );
                                                }}
                                              >
                                                ...ALL groups
                                                <div className="ConfirmDelete">
                                                  <i
                                                    className={getIconClassName(
                                                      "DoubleChevronRight8",
                                                    )}
                                                  ></i>
                                                  <i
                                                    className={getIconClassName(
                                                      "UserRemove",
                                                    )}
                                                  ></i>
                                                </div>
                                              </div>
                                              <div className="Desc">
                                                Deletes this user from{" "}
                                                <strong>ALL</strong> Assignment
                                                Groups. Best used for users who
                                                have left the organisation, or
                                                to completely reset a user's
                                                permissions if they have moved
                                                between responsibility areas.
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                              {this.state.QueuedOperations.filter(
                                (iith) =>
                                  iith.Group1 === ith &&
                                  iith.Op === QueuedOperationType.AddMember,
                              ).map((iith, i) => {
                                return (
                                  <div
                                    className="MemberBox QueuedAdd"
                                    key={
                                      "qo_" +
                                      iith.Group1.Human +
                                      "_" +
                                      iith.Value1 +
                                      "_" +
                                      i.toString()
                                    }
                                  >
                                    <div
                                      className="MemberLogin"
                                      title={iith.Value1 + " (queued for add)"}
                                    >
                                      {iith.Value1}
                                    </div>
                                  </div>
                                );
                              })}
                              {ith.Mechanism === "Delegated Group" ? (
                                <React.Fragment>
                                  <div className="DelegatedGroupExplanation">
                                    Derives from Delegated Group:
                                  </div>
                                  <div className="DelegatedGroupActual">
                                    <a
                                      href={
                                        "https://www1.essex.ac.uk/it/groups-lists/delegated/group-members.aspx?group=" +
                                        ith.NoneditableGroupName
                                      }
                                      target="_blank"
                                    >
                                      {ith.NoneditableGroupName}{" "}
                                      <i
                                        className={getIconClassName(
                                          "OpenInNewWindow",
                                        )}
                                      ></i>
                                    </a>
                                  </div>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                              {ith.Mechanism === "Mailing List" ? (
                                <React.Fragment>
                                  <div className="DelegatedGroupExplanation">
                                    Derives from Mailing List:
                                  </div>
                                  <div className="DelegatedGroupActual">
                                    <a
                                      href={
                                        "https://www1.essex.ac.uk/it/groups-lists/mailinglists/listinfo.aspx?list=" +
                                        ith.NoneditableGroupName
                                      }
                                      target="_blank"
                                    >
                                      {ith.NoneditableGroupName}{" "}
                                      <i
                                        className={getIconClassName(
                                          "OpenInNewWindow",
                                        )}
                                      ></i>
                                    </a>
                                  </div>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="NotificationsTitle">
                              Notifications
                            </div>
                            <div
                              className={
                                "NotificationsSetting" +
                                (this.state.QueuedOperations.findIndex(
                                  (iith) =>
                                    iith.Group1.Machine === ith.Machine &&
                                    (iith.Op ===
                                      QueuedOperationType.NotificationsToMembershipAndOverride ||
                                      iith.Op ===
                                        QueuedOperationType.NotificationsToMembershipOnly ||
                                      iith.Op ===
                                        QueuedOperationType.NotificationsToMuted ||
                                      iith.Op ===
                                        QueuedOperationType.NotificationsToOverrideOnly),
                                ) > -1
                                  ? " QueuedChange"
                                  : "")
                              }
                            >
                              {this.state.QueuedOperations.filter(
                                (iith) =>
                                  iith.Group1.Machine === ith.Machine &&
                                  iith.Op ===
                                    QueuedOperationType.NotificationsToMembershipAndOverride,
                              ).length > 0 ? (
                                <div
                                  className="NotificationsSettingCurrent"
                                  title="Queued Change"
                                >
                                  Membership and Notifier
                                  <div className="CurrentIcon">
                                    <MailAddRegular></MailAddRegular>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.QueuedOperations.filter(
                                (iith) =>
                                  iith.Group1.Machine === ith.Machine &&
                                  iith.Op ===
                                    QueuedOperationType.NotificationsToMembershipOnly,
                              ).length > 0 ? (
                                <div
                                  className="NotificationsSettingCurrent"
                                  title="Queued Change"
                                >
                                  Membership Only
                                  <div className="CurrentIcon">
                                    <MailMultipleRegular></MailMultipleRegular>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.QueuedOperations.filter(
                                (iith) =>
                                  iith.Group1.Machine === ith.Machine &&
                                  iith.Op ===
                                    QueuedOperationType.NotificationsToMuted,
                              ).length > 0 ? (
                                <div
                                  className="NotificationsSettingCurrent"
                                  title="Queued Change"
                                >
                                  No Notification
                                  <div className="CurrentIcon">
                                    <MailDismissRegular></MailDismissRegular>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.QueuedOperations.filter(
                                (iith) =>
                                  iith.Group1.Machine === ith.Machine &&
                                  iith.Op ===
                                    QueuedOperationType.NotificationsToOverrideOnly,
                              ).length > 0 ? (
                                <div
                                  className="NotificationsSettingCurrent"
                                  title="Queued Change"
                                >
                                  Notifier Only
                                  <div className="CurrentIcon">
                                    <MailSettingsRegular></MailSettingsRegular>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.QueuedOperations.findIndex(
                                (iith) =>
                                  iith.Group1.Machine === ith.Machine &&
                                  (iith.Op ===
                                    QueuedOperationType.NotificationsToMembershipAndOverride ||
                                    iith.Op ===
                                      QueuedOperationType.NotificationsToMembershipOnly ||
                                    iith.Op ===
                                      QueuedOperationType.NotificationsToMuted ||
                                    iith.Op ===
                                      QueuedOperationType.NotificationsToOverrideOnly),
                              ) === -1 ? (
                                <div className="NotificationsSettingCurrent">
                                  {(ith.NotificationMode === "MembershipOnly"
                                    ? "Membership Only"
                                    : "") +
                                    (ith.NotificationMode ===
                                    "MembershipAndNotifier"
                                      ? "Membership and Notifier"
                                      : "") +
                                    (ith.NotificationMode === "NotifierOnly"
                                      ? "Notifier Only"
                                      : "") +
                                    (ith.NotificationMode === "NoNotifications"
                                      ? "No Notifications"
                                      : "")}
                                  <div className="CurrentIcon">
                                    {ith.NotificationMode ===
                                    "MembershipOnly" ? (
                                      <MailMultipleRegular></MailMultipleRegular>
                                    ) : (
                                      ""
                                    )}
                                    {ith.NotificationMode ===
                                    "MembershipAndNotifier" ? (
                                      <MailAddRegular></MailAddRegular>
                                    ) : (
                                      ""
                                    )}
                                    {ith.NotificationMode === "NotifierOnly" ? (
                                      <MailSettingsRegular></MailSettingsRegular>
                                    ) : (
                                      ""
                                    )}
                                    {ith.NotificationMode ===
                                    "NoNotifications" ? (
                                      <MailDismissRegular></MailDismissRegular>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="NotificationsSettingContent">
                                <div
                                  className={
                                    "MembershipOnly" +
                                    (ith.NotificationMode === "MembershipOnly"
                                      ? " Disabled"
                                      : "")
                                  }
                                >
                                  <div
                                    className="Title"
                                    onClick={(ev) => {
                                      this.QueueGroupNotifySetMembersOnly(ith);
                                    }}
                                  >
                                    Membership Only
                                    <div className="ConfirmChange">
                                      <MailMultipleRegular></MailMultipleRegular>
                                    </div>
                                  </div>
                                  <div className="Desc">
                                    This group's notifications are emailed to
                                    all members.
                                  </div>
                                </div>
                                <div
                                  className={
                                    "MembershipAndNotifier" +
                                    (ith.NotificationMode ===
                                    "MembershipAndNotifier"
                                      ? " Disabled"
                                      : "")
                                  }
                                >
                                  <div
                                    className="Title"
                                    onClick={(ev) => {
                                      this.QueueGroupNotifySetMembersAndOverride(
                                        ith,
                                      );
                                    }}
                                  >
                                    Membership and Notifier
                                    <div className="ConfirmChange">
                                      <MailAddRegular></MailAddRegular>
                                    </div>
                                  </div>
                                  <div className="Desc">
                                    This group's notifications are emailed to
                                    all members, and to the specified Notifier
                                    addresses.
                                  </div>
                                </div>
                                <div
                                  className={
                                    "NotifierOnly" +
                                    (ith.NotificationMode === "NotifierOnly"
                                      ? " Disabled"
                                      : "")
                                  }
                                >
                                  <div
                                    className="Title"
                                    onClick={(ev) => {
                                      this.QueueGroupNotifySetOverrideOnly(ith);
                                    }}
                                  >
                                    Notifier Only
                                    <div className="ConfirmChange">
                                      <MailSettingsRegular></MailSettingsRegular>
                                    </div>
                                  </div>
                                  <div className="Desc">
                                    This group's notifications are only emailed
                                    to the specified Notifier addresses.
                                  </div>
                                </div>
                                <div
                                  className={
                                    "NoNotifications" +
                                    (ith.NotificationMode === "NoNotifications"
                                      ? " Disabled"
                                      : "")
                                  }
                                >
                                  <div
                                    className="Title"
                                    onClick={(ev) => {
                                      this.QueueGroupNotifySetMuted(ith);
                                    }}
                                  >
                                    No Notifications
                                    <div className="ConfirmChange">
                                      <MailDismissRegular></MailDismissRegular>
                                    </div>
                                  </div>
                                  <div className="Desc">
                                    This group's notifications are muted.
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.QueuedOperations.filter(
                              (iith) =>
                                iith.Group1.Machine === ith.Machine &&
                                iith.Op ===
                                  QueuedOperationType.NotificationsToMembershipAndOverride,
                            ).length > 0 ||
                            this.state.QueuedOperations.filter(
                              (iith) =>
                                iith.Group1.Machine === ith.Machine &&
                                iith.Op ===
                                  QueuedOperationType.NotificationsToOverrideOnly,
                            ).length > 0 ||
                            ith.NotificationMode === "MembershipAndNotifier" ||
                            ith.NotificationMode === "NotifierOnly" ? (
                              <React.Fragment>
                                <div className="NotificationsTitle">
                                  Notifier
                                </div>
                                <textarea
                                  title={
                                    this.state.QueuedOperations.filter(
                                      (iith) =>
                                        iith.Group1.Machine === ith.Machine &&
                                        iith.Op ===
                                          QueuedOperationType.SetGroupNotifier,
                                    ).length > 0
                                      ? "Queued Change"
                                      : ""
                                  }
                                  className={
                                    "NotifierTextarea" +
                                    (this.state.QueuedOperations.filter(
                                      (iith) =>
                                        iith.Group1.Machine === ith.Machine &&
                                        iith.Op ===
                                          QueuedOperationType.SetGroupNotifier,
                                    ).length > 0
                                      ? " Disabled"
                                      : "")
                                  }
                                  disabled={
                                    this.state.QueuedOperations.filter(
                                      (iith) =>
                                        iith.Group1.Machine === ith.Machine &&
                                        iith.Op ===
                                          QueuedOperationType.SetGroupNotifier,
                                    ).length > 0
                                  }
                                  rows={5}
                                  defaultValue={ith._notificationEmail}
                                  onChange={(ev) => {
                                    ith._notificationEmail = ev.target.value;
                                  }}
                                ></textarea>
                                <div
                                  title={
                                    this.state.QueuedOperations.filter(
                                      (iith) =>
                                        iith.Group1.Machine === ith.Machine &&
                                        iith.Op ===
                                          QueuedOperationType.SetGroupNotifier,
                                    ).length > 0
                                      ? "Queued Change"
                                      : ""
                                  }
                                  className={
                                    "NotifierApply" +
                                    (this.state.QueuedOperations.filter(
                                      (iith) =>
                                        iith.Group1.Machine === ith.Machine &&
                                        iith.Op ===
                                          QueuedOperationType.SetGroupNotifier,
                                    ).length > 0
                                      ? " Disabled"
                                      : "")
                                  }
                                  onClick={() => {
                                    this.QueueGroupNotifySetTarget(
                                      ith,
                                      ith._notificationEmail,
                                    );
                                  }}
                                >
                                  Set Notifier
                                </div>
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                })
              : ""}
          </div>
        </div>
        <div className="OperationsBlock">
          {this.state.LiveDA !== undefined ? (
            <div className="DBPicker">
              <div className="SidebarTitle">
                Connection
                <div
                  className={"DBTest" + (this.state._connTest ? " Active" : "")}
                  onClick={() => {
                    this.setState(
                      {
                        _connTest: true,
                        _connLive: false,
                        ErrorMessages: [],
                        StatusMessages: [],
                        _workflowSystemFilters: [],
                        _departmentFacultyFilters: [],
                        UserFilter: "",
                        _userFilter: "",
                        _showWorkflowFilters: false,
                        _showCampusFilters: false,
                        _showStudyFilters: false,
                        _showFacultyFilters: false,
                        _showDepartmentFilters: false,
                        _showStageFilters: false,
                        _showUserFilter: false,
                        _expandGO_AddUser: false,
                        _expandGO_Split: false,
                        _expandGO_Merge: false,
                        _processingOperations: false,
                        AllWorkflowFilters: [],
                        AllCampusFilters: [],
                        AllStudyFilters: [],
                        AllFacultyFilters: [],
                        AllDepartmentFilters: [],
                        AllWorkflowStageFilters: [],
                        SelectedWorkflowFilters: [],
                        SelectedCampusFilters: [],
                        SelectedStudyFilters: [],
                        SelectedFacultyFilters: [],
                        SelectedDepartmentFilters: [],
                        SelectedWorkflowStageFilters: [],
                        ActiveWorkflowFilters: [],
                        ActiveCampusFilters: [],
                        ActiveStudyFilters: [],
                        ActiveFacultyFilters: [],
                        ActiveDepartmentFilters: [],
                        ActiveWorkflowStageFilters: [],
                        _loadingGroups: true,
                        RetrievedWorkflowAssignmentGroups: [],
                        SelectedGroups: [],
                        QueuedOperations: [],
                      },
                      () => {
                        this.Load();
                      },
                    );
                  }}
                >
                  Test
                </div>
                <div
                  className={"DBLive" + (this.state._connLive ? " Active" : "")}
                  onClick={() => {
                    this.setState(
                      {
                        _connTest: false,
                        _connLive: true,
                        ErrorMessages: [],
                        StatusMessages: [],
                        _workflowSystemFilters: [],
                        _departmentFacultyFilters: [],
                        UserFilter: "",
                        _userFilter: "",
                        _showWorkflowFilters: false,
                        _showCampusFilters: false,
                        _showStudyFilters: false,
                        _showFacultyFilters: false,
                        _showDepartmentFilters: false,
                        _showStageFilters: false,
                        _showUserFilter: false,
                        _expandGO_AddUser: false,
                        _expandGO_Split: false,
                        _expandGO_Merge: false,
                        _processingOperations: false,
                        AllWorkflowFilters: [],
                        AllCampusFilters: [],
                        AllStudyFilters: [],
                        AllFacultyFilters: [],
                        AllDepartmentFilters: [],
                        AllWorkflowStageFilters: [],
                        SelectedWorkflowFilters: [],
                        SelectedCampusFilters: [],
                        SelectedStudyFilters: [],
                        SelectedFacultyFilters: [],
                        SelectedDepartmentFilters: [],
                        SelectedWorkflowStageFilters: [],
                        ActiveWorkflowFilters: [],
                        ActiveCampusFilters: [],
                        ActiveStudyFilters: [],
                        ActiveFacultyFilters: [],
                        ActiveDepartmentFilters: [],
                        ActiveWorkflowStageFilters: [],
                        _loadingGroups: true,
                        RetrievedWorkflowAssignmentGroups: [],
                        SelectedGroups: [],
                        QueuedOperations: [],
                      },
                      () => {
                        this.Load();
                      },
                    );
                  }}
                >
                  Live
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="CGASelectedGroups">
            <div className="SidebarTitle">
              Selected Groups{" "}
              <div
                className="TitleCommand"
                onClick={() => {
                  this.setState({ SelectedGroups: [] });
                }}
              >
                <i
                  title="Clear All"
                  className={getIconClassName("DependencyRemove")}
                ></i>
              </div>
            </div>
            {this.state.SelectedGroups.map((th, i) => {
              return (
                <div
                  key={"sidebar_selectedgroup_" + i.toString()}
                  className="SelectedGroup"
                >
                  {th.Human}
                  <div
                    className="DeselectGroup"
                    onClick={() => {
                      this.ToggleGroupSelect(th);
                    }}
                  >
                    <i className={getIconClassName("StatusCircleErrorX")}></i>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="GroupOperations">
            <div className="SidebarTitle">Operations</div>
            <div
              className={
                "GroupOperation" +
                (this.state._expandGO_AddUser &&
                this.state.SelectedGroups.length
                  ? " Expanded"
                  : "") +
                (this.state.SelectedGroups.length === 0 ? " Disabled" : "")
              }
              title={
                this.state.SelectedGroups.length === 0
                  ? "Must have at least one Selected Group."
                  : ""
              }
            >
              <div
                className="OperationExpander"
                onClick={() => {
                  this.setState({
                    _expandGO_AddUser: !this.state._expandGO_AddUser,
                  });
                }}
              >
                Add User
              </div>
              <div className="OperationParameters">
                <label htmlFor="Op_NewUsername">
                  Add user to all selected groups.
                </label>
                <input
                  id="Op_NewUsername"
                  value={this.props.PossibleUsername}
                  onChange={(ev) => {
                    const val = ev.target.value;
                    this.props.SetPossibleUsername(val);
                  }}
                  onKeyUp={(ev) => {
                    if (ev.key === "Enter") {
                      this.QueueAddUserToSelectedGroups();
                    }
                  }}
                  type="text"
                ></input>
                <i
                  onClick={() => {
                    if (
                      this.props.UsernameRegexMatch === TriState.True &&
                      this.props.UsernameValid === TriState.True
                    )
                      this.QueueAddUserToSelectedGroups();
                  }}
                  className={"InlineCommand " + getIconClassName("AddFriend")}
                ></i>
                {this.props.UsernameRegexMatch === TriState.False ||
                this.props.UsernameValid === TriState.False ? (
                  <div className="Warning">
                    {this.props.PossibleUsername} isn't a valid username.
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {
              //  <div className={'GroupOperation' + (this.state._expandGO_Split ? ' Expanded' : '')}>
              //    <div className='OperationExpander' onClick={() => {this.setState({_expandGO_Split:!this.state._expandGO_Split})}}>Split Group</div>
              //    <div className='OperationDescription'>Split one group into two.</div>
              //    <div className='OperationParameters'>
              //      <input onChange={ (ev) => { this.setState( { _addUsername: ev.target.value } ) } } type='text'></input>
              //      <button onClick={ () => {} }>Add</button>
              //    </div>
              //  </div>
              //  <div className={'GroupOperation' + (this.state._expandGO_Merge ? ' Expanded' : '')}>
              //    <div className='OperationExpander' onClick={() => {this.setState({_expandGO_Merge:!this.state._expandGO_Merge})}}>Merge Groups</div>
              //    <div className='OperationDescription'>Merge two groups into one.</div>
              //    <div className='OperationParameters'>
              //      <input onChange={ (ev) => { this.setState( { _addUsername: ev.target.value } ) } } type='text'></input>
              //      <button onClick={ () => {} }>Add</button>
              //    </div>
              //  </div>
            }
          </div>
          <div className="QueuedOperations">
            <div className="SidebarTitle">
              Queued Operations{" "}
              <div
                className="TitleCommand"
                onClick={() => {
                  this.setState({ QueuedOperations: [] });
                }}
              >
                <i
                  title="Clear All"
                  className={getIconClassName("DependencyRemove")}
                ></i>
              </div>
            </div>
            {this.state.QueuedOperations.map((th, i) => {
              return (
                <React.Fragment key={"queueop_" + i.toString()}>
                  {this.RenderOperation(th)}
                </React.Fragment>
              );
            })}
          </div>
          {this.state.StatusMessages.length > 0 ||
          this.state.ErrorMessages.length > 0 ? (
            <div className="DoIt Inactive">
              <div className="SidebarTitle">Confirm Changes</div>
              <div className="SidebarDescription">
                Carries out all queued operations.
              </div>
              <div className="Enact">
                <i className={getIconClassName("Processing")}></i>
              </div>
            </div>
          ) : (
            <div
              className="DoIt"
              onClick={() => {
                this.ProcessQueuedOperations();
              }}
            >
              <div className="SidebarTitle">Confirm Changes</div>
              <div className="SidebarDescription">
                Carries out all queued operations.
              </div>
              <div className="Enact">
                <i className={getIconClassName("Processing")}></i>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  async ProcessQueuedOperations() {
    this.setState({ _processingOperations: true }, async () => {
      var tasks = [];

      this.state.QueuedOperations.forEach(async (th) => {
        tasks.push(this.EnactOperation(th));
      });

      await Promise.all(tasks);

      await this.RegisterStatus(
        "Workflow Assignment Groups",
        "Scanning...",
      ).then(
        () => {
          setTimeout(() => {
            this.GetGroups().then(async () => {
              await this.DeregisterStatus("Workflow Assignment Groups");
              await this.DeregisterError("Workflow Assignment Groups");

              await this.ApplyFilters();
              //  console.log('View As User Permissions');
              //  console.log(this.state.ViewAsUserPermissions);
            });
          }, 300);
        },
        async (error) => {
          await this.RegisterStatus("Workflow Assignment Groups", "Failed.");
          await this.RegisterError("Workflow Assignment Groups", error);
        },
      );

      this.setState({ _processingOperations: false, SelectedGroups: [] });
    });
  }

  RenderOperation(qop: QueuedOperation): React.ReactNode {
    return (
      <div className="QueuedOperation">
        <div className="OperationDescription">
          {(qop.Op === QueuedOperationType.DeleteMember
            ? "Remove " + qop.Value1 + " from " + qop.Group1.Human + "."
            : "") +
            (qop.Op === QueuedOperationType.AddMember
              ? "Add " + qop.Value1 + " to " + qop.Group1.Human + "."
              : "") +
            (qop.Op === QueuedOperationType.MergeGroups
              ? "Merge " + qop.Group1.Human + " with " + qop.Group2.Human + "."
              : "") +
            (qop.Op === QueuedOperationType.NotificationsToMembershipAndOverride
              ? "Set " + qop.Group1.Human + " to email Membership and Notifier."
              : "") +
            (qop.Op === QueuedOperationType.NotificationsToMembershipOnly
              ? "Set " + qop.Group1.Human + " to email Membership only."
              : "") +
            (qop.Op === QueuedOperationType.NotificationsToMuted
              ? "Mute notifications for " + qop.Group1.Human + "."
              : "") +
            (qop.Op === QueuedOperationType.NotificationsToOverrideOnly
              ? "Set " + qop.Group1.Human + " to email Notifier only."
              : "") +
            (qop.Op === QueuedOperationType.SetGroupNotifier
              ? "Set " + qop.Group1.Human + " Notifier to " + qop.Value1 + "."
              : "") +
            (qop.Op === QueuedOperationType.SplitGroups
              ? "Split " +
                qop.Group1 +
                " along " +
                qop.Value1 +
                " = [ " +
                qop.Value2 +
                " | " +
                qop.Value3 +
                " ]"
              : "")}
        </div>
        {this.state._processingOperations ? (
          ""
        ) : qop.Succeeded ? (
          <div
            className="OperationSucceeded"
            onClick={() => {
              this.DequeueOperation(qop);
            }}
          >
            <i className={getIconClassName("TaskLogo")}></i>
          </div>
        ) : (
          <div
            className="OperationCancel"
            onClick={() => {
              this.DequeueOperation(qop);
            }}
          >
            <i className={getIconClassName("StatusCircleErrorX")}></i>
          </div>
        )}
      </div>
    );
  }

  DequeueOperation(qop: QueuedOperation) {
    this.setState({
      QueuedOperations: [...this.state.QueuedOperations].filter(
        (th) => th !== qop,
      ),
    });
  }

  OperationAlreadyQueued(
    grp: AssignmentGroup,
    opt: QueuedOperationType,
    val: string,
  ): boolean {
    return this.state.QueuedOperations.some(
      (th) => th.Group1 === grp && th.Op === opt && th.Value1 === val,
    );
  }

  QueueGroupNotifySetTarget(ith: AssignmentGroup, _notificationEmail: string) {
    if (
      this.state.QueuedOperations.filter(
        (iith) =>
          iith.Group1.Machine === ith.Machine &&
          iith.Op === QueuedOperationType.SetGroupNotifier,
      ).length === 0
    ) {
      this.setState({
        QueuedOperations: [...this.state.QueuedOperations].concat({
          System: ith.System,
          Mechanism: ith.Mechanism,
          Group1: ith,

          Op: QueuedOperationType.SetGroupNotifier,
          Value1: _notificationEmail,

          Succeeded: false,
        }),
      });
    }
  }

  QueueGroupNotifySetMuted(ith: AssignmentGroup) {
    if (ith.NotificationMode !== "NoNotifications") {
      this.setState({
        QueuedOperations: [...this.state.QueuedOperations].concat({
          System: ith.System,
          Mechanism: ith.Mechanism,
          Group1: ith,
          Op: QueuedOperationType.NotificationsToMuted,
          Succeeded: false,
        }),
      });
    }
  }

  QueueGroupNotifySetOverrideOnly(ith: AssignmentGroup) {
    if (ith.NotificationMode !== "NotifierOnly") {
      this.setState({
        QueuedOperations: [...this.state.QueuedOperations].concat({
          System: ith.System,
          Mechanism: ith.Mechanism,
          Group1: ith,
          Op: QueuedOperationType.NotificationsToOverrideOnly,
          Succeeded: false,
        }),
      });
    }
  }

  QueueGroupNotifySetMembersAndOverride(ith: AssignmentGroup) {
    if (ith.NotificationMode !== "MembershipAndNotifier") {
      this.setState({
        QueuedOperations: [...this.state.QueuedOperations].concat({
          System: ith.System,
          Mechanism: ith.Mechanism,
          Group1: ith,
          Op: QueuedOperationType.NotificationsToMembershipAndOverride,
          Succeeded: false,
        }),
      });
    }
  }

  QueueGroupNotifySetMembersOnly(ith: AssignmentGroup) {
    if (ith.NotificationMode !== "MembershipOnly") {
      this.setState({
        QueuedOperations: [...this.state.QueuedOperations].concat({
          System: ith.System,
          Mechanism: ith.Mechanism,
          Group1: ith,
          Op: QueuedOperationType.NotificationsToMembershipOnly,
          Succeeded: false,
        }),
      });
    }
  }

  QueueDeleteFromAllGroups(agm: AssignmentGroupMember) {
    var cloneops = [...this.state.QueuedOperations];

    this.state.RetrievedWorkflowAssignmentGroups.forEach((th) => {
      th.Groups.forEach((_group) => {
        if (_group.Members.some((th) => th.Username === agm.Username)) {
          if (
            !this.OperationAlreadyQueued(
              _group,
              QueuedOperationType.DeleteMember,
              agm.Username,
            )
          ) {
            cloneops.push({
              System: _group.System,
              Mechanism: _group.Mechanism,
              Group1: _group,
              Op: QueuedOperationType.DeleteMember,
              Value1: agm.Username,
              Succeeded: false,
            });
          }
        }

        if (
          this.OperationAlreadyQueued(
            _group,
            QueuedOperationType.AddMember,
            agm.Username,
          )
        ) {
          cloneops = cloneops.filter(
            (th) =>
              !(
                th.Group1 === _group &&
                th.Op === QueuedOperationType.AddMember &&
                th.Value1 === agm.Username
              ),
          );
        }
      });
    });

    this.setState({ QueuedOperations: cloneops });
  }

  QueueDeleteFromVisibleGroups(agm: AssignmentGroupMember) {
    var cloneops = [...this.state.QueuedOperations];

    this.state.RetrievedWorkflowAssignmentGroups.filter(
      (th) => th.Visible,
    ).forEach((_wfagroup) => {
      _wfagroup.Groups.filter((th) => th.Visible).forEach((_group) => {
        if (_group.Members.some((th) => th.Username === agm.Username)) {
          if (
            !this.OperationAlreadyQueued(
              _group,
              QueuedOperationType.DeleteMember,
              agm.Username,
            )
          ) {
            cloneops.push({
              System: _group.System,
              Mechanism: _group.Mechanism,
              Group1: _group,
              Op: QueuedOperationType.DeleteMember,
              Value1: agm.Username,
              Succeeded: false,
            });
          }
        }

        if (
          this.OperationAlreadyQueued(
            _group,
            QueuedOperationType.AddMember,
            agm.Username,
          )
        ) {
          cloneops = cloneops.filter(
            (th) =>
              !(
                th.Group1 === _group &&
                th.Op === QueuedOperationType.AddMember &&
                th.Value1 === agm.Username
              ),
          );
        }
      });
    });

    this.setState({ QueuedOperations: cloneops });
  }

  QueueDeleteFromSelectedGroups(agm: AssignmentGroupMember) {
    var cloneops = [...this.state.QueuedOperations];

    this.state.SelectedGroups.forEach((_group) => {
      if (_group.Members.some((th) => th.Username === agm.Username)) {
        if (
          !this.OperationAlreadyQueued(
            _group,
            QueuedOperationType.DeleteMember,
            agm.Username,
          )
        ) {
          cloneops.push({
            System: _group.System,
            Mechanism: _group.Mechanism,
            Group1: _group,
            Op: QueuedOperationType.DeleteMember,
            Value1: agm.Username,
            Succeeded: false,
          });
        }
      }

      if (
        this.OperationAlreadyQueued(
          _group,
          QueuedOperationType.AddMember,
          agm.Username,
        )
      ) {
        cloneops = cloneops.filter(
          (th) =>
            !(
              th.Group1 === _group &&
              th.Op === QueuedOperationType.AddMember &&
              th.Value1 === agm.Username
            ),
        );
      }
    });

    this.setState({ QueuedOperations: cloneops });
  }

  QueueAddUserToSelectedGroups() {
    var cloneops = [...this.state.QueuedOperations];

    this.state.SelectedGroups.forEach((_group) => {
      if (
        !_group.Members.some(
          (_member) => _member.Username === this.props.PossibleUsername,
        )
      ) {
        if (
          !this.OperationAlreadyQueued(
            _group,
            QueuedOperationType.AddMember,
            this.props.PossibleUsername,
          )
        ) {
          cloneops.push({
            System: _group.System,
            Mechanism: _group.Mechanism,
            Group1: _group,
            Op: QueuedOperationType.AddMember,
            Value1: this.props.PossibleUsername,
            Succeeded: false,
          });
        }
      }

      if (
        this.OperationAlreadyQueued(
          _group,
          QueuedOperationType.DeleteMember,
          this.props.PossibleUsername,
        )
      ) {
        cloneops = cloneops.filter(
          (th) =>
            !(
              th.Group1 === _group &&
              th.Op === QueuedOperationType.DeleteMember &&
              th.Value1 === this.props.PossibleUsername
            ),
        );
      }
    });

    this.setState({ QueuedOperations: cloneops });
  }

  QueueDeleteFromThisGroup(ag: AssignmentGroup, agm: AssignmentGroupMember) {
    var cloneops = [...this.state.QueuedOperations];

    if (ag.Members.some((th) => th.Username === agm.Username)) {
      if (
        !this.OperationAlreadyQueued(
          ag,
          QueuedOperationType.DeleteMember,
          agm.Username,
        )
      ) {
        cloneops.push({
          System: ag.System,
          Mechanism: ag.Mechanism,
          Group1: ag,
          Op: QueuedOperationType.DeleteMember,
          Value1: agm.Username,
          Succeeded: false,
        });
      }
    }

    if (
      this.OperationAlreadyQueued(
        ag,
        QueuedOperationType.AddMember,
        agm.Username,
      )
    ) {
      cloneops = cloneops.filter(
        (th) =>
          !(
            th.Group1 === ag &&
            th.Op === QueuedOperationType.AddMember &&
            th.Value1 === agm.Username
          ),
      );
    }

    this.setState({ QueuedOperations: cloneops });
  }

  QueueAddUserToThisGroup(ag: AssignmentGroup) {
    var cloneops = [...this.state.QueuedOperations];
    const _username = this.props.PossibleUsername;

    if (!ag.Members.some((th) => th.Username === _username)) {
      if (
        !this.OperationAlreadyQueued(
          ag,
          QueuedOperationType.AddMember,
          _username,
        )
      ) {
        cloneops.push({
          System: ag.System,
          Mechanism: ag.Mechanism,
          Group1: ag,
          Op: QueuedOperationType.AddMember,
          Value1: _username.trim(),
          Succeeded: false,
        });
      }
    }

    if (
      this.OperationAlreadyQueued(
        ag,
        QueuedOperationType.DeleteMember,
        _username,
      )
    ) {
      cloneops = cloneops.filter(
        (th) =>
          !(
            th.Group1 === ag &&
            th.Op === QueuedOperationType.DeleteMember &&
            th.Value1 === _username
          ),
      );
    }

    this.setState({ QueuedOperations: cloneops });
  }

  ClearFilters() {
    this.setState(
      {
        SelectedWorkflowFilters: [],
        SelectedCampusFilters: [],
        SelectedStudyFilters: [],
        SelectedFacultyFilters: [],
        SelectedDepartmentFilters: [],
        SelectedWorkflowStageFilters: [],
        ActiveWorkflowFilters: [],
        ActiveCampusFilters: [],
        ActiveStudyFilters: [],
        ActiveFacultyFilters: [],
        ActiveDepartmentFilters: [],
        ActiveWorkflowStageFilters: [],
        UserFilter: "",
        _showWorkflowFilters: false,
        _showCampusFilters: false,
        _showStudyFilters: false,
        _showFacultyFilters: false,
        _showDepartmentFilters: false,
        _showStageFilters: false,
        _showUserFilter: false,
      },
      () => {
        this.CalculateVisibilities();
      },
    );
  }

  SelectESFWorkflows() {
    const ESFwfnames = [
      "Change of Mode",
      "Request for Intermission",
      "Request for Withdrawal",
      "CAS Extension Request",
      "Research Away Request",
      "Dissertation Extension Request",
      "Change of Course - Research",
      "Registry Change of course workflow (UG)",
      "Registry Change of course workflow (PG)",
      "Registry Special Syllabus workflow (UG)",
      "Registry Special Syllabus workflow (PG)",
    ];
    const selected: WorkflowFilter[] = this.state.AllWorkflowFilters.filter(
      (th) => ESFwfnames.includes(th.Human),
    );

    this.setState({ SelectedWorkflowFilters: selected });
  }

  ApplyFilters = () => {
    this.setState(
      {
        ActiveWorkflowFilters: this.state.SelectedWorkflowFilters,
        ActiveCampusFilters: this.state.SelectedCampusFilters,
        ActiveStudyFilters: this.state.SelectedStudyFilters,
        ActiveFacultyFilters: this.state.SelectedFacultyFilters,
        ActiveDepartmentFilters: this.state.SelectedDepartmentFilters,
        ActiveWorkflowStageFilters: this.state.SelectedWorkflowStageFilters,
        _showWorkflowFilters: false,
        _showCampusFilters: false,
        _showStudyFilters: false,
        _showFacultyFilters: false,
        _showDepartmentFilters: false,
        _showStageFilters: false,
      },
      () => {
        this.CalculateVisibilities();
      },
    );
  };

  CalculateVisibilities() {
    var clone: WorkflowAssignmentGroup[] = [
      ...this.state.RetrievedWorkflowAssignmentGroups,
    ];

    for (let i = 0; i < clone.length; i++) {
      clone[i].Visible = false;
      for (let j = 0; j < clone[i].Groups.length; j++) {
        clone[i].Groups[j].Visible = false;
      }
    }

    for (let i = 0; i < clone.length; i++) {
      clone[i].Visible =
        this.state.ActiveWorkflowFilters.length === 0 ||
        this.state.ActiveWorkflowFilters.findIndex(
          (th) =>
            th.Human === clone[i].Workflow || th.Machine === clone[i].Workflow,
        ) > -1;
      if (clone[i].Visible) {
        for (let j = 0; j < clone[i].Groups.length; j++) {
          let filterCount: number = 0;
          let matchingFilterCount: number = 0;

          if (
            filterCount === matchingFilterCount &&
            this.state.ActiveCampusFilters.length > 0
          ) {
            filterCount++;
            this.state.ActiveCampusFilters.forEach((th) => {
              if (
                clone[i].Groups[j].Campus.findIndex(
                  (ith) => ith.toUpperCase() === th.Machine.toUpperCase(),
                ) > -1
              ) {
                matchingFilterCount++;
              }
            });
          }
          if (
            filterCount === matchingFilterCount &&
            this.state.ActiveDepartmentFilters.length > 0
          ) {
            filterCount++;
            this.state.ActiveDepartmentFilters.forEach((th) => {
              if (
                clone[i].Groups[j].Department.findIndex(
                  (ith) => ith.toUpperCase() === th.Machine.toUpperCase(),
                ) > -1
              ) {
                matchingFilterCount++;
              }
            });
          }
          if (
            filterCount === matchingFilterCount &&
            this.state.ActiveFacultyFilters.length > 0
          ) {
            filterCount++;
            this.state.ActiveFacultyFilters.forEach((th) => {
              if (
                clone[i].Groups[j].Faculty.findIndex(
                  (ith) => ith.toUpperCase() === th.Machine.toUpperCase(),
                ) > -1
              ) {
                matchingFilterCount++;
              }
            });
          }
          if (
            filterCount === matchingFilterCount &&
            this.state.ActiveStudyFilters.length > 0
          ) {
            filterCount++;
            this.state.ActiveStudyFilters.forEach((th) => {
              if (
                clone[i].Groups[j].Study.findIndex(
                  (ith) => ith.toUpperCase() === th.Machine.toUpperCase(),
                ) > -1
              ) {
                matchingFilterCount++;
              }
            });
          }
          if (
            filterCount === matchingFilterCount &&
            this.state.ActiveWorkflowStageFilters.length > 0
          ) {
            filterCount++;
            this.state.ActiveWorkflowStageFilters.forEach((th) => {
              if (
                clone[i].Groups[j].Stage.findIndex(
                  (ith) => ith.toUpperCase() === th.Machine.toUpperCase(),
                ) > -1
              ) {
                matchingFilterCount++;
              }
            });
          }
          if (
            filterCount === matchingFilterCount &&
            this.state.UserFilter !== undefined &&
            this.state.UserFilter !== ""
          ) {
            filterCount++;
            if (
              clone[i].Groups[j].Members.findIndex(
                (th) =>
                  th.Username.toUpperCase() ===
                  this.state.UserFilter.toUpperCase(),
              ) > -1
            ) {
              matchingFilterCount++;
            }
          }

          if (
            filterCount === matchingFilterCount ||
            this.IsGroupSelected(clone[i].Groups[j])
          ) {
            clone[i].Groups[j].Visible = true;
          }
        }
        if (clone[i].Groups.every((th) => th.Visible === false)) {
          clone[i].Visible = false;
        }
      }
    }

    this.setState({ RetrievedWorkflowAssignmentGroups: clone });
  }

  GetGroups = async () => {
    return await this.getData({
      sproc: "sputilities.webui.WAGE_GetGroups",
      params: ["@Username", this.props.ActualUser],
    });
  };
  GetWorkflowWorkflows = async () => {
    return await this.getData({
      sproc: "sputilities.webui.WAGE_GetWorkflowFilters",
      params: ["@Username", this.props.ActualUser],
    });
  };
  GetWorkflowCampuses = async () => {
    return await this.getData({
      sproc: "sputilities.webui.WAGE_GetCampusFilters",
      params: ["@Username", this.props.ActualUser],
    });
  };
  GetWorkflowStudyNatureLevels = async () => {
    return await this.getData({
      sproc: "sputilities.webui.WAGE_GetStudyFilters",
      params: ["@Username", this.props.ActualUser],
    });
  };
  GetWorkflowFaculties = async () => {
    return await this.getData({
      sproc: "sputilities.webui.WAGE_GetFacultyFilters",
      params: ["@Username", this.props.ActualUser],
    });
  };
  GetWorkflowDepartments = async () => {
    return await this.getData({
      sproc: "sputilities.webui.WAGE_GetDepartmentFilters",
      params: ["@Username", this.props.ActualUser],
    });
  };
  GetWorkflowStages = async () => {
    return await this.getData({
      sproc: "sputilities.webui.WAGE_GetWFStageFilters",
      params: ["@Username", this.props.ActualUser],
    });
  };

  async EnactOperation(th: QueuedOperation) {
    var prms: string[] = [
      "@Username",
      this.props.ViewingAsUser || this.props.ActualUser,
      "@Operation",
      QueuedOperationType[th.Op],
      "@System",
      th.System,
      "@Mechanism",
      th.Mechanism,
    ];
    if (th.Group1 && th.Group1.Machine) {
      prms = prms.concat(["@Group1", th.Group1.Machine]);
    }
    if (th.Group2 && th.Group2.Machine) {
      prms = prms.concat(["@Group2", th.Group2.Machine]);
    }
    if (th.Value1) {
      prms = prms.concat(["@Value1", th.Value1]);
    }
    if (th.Value2) {
      prms = prms.concat(["@Value2", th.Value2]);
    }
    if (th.Value3) {
      prms = prms.concat(["@Value3", th.Value3]);
    }
    return await this.getData(
      { sproc: "sputilities.webui.WAGE_EnactOperation", params: prms },
      th,
    );
  }

  getData = async (params: IParams, copilot?: any) => {
    if (this.state._connLive) {
      return await this.state.LiveDA.get("/sproc", JSON.stringify(params)).then(
        (data) => {
          this.handleData(params, data, copilot);
        },
        (reason) => {
          this.setState({
            ErrorMessages: [...this.state.ErrorMessages].concat(reason),
          });
        },
      );
    } else {
      return await this.props.da.get("/sproc", JSON.stringify(params)).then(
        (data) => {
          this.handleData(params, data, copilot);
        },
        (reason) => {
          this.setState({
            ErrorMessages: [...this.state.ErrorMessages].concat(reason),
          });
        },
      );
    }
  };

  private handleData(params: IParams, data: IItem[][], copilot?: any) {
    if (data !== undefined) {
      this.props.Logger.log(
        params.sproc + " - tables received: " + data.length,
        LoggingLevel.DEBUG,
      );
      if (data.length === 1 && data[0].length === 1 && data[0][0]["ERROR"]) {
        this.setState({
          ErrorMessages: [...this.state.ErrorMessages].concat({
            Reference: params.sproc,
            Message: data[0][0]["ERROR"],
          }),
        });
      } else {
        if (params.sproc === "sputilities.webui.WAGE_GetWorkflowFilters") {
          this.setState({
            AllWorkflowFilters: data[0].map((th, i) => {
              return {
                System: th.System,
                Machine: th.Machine,
                Human: th.Human,
              };
            }),
          });
        } else if (params.sproc === "sputilities.webui.WAGE_GetCampusFilters") {
          this.setState({
            AllCampusFilters: data[0].map((th, i) => {
              return { Machine: th.Machine, Human: th.Human };
            }),
          });
        } else if (params.sproc === "sputilities.webui.WAGE_GetStudyFilters") {
          this.setState({
            AllStudyFilters: data[0].map((th, i) => {
              return { Machine: th.Machine, Human: th.Human };
            }),
          });
        } else if (
          params.sproc === "sputilities.webui.WAGE_GetFacultyFilters"
        ) {
          this.setState({
            AllFacultyFilters: data[0].map((th, i) => {
              return { Machine: th.Machine, Human: th.Human };
            }),
          });
        } else if (
          params.sproc === "sputilities.webui.WAGE_GetDepartmentFilters"
        ) {
          this.setState({
            AllDepartmentFilters: data[0].map((th, i) => {
              return {
                Faculty: th.Faculty,
                Machine: th.Machine,
                Human: th.Human,
              };
            }),
          });
        } else if (
          params.sproc === "sputilities.webui.WAGE_GetWFStageFilters"
        ) {
          this.setState({
            AllWorkflowStageFilters: data[0].map((th, i) => {
              return { Machine: th.Machine, Human: th.Human };
            }),
          });
        } else if (params.sproc === "sputilities.webui.WAGE_EnactOperation") {
          var clone = [...this.state.QueuedOperations];
          var thisOne = clone.findIndex((th) => th === copilot);
          clone[thisOne].Succeeded = true;
          setTimeout(() => {
            this.DequeueOperation(clone[thisOne]);
          }, 5000);
          this.setState({ QueuedOperations: clone });
        } else if (params.sproc === "sputilities.webui.WAGE_GetGroups") {
          var assembledWAGs: WorkflowAssignmentGroup[] = [];

          data[0].forEach((th) => {
            var thisWAG = assembledWAGs.find(
              (ith) => ith.Workflow === th.Workflow && ith.System === th.System,
            );

            if (thisWAG === undefined) {
              assembledWAGs.push({
                Visible: false,
                Workflow: th.Workflow,
                System: th.System,
                Groups: [],
              });

              thisWAG = assembledWAGs.find(
                (ith) =>
                  ith.Workflow === th.Workflow && ith.System === th.System,
              );
            }

            var thisAAG = thisWAG.Groups.find(
              (ith) =>
                ith.Machine === th.Machine &&
                ith.Human === th.Human &&
                ith.Mechanism === th.Mechanism &&
                ith.NoneditableGroupName === th.NoneditableGroupName &&
                ith.NotificationMode === th.NotificationMode &&
                ith.NotificationEmail === th.NotificationEmail &&
                ith.OverridesNormalEmail === th.OverridesNormalEmail,
            );

            if (thisAAG === undefined) {
              thisWAG.Groups.push({
                Visible: false,
                Campus: [],
                Study: [],
                Faculty: [],
                Department: [],
                Stage: [],
                System: th.System,
                Machine: th.Machine,
                Human: th.Human,
                Mechanism: th.Mechanism,
                NoneditableGroupName: th.NoneditableGroupName,
                NotificationMode: th.NotificationMode,
                NotificationEmail: th.NotificationEmail,
                _notificationEmail: th.NotificationEmail,
                OverridesNormalEmail: th.OverridesNormalEmail,
                Members: [],
              });
              thisAAG = thisWAG.Groups.find(
                (ith) =>
                  ith.Machine === th.Machine &&
                  ith.Human === th.Human &&
                  ith.Mechanism === th.Mechanism &&
                  ith.NoneditableGroupName === th.NoneditableGroupName &&
                  ith.NotificationEmail === th.NotificationEmail &&
                  ith.NotificationMode === th.NotificationMode &&
                  ith.OverridesNormalEmail === th.OverridesNormalEmail,
              );
            }

            if (
              thisAAG.Campus.findIndex(
                (iith) => iith.toUpperCase() === th.Campus,
              ) === -1
            ) {
              thisAAG.Campus.push(th.Campus);
            }
            if (thisAAG.Study.findIndex((iith) => iith === th.Study) === -1) {
              thisAAG.Study.push(th.Study);
            }
            if (
              thisAAG.Faculty.findIndex((iith) => iith === th.Faculty) === -1
            ) {
              thisAAG.Faculty.push(th.Faculty);
            }
            if (
              thisAAG.Department.findIndex((iith) => iith === th.Department) ===
              -1
            ) {
              thisAAG.Department.push(th.Department);
            }
            if (thisAAG.Stage.findIndex((iith) => iith === th.Stage) === -1) {
              thisAAG.Stage.push(th.Stage);
            }
            if (
              thisAAG.Members.findIndex(
                (ith) =>
                  ith.Username === th.Username &&
                  ith.UserFullName === th.UserFullName,
              ) === -1
            ) {
              thisAAG.Members.push({
                Username: th.Username,
                UserFullName: th.UserFullName,
              });
            }
          });

          this.setState({
            _loadingGroups: false,
            RetrievedWorkflowAssignmentGroups: assembledWAGs,
          });
        }
      }
    }
  }

  ToggleGroupSelect(ag: AssignmentGroup) {
    if (this.IsGroupSelected(ag)) {
      this.setState({
        SelectedGroups: [...this.state.SelectedGroups].filter(
          (th) => th !== ag,
        ),
      });
    } else {
      this.setState({
        SelectedGroups: [...this.state.SelectedGroups].concat(ag),
      });
    }
  }

  ToggFiltDeptByFac(faculty: string) {
    if (this.IsFiltDeptByFac(faculty)) {
      this.setState({
        _departmentFacultyFilters: [
          ...this.state._departmentFacultyFilters,
        ].filter((th) => th !== faculty),
      });
    } else {
      this.setState({
        _departmentFacultyFilters: [
          ...this.state._departmentFacultyFilters,
        ].concat(faculty),
      });
    }
  }

  ToggFiltWF(workflow: string) {
    if (this.IsFiltWF(workflow)) {
      this.setState({
        SelectedWorkflowFilters: [...this.state.SelectedWorkflowFilters].filter(
          (th) => th.Machine !== workflow,
        ),
      });
    } else {
      this.setState({
        SelectedWorkflowFilters: [...this.state.SelectedWorkflowFilters].concat(
          this.state.AllWorkflowFilters.find((th) => th.Machine === workflow),
        ),
      });
    }
  }

  ToggFiltCam(campus: string) {
    if (this.IsFiltCam(campus)) {
      this.setState({
        SelectedCampusFilters: [...this.state.SelectedCampusFilters].filter(
          (th) => th.Machine !== campus,
        ),
      });
    } else {
      this.setState({
        SelectedCampusFilters: [...this.state.SelectedCampusFilters].concat(
          this.state.AllCampusFilters.find((th) => th.Machine === campus),
        ),
      });
    }
  }

  ToggFiltStud(study: string) {
    if (this.IsFiltStud(study)) {
      this.setState({
        SelectedStudyFilters: [...this.state.SelectedStudyFilters].filter(
          (th) => th.Machine !== study,
        ),
      });
    } else {
      this.setState({
        SelectedStudyFilters: [...this.state.SelectedStudyFilters].concat(
          this.state.AllStudyFilters.find((th) => th.Machine === study),
        ),
      });
    }
  }

  ToggFiltFac(faculty: string) {
    if (this.IsFiltFac(faculty)) {
      this.setState({
        SelectedFacultyFilters: [...this.state.SelectedFacultyFilters].filter(
          (th) => th.Machine !== faculty,
        ),
      });
    } else {
      this.setState({
        SelectedFacultyFilters: [...this.state.SelectedFacultyFilters].concat(
          this.state.AllFacultyFilters.find((th) => th.Machine === faculty),
        ),
      });
    }
  }

  ToggFiltDept(department: string) {
    if (this.IsFiltDept(department)) {
      this.setState({
        SelectedDepartmentFilters: [
          ...this.state.SelectedDepartmentFilters,
        ].filter((th) => th.Machine !== department),
      });
    } else {
      this.setState({
        SelectedDepartmentFilters: [
          ...this.state.SelectedDepartmentFilters,
        ].concat(
          this.state.AllDepartmentFilters.find(
            (th) => th.Machine === department,
          ),
        ),
      });
    }
  }

  ToggFiltWFST(workflowstate: string) {
    if (this.IsFiltWFST(workflowstate)) {
      this.setState({
        SelectedWorkflowStageFilters: [
          ...this.state.SelectedWorkflowStageFilters,
        ].filter((th) => th.Machine !== workflowstate),
      });
    } else {
      this.setState({
        SelectedWorkflowStageFilters: [
          ...this.state.SelectedWorkflowStageFilters,
        ].concat(
          this.state.AllWorkflowStageFilters.find(
            (th) => th.Machine === workflowstate,
          ),
        ),
      });
    }
  }

  ToggShowWFFilts() {
    this.setState({
      _showWorkflowFilters: !this.state._showWorkflowFilters,
      _showCampusFilters: false,
      _showStudyFilters: false,
      _showFacultyFilters: false,
      _showDepartmentFilters: false,
      _showStageFilters: false,
      _showUserFilter: false,
    });
  }

  ToggShowCampFilts() {
    this.setState({
      _showWorkflowFilters: false,
      _showCampusFilters: !this.state._showCampusFilters,
      _showStudyFilters: false,
      _showFacultyFilters: false,
      _showDepartmentFilters: false,
      _showStageFilters: false,
      _showUserFilter: false,
    });
  }

  ToggShowStudFilts() {
    this.setState({
      _showWorkflowFilters: false,
      _showCampusFilters: false,
      _showStudyFilters: !this.state._showStudyFilters,
      _showFacultyFilters: false,
      _showDepartmentFilters: false,
      _showStageFilters: false,
      _showUserFilter: false,
    });
  }

  ToggShowFacFilts() {
    this.setState({
      _showWorkflowFilters: false,
      _showCampusFilters: false,
      _showStudyFilters: false,
      _showFacultyFilters: !this.state._showFacultyFilters,
      _showDepartmentFilters: false,
      _showStageFilters: false,
      _showUserFilter: false,
    });
  }

  ToggShowDeptFilts() {
    this.setState({
      _showWorkflowFilters: false,
      _showCampusFilters: false,
      _showStudyFilters: false,
      _showFacultyFilters: false,
      _showDepartmentFilters: !this.state._showDepartmentFilters,
      _showStageFilters: false,
      _showUserFilter: false,
    });
  }

  ToggShowStageFilts() {
    this.setState({
      _showWorkflowFilters: false,
      _showCampusFilters: false,
      _showStudyFilters: false,
      _showFacultyFilters: false,
      _showDepartmentFilters: false,
      _showStageFilters: !this.state._showStageFilters,
      _showUserFilter: false,
    });
  }

  ToggShowUserFilt() {
    this.setState({
      _showWorkflowFilters: false,
      _showCampusFilters: false,
      _showStudyFilters: false,
      _showFacultyFilters: false,
      _showDepartmentFilters: false,
      _showStageFilters: false,
      _showUserFilter: !this.state._showUserFilter,
    });
  }

  IsGroupSelected(ag: AssignmentGroup) {
    return this.state.SelectedGroups.includes(ag);
  }
  IsFiltDeptByFac(faculty: string) {
    return (
      this.state._departmentFacultyFilters.findIndex((th) => th === faculty) >
      -1
    );
  }
  IsFiltWFBySys(system: string) {
    return (
      this.state._workflowSystemFilters.findIndex((th) => th === system) > -1
    );
  }
  IsFiltWF(workflow: string) {
    return (
      this.state.SelectedWorkflowFilters.findIndex(
        (th) => th.Machine === workflow,
      ) > -1
    );
  }
  IsFiltCam(campus: string) {
    return (
      this.state.SelectedCampusFilters.findIndex(
        (th) => th.Machine === campus,
      ) > -1
    );
  }
  IsFiltStud(study: string) {
    return (
      this.state.SelectedStudyFilters.findIndex((th) => th.Machine === study) >
      -1
    );
  }
  IsFiltFac(faculty: string) {
    return (
      this.state.SelectedFacultyFilters.findIndex(
        (th) => th.Machine === faculty,
      ) > -1
    );
  }
  IsFiltDept(department: string) {
    return (
      this.state.SelectedDepartmentFilters.findIndex(
        (th) => th.Machine === department,
      ) > -1
    );
  }
  IsFiltWFST(workflowstate: string) {
    return (
      this.state.SelectedWorkflowStageFilters.findIndex(
        (th) => th.Machine === workflowstate,
      ) > -1
    );
  }
}

type DepartmentFilter = {
  Faculty: string;
  Machine: string;
  Human: string;
};

type WorkflowFilter = {
  System: string;
  Machine: string;
  Human: string;
};

type CampusFilter = {
  Machine: string;
  Human: string;
};

type StudyFilter = {
  Machine: string;
  Human: string;
};

type FacultyFilter = {
  Machine: string;
  Human: string;
};

type WorkflowStageFilter = {
  Machine: string;
  Human: string;
};

type WorkflowAssignmentGroup = {
  Visible: boolean;
  System: string;
  Workflow: string;
  Groups: AssignmentGroup[];
};

type QueuedOperation = {
  System: string;
  Mechanism: string;
  Group1: AssignmentGroup;
  Group2?: AssignmentGroup;
  Op: QueuedOperationType;
  Value1?: string;
  Value2?: string;
  Value3?: string;
  Succeeded: boolean;
};

type AssignmentGroup = {
  Visible: boolean;
  Campus: string[];
  Study: string[];
  Faculty: string[];
  Department: string[];
  Stage: string[];
  System: string;
  Machine: string; // groupid
  Human: string;
  Mechanism: string;
  NoneditableGroupName: string;
  NotificationMode: string;
  NotificationEmail: string;
  _notificationEmail: string;
  OverridesNormalEmail: string;
  Members: AssignmentGroupMember[];
};

type AssignmentGroupMember = {
  Username: string;
  UserFullName: string;
};

export default WorkflowAssignmentGroupEditor;
