import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../data/DataAccess";
import { Logging } from "../../Logging";
import "../../assets/css/ESF_LF/ESF_LF.css";
import { Link } from "react-router-dom";
import IfPermitted from "../IfPermitted";
import dayjs from "dayjs";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<string>;
  AuthedUser: User;
  ViewAsUser: User;
  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
  Navigation: (url: string) => void;
}

interface IState {
  // functional state parts
  Errors: string[];

  // Search Panel
  _SearchFor: string;
}

const StringRegexIdentifier = /^[a-zA-Z0-9]*$/;
const StringRegexName = /^[a-zA-Z' -]*$/;
const StringRegexEmail = /^[a-zA-Z0-9\.-]*@essex\.ac\.uk$/;
const DateRegexESIS = /^[0-9]{2}\/[a-zA-Z]{3}\/[0-9]{4}$/;
const DateRegexHyphen = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
const DateRegexSlash = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
const jsMonths = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

class ESF_LF extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      _SearchFor: "",
      Errors: [],
    };
  }

  public async componentDidMount() {
    window.document.title = "University of Essex - Electronic Student Files";
  }

  GetValidDOB = (term: string): dayjs.Dayjs | undefined => {
    if (DateRegexSlash.test(term)) {
      // dayjs constructor has parameters for format and locale,
      // but either doesn't respect them or has an undocumented gotcha
      // that I'm sure exists on the other end of two days' googling
      // or something equally insufferable

      //  let ret = dayjs();
      //  let splits = term.split("/").map((th) => Number.parseInt(th));
      //  ret = ret
      //    .set("date", splits[0])
      //    .set("month", splits[1] - 1) // good old zero-indexed months
      //    .set("year", splits[2]);
      //  if (ret.isValid()) {
      //    return ret;
      //  }

      // just so; the parameters don't work at all unless you import a
      // specific extra sublibrary. the method does not tell you they don't work.
      // it just blithely goes on about its day as though it respected all your
      // inputs. this industry is driving me to insanity.

      if (dayjs(term, "DD/MM/YYYY", true).isValid()) {
        return dayjs(term, "DD/MM/YYYY", true);
      }
    }

    if (DateRegexHyphen.test(term)) {
      if (dayjs(term, "DD-MM-YYYY", true).isValid()) {
        return dayjs(term, "DD-MM-YYYY", true);
      }
    }

    if (DateRegexESIS.test(term)) {
      // haha, this one doesn't work in strict! Very odd.
      if (dayjs(term).isValid()) {
        return dayjs(term);
      }
    }

    return undefined;
  };
  IsValidStringSearch = (term: string): boolean => {
    return (
      StringRegexEmail.test(term) ||
      StringRegexName.test(term) ||
      StringRegexIdentifier.test(term)
    );
  };

  IsValidSearch = (term: string): boolean => {
    return (
      StringRegexEmail.test(term) ||
      StringRegexName.test(term) ||
      StringRegexIdentifier.test(term) ||
      DateRegexESIS.test(term) ||
      DateRegexHyphen.test(term) ||
      DateRegexSlash.test(term)
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="StructPanels">
          <div className="StructPanelCluster Row Halved">
            <IfPermitted Whitelist={["ESF.Reader"]}>
              <Link
                className="StructPanel"
                to={this.props.GetLink(
                  [
                    { Mode: "Tasklists", Index: 0 },
                    { Mode: "WEBUI_mytasks", Index: 1 },
                  ],
                  [],
                )}
              >
                <div className="PanelBar Title">
                  <div className="Left">My Current Tasks</div>
                  <div className="Breaker"></div>
                  <div className="Right">Workflow</div>
                </div>
                {/* <div className='PanelBar Action'>
            My Current Tasks
          </div> */}
                <div className="PanelBody">
                  See your current workflow tasks.
                </div>
              </Link>
            </IfPermitted>
            <IfPermitted Whitelist={["ESF.Uploader"]}>
              <Link
                className="StructPanel"
                to={this.props.GetLink([{ Mode: "Upload", Index: 0 }], [])}
              >
                <div className="PanelBar Title">
                  <div className="Left">Upload Document to ESF</div>
                  <div className="Breaker"></div>
                  <div className="Right">Upload</div>
                </div>
                {/* <div className='PanelBar Action'>
            Upload Document to ESF
          </div> */}
                <div className="PanelBody">
                  Use this tool to upload a document to a student file.
                </div>
              </Link>
            </IfPermitted>
            <IfPermitted Whitelist={["ESF.Reader"]}>
              <Link
                className="StructPanel"
                to={this.props.GetLink(
                  [
                    { Mode: "Utilities", Index: 0 },
                    { Mode: "ReviewTaskAssignees", Index: 1 },
                  ],
                  [],
                )}
              >
                <div className="PanelBar Title">
                  <div className="Left">ESF Workflow Assignees</div>
                  <div className="Breaker"></div>
                  <div className="Right">Task Assignments</div>
                </div>
                {/* <div className='PanelBar Action'>
            Review workflow task assignees for ESF Forms
          </div> */}
                <div className="PanelBody">
                  Check workflow task assignees (for ESF Collagen Forms) and who
                  gets notification emails
                </div>
              </Link>
            </IfPermitted>
            <IfPermitted Whitelist={["ESF.RegDocUploader"]}>
              <Link
                className="StructPanel"
                to={this.props.GetLink(
                  [{ Mode: "RegistrationUpload", Index: 0 }],
                  [],
                )}
              >
                <div className="PanelBar Title">
                  <div className="Left">Upload Registration Document</div>
                  <div className="Breaker"></div>
                  <div className="Right">Reg Upload</div>
                </div>
                {/* <div className='PanelBar Action'>
            Upload Document to ESF
          </div> */}
                <div className="PanelBody">
                  Use this tool to bulk upload Registration documents to student
                  files.
                </div>
              </Link>
            </IfPermitted>
            <IfPermitted Whitelist={["ESF.Reader"]}>
              <Link
                className="StructPanel"
                to={this.props.GetLink(
                  [
                    { Mode: "Utilities", Index: 0 },
                    { Mode: "LecturerModuleTimetables", Index: 1 },
                  ],
                  [],
                )}
              >
                <div className="PanelBar Title">
                  <div className="Left">Lecturer/Module Timetables</div>
                  <div className="Breaker"></div>
                  <div className="Right">Timetables</div>
                </div>
                {/* <div className='PanelBar Action'>
            Check Lecturer / Module Timetables
          </div> */}
                <div className="PanelBody">
                  Use this tool to check the academic timetable as scoped to any
                  lecturer or module.
                </div>
              </Link>
            </IfPermitted>
            <div className="StructPanelCluster Row Halved">
              <IfPermitted Whitelist={["ESF.Reader"]}>
                <div className="StructPanel">
                  <div className="PanelBar Title">
                    <div className="Left">Search Current/Archived Students</div>
                    <div className="Breaker"></div>
                    <div className="Right">Student Files</div>
                  </div>
                  {/* <div className='PanelBar Action Search'></div> */}
                  <div className="PanelBody">
                    <div id="SearchPanel">
                      <p className="InputRow Wide">
                        <label htmlFor="Searcher">
                          Enter Surname, PRID, login, or reg no.
                        </label>
                      </p>
                      <p className="InputRow Wide">
                        <input
                          className={
                            !this.IsValidSearch(this.state._SearchFor)
                              ? "InvalidSearch"
                              : undefined
                          }
                          type="text"
                          value={this.state._SearchFor}
                          id="Searcher"
                          name="Searcher"
                          onChange={(event) => {
                            const pattern = /^[0-9A-Za-z\/ '\-@\.]*$/g;
                            const inp = event.target.value;
                            this.setState({
                              _SearchFor:
                                inp === ""
                                  ? ""
                                  : pattern.test(inp)
                                    ? inp
                                    : this.state._SearchFor,
                            });
                          }}
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              const sterm =
                                this.state._SearchFor ??
                                "".trim().replaceAll(" ", "_");

                              const dterm = this.GetValidDOB(sterm);

                              if (dterm !== undefined) {
                                this.props.Navigation(
                                  "/Search/CurrentStudentsESFbyDOB/" +
                                    dterm.format("DD-MM-YYYY"),
                                );
                              } else {
                                this.props.Navigation(
                                  "/Search/CurrentStudentsESF/" + sterm,
                                );
                              }
                            }
                          }}
                          onPaste={(e) => {
                            const sterm = e.clipboardData
                              .getData("text")
                              .trim()
                              .replaceAll(" ", "_");

                            if (DateRegexESIS.test(sterm)) {
                              const dterm = this.GetValidDOB(sterm);

                              if (dterm !== undefined) {
                                // Only accept values in format 01/JAN/2021 or 01-01-1999
                                this.props.Navigation(
                                  "/Search/CurrentStudentsESFbyDOB/" +
                                    dterm.format("DD-MM-YYYY"),
                                );
                              }
                            }
                          }}
                        />
                        {this.state._SearchFor === "" ? (
                          <></>
                        ) : this.IsValidSearch(this.state._SearchFor) ? (
                          <></>
                        ) : this.GetValidDOB(this.state._SearchFor) !==
                          undefined ? (
                          <></>
                        ) : (
                          <div className="InvalidSearch">
                            Invalid or incomplete input. This field accepts
                            dates, names, logins, or UoE reference codes (PRID,
                            UCAS No, PG No, etc).
                          </div>
                        )}
                      </p>
                      <p className="SubmitRow Left">
                        {this.IsValidStringSearch(this.state._SearchFor) ? (
                          <Link
                            to={this.props.GetLink(
                              [
                                { Mode: "Search", Index: 0 },
                                {
                                  Mode: "CurrentStudentsESF",
                                  Index: 1,
                                },
                                {
                                  Mode: this.state._SearchFor
                                    .trim()
                                    .replaceAll(" ", "_"),
                                  Index: 2,
                                },
                              ],
                              [],
                            )}
                          >
                            Search Current Students
                          </Link>
                        ) : this.GetValidDOB(this.state._SearchFor) !==
                          undefined ? (
                          <Link
                            className="CurrentSearch"
                            to={this.props.GetLink(
                              [
                                { Mode: "Search", Index: 0 },
                                { Mode: "CurrentStudentsESFbyDOB", Index: 1 },
                                {
                                  Mode: this.GetValidDOB(
                                    this.state._SearchFor,
                                  ).format("DD-MM-YYYY"),
                                  Index: 2,
                                },
                              ],
                              [],
                            )}
                          >
                            Search Current Students
                          </Link>
                        ) : (
                          <span className="CurrentSearch Disabled">
                            Search Current Students
                          </span>
                        )}
                        {this.IsValidStringSearch(this.state._SearchFor) ? (
                          <Link
                            className="ArchiveSearch"
                            to={this.props.GetLink(
                              [
                                { Mode: "Search", Index: 0 },
                                { Mode: "ArchivedStudentsESF", Index: 1 },
                                {
                                  Mode: this.state._SearchFor
                                    .trim()
                                    .replaceAll(" ", "_"),
                                  Index: 2,
                                },
                              ],
                              [],
                            )}
                          >
                            Search Archived Students
                          </Link>
                        ) : (
                          <span className="ArchiveSearch Disabled">
                            Search Archived Students
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </IfPermitted>
              <div className="StructPanelBlock">
                <IfPermitted Whitelist={["ESF.Teams", "ESF.CourseRecordsTeam"]}>
                  <div className="StructPanel Cassette">
                    <div className="PanelBar Title">
                      <div className="Left">Student Progress, PG RE & CRT</div>
                      <div className="Breaker"></div>
                      <div className="Right">ESF</div>
                    </div>
                    <div className="PanelBody"></div>
                    <Link
                      className="PanelBar Action"
                      to={this.props.GetLink(
                        [
                          { Mode: "Tasklists", Index: 0 },
                          { Mode: "WEBUI_myteamtasks", Index: 1 },
                        ],
                        [],
                      )}
                    >
                      My Team's Workflows In Progress
                    </Link>
                    <Link
                      className="PanelBar Action"
                      to={this.props.GetLink(
                        [
                          { Mode: "Tasklists", Index: 0 },
                          { Mode: "WEBUI_myteamtaskscompleted", Index: 1 },
                        ],
                        [],
                      )}
                    >
                      My Team's Workflows Recently Completed
                    </Link>
                    <Link
                      className="PanelBar Action"
                      to={this.props.GetLink(
                        [
                          { Mode: "Tasklists", Index: 0 },
                          { Mode: "WEBUI_allteamtasks", Index: 1 },
                          { Mode: "ALL", Index: 2 },
                        ],
                        [],
                      )}
                    >
                      All ESF Workflows in Progress
                    </Link>
                    <Link
                      className="PanelBar Action"
                      to={this.props.GetLink(
                        [
                          { Mode: "Tasklists", Index: 0 },
                          { Mode: "WEBUI_allteamtaskscompleted", Index: 1 },
                          { Mode: "ALL", Index: 2 },
                        ],
                        [],
                      )}
                    >
                      All ESF Workflows Recently Completed
                    </Link>
                  </div>
                </IfPermitted>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ESF_LF;
