import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../data/DataAccess";
import { Logging } from "../../Logging";
import "../../assets/css/EAF_LF/EAF_LF.css";
import { Link } from "react-router-dom";
import IfPermitted from "../IfPermitted";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<string>;
  AuthedUser: User;
  ViewAsUser: User;

  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
}

interface IState {
  // functional state parts
  Errors: string[];
}

class EAF_LF extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      Errors: [],
    };
  }

  public async componentDidMount() {
    window.document.title = "University of Essex - Electronic Applicant Files";
  }

  render() {
    return (
      <React.Fragment>
        <div className="StructPanels">
          <div className="StructPanelCluster Row Thirded">
            <IfPermitted Whitelist={["UGEAF.Reader"]}>
              <a
                className="StructPanel Recolour_UGEAF_LF"
                href={this.props.GetLink([{ Mode: "UGEAF", Index: 0 }], [])}
              >
                <div className="PanelBar Title">
                  <div className="Left">UG Electronic Applicant Files</div>
                  <div className="Breaker"></div>
                  <div className="Right">EAF</div>
                </div>
                {/* <div className='PanelBar Action'>Undergraduate Electronic Applicant Files</div> */}
                <div className="PanelBody">
                  Use UGEAF to view and modify records related to any
                  undergraduate applicant.
                </div>
              </a>
            </IfPermitted>
            <IfPermitted Whitelist={["PGEAF.Reader"]}>
              <a
                className="StructPanel Recolour_PGEAF_LF"
                href={this.props.GetLink([{ Mode: "PGEAF", Index: 0 }], [])}
              >
                <div className="PanelBar Title">
                  <div className="Left">PG Electronic Applicant Files</div>
                  <div className="Breaker"></div>
                  <div className="Right">EAF</div>
                </div>
                {/* <div className='PanelBar Action'>Postgraduate Electronic Applicant Files</div> */}
                <div className="PanelBody">
                  Use PGEAF to view and modify records related to any
                  postgraduate applicant.
                </div>
              </a>
            </IfPermitted>
            <IfPermitted Whitelist={["Clearing.Reader"]}>
              <a
                className="StructPanel Recolour_Clearing_LF"
                href={this.props.GetLink([{ Mode: "Clearing", Index: 0 }], [])}
              >
                <div className="PanelBar Title">
                  <div className="Left">Clearing Documents</div>
                  <div className="Breaker"></div>
                  <div className="Right">Portal</div>
                </div>
                {/* <div className='PanelBar Action'>Clearing Documents</div> */}
                <div className="PanelBody">
                  Use the Clearing documents area to upload and modify documents
                  related to any applicant going through the Clearing process.
                </div>
              </a>
            </IfPermitted>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EAF_LF;
